enum Permission {
	RolesRead = "rolesRead",
	RolesAdd = "rolesAdd",
	RolesUpdate = "rolesUpdate",
	RolesDelete = "rolesDelete",

	UsersRead = "usersRead",
	UsersAdd = "usersAdd",
	UsersUpdate = "usersUpdate",
	UsersDelete = "usersDelete",

	SuppliersRead = "suppliersRead",
	SuppliersAdd = "suppliersAdd",
	SuppliersUpdate = "suppliersUpdate",
	SuppliersDelete = "suppliersDelete",

	ProductsRead = "productsRead",
	ProductsAdd = "productsAdd",
	ProductsUpdate = "productsUpdate",
	ProductsDelete = "productsDelete",

	CustomersRead = "customersRead",
	CustomersAdd = "customersAdd",
	CustomersUpdate = "customersUpdate",
	CustomersDelete = "customersDelete",

	ContractorsRead = "contractorsRead",
	ContractorsAdd = "contractorsAdd",
	ContractorsUpdate = "contractorsUpdate",
	ContractorsDelete = "contractorsDelete",

	ConstructionYardsRead = "constructionYardsRead",
	ConstructionYardsAdd = "constructionYardsAdd",
	ConstructionYardsUpdate = "constructionYardsUpdate",
	ConstructionYardsDelete = "constructionYardsDelete",

	ProjectsRead = "projectsRead",
	ProjectsAdd = "projectsAdd",
	ProjectsUpdate = "projectsUpdate",
	ProjectsDelete = "projectsDelete",

	BrandsRead = "brandsRead",
	BrandsAdd = "brandsAdd",
	BrandsUpdate = "brandsUpdate",
	BrandsDelete = "brandsDelete",

	ProductTypesRead = "productTypesRead",
	ProductTypesAdd = "productTypesAdd",
	ProductTypesUpdate = "productTypesUpdate",
	ProductTypesDelete = "productTypesDelete",

	ShopsRead = "shopsRead",
	ShopsAdd = "shopsAdd",
	ShopsUpdate = "shopsUpdate",
	ShopsDelete = "shopsDelete",

	ContactTypesRead = "contactTypesRead",
	ContactTypesAdd = "contactTypesAdd",
	ContactTypesUpdate = "contactTypesUpdate",
	ContactTypesDelete = "contactTypesDelete",

	CommunicationTemplatesRead = "communicationTemplatesRead",
	CommunicationTemplatesAdd = "communicationTemplatesAdd",
	CommunicationTemplatesUpdate = "communicationTemplatesUpdate",
	CommunicationTemplatesDelete = "communicationTemplatesDelete",

	DossiersRead = "dossiersRead",
	DossiersAdd = "dossiersAdd",
	DossiersUpdate = "dossiersUpdate",
	DossiersDelete = "dossiersDelete",

	ProcurementView = "procurementView",
	WarehouseView = "warehouseView",
	PlanningView = "planningView",
	InvoicingView = "invoicingView",

	TeamsRead = "teamsRead",
	TeamsAdd = "teamsAdd",
	TeamsUpdate = "teamsUpdate",
	TeamsDelete = "teamsDelete",

	StockRead = "stockRead",
	StockAdd = "stockAdd",

	PrintLabels = "printLabels",

	Settings = "settings",

	SettingsRead = "settingsRead",
	SettingsUpdate = "settingsUpdate",

	Home = "home"
}

export default Permission;