import React from "react";

import { DropDownList, DropDownListChangeEvent, DropDownListProps } from "@progress/kendo-react-dropdowns";

const DropDownListWithEmptyEntry: React.FC<DropDownListProps> = (props: DropDownListProps) => {
	function onChange(event: DropDownListChangeEvent): void {
		if (event.value && !event.value[props.dataItemKey]) {
			event.value = null;
		}
		props.onChange(event);
	}

	// tslint:disable-next-line: no-any
	const defaultItem: any = {};
	defaultItem[props.dataItemKey] = null;
	defaultItem[props.textField] = "";

	return (
		<DropDownList {...props} data={[defaultItem, ...props.data]} onChange={onChange} />
	);
};

export default DropDownListWithEmptyEntry;