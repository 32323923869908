import React from "react";

import style from "./help.module.scss";

const Help: React.FC = () => {
	return (
		<div className="row" style={{marginTop: "100px"}}>
			<div className="col">
					<div className={style.helpVideoContainer}>
						<iframe title="DossierbeheerderModule" className={style.helpVideo} src="https://www.youtube.com/embed/pSGjZMnWuT0" frameBorder="0" />
					</div>
					<div className={style.helpVideoContainer}>
						<iframe title="FacturatieModule" className={style.helpVideo} src="https://www.youtube.com/embed/JxWuolXPwE4" frameBorder="0" />
					</div>
					<div className={style.helpVideoContainer}>
						<iframe title="PlanningModule" className={style.helpVideo} src="https://www.youtube.com/embed/A8nMf07s4sc" frameBorder="0" />
					</div>
			</div>
			<div className="col">
				<div className={style.helpVideoContainer}>
					<iframe title="VerkoperModule" className={style.helpVideo} src="https://www.youtube.com/embed/gs9NB7GnJr0" frameBorder="0" />
				</div>
				<div className={style.helpVideoContainer}>
					<iframe title="PlaatsersModule" className={style.helpVideo} src="https://www.youtube.com/embed/8rOKkaJHc2U" frameBorder="0" />
				</div>
				<div className={style.helpVideoContainer}>
					<iframe title="AankoopModule" className={style.helpVideo} src="https://www.youtube.com/embed/DaBar8YNCM8" frameBorder="0" />
				</div>
			</div>
		</div>
	);
};

export default Help;