import { UserInfo } from "react-adal";

import { authContext } from "../../services/authentication";
import { ICompany } from "../../utils/types/models";
import { IAction } from "../../utils/types/types";

const SET_LOGIN_SUCCESS: string = "SET_LOGIN_SUCCESS";
const SET_LOGIN_ERROR: string = "SET_LOGIN_ERROR";
const SET_LOGOUT_SUCCESS: string = "SET_LOGOUT_SUCCESS";
const SET_CURRENTLANGUAGE: string = "SET_CURRENTLANGUAGE";
const SET_CURRENTCOMPANY: string = "SET_CURRENTCOMPANY";

function setLoginError(errorDesc: string, error: string): IAction {
	return {
		type: SET_LOGIN_ERROR,
		errorDesc,
		error
	};
}

function checkLoggedInUser(): IAction {
	const userInfo: UserInfo = authContext().getCachedUser();
	if (userInfo) {
		return {
			type: SET_LOGIN_SUCCESS,
			userInfo
		};
	} else {
		return {
			type: SET_LOGOUT_SUCCESS
		};
	}
}

function logout(): IAction {
	authContext().logOut();
	return checkLoggedInUser();
}

function setCurrentLanguage(language: string): IAction {
	return {
		type: SET_CURRENTLANGUAGE,
		language
	};
}

function setCurrentCompany(currentCompany: ICompany): IAction {
	return {
		type: SET_CURRENTCOMPANY,
		currentCompany
	};
}

export { checkLoggedInUser, logout, setLoginError, setCurrentLanguage, setCurrentCompany };
