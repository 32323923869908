import React, { useEffect, useState } from "react";

import { addDays } from "@progress/kendo-date-math";
import { SelectionRange } from "@progress/kendo-react-dateinputs";
import { IntlService, useInternationalization } from "@progress/kendo-react-intl";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import sortBy from "lodash/sortBy";
import { useTranslation } from "react-i18next";

import { IDossierTask, IUser } from "../../../../utils/types/models";
import { dateToString, dateToStringDayJs } from "../../../../utils/utils";
import DraggableTask from "./draggableTask";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import updateLocale from "dayjs/plugin/updateLocale";
import style from "./planningView.module.scss";

dayjs.extend(isBetween);
dayjs.extend(updateLocale);

dayjs.updateLocale("be", {});

interface IProps {
	week: SelectionRange;
	companyId: number;
	drag: (draggedTask: IDossierTask, event: React.DragEvent<HTMLDivElement>, draggingFromFirstAssignee: boolean) => void;
	drop: (dropDate: Date, event: React.DragEvent<HTMLDivElement>, assignee1?: IUser) => void;
	cancelTask?: (task: IDossierTask) => void;
	plannedTasks: IDossierTask[];
	teams: IUser[];
}

interface ISortedWeek {
	monday: IDossierTask[];
	tuesday: IDossierTask[];
	wednesday: IDossierTask[];
	thursday: IDossierTask[];
	friday: IDossierTask[];
}

const WeekPlanning: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const intl: IntlService = useInternationalization();
	const [sortedTasks, setSortedTasks] = useState<ISortedWeek>({ monday: [], tuesday: [], wednesday: [], thursday: [], friday: [] });

	function isTaskOnDate(taskStart: string, taskStop: string, date: string, tmpTask: IDossierTask, daysToAdd: number): boolean {
		if (taskStop === "") {
			return dateToString(tmpTask.startDate, intl) === dateToString(addDays(props.week.start, daysToAdd), intl);
		}
		return dayjs(date).isBetween(taskStart, taskStop, "date", "[]");
	}

	useEffect(() => {
		const newSortedTasks: ISortedWeek = { monday: [], tuesday: [], wednesday: [], thursday: [], friday: [] };
		for (const tmpTask of props.plannedTasks) {
			if (isTaskOnDate(dateToStringDayJs(tmpTask.startDate, intl), dateToStringDayJs(tmpTask.stopDate, intl), dateToStringDayJs(props.week.start, intl), tmpTask, 0)) {
				newSortedTasks.monday.push(tmpTask);
			}
			if (
				isTaskOnDate(dateToStringDayJs(tmpTask.startDate, intl), dateToStringDayJs(tmpTask.stopDate, intl), dateToStringDayJs(dayjs(props.week.start).add(1, "day").toDate(), intl), tmpTask, 1)
			) {
				newSortedTasks.tuesday.push(tmpTask);
			}
			if (
				isTaskOnDate(dateToStringDayJs(tmpTask.startDate, intl), dateToStringDayJs(tmpTask.stopDate, intl), dateToStringDayJs(dayjs(props.week.start).add(2, "day").toDate(), intl), tmpTask, 2)
			) {
				newSortedTasks.wednesday.push(tmpTask);
			}
			if (
				isTaskOnDate(dateToStringDayJs(tmpTask.startDate, intl), dateToStringDayJs(tmpTask.stopDate, intl), dateToStringDayJs(dayjs(props.week.start).add(3, "day").toDate(), intl), tmpTask, 3)
			) {
				newSortedTasks.thursday.push(tmpTask);
			}
			if (
				isTaskOnDate(dateToStringDayJs(tmpTask.startDate, intl), dateToStringDayJs(tmpTask.stopDate, intl), dateToStringDayJs(dayjs(props.week.start).add(4, "day").toDate(), intl), tmpTask, 4)
			) {
				newSortedTasks.friday.push(tmpTask);
			}
		}
		setSortedTasks(newSortedTasks);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.plannedTasks, intl, props.week.start]);

	return (
		<div className={"row " + style.weekPlanning}>
			{map(Object.keys(sortedTasks), (day: keyof ISortedWeek, i: number) => (
				<div key={day} className="col">
					<div style={{ textAlign: "center" }}>{t(day) + "  " + dateToString(addDays(props.week.start, i), intl)}</div>
					<div className={style.weekday}>
						{map(orderBy(props.teams, ["sequenceNumber"], ["asc"]), (person: IUser) => {
							return (
								<div
									key={`${day}_person_${person.id}`}
									id={`${day}_person_${person.id}`}
									className={style.installerContainer}
									onDrop={(event: React.DragEvent<HTMLDivElement>) => props.drop(addDays(props.week.start, i), event, person)}
									onDragOver={(event: React.DragEvent<HTMLDivElement>) => event.preventDefault()}
								>
									<fieldset className={style.installer}>
										<legend className={style.legendInstaller}>{person.fullName}</legend>
										{map(sortBy(sortedTasks[day], "startTime"), (task: IDossierTask) => {
											if (task.assignee1Id === person.id || task.assignee2Id === person.id) {
												return (
													<DraggableTask
														key={task.id}
														task={task}
														weekCard={true}
														drag={(draggedTask: IDossierTask, event: React.DragEvent<HTMLDivElement>) => props.drag(draggedTask, event, task.assignee1Id === person.id)}
														cancelTask={props.cancelTask}
													/>
												);
											}
											return "";
										})}
									</fieldset>
								</div>
							);
						})}
					</div>
				</div>
			))}
		</div>
	);
};

export default WeekPlanning;
