import React, { useEffect, useReducer, useState } from "react";

import { DataResult, process, State } from "@progress/kendo-data-query";
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent, GridExpandChangeEvent, GridRowClickEvent, GridRowProps, GridToolbar } from "@progress/kendo-react-grid";
import { GridFilterChangeEvent, GridRowDoubleClickEvent } from "@progress/kendo-react-grid/dist/npm/interfaces/events";
import { IntlService, useInternationalization } from "@progress/kendo-react-intl";
import FileDownload from "js-file-download";
import each from "lodash/each";
import find from "lodash/find";
import first from "lodash/first";
import get from "lodash/get";
import includes from "lodash/includes";
import remove from "lodash/remove";
import some from "lodash/some";
import sumBy from "lodash/sumBy";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import callApi from "../../../../services/api/callApi";
import Endpoint from "../../../../services/api/endpoint";
import { hasPermission } from "../../../../services/authentication";
import { printLabels } from "../../../../services/labelPrinting";
import { getInitialState, initialProcurementState } from "../../../../state";
import { hideLoader, showLoader } from "../../../../state/actions/loaderActions";
import { derender, render } from "../../../../state/actions/renderActions";
import { articleDeliveryReducer, procurementReducer } from "../../../../state/reducers";
import { IApplicationState } from "../../../../store";
import { ArticleGroup, Permission } from "../../../../utils/enums";
import { IArticle, IArticleDelivery, IProcurementItem, IRemark, IUser } from "../../../../utils/types/models";
import { getDeliveryOrPickingsRepresentation, isArticleForPurchaseOrder, isNullOrEmpty, newKey } from "../../../../utils/utils";
import Confirm from "../../../global/confirm";
import { getGridCommandColumn } from "../../../global/gridpanel";
import { weekPickerFilterCell } from "../../../global/gridpanel/customCells/filterCells";
import { customCell, dateCell, weekCell } from "../../../global/gridpanel/customCells/gridCells";
import { IRecordCommand } from "../../../global/gridpanel/customCells/gridCells/commandCell";
import { IRoutedTabProps } from "../../../global/routertabpanel";
import getArticleSubRecords from "../dossiermanagement/editor/articles/articleSubRecords";
import RemarkEditor from "./remarkEditor";

import "../../../../assets/masterDetail.scss";
import ToolbarFilter from "../../../global/toolbarFilter";

const Procurement: React.FC<IRoutedTabProps> = () => {
	const { t } = useTranslation();
	const intl: IntlService = useInternationalization();
	const [gridState, setGridState] = useState<State>({
		skip: 0,
		take: 100,
		sort: [
			{
				field: "lastOrderDate",
				dir: "desc"
			}
		]
	});
	const [stateTimeout, setStateTimeout] = useState<NodeJS.Timeout>(null);
	const [displayFilter, setDisplayFilter] = useState(gridState.filter);
	const [articleGridState, setArticleGridState] = useState<State>({
		filter: {
			logic: "or",
			filters: [
				{ field: "group", operator: "eq", value: ArticleGroup.Appliance },
				{ field: "group", operator: "eq", value: ArticleGroup.Plumbing },
				{ field: "group", operator: "eq", value: ArticleGroup.Accessory }
			]
		}
	});
	const [selectedItem, setSelectedItem] = useState<{ dossierId: number; supplierId: number }>(null);
	const [expandedArticleIds, setExpandedArticleIds] = useState<number[]>([]);
	const [tradeplaceFilename, setTradeplaceFilename] = useState("");
	const [pokSearch, setPokSearch] = useState("");
	const [state, dispatch] = useReducer(procurementReducer, initialProcurementState);
	const [articleDeliveryState, articleDeliveryDispatch] = useReducer(articleDeliveryReducer, getInitialState<IArticleDelivery>());
	const currentUser: IUser = useSelector((applicationState: IApplicationState) => applicationState.authenticationState.currentUser);
	const reduxDispatch: Dispatch = useDispatch();

	useEffect(() => {
		if (!state.isUpdating && (state.updated || state.tradeplaceFile)) {
			callApi(dispatch, Endpoint.ProcurementList, "POST", { companyId: currentUser.currentCompanyId }, gridState);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.isUpdating, state.updated, state.tradeplaceFile]);

	useEffect(() => {
		callApi(dispatch, Endpoint.ProcurementList, "POST", { companyId: currentUser.currentCompanyId, pokFilter: isNullOrEmpty(pokSearch) ? null : pokSearch }, gridState);
	}, [gridState, currentUser, pokSearch]);

	useEffect(() => {
		if (state.isListLoading || state.isUpdating || articleDeliveryState.isAdding) {
			reduxDispatch(showLoader());
		} else if (!state.isListLoading && !state.isUpdating && !articleDeliveryState.isAdding) {
			reduxDispatch(hideLoader());
		}
	}, [state.isListLoading, state.isUpdating, articleDeliveryState.isAdding, reduxDispatch]);

	useEffect(() => {
		if (state.tradeplaceFile) {
			FileDownload(state.tradeplaceFile, tradeplaceFilename + ".txt");
			setTradeplaceFilename("");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.tradeplaceFile]);

	function onRowClick(event: GridRowClickEvent): void {
		setSelectedItem({
			dossierId: event.dataItem.dossierId,
			supplierId: event.dataItem.supplierId
		});
	}

	function rowRender(row: React.ReactElement<HTMLTableRowElement>, rowProps: GridRowProps): React.ReactNode {
		if (selectedItem) {
			const htmlRowProps: HTMLTableRowElement = { ...row.props };
			const isSelected: boolean = rowProps.dataItem.dossierId === selectedItem.dossierId && rowProps.dataItem.supplierId === selectedItem.supplierId;
			if (isSelected && !includes(htmlRowProps.className, "k-state-selected")) {
				htmlRowProps.className += " k-state-selected";
			} else if (!isSelected && includes(htmlRowProps.className, "k-state-selected")) {
				htmlRowProps.className = htmlRowProps.className.replace("k-state-selected", "");
			}
			return React.cloneElement(row, htmlRowProps, row.props.children);
		}
		return React.cloneElement(row, row.props, row.props.children);
	}

	function statusCell(gridCellprops: GridCellProps): JSX.Element {
		let total: number = 0;
		let subTotal: number = 0;
		each(gridCellprops.dataItem.articles, (article: IArticle) => {
			if (isArticleForPurchaseOrder(article)) {
				total += article.count;
				if (get(article, gridCellprops.field)) {
					subTotal += article.count;
				}
			}
		});
		return <>{subTotal + "/" + total}</>;
	}

	function procurementItemDeliveryCell(gridCellprops: GridCellProps): JSX.Element {
		let total: number = 0;
		let subTotal: number = 0;
		each(gridCellprops.dataItem.articles, (article: IArticle) => {
			if (isArticleForPurchaseOrder(article)) {
				total += article.count;
				subTotal += sumBy(article.deliveries, "count");
			}
		});
		return <>{subTotal + "/" + total}</>;
	}

	function articleDeliveryCell(gridCellprops: GridCellProps): JSX.Element {
		return <>{getDeliveryOrPickingsRepresentation(gridCellprops.dataItem.count, gridCellprops.dataItem.deliveries, intl)}</>;
	}

	function expandChange(event: GridExpandChangeEvent): void {
		setExpandedArticleIds((previousIds: number[]) => {
			const id: number = event.dataItem.id;
			if (event.value && !includes(previousIds, id)) {
				return [...previousIds, id];
			} else if (!event.value && includes(previousIds, id)) {
				return remove(previousIds, id);
			}
		});
	}

	function doPurchaseOrderAction(procurementItem: IProcurementItem, endpoint: Endpoint): void {
		callApi(dispatch, endpoint, "GET", { dossierId: procurementItem.dossierId, supplierId: procurementItem.supplierId });
	}

	function articleFullyDelivered(article: IArticle): boolean {
		return article.count === sumBy(article.deliveries, "count");
	}

	function refreshGrid(): void {
		callApi(dispatch, Endpoint.ProcurementList, "POST", { companyId: currentUser.currentCompanyId, pokFilter: isNullOrEmpty(pokSearch) ? null : pokSearch }, gridState);
	}

	const dossierCommands: IRecordCommand<IProcurementItem>[] = [
		{
			tooltip: t("edit"),
			iconClass: "las la-pencil-alt",
			recordAction: (dataItem: IProcurementItem) => {
				editDossier(dataItem.dossierId);
			}
		},
		{
			tooltip: t("addRemark"),
			iconClass: "las la-file-alt",
			recordAction: (dataItem: IProcurementItem) => {
				const key: string = newKey("Remark_Editor");
				reduxDispatch(
					render(key, RemarkEditor, {
						dossierId: dataItem.dossierId,
						articles: dataItem.articles,
						close: (remark: IRemark) => {
							if (remark) {
								callApi(dispatch, Endpoint.ProcurementList, "POST", { companyId: currentUser.currentCompanyId }, gridState);
							}
							reduxDispatch(derender(key));
						}
					})
				);
			}
		}
	];

	function editDossier(id: number): void {
		window.open("#" + "/work/dossiermanagement/:id".replace(":id", id.toString()), "_blank");
	}

	function getArticleGridContent(): DataResult {
		if (selectedItem) {
			const selectedProcurementItem: IProcurementItem = find(state.list, { dossierId: selectedItem.dossierId, supplierId: selectedItem.supplierId });
			if (selectedProcurementItem) {
				const articles: DataResult = process(selectedProcurementItem.articles, articleGridState);
				each(articles.data, (article: IArticle) => {
					if (includes(expandedArticleIds, article.id)) {
						// @ts-ignore
						article.expanded = true;
					}
				});

				return articles;
			}
		}
		return null;
	}

	const procurementCommands: IRecordCommand<IProcurementItem>[] = [
		{
			iconClass: "las la-truck",
			tooltip: t("createPurchaseOrders"),
			recordAction: (dataItem: IProcurementItem) => doPurchaseOrderAction(dataItem, Endpoint.ProcurementCreatePurchaseOrders),
			showCommand: (dataItem: IProcurementItem) => some(dataItem.articles, (article: IArticle) => isArticleForPurchaseOrder(article) && isNullOrEmpty(article.pokNumber))
		},
		{
			iconClass: "las la-envelope-open-text",
			tooltip: t("orderViaEMail"),
			recordAction: (dataItem: IProcurementItem) => doPurchaseOrderAction(dataItem, Endpoint.ProcurementOrderPurchaseOrdersViaMail),
			showCommand: (dataItem: IProcurementItem) => some(dataItem.articles, (article: IArticle) => isArticleForPurchaseOrder(article) && !isNullOrEmpty(article.pokNumber) && !article.orderDate)
		},
		{
			iconClass: "las la-share",
			tooltip: t("resentOrderEmail"),
			recordAction: (dataItem: IProcurementItem) => doPurchaseOrderAction(dataItem, Endpoint.ProcurementResentPurchaseOrderMail),
			showCommand: (dataItem: IProcurementItem) => some(dataItem.articles, (article: IArticle) => isArticleForPurchaseOrder(article) && !isNullOrEmpty(article.pokNumber) && article.orderDate)
		},
		{
			iconClass: "las la-file-download",
			tooltip: t("downloadTradeplaceFile"),
			recordAction: (dataItem: IProcurementItem) => {
				setTradeplaceFilename(dataItem.dossierNumber + "_" + dataItem.supplierName);
				doPurchaseOrderAction(dataItem, Endpoint.ProcurementOrderPurchaseOrdersViaTradePlace);
			},
			showCommand: (dataItem: IProcurementItem) => some(dataItem.articles, (article: IArticle) => isArticleForPurchaseOrder(article) && !isNullOrEmpty(article.pokNumber) && !article.orderDate)
		},
		{
			iconClass: "las la-tasks",
			tooltip: t("setPurchaseOrdersChecked"),
			recordAction: (dataItem: IProcurementItem) => doPurchaseOrderAction(dataItem, Endpoint.ProcurementSetPurchaseOrdersChecked),
			showCommand: (dataItem: IProcurementItem) => {
				return some(dataItem.articles, (article: IArticle) => {
					return isArticleForPurchaseOrder(article) && !isNullOrEmpty(article.pokNumber) && article.orderDate && articleFullyDelivered(article) && !article.checkDate;
				});
			}
		}
	];

	const articleCommands: IRecordCommand<IArticle>[] = [
		{
			iconClass: "las la-truck-loading",
			tooltip: t("delivered"),
			recordAction: (dataItem: IArticle) => {
				const confirmKey: string = newKey("confirmDelivery");
				reduxDispatch(
					render(confirmKey, Confirm, {
						title: t("confirmDeliveryTitle"),
						children: t("confirmDelivery"),
						onConfirm: () => {
							const articleDelivery: IArticleDelivery = {
								id: 0,
								articleId: dataItem.id,
								count: 1
							};
							const urlParameters: Record<string, number> = { dossierId: dataItem.dossierId, articleId: dataItem.id };
							callApi(
								articleDeliveryDispatch,
								Endpoint.ArticleDeliveries,
								"POST",
								urlParameters,
								articleDelivery,
								null,
								(success: boolean) => {
									if (success) {
										reduxDispatch(derender(confirmKey));
										refreshGrid();
									}
								},
								false
							);
						},
						onDecline: () => reduxDispatch(derender(confirmKey))
					})
				);
			},
			showCommand: (dataItem: IArticle) => {
				return dataItem.count > sumBy(dataItem.deliveries, "count");
			}
		}
	];

	if (hasPermission(Permission.PrintLabels)) {
		procurementCommands.push({
			iconClass: "las la-print",
			tooltip: t("printLabels"),
			recordAction: (dataItem: IProcurementItem) => {
				printLabels(first(dataItem.articles).dossier, t, ...dataItem.articles);
			}
		});
		articleCommands.push({
			iconClass: "las la-print",
			tooltip: t("printLabels"),
			recordAction: (dataItem: IArticle) => {
				printLabels(dataItem.dossier, t, dataItem);
			}
		});
	}

	function deleteArticleDelivery(confirmKey: string, subArticle: IArticleDelivery): void {

		callApi(
			articleDeliveryDispatch,
			Endpoint.ArticleDeliveriesDelete,
			"DELETE",
			{ id: subArticle.id }
		);

		reduxDispatch(derender(confirmKey));
		refreshGrid();
	}

	return (
		<div className="d-flex flex-column">
			<Grid
				className="noTopBorder masterGrid"
				total={state.totalCount}
				{...gridState}
				onDataStateChange={(event: GridDataStateChangeEvent) => setGridState(event.data)}
				onRowClick={onRowClick}
				onRowDoubleClick={(event: GridRowDoubleClickEvent) => editDossier(event.dataItem.dossierId)}
				rowRender={rowRender}
				data={state.list}
				filter={displayFilter}
				filterable
				sortable
				pageable={{ pageSizes: [10, 25, 50, 100] }}
				scrollable="scrollable"
				onFilterChange={(event: GridFilterChangeEvent) => {
					clearTimeout(stateTimeout);
					setStateTimeout(
						setTimeout(
							() =>
								setGridState((oldState: State) => {
									return { ...oldState, filter: event.filter };
								}),
							500
						)
					);
					setDisplayFilter(event.filter);
				}}
			>
				<GridToolbar>
					<div className="toolbarButtonContainer d-flex width-100 align-items-center">
						<ToolbarFilter placeholder={t("searchByPok")} onSetFilter={setPokSearch} />
						<div className="flex-grow-1" />
						<i className="refreshButton las la-sync" onClick={refreshGrid} />
					</div>
				</GridToolbar>
				{getGridCommandColumn(t("actions"), dossierCommands)}
				<GridColumn field="dossierNumber" title={t("dossierNumber")} />
				<GridColumn field="supplierName" title={t("supplier")} />
				<GridColumn field="deliveryWeek" title={t("deliveryWeek")} filterCell={weekPickerFilterCell()} cell={customCell(weekCell())} />
				<GridColumn field="firstOrderDate" title={t("firstOrderDate")} cell={customCell(dateCell())} filter="date" />
				<GridColumn field="orderDate" title={t("orderDate")} cell={customCell(statusCell)} filterable={false} sortable={false} />
				<GridColumn field="deliverDate" title={t("deliverDate")} cell={customCell(procurementItemDeliveryCell)} filterable={false} sortable={false} />
				<GridColumn field="checkDate" title={t("checkDate")} cell={customCell(statusCell)} filterable={false} sortable={false} />
				{getGridCommandColumn(t("actions"), procurementCommands)}
			</Grid>
			<div className="detailGrid d-flex flex-column">
				<div className="header">{t("articles")}</div>
				<Grid
					onDataStateChange={(event: GridDataStateChangeEvent) => setArticleGridState(event.dataState)}
					data={getArticleGridContent()}
					{...articleGridState}
					sortable
					className="flex-grow-1"
					style={{ overflowY: "auto" }}
					onExpandChange={expandChange}
					expandField="expanded"
					detail={getArticleSubRecords(true, false, false, deleteArticleDelivery)}
				>
					<GridColumn field="description" title={t("product")} />
					<GridColumn field="count" title={t("count")} />
					<GridColumn field="pokNumber" title={t("pokNumber")} />
					<GridColumn field="orderDate" title={t("orderDate")} cell={customCell(dateCell())} />
					<GridColumn field="deliverDate" title={t("deliverDate")} cell={customCell(articleDeliveryCell)} />
					<GridColumn field="checkDate" title={t("checkDate")} cell={customCell(dateCell())} />
					{getGridCommandColumn<IArticle>(t("actions"), articleCommands)}
				</Grid>
			</div>
		</div>
	);
};

export default Procurement;
