import React from "react";
import ReactDOM from "react-dom";

import Axios, { AxiosResponse } from "axios";
import { runWithAdal } from "react-adal";
import { Provider } from "react-redux";

import App from "./App";
import { authContext, setAdCredentials } from "./services/authentication";
import store from "./store";

import "./index.scss";
import "./services/translation/i18n";

Axios.get("/api/environment").then((result: AxiosResponse) => {
	setAdCredentials(result.data.tenantId, result.data.clientId);
	runWithAdal(authContext(), () => {
		ReactDOM.render((
			<Provider store={store}>
				<App />
			</Provider>
		), document.getElementById("root"));
	}, true);
});
