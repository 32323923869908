import React from "react";

import { useTranslation } from "react-i18next";

import { Permission } from "../../../utils/enums";
import RoutedTabPanel from "../../global/routertabpanel";
import CommunicationTemplates from "./communicationtemplates";
import ContactTypes from "./contacttypes";

import content from "../../../assets/communication.png";

const Communication: React.FC = () => {
	const { t } = useTranslation();

	return (
		<RoutedTabPanel title={t("communication")} image={content}>
			<ContactTypes reactKey="contacttypes" label={t("contactTypes")} url="/contacttypes" tileIcon="las la-address-book" permissions={[Permission.ContactTypesRead]} />
			<CommunicationTemplates
				reactKey="communcationtemlates"
				label={t("communicationTemplates")}
				url="/communicationtemplates"
				tileIcon="las la-satellite-dish"
				tileLabel={t("communicationTemplatesSplitted")}
				permissions={[Permission.CommunicationTemplatesRead]}
			/>
		</RoutedTabPanel>
	);
};

export default Communication;