import remove from "lodash/remove";
import some from "lodash/some";
import values from "lodash/values";

import { DepositType } from "../../../../../utils/enums";
import { IDossier, IStockLine, IUser } from "../../../../../utils/types/models";
import { IDictionary } from "../../../../../utils/types/types";
import { DossierEditorAction } from "./dossierEditorActions";

export interface IState {
	currentStep: number;
	dossier: IDossier;
	dataChanged: boolean;
	sectionChangedStatus: IDictionary<boolean>;
	sectionValidStatus: IDictionary<boolean>;
	stockChanges: IStockLine[];
}

export function getInitialDossierState(id: string, currentUser: IUser): IState {
	return {
		currentStep: 0,
		dossier: {
			id: !id ? 0 : parseInt(id, 0),
			createdDate: new Date(),
			updatedDate: new Date(),
			dossierNumber: "",
			sellingDate: new Date(),
			creationDate: new Date(),
			customerId: 0,
			customer: null,
			constructionYardId: 0,
			constructionYard: null,
			dossierManagerId: currentUser.id,
			dossierManager: currentUser,
			salesRepId: 0,
			salesRep: null,
			vatType: null,
			binNumber: "",
			checkInAtWork: "",
			material: "",
			worktop: "",
			unit: "",
			cupboards: 0,
			description: "",
			parking: "",
			floor: "",
			isLiftNeeded: "",
			hasSimpleAccess: "",
			fixedDeliveryDate: false,
			complete: false,
			readyForPicking: false,
			active: true,
			totalArticleExclVat: 0,
			totalArticleInclVat: 0,
			totalArticleVat: 0,
			documents: [],
			articles: [],
			contacts: [],
			remarks: [],
			communications: [],
			depositType: DepositType.None,
			depositAmountExclVat: 0,
			depositVat: null,
			depositInvoiced: false,
			depositPayed: false,
			amountAfterInstallation: 0,
			amountAfterInstallationPayed: false,
			amountAtDeliveryPayed: false,
			amountAfterInstallationInvoiced: false,
			amountAtDeliveryInvoiced: false,
			amountAtDelivery: 0,
			scanLocation: "",
			tasks: [],
			serviceItems: []
		},
		dataChanged: false,
		sectionChangedStatus: {
			general: false,
			documents: false,
			articles: false,
			contacts: false,
			remarks: false,
			invoicing: false,
			planning: false,
			aftersalce: false
		},
		sectionValidStatus: {
			general: true
		},
		stockChanges: []
	};
}

export function reducer(state: IState, action: DossierEditorAction): IState {
	switch (action.type) {
		case "dossier":
			return {
				...state,
				dossier: action.dossier
			};
		case "currentStep":
			return {
				...state,
				currentStep: action.current
			};
		case "sectionChanged":
			const newState: IState = {
				...state,
				sectionChangedStatus: {
					...state.sectionChangedStatus,
					[action.section]: action.changed
				}
			};
			newState.dataChanged = some(values(newState.sectionChangedStatus));
			return newState;
		case "sectionValid":
			return {
				...state,
				sectionValidStatus: {
					...state.sectionValidStatus,
					[action.section]: action.valid
				}
			};
		case "addStockChange":
			const newStockChangesAdd: IStockLine[] = [...state.stockChanges];
			newStockChangesAdd.push(action.stockLine);
			return {
				...state,
				stockChanges: newStockChangesAdd
			};
		case "removeStockChange":
			const newStockChangesRemove: IStockLine[] = [...state.stockChanges];
			remove(newStockChangesRemove, action.stockLine);
			return {
				...state,
				stockChanges: newStockChangesRemove
			};
	}
}
