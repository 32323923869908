import React, { useEffect, useReducer, useRef, useState } from "react";

import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Endpoint from "../../../../services/api/endpoint";
import { getInitialState } from "../../../../state";
import { productTypeReducer } from "../../../../state/reducers";
import { IApplicationState } from "../../../../store";
import { handleChange } from "../../../../utils/editorUtils";
import { IBrand, IProductType, IUser } from "../../../../utils/types/models";
import { isNullOrEmpty } from "../../../../utils/utils";
import EntityEditor, { IEditorProps, IEditScreenProps, YesNoSwitch } from "../../../global/editor";

const ProductTypeEditor: React.FC<IEditorProps<IProductType>> = (props: IEditorProps<IBrand>) => {
	const { t } = useTranslation();
	const [state, dispatch] = useReducer(productTypeReducer, getInitialState<IProductType>());
	const currentUser: IUser = useSelector((reduxState: IApplicationState) => reduxState.authenticationState.currentUser);
	const initialProductType: IProductType = {
		id: (props as IEditScreenProps<IProductType>).recordId || 0,
		name: "",
		active: true,
		companyId: currentUser.currentCompanyId
	};
	const [productType, setProductType] = useState<IProductType>(initialProductType);
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const firstField: React.MutableRefObject<Input> = useRef();

	useEffect(() => {
		if (state.entity) {
			setProductType(state.entity);
		}
	}, [state.entity]);

	function onChange(event: React.ChangeEvent<HTMLInputElement> | SwitchChangeEvent | ComboBoxChangeEvent): void {
		setProductType(handleChange(productType, event));
		setDataChanged(true);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(productType.name)) {
			messages.push(t("fill_in_required_field", { field: t("name").toLowerCase() }));
		}
		return messages;
	}

	const readonly: boolean = (props as IEditScreenProps<IProductType>).readonly;

	return (
		<EntityEditor
			width="70%"
			record={productType}
			endpoint={Endpoint.ProductTypes}
			entityState={state}
			dispatch={dispatch}
			dataChanged={dataChanged}
			entityType={t("type")}
			readonly={readonly}
			recordName={productType.name}
			close={props.close}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<div className="k-form">
				<label className="k-form-field">
					<span>{t("name")} *</span>
					<Input name="name" ref={firstField} className="full-width-field" value={productType.name} onChange={onChange} required disabled={readonly} />
				</label>
				{!props.hideActive && (
					<div className="k-form-field">
						<span>{t("active")}</span>
						<YesNoSwitch name="active" checked={productType.active} onChange={onChange} disabled={readonly} />
					</div>
				)}
			</div>
		</EntityEditor>
	);
};

export default ProductTypeEditor;
