/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useRef, useState } from "react";

import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import every from "lodash/every";
import find from "lodash/find";
import first from "lodash/first";
import { useTranslation } from "react-i18next";

import callApi from "../../../../services/api/callApi";
import Endpoint from "../../../../services/api/endpoint";
import { ILanguageConfiguration, languages } from "../../../../services/translation/languages";
import { getInitialState, getInitialSystemState, initalUserState } from "../../../../state";
import { companyReducer, roleReducer, userReducer } from "../../../../state/reducers";
import { handleChange } from "../../../../utils/editorUtils";
import ILanguageItem from "../../../../utils/types/languageitem";
import { ICompany, IRole, IUser, IUserCompany, IUserRole } from "../../../../utils/types/models";
import { emailRegex, isNullOrEmpty } from "../../../../utils/utils";
import EntityEditor, { IEditorProps, IEditScreenProps, TranslatedSwitch, YesNoSwitch } from "../../../global/editor";
import TabPanel, { Tab } from "../../../global/tabpanel";

import editorStyles from "../../../../assets/editor.module.scss";

const UserEditor: React.FC<IEditorProps<IUser>> = (props: IEditorProps<IUser>) => {
	const { t } = useTranslation();
	const languageItems: ILanguageItem[] = languages.map((languageItem: ILanguageConfiguration) => {
		return {
			code: languageItem.code,
			translationKey: languageItem.translationKey,
			description: t(languageItem.translationKey)
		};
	});
	const [userState, userDispatch] = useReducer(userReducer, initalUserState);
	const [roleState, roleDispatch] = useReducer(roleReducer, getInitialState<IRole>());
	const [companyState, companyDispatch] = useReducer(companyReducer, getInitialSystemState<ICompany>());
	const initalUser: IUser = {
		id: (props as IEditScreenProps<IUser>).recordId || 0,
		firstName: "",
		lastName: "",
		fullName: "",
		email: "",
		language: "nl",
		active: true,
		installer: false,
		leadInstaller: false,
		currentCompanyId: null,
		userRoles: [],
		userCompanies: []
	};
	const [user, setUser] = useState<IUser>(initalUser);
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const firstField: React.MutableRefObject<Input> = useRef();

	useEffect(() => {
		callApi(roleDispatch, Endpoint.Roles, "GET");
		callApi(companyDispatch, Endpoint.Companies, "GET");
	}, []);

	useEffect(() => {
		if (userState.entity) {
			setUser(userState.entity);
		}
	}, [userState.entity]);

	function close(record?: IUser): void {
		props.close(record);
	}

	function onChange(event: React.ChangeEvent<HTMLInputElement> | SwitchChangeEvent): void {
		setUser(handleChange(user, event));
		setDataChanged(true);
	}

	function onLanguageChange(event: DropDownListChangeEvent): void {
		const userRecord: IUser = { ...user };

		if (event.target.value) {
			userRecord.language = event.target.value.code;
			setUser(userRecord);
			setDataChanged(true);
		}
	}

	function onChangeAllRoles(event: SwitchChangeEvent): void {
		const userRecord: IUser = { ...user };
		userRecord.userRoles = [];
		if (event.value) {
			roleState.entities.forEach((role: IRole) => {
				userRecord.userRoles.push({ roleId: role.id, userId: userRecord.id });
			});
		}
		setDataChanged(true);
		setUser(userRecord);
	}

	function onChangeRole(event: SwitchChangeEvent): void {
		const userRecord: IUser = { ...user };
		const roleId: number = parseInt(event.target.name, 0);
		if (event.value) {
			userRecord.userRoles.push({ roleId, userId: userRecord.id });
			setDataChanged(true);
		} else {
			const matchedRole: IUserRole = userRecord.userRoles.find((userRole: IUserRole) => userRole.roleId === roleId);
			if (matchedRole) {
				userRecord.userRoles.splice(userRecord.userRoles.indexOf(matchedRole), 1);
				setDataChanged(true);
			}
		}
		setUser(userRecord);
	}

	function onChangeAllCompanies(event: SwitchChangeEvent): void {
		const userRecord: IUser = { ...user };
		userRecord.userCompanies = [];
		if (event.value) {
			companyState.entities.forEach((company: ICompany) => {
				userRecord.userCompanies.push({ companyId: company.id, userId: userRecord.id });
			});
		}
		setDataChanged(true);
		setUser(userRecord);
	}

	function onChangeCompany(event: SwitchChangeEvent): void {
		const userRecord: IUser = { ...user };
		const companyId: number = parseInt(event.target.name, 0);
		if (event.value) {
			userRecord.userCompanies.push({ companyId, userId: userRecord.id });
			setDataChanged(true);
		} else {
			const matchedCompany: IUserCompany = userRecord.userCompanies.find((userRole: IUserCompany) => userRole.companyId === companyId);
			if (matchedCompany) {
				userRecord.userCompanies.splice(userRecord.userCompanies.indexOf(matchedCompany), 1);
				setDataChanged(true);
			}
		}
		setUser(userRecord);
	}

	function allEntitiesSelected<S, T>(entities: S[], userEntities: T[], match: (entity: S, userEntity: T) => boolean): boolean {
		if (userEntities && entities.length > 0) {
			let hasAllRoles: boolean = true;
			entities.forEach((entity: S): void => {
				const matchedCompany: T = userEntities.find((userEntity: T) => match(entity, userEntity));
				if (!matchedCompany) {
					hasAllRoles = false;
					return;
				}
			});

			return hasAllRoles;
		}
		return false;
	}

	function hasEntity<T>(entities: T[], match: (entity: T) => boolean): boolean {
		if (entities) {
			const matchedRole: T = entities.find(match);
			return matchedRole ? true : false;
		}
		return false;
	}

	function allRolesSelected(): boolean {
		return allEntitiesSelected<IRole, IUserRole>(roleState.entities, user.userRoles, (role: IRole, roleCompany: IUserRole) => roleCompany.roleId === role.id);
	}

	function hasRole(roleId: number): boolean {
		return hasEntity<IUserRole>(user.userRoles, (userRole: IUserRole) => userRole.roleId === roleId);
	}

	function allCompaniesSelected(): boolean {
		return allEntitiesSelected<ICompany, IUserCompany>(companyState.entities, user.userCompanies, (company: ICompany, userCompany: IUserCompany) => userCompany.companyId === company.id);
	}

	function hasCompany(companyId: number): boolean {
		return hasEntity<IUserCompany>(user.userCompanies, (userRole: IUserCompany) => userRole.companyId === companyId);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(user.firstName)) {
			messages.push(t("fill_in_required_field", { field: t("firstName").toLowerCase() }));
		}
		if (isNullOrEmpty(user.lastName)) {
			messages.push(t("fill_in_required_field", { field: t("firstName").toLowerCase() }));
		}
		if (isNullOrEmpty(user.email) || !user.email.match(emailRegex)) {
			messages.push(t("fill_in_required_field", { field: t("email").toLowerCase() }));
		}
		if (isNullOrEmpty(user.language)) {
			messages.push(t("fill_in_required_field", { field: t("language").toLowerCase() }));
		}
		if (!user.userRoles || user.userRoles.length <= 0) {
			messages.push(t("select_at_least_one", { entity: t("role").toLowerCase() }));
		}
		if (!user.userCompanies || user.userCompanies.length <= 0) {
			messages.push(t("select_at_least_one", { entity: t("company").toLowerCase() }));
		}
		if (messages.length <= 0 && (!user.currentCompanyId || every(user.userCompanies, (userCompany: IUserCompany) => userCompany.companyId !== user.currentCompanyId))) {
			user.currentCompanyId = first(user.userCompanies).companyId;
		}
		return messages;
	}

	const readonly: boolean = (props as IEditScreenProps<IUser>).readonly;

	return (
		<EntityEditor
			width="70%"
			record={user}
			endpoint={Endpoint.Users}
			entityState={userState}
			entityType={t("user")}
			dispatch={userDispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={user.firstName + " " + user.lastName}
			close={close}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<TabPanel tabBarStyle={{ margin: "-16px -16px 0" }}>
				<Tab reactKey="details" label={t("details")}>
					<div className="k-form">
						<label className="k-form-field">
							<span>{t("firstName")} *</span>
							<Input name="firstName" ref={firstField} className="full-width-field" value={user.firstName} onChange={onChange} required disabled={readonly} />
						</label>
						<label className="k-form-field">
							<span>{t("lastName")} *</span>
							<Input name="lastName" className="full-width-field" value={user.lastName} onChange={onChange} required disabled={readonly} />
						</label>
						<label className="k-form-field">
							<span>{t("email")} *</span>
							<Input name="email" type="email" className="full-width-field" value={user.email} onChange={onChange} required disabled={readonly} />
						</label>
						<label className="k-form-field">
							<span>{t("language")} *</span>
							<DropDownList
								className="full-width-field"
								data={languageItems}
								dataItemKey="code"
								textField="description"
								value={find(languageItems, { code: user.language })}
								onChange={onLanguageChange}
								required
								disabled={readonly}
							/>
						</label>
						<label className="k-form-field">
							<span>{t("sequenceNumber")}</span>
							<Input name="sequenceNumber" type="number" className="full-width-field" value={user.sequenceNumber} min={0} onChange={onChange} disabled={readonly} />
						</label>
						<div className="k-form-field">
							<span>{t("active")}</span>
							<YesNoSwitch name="active" checked={user.active} onChange={onChange} disabled={readonly} />
						</div>
						<div className="k-form-field">
							<span>{t("installer")}</span>
							<YesNoSwitch name="installer" checked={user.installer} onChange={onChange} disabled={readonly} />
						</div>
						<div className="k-form-field">
							<span>{t("leadInstaller")}</span>
							<YesNoSwitch name="leadInstaller" checked={user.leadInstaller} onChange={onChange} disabled={readonly} />
						</div>
					</div>
				</Tab>
				<Tab reactKey="roles" label={t("roles")}>
					<div className="k-form">
						<div className={"row " + editorStyles.spaced2Row + " " + editorStyles.switchRow}>
							<div className="col-md-11">{t("selectAll")}</div>
							<div className="col-md-1">
								<TranslatedSwitch checked={allRolesSelected()} onChange={onChangeAllRoles} disabled={readonly} />
							</div>
						</div>
						{roleState.entities.map((role: IRole) => (
							<div className={"row spaced-row " + editorStyles.switchRow} key={role.id}>
								<div className="col-md-11">
									<span className={hasRole(role.id) ? editorStyles.checkedRow : ""}>{role.name}</span>
								</div>
								<div className="col-md-1">
									<TranslatedSwitch name={role.id.toString()} onChange={onChangeRole} checked={hasRole(role.id)} disabled={readonly} />
								</div>
							</div>
						))}
					</div>
				</Tab>
				<Tab reactKey="companies" label={t("companies")}>
					<div className="k-form">
						<div className={"row " + editorStyles.spaced2Row + " " + editorStyles.switchRow}>
							<div className="col-md-11">{t("selectAll")}</div>
							<div className="col-md-1">
								<TranslatedSwitch checked={allCompaniesSelected()} onChange={onChangeAllCompanies} disabled={readonly} />
							</div>
						</div>
						{companyState.entities.map((company: ICompany) => (
							<div className={"row spaced-row " + editorStyles.switchRow} key={company.id}>
								<div className="col-md-11">
									<span className={hasCompany(company.id) ? editorStyles.checkedRow : ""}>{company.name}</span>
								</div>
								<div className="col-md-1">
									<TranslatedSwitch name={company.id.toString()} onChange={onChangeCompany} checked={hasCompany(company.id)} disabled={readonly} />
								</div>
							</div>
						))}
					</div>
				</Tab>
			</TabPanel>
		</EntityEditor>
	);
};

export default UserEditor;
