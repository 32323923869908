import chunk from "lodash/chunk";
import keys from "lodash/keys";
import map from "lodash/map";
import React, { useEffect, useReducer, useState } from "react";

import pkg from "../../../../package.json";

import { Button } from "@progress/kendo-react-buttons";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch as ReduxDispatch } from "redux";
import settings from "../../../assets/settings.png";
import callApi from "../../../services/api/callApi";
import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { hideLoader, showLoader } from "../../../state/actions/loaderActions";
import settingReducer from "../../../state/reducers/settingsReducer";
import { Permission } from "../../../utils/enums";
import { initialSettingState, ISetting } from "../../../utils/types/models";
import { IAction } from "../../../utils/types/types";
import SettingEditor from "./settingsEditor";

import { derender, render } from "../../../state/actions/renderActions";
import { newKey } from "../../../utils/utils";
import Confirm from "../../global/confirm";
import style from "./settings.module.scss";

const Settings: React.FC = () => {
	const { t } = useTranslation();
	const [settingsChanged, setSettingsChanged] = useState(false);
	const [settingsState, settingsDispatch] = useReducer(settingReducer, initialSettingState);
	const reduxDispatch: ReduxDispatch = useDispatch();

	const readOnly: boolean = !hasPermission(Permission.SettingsUpdate);

	useEffect(() => {
		callApi(settingsDispatch, Endpoint.Settings, "GET");
	}, []);

	useEffect(() => {
		if (settingsState.settingsLoading || settingsState.isUpdating) {
			reduxDispatch(showLoader());
		} else {
			reduxDispatch(hideLoader());
		}
	}, [settingsState.settingsLoading, settingsState.isUpdating, reduxDispatch]);

	function onChange(settingsKey: string, setting: ISetting): void {
		settingsDispatch(updateSetting(settingsKey, setting));
		setSettingsChanged(true);
	}

	function updateSetting(settingKey: string, setting: ISetting): IAction {
		return {
			type: "UPDATE_SETTING",
			settingKey,
			setting
		};
	}

	function reset(): void {
		const confirmKey: string = newKey("confirm");
		reduxDispatch(
			render(confirmKey, Confirm, {
				title: t("confirm_title", { action: t("reset").toLocaleLowerCase() }),
				onConfirm: () => {
					reduxDispatch(derender(confirmKey));
					callApi(settingsDispatch, Endpoint.Settings, "GET");
					setSettingsChanged(false);
				},
				onDecline: () => reduxDispatch(derender(confirmKey)),
				children: t("confirm_reset")
			})
		);
	}

	function save(setNavigationAllowed?: (navigationAllowed: boolean) => void): void {
		if (setNavigationAllowed) {
			callApi(settingsDispatch, Endpoint.Settings, "PUT", null, settingsState.settings, null, (success: boolean) => {
				if (success) {
					setNavigationAllowed(true);
				}
			});
		} else {
			callApi(settingsDispatch, Endpoint.Settings, "PUT", null, settingsState.settings, null, (success: boolean) => {
				if (success) {
					setSettingsChanged(false);
				}
			});
		}
	}

	return (
		<div
			style={{
				padding: "50px 20px 20px",
				width: "100%",
				height: "98%",
				backgroundImage: "url(" + settings + ")",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "right bottom",
				backgroundSize: "800px"
			}}
		>
			<h2 style={{ margin: "10px 0 0 20px" }}>{t("settings")}</h2>
			<div style={{ marginLeft: "30px", fontStyle: "italic", marginBottom: "30px" }}>Version: {pkg.version}</div>
			{map(chunk(keys(settingsState.settings), 2), (settingKeys: string[], index: number) => (
				<div className={"row "} key={"setting-row-" + index} style={{ paddingBottom: "10px" }}>
					{map(settingKeys, (settingKey: string) => (
						<React.Fragment key={settingKey}>
							<div className="col-2">{t(settingKey)}:</div>
							<div className="col-4" key={settingKey}>
								<SettingEditor setting={settingsState.settings[settingKey]} onChange={(setting: ISetting) => onChange(settingKey, setting)} readOnly={false} />
							</div>
						</React.Fragment>
					))}
				</div>
			))}
			{!readOnly && (
				<div className={style.buttonRow + " row"}>
					<Button onClick={reset} disabled={!settingsChanged}>
						{t("reset")}
					</Button>
					<Button primary onClick={() => save()} disabled={!settingsChanged}>
						{t("save")}
					</Button>
				</div>
			)}
		</div>
	);
};

export default Settings;
