import { Reducer } from "react";

import { initialProcurementState, IProcurementState } from "..";
import Endpoint from "../../services/api/endpoint";
import { IProcurementItem } from "../../utils/types/models";
import { IAction } from "../../utils/types/types";

const procurementReducer: Reducer<IProcurementState, IAction> = (state: IProcurementState = initialProcurementState, action: IAction) => {
	switch (action.type) {
		case "REQUEST_POST_" + Endpoint.ProcurementList:
		case "REQUEST_POST_" + Endpoint.ProcurementListByPok:
			state = {
				...state,
				isListLoading: true,
				listLoadError: null
			};
			break;
		case "REQUEST_SUCCESS_POST_" + Endpoint.ProcurementList:
		case "REQUEST_SUCCESS_POST_" + Endpoint.ProcurementListByPok:
			if (action.payload && action.payload.response && action.payload.response.data) {
				let results: IProcurementItem[] = action.payload.response.data.data;
				if (action.payload.response.data.group) {
					results = action.payload.response.data.group;
				}
				state = {
					...state,
					list: results || [],
					totalCount: action.payload.response.data.total || 0
				};
			}
			break;
		case "REQUEST_FAILURE_POST_" + Endpoint.ProcurementList:
		case "REQUEST_FAILURE_POST_" + Endpoint.ProcurementListByPok:
			state = {
				...state,
				listLoadError: action.payload.error
			};
			break;
		case "REQUEST_END_POST_" + Endpoint.ProcurementList:
		case "REQUEST_END_POST_" + Endpoint.ProcurementListByPok:
			state = {
				...state,
				isListLoading: false
			};
			break;
		case "CLEAR_POST_" + Endpoint.ProcurementList:
		case "CLEAR_POST_" + Endpoint.ProcurementListByPok:
			state = {
				...state,
				list: [],
				totalCount: 0,
				isListLoading: true,
				listLoadError: null
			};
			break;
		case "REQUEST_GET_" + Endpoint.ProcurementCreatePurchaseOrders:
		case "REQUEST_GET_" + Endpoint.ProcurementOrderPurchaseOrdersViaMail:
		case "REQUEST_GET_" + Endpoint.ProcurementResentPurchaseOrderMail:
		case "REQUEST_GET_" + Endpoint.ProcurementOrderPurchaseOrdersViaTradePlace:
		case "REQUEST_GET_" + Endpoint.ProcurementSetPurchaseOrdersChecked:
			state = {
				...state,
				isUpdating: true,
				tradeplaceFile: null,
				updated: false,
				updateError: null
			};
			break;
		case "REQUEST_SUCCESS_GET_" + Endpoint.ProcurementCreatePurchaseOrders:
		case "REQUEST_SUCCESS_GET_" + Endpoint.ProcurementOrderPurchaseOrdersViaMail:
		case "REQUEST_SUCCESS_GET_" + Endpoint.ProcurementSetPurchaseOrdersChecked:
			state = {
				...state,
				updated: true
			};
			break;
		case "REQUEST_SUCCESS_GET_" + Endpoint.ProcurementOrderPurchaseOrdersViaTradePlace:
			if (action.payload && action.payload.response && action.payload.response.data) {
				state = {
					...state,
					tradeplaceFile: action.payload.response.data
				};
			}
			break;
		case "REQUEST_FAILURE_GET_" + Endpoint.ProcurementCreatePurchaseOrders:
		case "REQUEST_FAILURE_GET_" + Endpoint.ProcurementOrderPurchaseOrdersViaMail:
		case "REQUEST_FAILURE_GET_" + Endpoint.ProcurementResentPurchaseOrderMail:
		case "REQUEST_FAILURE_GET_" + Endpoint.ProcurementOrderPurchaseOrdersViaTradePlace:
		case "REQUEST_FAILURE_GET_" + Endpoint.ProcurementSetPurchaseOrdersChecked:
			state = {
				...state,
				updateError: action.payload.error
			};
			break;
		case "REQUEST_END_GET_" + Endpoint.ProcurementCreatePurchaseOrders:
		case "REQUEST_END_GET_" + Endpoint.ProcurementOrderPurchaseOrdersViaMail:
		case "REQUEST_END_GET_" + Endpoint.ProcurementResentPurchaseOrderMail:
		case "REQUEST_END_GET_" + Endpoint.ProcurementOrderPurchaseOrdersViaTradePlace:
		case "REQUEST_END_GET_" + Endpoint.ProcurementSetPurchaseOrdersChecked:
			state = {
				...state,
				isUpdating: false
			};
			break;
		default:
			return state;
	}
	return state;
};

export default procurementReducer;