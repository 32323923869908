import React, { useEffect, useState } from "react";
import { Link, NavLink, NavLinkProps, Redirect, Route, RouteComponentProps, RouteProps, Switch, withRouter } from "react-router-dom";

import { hasPermission } from "../../../services/authentication";
import { Permission } from "../../../utils/enums";

import style from "./tabpanel.module.scss";

export interface IRoutedTabProps {
	label: string;
	reactKey: string;
	url: string;
	tileIcon?: string;
	tileLabel?: string;
	permissions?: Permission[];
}

interface IProps extends RouteComponentProps {
	title?: string;
	image?: string;
	loadFirstTab?: boolean;
	children: React.ReactElement<IRoutedTabProps>[] | React.ReactElement<IRoutedTabProps>;
}

function getTabLink(parentUrl: string, child: IRoutedTabProps): React.ReactElement<NavLinkProps> {
	return (
		<NavLink to={parentUrl + child.url} key={child.reactKey} activeClassName={style.active}>
			<li>{child.label}</li>
		</NavLink>
	);
}

function getTileLink(parentUrl: string, child: IRoutedTabProps): React.ReactElement<NavLinkProps> {
	return (
		<Link to={parentUrl + child.url} key={child.reactKey}>
			<div className={style.tile + " d-flex flex-column align-items-center"}>
				<div className="flex-grow-1 d-flex align-items-center">
					<i className={child.tileIcon} />
				</div>
				<span>{child.tileLabel || child.label}</span>
			</div>
		</Link >
	);
}

function getRoute(parentUrl: string, child: React.ReactElement<IRoutedTabProps>): React.ReactElement<RouteProps> {
	return <Route key={child.props.reactKey} path={parentUrl + child.props.url} component={() => child} />;
}

const RoutedTabPanel: React.FC<IProps> = (props: IProps) => {
	const [tabLinks, setTabLinks] = useState<React.ReactElement<RouteProps>[]>([]);
	const [tileLinks, setTileLinks] = useState<React.ReactElement<RouteProps>[]>([]);
	const [routes, setRoutes] = useState<React.ReactElement<RouteProps>[]>([]);

	useEffect(() => {
		if (props.children) {
			const newTabLinks: React.ReactElement<RouteProps>[] = [];
			const newTileLinks: React.ReactElement<RouteProps>[] = [];
			const newRoutes: React.ReactElement<RouteProps>[] = [];
			const tabs: React.ReactElement<IRoutedTabProps>[] = Array.isArray(props.children) ? props.children : [props.children];
			tabs.forEach(
				(child: React.ReactElement<IRoutedTabProps>): void => {
					if (!child.props.permissions || hasPermission(...child.props.permissions)) {
						newTabLinks.push(getTabLink(props.match.url, child.props));
						newTileLinks.push(getTileLink(props.match.url, child.props));
						newRoutes.push(getRoute(props.match.url, child));
					}
				}
			);

			setTabLinks(newTabLinks);
			setTileLinks(newTileLinks);
			setRoutes(newRoutes);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div id={style.tabPanel} className="d-flex flex-column">
			<div className={style.topBar}>
				<ul>{tabLinks}</ul>
			</div>
			<div className={style.contentContainer + " d-flex"}>
				<Switch>
					{routes}
					{routes && routes.length > 0 && props.loadFirstTab && <Redirect to={routes[0].props.path as string} />}
					<Route path={props.match.url}>
						<div className={style.content} style={{ backgroundImage: "url(" + props.image + ")" }}>
							<h1>{props.title}</h1>
							<div className={style.tiles + " d-flex flex-wrap"}>
								{tileLinks}
							</div>
						</div>
					</Route>
				</Switch>
			</div>
		</div>
	);
};

export default withRouter(RoutedTabPanel);
