import React from "react";

import style from "./step.module.scss";

export interface IStepProps {
	title: string;
	type?: "completed" | "active" | "empty" | "error";
	itemIndex?: number;
	onClick?: (current: number) => void;
}

const Step: React.FC<IStepProps> = (props: IStepProps) => {
	return (
		<div className={style.step + " " + style[props.type]} onClick={() => props.onClick(props.itemIndex)}>

			<div className={style.title}>{props.title}</div>
		</div>
	);
};

export default Step;