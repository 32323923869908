import React, { useEffect, useReducer, useState } from "react";

import { Button } from "@progress/kendo-react-buttons";
import { DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBoxCloseEvent, ComboBoxFilterChangeEvent, DropDownList, DropDownListChangeEvent, ListItemProps } from "@progress/kendo-react-dropdowns";
import { Input, NumericTextBoxChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import map from "lodash/map";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import { IntlService, useInternationalization } from "@progress/kendo-react-intl";
import callApi from "../../../../../../services/api/callApi";
import Endpoint from "../../../../../../services/api/endpoint";
import { initialPriceState, initialProductState } from "../../../../../../state";
import { hideLoader, showLoader } from "../../../../../../state/actions/loaderActions";
import { priceReducer, productReducer } from "../../../../../../state/reducers";
import { IApplicationState } from "../../../../../../store";
import { getDate, handleChange, setEntity } from "../../../../../../utils/editorUtils";
import { ArticleGroup, Permission, VatType } from "../../../../../../utils/enums";
import { IArticle, IProduct, IUser } from "../../../../../../utils/types/models";
import { getDeliveryOrPickingsRepresentation, isArticleForPurchaseOrder } from "../../../../../../utils/utils";
import { EnumDropDownList, ManageableField, NumericInput, SearchBox, YesNoSwitch } from "../../../../../global/editor";
import ProductEditor from "../../../../gamma/products/editor";
import PageEditor from "../pageEditor";

import style from "./editor.module.scss";

interface IProps {
	record?: IArticle;
	vatType: VatType;
	onClose: (record?: IArticle) => void;
	readOnly: boolean;
}

const ArticleEditor: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const intl: IntlService = useInternationalization();
	const [productState, productDispatch] = useReducer(productReducer, initialProductState);
	const [priceState, priceDispatch] = useReducer(priceReducer, initialPriceState);
	const initialArticle: IArticle = {
		id: null,
		group: null,
		productId: 0,
		dossierId: null,
		description: "",
		count: 1,
		discount: 0,
		discountPrice: 0,
		salesPriceInclVat: null,
		purchasePriceExclVat: null,
		salesPriceExclVat: null,
		lineTotalIncl: null,
		lineTotalExcl: null,
		lineTotalVat: null,
		vatType: props.vatType,
		vat: null,
		deliveries: [],
		pickings: []
	};
	const [article, setArticle] = useState<IArticle>(props.record || initialArticle);
	const [dataChanged, setDataChanged] = useState(false);
	const [readyToCalculate, setReadyToCalculate] = useState(false);
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);
	const reduxDispatch: Dispatch = useDispatch();

	useEffect(() => {
		if (priceState.isExecuting) {
			reduxDispatch(showLoader());
		} else {
			reduxDispatch(hideLoader());
		}
	}, [priceState.isExecuting, reduxDispatch]);

	useEffect(() => {
		if (priceState.priceResult) {
			setReadyToCalculate(false);
			updateArticle((prevArticle: IArticle) => ({
				...prevArticle,
				salesPriceInclVat: priceState.priceResult.salesPriceInclVat !== 0 ? priceState.priceResult.salesPriceInclVat : prevArticle.salesPriceInclVat,
				salesPriceExclVat: priceState.priceResult.salesPriceExclVat !== 0 ? priceState.priceResult.salesPriceExclVat : prevArticle.salesPriceExclVat,
				vat: priceState.priceResult.vat,
				lineTotalIncl: priceState.priceResult.lineTotalIncl,
				discountPrice: priceState.priceResult.discountPrice,
				lineTotalExcl: priceState.priceResult.lineTotalExcl,
				lineTotalVat: priceState.priceResult.lineTotalVat
			}));
		}
	}, [priceState.isExecuting, priceState.priceResult]);

	function onChangeWithTotalCalculation(event: NumericTextBoxChangeEvent | ComboBoxCloseEvent | DropDownListChangeEvent): void {
		const newArticle: IArticle = handleChange(article, event);

		if (event.target.name === "salesPriceInclVat") {
			newArticle.salesPriceExclVat = 0;
		} else if (event.target.name === "salesPriceExclVat") {
			newArticle.salesPriceInclVat = 0;
		}

		updateArticle(newArticle);
		setReadyToCalculate(true);
	}

	function onChange(event: React.ChangeEvent<HTMLInputElement> | ComboBoxCloseEvent | NumericTextBoxChangeEvent | DatePickerChangeEvent | DropDownListChangeEvent | SwitchChangeEvent): void {
		const isPurchaseArticle: boolean = isArticleForPurchaseOrder(article);
		const newArticle: IArticle = handleChange(article, event);
		const newIsPurchaseArticle: boolean = isArticleForPurchaseOrder(newArticle);

		if ((event as ComboBoxCloseEvent).target.name === "group") {
			if (!newIsPurchaseArticle) {
				newArticle.procurementArticle = null;
			} else if (!isPurchaseArticle && newIsPurchaseArticle) {
				newArticle.procurementArticle = true;
			}
		}
		updateArticle(newArticle);
	}

	function onClose(event: ComboBoxCloseEvent): void {
		setProduct(event.target.value);
	}

	function setProduct(product?: IProduct): void {
		if (product && !product.active && product.replacementProduct) {
			product = product.replacementProduct;
		}
		const newArticle: IArticle = setEntity(article, product, "product", "productId");
		newArticle.description = product?.description;
		newArticle.salesPriceExclVat = product?.salesPriceExclVat;
		if (product && newArticle.vatType) {
			let vat: number = 0;
			switch (newArticle.vatType) {
				case VatType.Vat6:
					vat = 6;
					break;
				case VatType.Vat21:
					vat = 21;
					break;
			}
			const vatAmount: number = Math.round(newArticle.salesPriceExclVat * vat) / 100;
			newArticle.vat = vatAmount;
			newArticle.salesPriceInclVat = Math.round((newArticle.salesPriceExclVat + vatAmount) * 100) / 100;
		}
		updateArticle(newArticle);
		calculate(newArticle);
	}

	function updateArticle(newArticle: React.SetStateAction<IArticle>): void {
		setArticle(newArticle);
		setDataChanged(true);
	}

	function calculate(articleToCalculate: IArticle): void {
		callApi(priceDispatch, Endpoint.Prices, "POST", null, articleToCalculate);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (!article.group) {
			messages.push(t("fill_in_required_field", { field: t("group").toLowerCase() }));
		}
		if (!article.productId) {
			messages.push(t("fill_in_required_field", { field: t("product").toLowerCase() }));
		}
		if (!Number.isFinite(article.salesPriceInclVat)) {
			messages.push(t("fill_in_required_field", { field: t("salesPriceInclVat").toLowerCase() }));
		}
		if (!article.vatType) {
			messages.push(t("fill_in_required_field", { field: t("vatType").toLowerCase() }));
		}
		if (!article.count) {
			messages.push(t("fill_in_required_field", { field: t("count").toLowerCase() }));
		}
		if (readyToCalculate) {
			messages.push(t("please_calculate"));
		}

		return messages;
	}

	const action: string = props.record ? t("edit") : t("add");
	return (
		<PageEditor
			width="70%"
			title={`${action} ${t("article")} ${article.product && article.product.modelNumber}`.trim()}
			close={props.onClose}
			dataChanged={dataChanged}
			getErrorMessages={getErrorMessages}
			action={action}
			record={article}
			readOnly={props.readOnly}
		>
			<div className="k-form">
				<div className="row">
					<div className="col">
						<fieldset>
							<legend>{t("product")}</legend>
							<div className="row">
								<div className="col">
									<label className="k-form-field">
										<span>{t("group")} *</span>
										<DropDownList
											name="group"
											className="full-width-field"
											data={map(ArticleGroup, (group: string) => ({ key: group, name: t(group) }))}
											value={
												article.group && {
													key: article.group,
													name: t(article.group)
												}
											}
											dataItemKey="key"
											textField="name"
											onChange={onChange}
											required
											disabled={props.readOnly}
										/>
									</label>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<ManageableField
										addScreen={ProductEditor}
										editScreen={ProductEditor}
										fieldLabel={t("product") + " *"}
										recordId={article.productId}
										addPermission={Permission.ProductsAdd}
										editPermission={Permission.ProductsUpdate}
										setEntity={setProduct}
										getByIdEndpoint={Endpoint.Products}
										readOnly={props.readOnly}
									>
										<SearchBox
											name="productId"
											entities={productState.entities}
											entityId={article.productId}
											entity={article.product}
											required
											textField="description"
											header={
												<div className={style.productSearchHeader + " row"}>
													<div className="col">{t("description")}</div>
													<div className="col">{t("replacementProduct")}</div>
													<div className="col-1">{t("active")}</div>
												</div>
											}
											itemRender={(li: React.ReactElement<HTMLLIElement>, listItemProps: ListItemProps) => {
												const liProps: HTMLLIElement = {
													...li.props,
													style: { ...li.props.style, padding: "0" }
												};
												return React.cloneElement(
													li,
													liProps,
													<div className={style.productLine + " row w-100" + (listItemProps.selected ? " " + style.selected : "")}>
														<div className="col">{listItemProps.dataItem.description}</div>
														<div className="col">{listItemProps.dataItem.replacementProduct && listItemProps.dataItem.replacementProduct.description}</div>
														<div className={(listItemProps.dataItem.active ? style.active : style.inactive) + " col-1"}>
															{listItemProps.dataItem.active ? t("yes") : t("no")}
														</div>
													</div>
												);
											}}
											onFilterChange={(event: ComboBoxFilterChangeEvent) =>
												callApi(productDispatch, Endpoint.Products, "GET", { search: event.filter.value, companyId: currentUser.currentCompanyId })
											}
											onClose={onClose}
											onClear={setProduct}
											disabled={props.readOnly}
										/>
									</ManageableField>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<label className="k-form-field">
										<span>{t("description")} *</span>
										<Input name="description" className="full-width-field" value={article.description} onChange={onChange} disabled={props.readOnly} />
									</label>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<label className="k-form-field">
										<span>{t("purchasePriceExclVat")}</span>
										<NumericInput
											name="purchasePriceExclVat"
											format="n2"
											className="full-width-field"
											value={article.purchasePriceExclVat}
											onChange={onChange}
											disabled={props.readOnly}
										/>
									</label>
								</div>
							</div>
						</fieldset>
					</div>
					<div className="col">
						<fieldset>
							<legend>{t("salesPrice")}</legend>
							<div className="row">
								<div className="col d-flex">
									<div className="flex-grow-1" style={{ marginRight: "10px" }}>
										<label className="k-form-field">
											<span>{t("count")} *</span>
											<NumericInput
												name="count"
												className="full-width-field"
												value={article.count}
												onChange={onChangeWithTotalCalculation}
												required
												min={1}
												disabled={props.readOnly}
											/>
										</label>
									</div>
									{!props.readOnly && (
										<div className="d-flex justify-content-end align-items-end">
											{readyToCalculate && <i className={"las la-exclamation-triangle " + style.readyForCalculationIcon} />}
											<Button primary iconClass="las la-calculator" disabled={!readyToCalculate} onClick={() => calculate(article)} />
										</div>
									)}
								</div>
							</div>
							<div className="row">
								<div className="col">
									<label className="k-form-field">
										<span>{t("salesPriceInclVat")} *</span>
										<NumericInput
											name="salesPriceInclVat"
											format="n2"
											className="full-width-field"
											value={article.salesPriceInclVat}
											onChange={onChangeWithTotalCalculation}
											required
											disabled={props.readOnly}
										/>
									</label>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<label className="k-form-field">
										<span>{t("vat") + " % *"}</span>
										<EnumDropDownList
											name="vatType"
											className="full-width-field"
											enum={VatType}
											value={article.vatType}
											onChange={onChangeWithTotalCalculation}
											required
											disabled={props.readOnly}
										/>
									</label>
								</div>
								<div className="col">
									<label className="k-form-field">
										<span>{t("vat") + " € *"}</span>
										<NumericInput name="vat" format="n2" className="full-width-field" value={article.vat} required disabled />
									</label>
								</div>
							</div>
							<fieldset>
								<div className="row">
									<div className="col">
										<label className="k-form-field">
											<span>{t("salesPriceExclVat")} *</span>
											<NumericInput
												name="salesPriceExclVat"
												format="n2"
												className="full-width-field"
												value={article.salesPriceExclVat}
												onChange={onChangeWithTotalCalculation}
												required
												disabled={props.readOnly}
											/>
										</label>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<label className="k-form-field">
											<span>{t("discount") + " %"}</span>
											<NumericInput
												name="discount"
												className="full-width-field"
												value={article.discount}
												onChange={onChangeWithTotalCalculation}
												min={0}
												disabled={props.readOnly}
											/>
										</label>
									</div>
									<div className="col">
										<label className="k-form-field">
											<span>{t("discount") + " €"}</span>
											<NumericInput name="discountprice" format="n2" className="full-width-field" value={article.discountPrice} disabled />
										</label>
									</div>
								</div>
							</fieldset>
							<fieldset>
								<div className="row">
									<div className="col">
										<label className="k-form-field">
											<span>{t("lineTotalExcl")}</span>
											<NumericInput name="priceExclVatDiscount" format="n2" className="full-width-field" value={article.lineTotalExcl} disabled />
										</label>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<label className="k-form-field">
											<span>{t("lineTotalVat")}</span>
											<NumericInput name="vatDiscountPrice" format="n2" className="full-width-field" value={article.lineTotalVat} disabled />
										</label>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<label className="k-form-field">
											<span>{t("lineTotalIncl")} *</span>
											<NumericInput name="lineTotal" format="n2" className="full-width-field" value={article.lineTotalIncl} disabled />
										</label>
									</div>
								</div>
							</fieldset>
						</fieldset>
					</div>
					<div className="col">
						<fieldset>
							<legend>Info</legend>
							{isArticleForPurchaseOrder(article) && (
								<div className="row">
									<div className="col">
										<div className="k-form-field">
											<span>{t("procurement")}</span>
											<YesNoSwitch name="procurementArticle" checked={article.procurementArticle} onChange={onChange} disabled={props.readOnly} />
										</div>
									</div>
								</div>
							)}
							<div className="row">
								<div className="col">
									<label className="k-form-field">
										<span>{t("pokNumber")}</span>
										<Input name="pokNumber" className="full-width-field" value={article.pokNumber} onChange={onChange} disabled={props.readOnly} />
									</label>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<label className="k-form-field">
										<span>{t("orderDate")}</span>
										<DatePicker
											name="orderDate"
											value={getDate(article.orderDate)}
											onChange={onChange}
											className="full-width-field"
											formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
											disabled={props.readOnly}
										/>
									</label>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<label className="k-form-field">
										<span>{t("deliverDate")}</span>
										<Input value={getDeliveryOrPickingsRepresentation(article.count, article.deliveries, intl)} disabled />
									</label>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<label className="k-form-field">
										<span>{t("checkDate")}</span>
										<DatePicker
											name="checkDate"
											value={getDate(article.checkDate)}
											onChange={onChange}
											className="full-width-field"
											formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
											disabled={props.readOnly}
										/>
									</label>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<label className="k-form-field">
										<span>{t("pickDate")}</span>
										<Input value={getDeliveryOrPickingsRepresentation(article.count, article.pickings, intl)} disabled />
									</label>
								</div>
							</div>
						</fieldset>
					</div>
				</div>
			</div>
		</PageEditor>
	);
};

export default ArticleEditor;
