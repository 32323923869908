import { DatePicker, DatePickerChangeEvent, TimePicker, TimePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBox, ComboBoxCloseEvent, DropDownList, DropDownListChangeEvent, DropDownListFocusEvent } from "@progress/kendo-react-dropdowns";
import { NumericTextBoxChangeEvent, Switch, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { NumericTextBoxHandle } from "@progress/kendo-react-inputs/dist/npm/numerictextbox/interfaces/NumericTextBoxHandle";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import includes from "lodash/includes";
import set from "lodash/set";
import React from "react";

import { EnumRadioGroupChangeEvent } from "../components/global/editor";
import { WeekPickerChangeEvent } from "../components/global/editor/weekPicker";
import { ArticleGroup } from "./enums";
import { IArticle, IEntity } from "./types/models";

function handleChange<T, R>(
	record: T,
	event:
		| React.ChangeEvent<HTMLInputElement>
		| React.ChangeEvent<HTMLTextAreaElement>
		| SwitchChangeEvent
		| ComboBoxCloseEvent
		| NumericTextBoxChangeEvent
		| DatePickerChangeEvent
		| TimePickerChangeEvent
		| DropDownListChangeEvent
		| EnumRadioGroupChangeEvent<R>
		| WeekPickerChangeEvent
		| DropDownListFocusEvent
): T {
	const newRecord: Record<string, unknown> = cloneDeep(record) as Record<string, unknown>;
	const target: HTMLInputElement | HTMLTextAreaElement | Switch | ComboBox | NumericTextBoxHandle | DatePicker | TimePicker | DropDownList = event.target;
	const field: string = target.name;

	if (field) {
		if (isDropDown(target)) {
			if (target.value) {
				if (Array.isArray(target.value)) {
					set(newRecord, field, target.value);
				} else {
					set(newRecord, field, target.value[target.props.dataItemKey]);
					if (field.endsWith("Id")) {
						set(newRecord, field.replace(/..$/, ""), target.value);
					}
				}
			} else {
				set(newRecord, field, undefined);
			}
		} else if (isEventWithValue(event)) {
			if (isDateChangeEvent(event) || isWeekChangeEvent(event)) {
				const date: Date = isDateChangeEvent(event) ? event.value : event.value.start;
				set(newRecord, field, date);
			} else {
				set(newRecord, field, event.value);
			}
		} else {
			set(newRecord, field, target.value);
		}
	}

	return newRecord as T;
}

function isDropDown(
	toBeDetermined: HTMLInputElement | HTMLTextAreaElement | Switch | ComboBox | NumericTextBoxHandle | DatePicker | TimePicker | DropDownList
): toBeDetermined is ComboBox | DropDownList {
	if ((toBeDetermined as ComboBox | DropDownList).props && (toBeDetermined as ComboBox | DropDownList).props.dataItemKey) {
		return true;
	}
	return false;
}

function isEventWithValue<R>(
	toBeDetermined:
		| React.ChangeEvent<HTMLInputElement>
		| React.ChangeEvent<HTMLTextAreaElement>
		| SwitchChangeEvent
		| ComboBoxCloseEvent
		| NumericTextBoxChangeEvent
		| DatePickerChangeEvent
		| TimePickerChangeEvent
		| DropDownListChangeEvent
		| EnumRadioGroupChangeEvent<R>
		| WeekPickerChangeEvent
		| DropDownListFocusEvent
): toBeDetermined is SwitchChangeEvent | NumericTextBoxChangeEvent | DatePickerChangeEvent | EnumRadioGroupChangeEvent<R> {
	if ((toBeDetermined as SwitchChangeEvent | NumericTextBoxChangeEvent | DatePickerChangeEvent | TimePickerChangeEvent | EnumRadioGroupChangeEvent<R>).value) {
		return true;
	}
	return false;
}

function isDateChangeEvent<R>(
	toBeDetermined: SwitchChangeEvent | NumericTextBoxChangeEvent | DatePickerChangeEvent | EnumRadioGroupChangeEvent<R> | WeekPickerChangeEvent | TimePickerChangeEvent
): toBeDetermined is DatePickerChangeEvent | TimePickerChangeEvent {
	if ((toBeDetermined as DatePickerChangeEvent | TimePickerChangeEvent).value.getTime) {
		return true;
	}
	return false;
}

function isWeekChangeEvent<R>(toBeDetermined: SwitchChangeEvent | NumericTextBoxChangeEvent | EnumRadioGroupChangeEvent<R> | WeekPickerChangeEvent): toBeDetermined is WeekPickerChangeEvent {
	if ((toBeDetermined as WeekPickerChangeEvent).value.start) {
		return true;
	}
	return false;
}

function getEntity<T extends IEntity>(entities: T[], entityId: number, entity: T): T {
	let result: T = null;
	if (entities && entities.length > 0 && entityId) {
		result = find(entities, (ent: T) => ent.id === entityId);
	}

	// included entityId for clear button
	if (!result && entityId && entity) {
		result = entity;
	}

	return result;
}

function setEntity<TParent extends object, T extends IEntity>(parent: TParent, entity: T, field: string, idField: string): TParent {
	const dossierRecord: TParent = { ...parent };
	if (entity) {
		set(dossierRecord, idField, entity.id);
	} else {
		set(dossierRecord, idField, null);
	}
	set(dossierRecord, field, entity);
	return dossierRecord;
}

function getDate(date: Date): Date {
	return date ? new Date(date) : null;
}

function isProcurementArticle(article: IArticle): boolean {
	return includes([ArticleGroup.Appliance, ArticleGroup.Plumbing, ArticleGroup.Accessory, ArticleGroup.Furniture, ArticleGroup.Worktops], article.group);
}

export { handleChange, getEntity, isProcurementArticle, setEntity, getDate };
