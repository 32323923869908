import React from "react";

import { IAction } from "../../utils/types/types";

// tslint:disable-next-line: no-any
export function render<T>(key: string, component: React.ComponentType<T>, props?: T): IAction {
	return {
		type: "RENDER",
		config: {
			key,
			component,
			props
		}
	};
}

export function derender(key: string): IAction {
	return {
		type: "DERENDER",
		key
	};
}
