import React from "react";

import { GridCellProps } from "@progress/kendo-react-grid";
import { Checkbox } from "@progress/kendo-react-inputs";
import get from "lodash/get";

import { cellContentsFunction } from ".";

export function checkBoxCell(fieldFilledInCheck: boolean): cellContentsFunction {
	return (props: GridCellProps) => {
		let value: boolean = props.dataItem[props.field];
		if (fieldFilledInCheck) {
			value = get(props.dataItem, props.field) ? true : false;
		}

		return <Checkbox checked={value} label="" />;
	};
}
