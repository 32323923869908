import React, { useState } from "react";

import { process, State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn, GridDataStateChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import { GridRowDoubleClickEvent } from "@progress/kendo-react-grid/dist/npm/interfaces/events";
import remove from "lodash/remove";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { derender, render } from "../../../../../../state/actions/renderActions";
import { IContact, IDossier } from "../../../../../../utils/types/models";
import { newKey } from "../../../../../../utils/utils";
import Confirm from "../../../../../global/confirm";
import { getGridCommandColumn } from "../../../../../global/gridpanel";
import { IFunctionCommand, IRecordCommand } from "../../../../../global/gridpanel/customCells/gridCells/commandCell";
import { IDossierSectionProps } from "../../editor";
import ContactEditor from "./editor";

import style from "../editor.module.scss";

const Contacts: React.FC<IDossierSectionProps> = (props: IDossierSectionProps) => {
	const { t } = useTranslation();
	const [gridState, setGridState] = useState<State>({ sort: [{ field: "name", dir: "asc" }] });
	const [currentNewId, setCurrentNewId] = useState(-1);
	const dispatch: Dispatch = useDispatch();

	function showEditor(record?: IContact): void {
		const key: string = newKey("Contact_Editor");
		dispatch(
			render(key, ContactEditor, {
				record,
				onClose: (contact: IContact) => {
					if (contact) {
						const newDossier: IDossier = { ...props.dossier };
						if (contact.id) {
							remove(newDossier.contacts, { id: contact.id });
						} else {
							contact.id = currentNewId;
							setCurrentNewId(currentNewId - 1);
						}
						newDossier.contacts.push(contact);
						props.onChange(newDossier);
					}
					dispatch(derender(key));
				},
				readOnly: props.readOnly
			})
		);
	}

	const commands: (IFunctionCommand<IContact> | IRecordCommand<IContact>)[] = [
		{
			tooltip: t("edit"),
			iconClass: "las la-pencil-alt",
			recordAction: (record: IContact) => {
				showEditor(record);
			}
		},
		{
			tooltip: t("remove"),
			iconClass: "las la-times",
			idAction: (id: number) => {
				const confirmKey: string = newKey("confirm");
				dispatch(
					render(confirmKey, Confirm, {
						title: t("confirm_title", { action: t("remove").toLowerCase() }),
						children: t("confirm_content", { action: t("remove").toLowerCase() }),
						onDecline: () => dispatch(derender(confirmKey)),
						onConfirm: () => {
							dispatch(derender(confirmKey));
							const newDossier: IDossier = { ...props.dossier };
							remove(newDossier.contacts, { id });
							props.onChange(newDossier);
						}
					})
				);
			}
		}
	];

	return (
		<div className="h-100 d-flex flex-column k-form">
			<div className="row">
				<div className="col">
					<h2 className={style.accentTitle}>{t("contacts")}</h2>
				</div>
				<div className="col" style={{ textAlign: "right" }}>
					<h3>
						{props.dossier?.dossierNumber} - {props.dossier?.customer?.name}
					</h3>
				</div>
			</div>

			<div className="row flex-grow-1">
				<div className="col">
					<Grid
						data={process(props.dossier.contacts, gridState)}
						{...gridState}
						sortable
						onDataStateChange={(event: GridDataStateChangeEvent) => setGridState(event.dataState)}
						onRowDoubleClick={(event: GridRowDoubleClickEvent) => showEditor(event.dataItem)}
						className="h-100"
					>
						{!props.readOnly && (
							<GridToolbar>
								<Button primary onClick={() => showEditor()}>
									{t("add")}
								</Button>
							</GridToolbar>
						)}
						<GridColumn field="type.name" title={t("type")} width="150px" />
						<GridColumn field="name" title={t("name")} />
						<GridColumn field="phone" title={t("phone")} />
						<GridColumn field="email" title={t("email")} />
						{!props.readOnly && getGridCommandColumn(t("actions"), commands)}
					</Grid>
				</div>
			</div>
		</div>
	);
};

export default Contacts;
