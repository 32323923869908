import React from "react";

export interface IProps {
	label: string;
	reactKey: string;
	className?: string;
	children: React.ReactNode;
}

const Tab: React.FC<IProps> = (props: IProps) => {
	return <React.Fragment>{props.children}</React.Fragment>;
};

export default Tab;