import axios, { AxiosRequestConfig } from "axios";
import some from "lodash/some";
import { AdalConfig, adalGetToken, AuthenticationContext } from "react-adal";

import store from "../store";
import { Permission } from "../utils/enums";
import { IUser } from "../utils/types/models";

let adalConfig: AdalConfig;

let _tenantId: string;
let _clientId: string;

let _authContext: AuthenticationContext;

export function setAdCredentials(tenantId: string, clientId: string): void {
	_tenantId = tenantId;
	_clientId = clientId;
}

export function authContext(): AuthenticationContext {
	if (_authContext) {
		return _authContext;
	}

	const tenantId: string = _tenantId;
	const clientId: string = _clientId;

	adalConfig = {
		tenant: tenantId,
		clientId,
		postLogoutRedirectUri: window.location.origin,
		endpoints: {
			api: clientId
		},
		popUp: true,

		callback: () => window.location.reload(),
		cacheLocation: "localStorage"
	};

	_authContext = new AuthenticationContext(adalConfig);

	return _authContext;
}

// tslint:disable-next-line: no-any
export async function fetch(requestOptions: AxiosRequestConfig, authNeeded: boolean): Promise<any> {
	if (!requestOptions.headers) {
		requestOptions.headers = {};
	}
	if (authNeeded) {
		const token: string = await adalGetToken(authContext(), adalConfig.endpoints.api);
		requestOptions.headers.Authorization = `Bearer ${token}`;
	}
	requestOptions.headers["Content-Type"] = "application/json";
	return axios(requestOptions);
}

export function clearUserCache(): void {
	authContext().clearCacheForResource(adalConfig.endpoints.api);
}

export function hasPermission(...permissions: Permission[]): boolean {
	const currentUser: IUser = store.getState().authenticationState.currentUser;

	if (!permissions || permissions.length <= 0) {
		return true;
	}

	if (currentUser) {
		return some(permissions, (permision: string) => {
			return currentUser.permissions[permision];
		});
	}

	return false;
}
