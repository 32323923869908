import React from "react";

import map from "lodash/map";

interface IProps {
	messages: string[];
}

const Messages: React.FC<IProps> = (props: IProps) => (
	<>
		{map(props.messages, (message: string, index: number) => (
			<React.Fragment key={"message_" + index}>
				{message}
				<br />
			</React.Fragment>
		))}
	</>
);

export default Messages;
