import { IDossier, IStockLine } from "../../../../../utils/types/models";

interface IChangeDossierAction {
	type: "dossier";
	dossier: IDossier;
}

export function changeDossier(dossier: IDossier): IChangeDossierAction {
	return {
		type: "dossier",
		dossier
	};
}

interface ISetCurrentStepAction {
	type: "currentStep";
	current: number;
}

export function setCurrentStep(current: number): ISetCurrentStepAction {
	return {
		type: "currentStep",
		current
	};
}

interface ISetSectionChangedAction {
	type: "sectionChanged";
	section: string;
	changed: boolean;
}

export function setSectionChanged(section: string, changed: boolean): ISetSectionChangedAction {
	return {
		type: "sectionChanged",
		section,
		changed
	};
}

interface ISetSectionValidAction {
	type: "sectionValid";
	section: string;
	valid: boolean;
}

export function setSectionValid(section: string, valid: boolean): ISetSectionValidAction {
	return {
		type: "sectionValid",
		section,
		valid
	};
}

interface IAddStockChangeAction {
	type: "addStockChange";
	stockLine: IStockLine;
}

export function addStockChange(count: number, productId: number): IAddStockChangeAction {
	return {
		type: "addStockChange",
		stockLine: {
			id: 0,
			count,
			productId
		}
	};
}

interface IRemoveStockChangeAction {
	type: "removeStockChange";
	stockLine: IStockLine;
}

export function removeStockChange(stockLine: IStockLine): IRemoveStockChangeAction {
	return {
		type: "removeStockChange",
		stockLine
	};
}

export type DossierEditorAction = IChangeDossierAction | ISetCurrentStepAction | ISetSectionChangedAction | ISetSectionValidAction | IAddStockChangeAction | IRemoveStockChangeAction;