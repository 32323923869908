import ITranslation from "../../utils/types/translation";

const translationsNL: ITranslation = {
	show: "Toon",
	searchDossierNumber: "Zoek op dossiernummer",
	dossierDescription: "Dossier beschrijving",
	taskDescription: "Taak beschrijving",
	taskPhotos: "Taak fotos",
	home: "Startpagina",
	gamma: "Gamma",
	masterData: "Masterdata",
	security: "Beveiliging",
	settings: "Instellingen",
	startSequenceNumberDossier: "Start bij deze volgnummer bij nieuwe taak via planning",
	help: "Help",
	privacy: "Privacy",
	schedule: "Inplannen",
	yourprofile: "Jouw profiel",
	logout: "Uitloggen",
	work: "Werk",
	exportExcel: "Exporteer Excel",
	runningDossiers: "Lopende dossiers",
	todaysTasks: "Taken voor vandaag",
	dossierManagement: "Dossierbeheer",
	planningManagementService: "Planningbeheer - Service",
	planningManagementInstallation: "Planningbeheer - Plaatsing",
	planningManagementDelivery: "Planningbeheer - Levering",
	warehouse: "Magazijn",
	procurement: "Aankoop",
	Service: "Service",
	firstOrderDate: "Eerste besteldatum",
	suppliers: "Leveranciers",
	products: "Producten",
	deliveryWeek: "Leverweek",
	searchByPok: "Zoek op POKnr",
	createNewTask: "Maak nieuwe taak",
	swap: "Wissel",
	customers: "Klanten",
	deliveryDate: "Leverdatum",
	constructionYards: "Werven",
	shops: "Winkels",
	projects: "Projecten",
	contactTypes: "Contacttypes",
	communicationTemplates: "Communicatietemplates",
	communicationTemplatesSplitted: "Communicatie-templates",
	users: "Gebruikers",
	roles: "Rollen",
	switchcompany: "Wijzig bedrijf",
	role: "Rol",
	companies: "Bedrijven",
	company: "Bedrijf",
	project: "Project",
	unit: "Unit",
	shop: "Winkel",
	welcome_message: "Welkom ",
	en: "Engels",
	nl: "Nederlands",
	active: "Actief",
	actions: "Acties",
	firstName: "Voornaam",
	lastName: "Achternaam",
	fullName: "Volledige naam",
	email: "Email",
	language: "Taal",
	name: "Naam",
	permission: "Permissie",
	address: "Adres",
	street: "Straat",
	number: "Nummer",
	dossierNumber: "Dossiernummer",
	postalCode: "Postcode",
	municipality: "Gemeente",
	province: "Provincie",
	country: "Land",
	modelNumber: "Model nummer",
	barcode: "Barcode (EAN of UPC)",
	dossierCannotBeDeleted: "Dit dossier kan niet worden verwijderd. Gelieve eerst de volgende items te verwijderen van dit dossier:",
	fetchEanInformation: "EAN informatie ophalen",
	code: "Code",
	type: "Type",
	productTypes: "Types",
	supplier: "Leverancier",
	description: "Beschrijving",
	salesPriceExclVat: "€ Verkoopsprijs excl. BTW",
	replacementProduct: "Vervangingsproduct",
	eanInformation: "EAN Informatie",
	brand: "Merk",
	brands: "Merken",
	manufacturer: "Producent",
	vatNumber: "BTW nummer",
	measured: "Opgemeten",
	writtenOut: "Uitgeschreven",
	general: "Algemeen",
	documents: "Documenten",
	articles: "Artikelen",
	putArticleInStock: "Wilt u het verwijderde artikel in stock plaatsen?",
	contacts: "Contacten",
	remarks: "Opmerkingen",
	communication: "Communicatie",
	invoicing: "Facturatie",
	planning: "Planning",
	tasks: "Taken",
	aftersales: "Na-service",
	contractor: "Aannemer",
	contractors: "Aannemers",
	dossier: "Dossier",
	soldOn: "Verkocht op",
	createdOn: "Aangemaakt op",
	customer: "Klant",
	copy: "Kopiëer",
	constructionYard: "Werf",
	dossierManager: "Dossierbeheerder",
	salesRep: "Verkoper",
	vat: "BTW",
	binNumber: "Baknummer",
	checkInAtWork: "CheckIN@Work",
	material: "Materiaal",
	cupboards: "Kasten",
	extraInformation: "Extra informatie",
	parking: "Parkeerplaats",
	floor: "Verdieping",
	isLiftNeeded: "Lift nodig?",
	hasSimpleAccess: "Simpele toegang?",
	measurement: "Opmeting",
	writeOut: "Uitschrijving",
	date: "Datum",
	executedBy: "Uitgevoerd door",
	complete: "Volledig",
	readyForPicking: "Klaarzetten",
	closed: "Afgesloten",
	fullDescriptionVat0: "Medecontractant - 0%",
	fullDescriptionVat6: "Renovatie - 6%",
	fullDescriptionVat21: "Nieuwbouw - 21%",
	Vat0: "0%",
	Vat6: "6%",
	Vat21: "21%",
	phone: "Telefoon",
	contactName: "Contactnaam",
	dossierContact: "Dossier contact",
	constructionYardContact: "Werf contact",
	file: "Bestand",
	subject: "Onderwerp",
	unable_to_display: "Het is onmogelijk om dit bestand te visualiseren.<1/>Download <3>hier</3>.",
	lastUploadedBy: "Laatst ge-upload door",
	lastUploadedDate: "Laatst ge-upload op",
	addRemark: "Opmerking toevoegen",
	createPurchaseOrders: "Aankooporders aanmaken",
	dossierMustBeComplete: "Het dossier moet volledig zijn voordat je dit kan doen.",
	pleaseSaveFirst: "Je kan deze actie enkel doen op opgeslagen dossiers.<1 />Gelieve eerst op te slaan.",
	product: "Product",
	group: "Groep",
	count: "Aantal",
	salesPriceInclVat: "€ Verkoopsprijs incl. BTW",
	salesPrice: "Verkoopsprijs",
	purchasePriceExclVat: "€ Aankoopprijs/stuk excl. BTW",
	discount: "Korting",
	pokNumber: "POKNr.",
	deliveries: "Leveringen",
	pickings: "Klaarzetten",
	orderDate: "Besteld",
	deliverDate: "Geleverd",
	checkDate: "Gecontroleerd",
	pickDate: "Klaargezet",
	Furniture: "Meubelen",
	Worktop: "Werkblad",
	Worktops: "Werkbladen",
	Plumbing: "Sanitair",
	Appliance: "Electro",
	Accessory: "Accessoires",
	Other: "Andere",
	netPrice: "Nettoprijs",
	unitPrice: "Stukprijs",
	lineTotal: "Lijntotaal",
	lineTotalIncl: "incl. BTW (incl. korting)",
	lineTotalExcl: "excl. BTW (incl. korting)",
	lineTotalVat: "BTW € (incl. korting)",
	totalInclVat: "Totaal incl. BTW: € ",
	totalExclVat: "Totaal excl. BTW: € ",
	totalVat: "Totaal BTW: € ",
	copyDossier: "Kopiëer dossier",
	newDossierNumber: "Nieuw dossier nummer",
	dossierCopied: "Dossier gekopiëerd",
	showNewDossier: "Wilt u naar het nieuwe dossier gaan?",
	save: "Opslaan",
	cancel: "Annuleer",
	cancelling: "Annuleren",
	ok: "Ok",
	close: "Sluiten",
	add: "Toevoegen",
	edit: "Wijzigen",
	remove: "Verwijderen",
	previous: "Vorige",
	next: "Volgende",
	confirmLeave: "De data is niet opgeslagen, ben je zeker dat je dit scherm wil verlaten?",
	confirmChangeFixedDate: "Dit is een taak met vaste datum. Ben je zeker dat je deze wilt verplaatsen?",
	selectDossierMessage: "Selecteer een dossier om taken te zien.",
	dossierViewCheck: "Toon alleen dossiers met nog in te plannen taken.",
	details: "Details",
	selectAll: "Selecteer alles",
	off: "Uit",
	on: "Aan",
	yes: "Ja",
	no: "Nee",
	year: "jaar",
	month: "maand",
	day: "dag",
	prevWeek: "Vorige Week",
	currentWeek: "Deze Week",
	nextWeek: "Volgende Week",
	monday: "Maandag",
	tuesday: "Dinsdag",
	wednesday: "Woensdag",
	thursday: "Donderdag",
	friday: "Vrijdag",
	saturday: "Zaterdag",
	related: "Gerelateerd",
	user: "Gebruiker",
	remark: "Opmerking",
	color: "Kleur",
	toBeAsked: "Nog te bevragen",
	relatedToArticlePhase: "Artikel fase?",
	article: "Artikel",
	photos: "Foto's",
	Green: "Groen",
	Orange: "Oranje",
	Red: "Rood",
	General: "Algemeen",
	Documents: "Documenten",
	ArticleFurniture: "Artikel-Meubelen",
	ArticleWorktops: "Artikel-Werkbladen",
	ArticlePlumbing: "Artikel-Sanitair",
	ArticleAppliance: "Artikel-Elektro",
	ArticleAccessory: "Artikel-Accessoires",
	ArticleOther: "Artikel-Andere",
	Communication: "Communicatie",
	Contacts: "Contacten",
	Invoicing: "Facturatie",
	Installation: "Plaatsing",
	AfterSales: "Na-Service",
	NotApplicable: "N.A.",
	Ordered: "Besteld",
	Delivered: "Geleverd",
	delivered: "Geleverd",
	Picked: "Klaargezet",
	template: "Template",
	to: "Aan",
	body: "Body",
	installer: "Plaatser",
	reminderBody: "Reminder",
	reminderDate: "Reminder verzenden op",
	status: "Status",
	Email: "Email",
	New: "Nieuw",
	Sending: "Verzenden",
	AwaitingReminder: "Reminder te verzenden",
	SendingReminder: "Verzenden reminder",
	Sent: "Verzonden",
	Error: "Error",
	ReminderError: "Reminder error",
	invoiceDepositOn: "Voorschot te factureren op",
	depositAmountExclVat: "Bedrag voorschot excl. BTW",
	vatOnDeposit: "BTW% op voorschot",
	depositInvoiced: "Voorschot gefactureerd",
	depositPayed: "Voorschot betaald",
	invoiced: "Gefactureerd",
	payed: "Betaald",
	None: "Geen",
	Now: "Nu",
	AfterMeasurement: "Na opmeting",
	AfterDossierCompletion: "Dossier volledig",
	created: "Aangemaakt",
	lastUpdated: "Laatst gewijzigd",
	sellingDate: "Verkoopsdatum",
	picked: "Klaargezet",
	passOnPickedDate: "Datum doorzetten",
	pickedDateChanged: "Datum klaargezet is aangepast",
	confirmPassonPickedDate: "Wenst u de datum door te zetten naar alle artikels?",
	installationWeek: "Plaatsingsweek",
	productionWeek: "Productieweek",
	scanLocation: "Scan locatie",
	fixedDate: "Vaste datum",
	colleague: "Collega",
	task: "Taak",
	planned: "Gepland",
	taskComplete: "Afgewerkt",
	showCompletedTasks: "Toon afgewerkte taken",
	Delivery: "Levering",
	loggedBy: "Gelogd door",
	Pending: "In behandeling",
	Closed: "Afgewerkt",
	stock: "Stock",
	stockMovement: "Stockbeweging",
	orderViaEMail: "Order via email",
	downloadTradeplaceFile: "Download Tradeplace bestand",
	setPurchaseOrdersChecked: "Zet aankooporders gecontroleerd",
	printLabels: "Print labels",
	invoiceSelected: "Factureer geselecteerde lijnen",
	amount: "€ Bedrag",
	amountInclVat: "€ Bedrag incl. BTW",
	amountExclVat: "€ Bedrag excl. BTW",
	Deposit: "Voorschot",
	OnDelivery: "Te betalen bij levering",
	AfterInstallation: "Te betalen na plaatsing",
	labelPrinted: "Het printen van uw label is aangevraagd",
	labelsPrinted: "Het printen van uw labels is aangevraagd",
	back: "Terug",
	success: "Success",
	error: "Error",
	pending_changes: "Wijzigingen",
	errorInvoicing: "Facturatie error. Als 'Nu' geselecteerd is, moet je een bedrag groter dan nul ingeven.",
	ask_save: "Er zijn nog steeds niet opgeslagen wijzigingen. Wilt u deze opslaan?",
	confirm_title: "Bevesting {{action}}",
	confirm_content: "Bent u zeker dat u dit record wil {{action}}?",
	notFound: "{{entity}} niet gevonden",
	createNew: "Dit <1/> is niet gevonden.<3/>Wilt u een nieuw aanmaken?",
	select_at_least_one: "Gelieve minstens 1 {{entity}} te selecteren.",
	fill_in_required_field: "Gelieve het verplicht veld '{{field}}' in te vullen.",
	fill_in_required_field_for_planned: "Gelieve het veld '{{field}}' in te vullen om een taak op gepland te zetten.",
	fill_in_required_field_for_fixedDate: "Gelieve het veld '{{field}}' in te vullen om een vaste datum te selecteren.",
	fill_in_required_field_absent: "Gelieve een lead of collega in te vullen als afwezige",
	fill_in_required_field_absent_date: "Gelieve een start en stopdatum van afwezigheid in te vullen",
	replacement_product_cannot_be_the_same: "Het vervangingsproduct mag niet hetzelfde zijn.",
	fill_in_correct_format: "Gelieve een correcte waarde in het veld '{{field}}' in te vullen. Bvb: '{{example}}'.",
	fill_in_correct_date_values: "Gelieve juiste waarden in datum en tijd velden in te vullen. (Start > Stop)",
	add_at_least_one: "Gelieve minstens 1 {{entity}} toe te voegen",
	please_calculate: "Gelieve nog de prijzen te berekenen",
	theseAreTheServerErrorMessages: "Dit zijn de server validatie errors:",
	or: "of",
	ourCustomerNumber: "Ons klant nummer",
	resentOrderEmail: "Order mail opnieuw versturen",
	reset: "Resetten",
	yourRemarks: "Jouw opmerkingen",
	assignee: "Verantwoordelijke",
	finished: "Afgewerkt",
	phone1: "Telefoon 1",
	phone2: "Telefoon 2",
	email1: "Email 1",
	email2: "Email 2",
	createdBy: "Aangemaakt door",
	by: "door",
	confirmDeliveryTitle: "Bevestig leverling",
	confirmDelivery: "Bent u zeker dat u 1 item van dit artikel op geleverd wil zetten?",
	displayedUsers: "Getoonde gebruikers",
	assign: "Toewijzen",
	time: "Tijd",
	lead: "Lead",
	leadInstaller: "Lead plaatser",
	print: "Print",
	timepicker: {
		cancel: "Annuleer",
		now: "NU",
		selectNow: "Selecteer Nu",
		set: "Kies",
		toggleClock: "Wissel klok",
		toggleTimeSelector: "Open/sluit"
	},
	permissions: {
		RolesRead: "Lezen",
		RolesAdd: "Toevoegen",
		RolesUpdate: "Wijzigen",
		RolesDelete: "Verwijderen",
		UsersRead: "Lezen",
		UsersAdd: "Toevoegen",
		UsersUpdate: "Wijzigen",
		UsersDelete: "Verwijderen",
		SuppliersRead: "Lezen",
		SuppliersAdd: "Toevoegen",
		SuppliersUpdate: "Wijzigen",
		SuppliersDelete: "Verwijderen",
		ProductsRead: "Lezen",
		ProductsAdd: "Toevoegen",
		ProductsUpdate: "Wijzigen",
		ProductsDelete: "Verwijderen",
		CustomersRead: "Lezen",
		CustomersAdd: "Toevoegen",
		CustomersUpdate: "Wijzigen",
		CustomersDelete: "Verwijderen",
		ContractorsRead: "Lezen",
		ContractorsAdd: "Toevoegen",
		ContractorsUpdate: "Wijzigen",
		ContractorsDelete: "Verwijderen",
		ConstructionYardsRead: "Lezen",
		ConstructionYardsAdd: "Toevoegen",
		ConstructionYardsUpdate: "Wijzigen",
		ConstructionYardsDelete: "Verwijderen",
		ProjectsRead: "Lezen",
		ProjectsAdd: "Toevoegen",
		ProjectsUpdate: "Wijzigen",
		ProjectsDelete: "Verwijderen",
		BrandsRead: "Lezen",
		BrandsAdd: "Toevoegen",
		BrandsUpdate: "Wijzigen",
		BrandsDelete: "Verwijderen",
		ProductTypesRead: "Lezen",
		ProductTypesAdd: "Toevoegen",
		ProductTypesUpdate: "Wijzigen",
		ProductTypesDelete: "Verwijderen",
		ShopsRead: "Lezen",
		ShopsAdd: "Toevoegen",
		ShopsUpdate: "Wijzigen",
		ShopsDelete: "Verwijderen",
		CommunicationTemplatesRead: "Lezen",
		CommunicationTemplatesAdd: "Toevoegen",
		CommunicationTemplatesUpdate: "Wijzigen",
		CommunicationTemplatesDelete: "Verwijderen",
		ContactTypesRead: "Lezen",
		ContactTypesAdd: "Toevoegen",
		ContactTypesUpdate: "Wijzigen",
		ContactTypesDelete: "Verwijderen",
		DossiersRead: "Lezen",
		DossiersAdd: "Toevoegen",
		DossiersUpdate: "Wijzigen",
		DossiersDelete: "Verwijderen",
		StockRead: "Lezen",
		StockAdd: "Toevoegen",
		ProcurementView: "Aankoopscherm",
		PlanningView: "Planningsscherm",
		WarehouseView: "Magazijnscherm",
		PrintLabels: "Toegestaan",
		InvoicingView: "Facutratiescherm",
		Settings: "Toegestaan",
		Home: "Toegestaan",
		TeamsRead: "Lezen",
		TeamsAdd: "Toevoegen",
		TeamsUpdate: "Wijzigen",
		TeamsDelete: "Verwijderen",
		SettingsRead: "Lezen",
		SettingsUpdate: "Wijzigen"
	},
	errors: {
		server_error: "De server heeft een '{{statuscode}}' antwoord terug gestuurd.",
		error_duplicate: "Dit record bestaat al",
		error_dbconcurrency: "Dit record is in tussentijd gewijzigd of verwijderd door iemand anders. Gelieve te refreshen.",
		error_entityused: "Deze entiteit wordt nog steeds gebruikt binnen de applicatie. Als je deze niet wil zien in de schermen, gelieve deze dan te deactiveren.",
		error_constraintfailure: "Een database validatie regel is gefaald.",
		error_sqlconnection: "Er kan geen verbinding met de database gemaakt worden.",
		error_sqlgeneral: "Er is een database error gebeurd.",
		error_validation: "Er is een validatie error.",
		error_connection: "Er kan geen verbinding met een externe service gemaakt worden.",
		error_argumentnull: "Er was een leeg {{argument}} op de server",
		error_productwrongreplacement: "Een product kan niet zijn eigen vervanginsproduct zijn.",
		error_general: "Er is een error op de server gebeurd.",
		error_server_validation: "Servar validatie error.",
		error_idfilledin: "De ID kan niet ingevuld zijn als je een entiteit toevoegd",
		error_routeiddoesnotmatch: "De ID in de url-route komt niet overeen met de ID in the entiteit",
		error_idnotfilledin: "De ID moet meegegeven worden als je probeert te deleten",
		error_toomanyresults: "Teveel resultaten",
		error_nofile: "Geen file om te uploaden",
		error_nobarcodeorsearch: "Geen barcode of zoekterm meegegeven",
		error_constructionyardexistsbutnotactive: "Werf gevonden, maar deze is niet actief",
		not_authenticated: "Je bent niet ingelogd op de server",
		not_found: "De server heeft geen resultaat gevonden",
		forbidden: "Je hebt geen rechten op dit deel van de server"
	},
	grid: {
		noRecords: "Geen data beschikbaar",
		groupPanelEmpty: "Sleep hier een kolom naar toe om erop te groeperen",
		filterClearButton: "Maak leeg",
		filterSubmitButton: "Filter",
		filterTitle: "Filter",
		sortAscending: "Sorteer Oplopend",
		sortDescending: "Sorteer Aflopend",
		pagerItemsPerPage: "items per pagina",
		pagerInfo: "{0} - {1} van {2} items",
		pagerFirstPage: "Ga naar de eerste pagina",
		pagerPreviousPage: "Ga naar de vorige pagina",
		pagerNextPage: "Ga naar de volgende pagina",
		pagerLastPage: "Ga naar de laatste pagina",
		pagerPage: "Pagina",
		pagerOf: "van",
		filterEqOperator: "Is gelijk aan",
		filterNotEqOperator: "Is niet gelijk aan",
		filterIsNullOperator: "Is null",
		filterIsNotNullOperator: "Is niet null",
		filterIsEmptyOperator: "Is leeg",
		filterIsNotEmptyOperator: "Is niet leeg",
		filterStartsWithOperator: "Begint met",
		filterContainsOperator: "Bevat",
		filterNotContainsOperator: "Bevat niet",
		filterEndsWithOperator: "Eindigt op",
		filterGteOperator: "Is groter dan of gelijk aan",
		filterGtOperator: "Is groter dat",
		filterLteOperator: "Is kleiner dan of gelijk aan",
		filterLtOperator: "Is kleiner dan",
		filterIsTrue: "Ja",
		filterIsFalse: "Nee",
		filterBooleanAll: "(Alles)",
		filterAfterOrEqualOperator: "Is na of gelijk aan",
		filterAfterOperator: "Is na",
		filterBeforeOperator: "Is voor",
		filterBeforeOrEqualOperator: "Is voor of gelijk aan",
		filterAndLogic: "En",
		filterOrLogic: "Of"
	},
	upload: {
		cancel: "Anuleer",
		clearSelectedFiles: "Maak leeg",
		dropFilesHere: "Bestanden hier neerzetten om te uploaden",
		headerStatusUploaded: "Klaar",
		headerStatusUploading: "Uploaden...",
		invalidFileExtension: "Bestandstype is net toegestaan.",
		invalidFiles: "Invalid file(s). Please check file upload requirements.",
		invalidMaxFileSize: "Bestand is te groot.",
		invalidMinFileSize: "Bestand is te klein.",
		remove: "Verwijder",
		retry: "Probeer opnieuw",
		select: "Selecteer bestanden...",
		uploadSelectedFiles: "Upload",
		total: "Totaal",
		files: "bestanden"
	},
	dateinput: {
		increment: "Verhoog waarde",
		decrement: "Verlaag waarde"
	},
	calendar: {
		today: "Vandaag"
	},
	datepicker: {
		toggleCalendar: "Toggle calendar"
	},
	multiviewcalendar: {
		prevView: "Navigeer naar vorige view",
		nextView: "Navigeer naar next view"
	},
	changeFutureTasks: "Verander toekomstige taken",
	changeFutureTasksQuestion: "Wilt u voor alle toekomstige taken de ploeg veranderen naar deze nieuwe ploeg?",
	team: "Ploeg",
	teams: "Ploegen",
	startDate: "Startdatum",
	startTime: "Starttijd",
	stopDate: "Stopdatum",
	stopTime: "Stoptijd",
	absent: "Afwezig",
	absenceReason: "Reden van afwezigheid",
	Illness: "Ziekte",
	Vacation: "Vakantie",
	sequenceNumber: "Volgnummer",
	fixedDeliveryDate: "Vaste leverdatum"
};

export default translationsNL;
