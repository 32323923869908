import React, { useEffect, useReducer } from "react";

import map from "lodash/map";
import sortBy from "lodash/sortBy";
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import { IntlService, useInternationalization } from "@progress/kendo-react-intl";
import callApi from "../../../../services/api/callApi";
import Endpoint from "../../../../services/api/endpoint";
import { initialDossierState } from "../../../../state";
import { hideLoader, showLoader } from "../../../../state/actions/loaderActions";
import { dossierReducer } from "../../../../state/reducers";
import { IApplicationState } from "../../../../store";
import { IDossier, IUser } from "../../../../utils/types/models";
import { getWeekRange, getWeekstring } from "../../../../utils/utils";
import { IRoutedTabProps } from "../../../global/routertabpanel";

import styles from "./warehouse.module.scss";

const Warehouse: React.FC<IRoutedTabProps> = () => {
	const { t } = useTranslation();
	const intl: IntlService = useInternationalization();
	const [state, dispatch] = useReducer(dossierReducer, initialDossierState);

	const currentUser: IUser = useSelector((applicationState: IApplicationState) => applicationState.authenticationState.currentUser);
	const reduxDispatch: Dispatch = useDispatch();

	useEffect(() => {
		callApi(dispatch, Endpoint.DossiersPicking, "GET", { companyId: currentUser.currentCompanyId });
	}, [currentUser.currentCompanyId]);

	useEffect(() => {
		if (state.isPickingDossiersLoading) {
			reduxDispatch(showLoader());
		} else if (!state.isPickingDossiersLoading) {
			reduxDispatch(hideLoader());
		}
		return () => {
			reduxDispatch(hideLoader());
		};
	}, [state.isPickingDossiersLoading, reduxDispatch]);

	function refreshDossiers(): void {
		callApi(dispatch, Endpoint.DossiersPicking, "GET", { companyId: currentUser.currentCompanyId });
	}

	return (
		<div className="d-flex flex-column w-100">
			<div className="k-header k-grid-toolbar">
				<div className="toolbarButtonContainer noButtons d-flex width-100 align-items-center" style={{ paddingRight: "9px" }}>
					<div className="flex-grow-1" />
					<i className="refreshButton las la-sync" onClick={refreshDossiers} />
				</div>
			</div>
			<div className={styles.warehouse + " flex-grow-1 d-flex flex-wrap w-100"}>
				{map(sortBy(state.pickingDossiers, "installationWeek"), (dossier: IDossier) => {
					return (
						<div key={dossier.dossierNumber} className={styles.dossierLabel + " flex-grow-1 d-flex align-items-center"}>
							<QRCode className={styles.qr} value={"{/#}" + dossier.id} size={256} fgColor="#f16d23" />
							<div className={styles.fields + " d-flex flex-column flex-wrap justify-content-center"}>
								<h2>{t("dossier")}:</h2>
								<h2>{t("customer")}:</h2>
								<h2>{t("constructionYard")}:</h2>
								<h2>{t("installationWeek")}:</h2>
								<div>{dossier.dossierNumber}</div>
								<div>{dossier.customer.name}</div>
								<div>{dossier.constructionYard.name}</div>
								<div>{dossier.installationWeek && getWeekstring(getWeekRange(dossier.installationWeek, intl))}</div>
							</div>
						</div>
					);
				})}
				{state.pickingDossiers && state.pickingDossiers.length % 2 !== 0 && <div className={styles.dossierLabel + " " + styles.fillingLabel + " flex-grow-1"} />}
			</div>
		</div>
	);
};

export default Warehouse;
