import { Reducer } from "react";

import { initialStatisticsState, IStatisticsState } from "..";
import Endpoint from "../../services/api/endpoint";
import { IAction } from "../../utils/types/types";

const statisticsReducer: Reducer<IStatisticsState, IAction> = (state: IStatisticsState = initialStatisticsState, action: IAction) => {
	switch (action.type) {
		case "REQUEST_GET_" + Endpoint.StatisticsRunningDossiersByColor:
			state = {
				...state,
				runningDossiersByColorLoading: false,
				runningDossiersByColorResult: [],
				runningDossiersByColorError: null
			};
			break;
		case "REQUEST_SUCCESS_GET_" + Endpoint.StatisticsRunningDossiersByColor:
			if (action.payload && action.payload.response && action.payload.response.data) {
				state = {
					...state,
					runningDossiersByColorResult: action.payload.response.data
				};
			}
			break;
		case "REQUEST_FAILURE_GET_" + Endpoint.StatisticsRunningDossiersByColor:
			state = {
				...state,
				runningDossiersByColorError: action.payload.error
			};
			break;
		case "REQUEST_END_GET_" + Endpoint.StatisticsRunningDossiersByColor:
			state = {
				...state,
				runningDossiersByColorLoading: false
			};
			break;
		case "CLEAR_GET_" + Endpoint.StatisticsRunningDossiersByColor:
			state = {
				...state,
				runningDossiersByColorLoading: false,
				runningDossiersByColorResult: [],
				runningDossiersByColorError: null
			};
			break;
		default:
			return state;
	}
	return state;
};

export default statisticsReducer;