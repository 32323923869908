enum Endpoint {
	Users = "USERS",
	UsersList = "USERS_LIST",
	UserLeadInstallers = "USER_LEAD_INSTALLERS",
	UserColleagueInstallers = "USER_COLLEAGUE_INSTALLERS",
	Installers = "INSTALLERS",
	CurrentUser = "CURRENT_USER",
	Roles = "ROLES",
	RolesList = "ROLES_LIST",
	Permissions = "PERMISSIONS",
	Companies = "COMPANIES",
	Municipalities = "COUNTRIES",
	Suppliers = "SUPPLIERS",
	SuppliersList = "SUPPLIERS_LIST",
	Products = "PRODUCTS",
	ProductsList = "PRODUCTS_LIST",
	ProductsBarcodeLookup = "PRODUCTS_BARCODE_LOOKUP",
	Customers = "CUSTOMERS",
	CustomersList = "CUSTOMERS_LIST",
	Contractors = "CONTRACTORS",
	ContractorsList = "CONTRACTORS_LIST",
	ConstructionYards = "CONSTRUCTIONYARDS",
	ConstructionYardsList = "CONSTRUCTIONYARDS_LIST",
	ConstructionYardsCopyFromCustomer = "CONSTRUCTIONYARDS_COPY_FROM_CUSTOMER",
	Dossiers = "DOSSIERS",
	DossiersList = "DOSSIERS_LIST",
	DossiersCopy = "DOSSIERS_COPY",
	DossiersProcurementList = "DOSSIERS_PROCUREMENT_LIST",
	DossiersToBePlanned = "DOSSIERS_TO_BE_PLANNED",
	DossiersPicking = "DOSSIERS_PICKING",
	DossiersByColor = "DOSSIERS_BYCOLOR_LIST",
	Projects = "PROJECTS",
	ProjectsList = "PROJECTS_LIST",
	Brands = "BRANDS",
	BrandsList = "BRANDS_LIST",
	ProductTypes = "PRODUCTTYPES",
	ProductTypesList = "PRODUCTTYPES_LIST",
	ProductStock = "PRODUCT_STOCK",
	ProductStockList = "PRODUCT_STOCK_LIST",
	Stock = "STOCK",
	StockList = "STOCK_LIST",
	Shops = "SHOPS",
	ShopsList = "SHOPS_LIST",
	CommunicationTemplates = "COMMUNICATIONTEMPLATES",
	CommunicationTemplatesList = "COMMUNICATIONTEMPLATES_LIST",
	ContactTypes = "CONTACTTYPES",
	ContactTypesList = "CONTACTTYPES_LIST",
	Prices = "PRICES",
	PriceTotal = "PRICES_TOTAL",
	Remarks = "REMARKS",
	MyRemarks = "MY_REMARKS",
	MyRemarksList = "MY_REMARKS_LIST",
	Tasks = "TASKS",
	TaskUpdate = "TASK_UPDATE",
	PlannedTasks = "PLANNED_TASKS",
	ProcurementList = "PROCUREMENT_LIST",
	ProcurementListByPok = "PROCUREMENT_LIST_BY_POK",
	ProcurementCreatePurchaseOrders = "PROCUREMENT_CREATE_PURCHASE_ORDERS",
	ProcurementOrderPurchaseOrdersViaMail = "PROCUREMENT_ORDER_PURCHASE_ORDERS_VIA_MAIL",
	ProcurementResentPurchaseOrderMail = "PROCUREMENT_RESENT_PURCHASE_ORDER_MAIL",
	ProcurementOrderPurchaseOrdersViaTradePlace = "PROCUREMENT_ORDER_PURCHASE_ORDERS_VIA_TRADEPLACE",
	ProcurementSetPurchaseOrdersChecked = "PROCUREMENT_SET_PURCHASE_ORDERS_CHECKED",
	PrintLabels = "PRINT_LABELS",
	Invoicing = "INVOICING",
	StatisticsRunningDossiersByColor = "RUNNING_DOSSIERS_BY_COLOR",
	ArticleDeliveries = "ARTICLE_DELIVERIES",
	ArticleDeliveriesDelete = "ARTICLE_DELIVERIES_DELETE",
	Teams = "TEAMS",
	TeamsList = "TEAMS_LIST",
	TeamsGetColleague = "TEAMS_GET_COLLEAGUE",
	FutureTasksTeamChange = "FUTURE_TASKS_TEAM_CHANGE",
	Settings = "SETTINGS",
	SettingsByKey = "SETTINGS_BY_KEY",
	TotalConfigurationField = "TOTAL_CONFIGURATION_FIELD",
	DownloadDossierExcel = "DOWNLOAD_DOSSIER_EXCEL"
}

export default Endpoint;
