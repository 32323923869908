import { TFunction } from "i18next";

import { hideLoader, showLoader } from "../state/actions/loaderActions";
import store from "../store";
import { isProcurementArticle } from "../utils/editorUtils";
import { IArticle, IDossier } from "../utils/types/models";
import { IRequestOptions } from "../utils/types/types";
import { urlFormat } from "../utils/utils";
import Endpoint from "./api/endpoint";
import endpoints from "./api/endpoints";
import { fetch } from "./authentication";
import handleError from "./errorHandling";
import { notify } from "./notification";

export async function printLabels(dossier: IDossier, t: TFunction, ...articles: IArticle[]): Promise<void> {
	store.dispatch(showLoader());
	let hasErrors: boolean = false;
	for (const article of articles) {
		if (article.id > 0 && isProcurementArticle(article)) {
			try {
				const requestOptions: IRequestOptions = {
					url: urlFormat(endpoints[Endpoint.PrintLabels], {articleId: article.id}),
					method: "GET",
					headers: {}
				};

				let countIteration: number = 0;
				while (countIteration < article.count) {
					await fetch(requestOptions, true);
					countIteration++;
				}
			} catch (err) {
				handleError(err);
				hasErrors = true;
			}
		}
	}
	if (!hasErrors) {
		notify(t("success"), t((articles.length > 1 ? "labelsPrinted" : "labelPrinted")), "success");
	}
	store.dispatch(hideLoader());
}