import React, { useEffect, useReducer, useState } from "react";

import { ComboBox, ComboBoxChangeEvent, ComboBoxCloseEvent, ComboBoxFilterChangeEvent, ListItemProps } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { useTranslation } from "react-i18next";

import callApi from "../../../services/api/callApi";
import Endpoint from "../../../services/api/endpoint";
import { getInitialSystemState } from "../../../state";
import { municipalityReducer } from "../../../state/reducers";
import { IAddress, IMunicipality } from "../../../utils/types/models";
import { isNullOrEmpty } from "../../../utils/utils";

interface IProps {
	address: IAddress;
	addressField: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	setAddress: (address: IAddress) => void;
	required: boolean;
	readonly: boolean;
}

const AddressEdit: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const [municipalityState, dispatch] = useReducer(municipalityReducer, getInitialSystemState<IMunicipality>());
	const [municipality, setMunicipality] = useState<IMunicipality>();

	useEffect(() => {
		if (!isNullOrEmpty(props.address.postalCode)) {
			setMunicipality({ id: 0, code: props.address.postalCode, name: "", province: "", country: "" });
		}
	}, [props.address.postalCode]);

	function onMunicipalityClose(event: ComboBoxCloseEvent): void {
		const newAddress: IAddress = { ...props.address };
		if (event.target.value && event.target.value.id) {
			const selectedMunicipality: IMunicipality = event.target.value;
			newAddress.postalCode = selectedMunicipality.code;
			newAddress.municipality = selectedMunicipality.name;
			newAddress.province = selectedMunicipality.province;
			newAddress.country = selectedMunicipality.country;
		} else if (event.target.value) {
			newAddress.postalCode = event.target.value.code;
		} else {
			newAddress.postalCode = "";
		}
		props.setAddress(newAddress);
	}

	function onMunicipalityChange(event: ComboBoxChangeEvent): void {
		setMunicipality(event.value);
	}

	function municipalityItem(li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps): React.ReactNode {
		return React.cloneElement(li, li.props, itemProps.dataItem.code + " " + itemProps.dataItem.name);
	}

	return (
		<React.Fragment>
			<div className="row">
				<div className="col-8">
					<label className="k-form-field">
						<span>{t("street")}{props.required && " *"}</span>
						<Input name={props.addressField + ".street"} className="full-width-field" value={props.address.street} onChange={props.onChange} required={props.required} disabled={props.readonly} />
					</label>
				</div>
				<div className="col-4">
					<label className="k-form-field">
						<span>{t("number")}{props.required && " *"}</span>
						<Input name={props.addressField + ".number"} className="full-width-field" value={props.address.number} onChange={props.onChange} required={props.required} disabled={props.readonly} />
					</label>
				</div>
			</div>
			<div className="row">
				<div className="col-4">
					<label className="k-form-field">
						<span>{t("postalCode")}{props.required && " *"}</span>
						<ComboBox
							name={props.addressField + ".postalCode"}
							className="full-width-field hiddenTrigger"
							data={municipalityState.entities}
							dataItemKey="id"
							textField="code"
							itemRender={municipalityItem}
							value={municipality}
							onClose={onMunicipalityClose}
							onChange={onMunicipalityChange}
							allowCustom={true}
							clearButton={false}
							filterable
							onFilterChange={(event: ComboBoxFilterChangeEvent) => callApi(dispatch, Endpoint.Municipalities, "GET", { search: event.filter.value })}
							required={props.required}
							disabled={props.readonly}
						/>

					</label>
				</div>
				<div className="col-8">
					<label className="k-form-field">
						<span>{t("municipality")}{props.required && " *"}</span>
						<Input
							name={props.addressField + ".municipality"}
							className="full-width-field"
							value={props.address.municipality}
							onChange={props.onChange}
							required={props.required}
							disabled={props.readonly}
						/>
					</label>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<label className="k-form-field">
						<span>{t("province")}</span>
						<Input name={props.addressField + ".province"} className="full-width-field" value={props.address.province} onChange={props.onChange} disabled={props.readonly} />
					</label>
				</div>
				<div className="col">
					<label className="k-form-field">
						<span>{t("country")}{props.required && " *"}</span>
						<Input name={props.addressField + ".country"} className="full-width-field" value={props.address.country} onChange={props.onChange} required={props.required} disabled={props.readonly} />
					</label>
				</div>
			</div>
		</React.Fragment>
	);
};

export default AddressEdit;