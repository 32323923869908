import React, { useState } from "react";

import { DatePicker, DatePickerChangeEvent, DateTimePicker } from "@progress/kendo-react-dateinputs";
import { Input } from "@progress/kendo-react-inputs";
import dayjs from "dayjs";
import remove from "lodash/remove";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { derender, render } from "../../../../../../state/actions/renderActions";
import { getDate, handleChange } from "../../../../../../utils/editorUtils";
import { IDossier, IDossierTask } from "../../../../../../utils/types/models";
import { newKey } from "../../../../../../utils/utils";
import WeekPicker, { WeekPickerChangeEvent } from "../../../../../global/editor/weekPicker";
import { IDossierSectionProps } from "../../editor";
import TaskEditor from "./taskEditor";
import TasksGrid from "./tasksGrid";

import style from "../editor.module.scss";

interface IProps extends IDossierSectionProps {
	updateArticlePickDate: () => void;
}

const Planning: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const [currentNewId, setCurrentNewId] = useState(-1);
	const dispatch: Dispatch = useDispatch();

	function onChange(event: React.ChangeEvent<HTMLInputElement> | DatePickerChangeEvent | WeekPickerChangeEvent): void {
		const newDossier: IDossier = handleChange(props.dossier, event);

		if (event.target.name === "productionWeek") {
			const installationWeek: Date = dayjs(event.target.value).add(2, "week").toDate();
			newDossier.installationWeek = new Date(installationWeek.getTime() - installationWeek.getTimezoneOffset() * 60000);
		}

		props.onChange(newDossier);
	}

	function showEditor(record?: IDossierTask): void {
		const key: string = newKey("Task_Editor");
		dispatch(
			render(key, TaskEditor, {
				record,
				onClose: (task: IDossierTask) => {
					if (task) {
						const newDossier: IDossier = { ...props.dossier };
						if (task.id) {
							remove(newDossier.tasks, { id: task.id });
						} else {
							task.id = currentNewId;
							setCurrentNewId(currentNewId - 1);
						}
						newDossier.tasks.push(task);
						props.onChange(newDossier);
					}
					dispatch(derender(key));
				},
				readOnly: props.readOnly
			})
		);
	}

	function onChangeTask(tasks: IDossierTask[]): void {
		const newDossier: IDossier = {
			...props.dossier,
			tasks: [...tasks]
		};
		props.onChange(newDossier);
	}

	return (
		<div className="k-form d-flex flex-column h-100">
			<div className="row">
				<div className="col">
					<h2 className={style.accentTitle}>{t("planning")}</h2>
				</div>
				<div className="col" style={{ textAlign: "right" }}>
					<h3>
						{props.dossier?.dossierNumber} - {props.dossier?.customer?.name}
					</h3>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<label className="k-form-field">
						<span>{t("created")}</span>
						<DateTimePicker value={getDate(props.dossier.createdDate)} className="full-width-field" disabled />
					</label>
				</div>
				<div className="col">
					<label className="k-form-field">
						<span>{t("lastUpdated")}</span>
						<DateTimePicker value={getDate(props.dossier.updatedDate)} className="full-width-field" disabled />
					</label>
				</div>
				<div className="col">
					<label className="k-form-field">
						<span>{t("sellingDate")}</span>
						<DatePicker
							name="sellingDate"
							value={getDate(props.dossier.sellingDate)}
							onChange={onChange}
							className="full-width-field"
							formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
							disabled={props.readOnly}
						/>
					</label>
				</div>
				<div className="col-4">
					<div className="row">
						<div className="col">
							<label className="k-form-field">
								<span>{t("picked")}</span>
								<DatePicker
									name="pickedDate"
									value={getDate(props.dossier.pickedDate)}
									onChange={onChange}
									className="full-width-field"
									formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
									disabled={props.readOnly}
								/>
							</label>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<label className="k-form-field">
						<span>{t("deliveryDate")}</span>
						<DatePicker
							name="deliveryDate"
							value={getDate(props.dossier.deliveryDate)}
							onChange={onChange}
							className="full-width-field"
							formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
							disabled={props.readOnly}
						/>
					</label>
				</div>
				<div className="col">
					<label className="k-form-field">
						<span>{t("installationWeek")}</span>
						<WeekPicker name="installationWeek" value={props.dossier.installationWeek} onChange={onChange} className="full-width-field" disabled={props.readOnly} />
					</label>
				</div>
				<div className="col">
					<label className="k-form-field">
						<span>{t("productionWeek")}</span>
						<WeekPicker name="productionWeek" value={props.dossier.productionWeek} onChange={onChange} className="full-width-field" disabled={props.readOnly} />
					</label>
				</div>
				<div className="col-4">
					<label className="k-form-field">
						<span>{t("scanLocation")}</span>
						<Input name="scanLocation" value={props.dossier.scanLocation} onChange={onChange} className="full-width-field" disabled={props.readOnly} />
					</label>
				</div>
			</div>
			<div className="row flex-grow-1" style={{ paddingTop: "20px" }}>
				<div className="col">
					<fieldset className="h-100">
						<legend className={style.accentTitle}>{t("tasks")}</legend>
						<TasksGrid
							tasks={props.dossier.tasks}
							dossierPrintData={{
								description: props.dossier?.description,
								dossierNumber: props.dossier?.dossierNumber,
								customerName: props.dossier?.customer?.name
							}}
							showEditor={showEditor}
							onChange={onChangeTask}
							readOnly={props.readOnly}
						/>
					</fieldset>
				</div>
			</div>
		</div>
	);
};

export default Planning;
