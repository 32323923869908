import React, { useEffect, useReducer, useRef, useState } from "react";

import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Endpoint from "../../../../services/api/endpoint";
import { getInitialState } from "../../../../state";
import { shopReducer } from "../../../../state/reducers";
import { IApplicationState } from "../../../../store";
import { handleChange } from "../../../../utils/editorUtils";
import { IAddress, IShop, IUser } from "../../../../utils/types/models";
import { isNullOrEmpty } from "../../../../utils/utils";
import EntityEditor, { AddressEdit, IEditorProps, IEditScreenProps, YesNoSwitch } from "../../../global/editor";

const ShopEditor: React.FC<IEditorProps<IShop>> = (props: IEditorProps<IShop>) => {
	const { t } = useTranslation();
	const [shopState, dispatch] = useReducer(shopReducer, getInitialState<IShop>());
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);
	const initalShop: IShop = {
		id: (props as IEditScreenProps<IShop>).recordId || 0,
		name: "",
		address: {
			street: "",
			number: "",
			postalCode: "",
			municipality: "",
			province: "",
			country: "",
			fullAddress: ""
		},
		active: true,
		companyId: currentUser.currentCompanyId
	};
	const [shop, setShop] = useState<IShop>(initalShop);
	const [dataChange, setDataChanged] = useState<boolean>(false);
	const firstField: React.MutableRefObject<Input> = useRef();

	useEffect(() => {
		if (shopState.entity) {
			setShop(shopState.entity);
		}
	}, [shopState.entity]);

	function onChange(event: React.ChangeEvent<HTMLInputElement> | SwitchChangeEvent | ComboBoxChangeEvent): void {
		setShop(handleChange(shop, event));
		setDataChanged(true);
	}

	function setAddress(address: IAddress): void {
		const newShop: IShop = { ...shop };
		newShop.address = address;
		setShop(newShop);
		setDataChanged(true);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(shop.name)) {
			messages.push(t("fill_in_required_field", { field: t("name").toLowerCase() }));
		}
		if (isNullOrEmpty(shop.address.street)) {
			messages.push(t("fill_in_required_field", { field: t("street").toLowerCase() }));
		}
		if (isNullOrEmpty(shop.address.number)) {
			messages.push(t("fill_in_required_field", { field: t("number").toLowerCase() }));
		}
		if (isNullOrEmpty(shop.address.postalCode)) {
			messages.push(t("fill_in_required_field", { field: t("postalCode").toLowerCase() }));
		}
		if (isNullOrEmpty(shop.address.municipality)) {
			messages.push(t("fill_in_required_field", { field: t("municipality").toLowerCase() }));
		}
		if (isNullOrEmpty(shop.address.country)) {
			messages.push(t("fill_in_required_field", { field: t("country").toLowerCase() }));
		}
		return messages;
	}

	const readonly: boolean = (props as IEditScreenProps<IShop>).readonly;
	return (
		<EntityEditor
			width="70%"
			record={shop}
			endpoint={Endpoint.Shops}
			entityState={shopState}
			dispatch={dispatch}
			dataChanged={dataChange}
			readonly={readonly}
			entityType={t("shop")}
			recordName={shop.name}
			close={props.close}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<div className="k-form">
				<label className="k-form-field">
					<span>{t("name")} *</span>
					<Input name="name" ref={firstField} className="full-width-field" value={shop.name} onChange={onChange} required disabled={readonly} />
				</label>
				<AddressEdit address={shop.address} addressField="address" onChange={onChange} setAddress={setAddress} required readonly={readonly} />
				{!props.hideActive && (
					<div className="k-form-field">
						<span>{t("active")}</span>
						<YesNoSwitch name="active" checked={shop.active} onChange={onChange} disabled={readonly} />
					</div>
				)}
			</div>
		</EntityEditor>
	);
};

export default ShopEditor;
