import React, { useState } from "react";

import remove from "lodash/remove";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { derender, render } from "../../../../../../state/actions/renderActions";
import { IDossier, IRemark } from "../../../../../../utils/types/models";
import { newKey } from "../../../../../../utils/utils";
import { IDossierSectionProps } from "../../editor";
import RemarkEditor from "./editor";
import RemarksGrid from "./remarksGrid";

import style from "../editor.module.scss";

const Remarks: React.FC<IDossierSectionProps> = (props: IDossierSectionProps) => {
	const { t } = useTranslation();
	const [currentNewId, setCurrentNewId] = useState(-1);
	const dispatch: Dispatch = useDispatch();

	function showEditor(record?: IRemark): void {
		const key: string = newKey("Remark_Editor");
		dispatch(
			render(key, RemarkEditor, {
				record,
				articles: props.dossier.articles,
				onClose: (remark: IRemark) => {
					if (remark) {
						const newDossier: IDossier = { ...props.dossier };
						if (remark.id) {
							remove(newDossier.remarks, { id: remark.id });
						} else {
							remark.id = currentNewId;
							setCurrentNewId(currentNewId - 1);
						}
						newDossier.remarks.push(remark);
						props.onChange(newDossier);
					}
					dispatch(derender(key));
				},
				readOnly: props.readOnly
			})
		);
	}

	function onChange(remarks: IRemark[]): void {
		const newDossier: IDossier = { ...props.dossier };
		newDossier.remarks = remarks;
		props.onChange(newDossier);
	}

	return (
		<div className="h-100 d-flex flex-column k-form">
			<div className="row">
				<div className="col">
					<h2 className={style.accentTitle}>{t("remarks")}</h2>
				</div>
				<div className="col" style={{ textAlign: "right" }}>
					<h3>
						{props.dossier?.dossierNumber} - {props.dossier?.customer?.name}
					</h3>
				</div>
			</div>

			<div className="row flex-grow-1">
				<div className="col">
					<RemarksGrid
						remarks={props.dossier.remarks}
						showEditor={showEditor}
						onChange={onChange}
						readOnly={props.readOnly}
						remarkPrintData={{ customerName: props.dossier?.customer?.name, description: props.dossier?.description, dossierNumber: props.dossier?.dossierNumber }}
					/>
				</div>
			</div>
		</div>
	);
};

export default Remarks;
