import { CompositeFilterDescriptor } from "@progress/kendo-data-query";

import { IAction } from "../../utils/types/types";

export function setFilter(filter: CompositeFilterDescriptor): IAction {
	return {
		type: "SET_FILTER",
		filter
	};
}

export function addFilter(field: string, operator: string, value: unknown): IAction {
	return {
		type: "ADD_FILTER",
		field,
		operator,
		value
	};
}

export function removeFilter(field: string): IAction {
	return {
		type: "REMOVE_FILTER",
		field
	};
}