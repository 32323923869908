import React, { useEffect, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";

import { History, Location } from "history";

import Confirm from "./confirm";

interface IProps {
	when: boolean;
	title: string;
	onConfirm: (setNavigationAllowed: (navigationAllowed: boolean) => void) => void;
	children: string | React.ReactElement;
}

const PendingSavePrompt: React.FC<IProps> = (props: IProps) => {
	const [modalVisible, setModalVisible] = useState(false);
	const [nextLocation, setNextLocation] = useState<Location>(null);
	const [navigationAllowed, setNavigationAllowed] = useState(false);
	const history: History = useHistory();

	useEffect(() => {
		if (!modalVisible && navigationAllowed && nextLocation) {
			history.push(nextLocation);
		}
	}, [modalVisible, navigationAllowed, history, nextLocation]);

	function handleBlockedNavigation(location: Location): boolean {
		if (!navigationAllowed) {
			setModalVisible(true);
			setNextLocation(location);
			return false;
		}

		return true;
	}

	function navigate(confirmed: boolean): void {
		setModalVisible(false);
		if (confirmed) {
			props.onConfirm(setNavigationAllowed);
		} else {
			setNavigationAllowed(true);
		}
	}

	return (
		<React.Fragment>
			<Prompt when={props.when} message={handleBlockedNavigation} />
			{modalVisible && (
				<Confirm
					title={props.title}
					onConfirm={() => navigate(true)}
					onDecline={() => navigate(false)}
					onCancel={() => setModalVisible(false)}
				>
					{props.children}
				</Confirm>
			)}
		</React.Fragment>
	);
};

export default PendingSavePrompt;