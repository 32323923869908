import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { AxiosError } from "axios";
import { UserInfo } from "react-adal";

import {
	IConstructionYard,
	IDossier,
	IExternalProductInformation,
	IInvoiceLine,
	IPriceCalculationResult,
	IPriceTotalCalculationResult,
	IProcurementItem,
	IProduct,
	ITeam,
	IUser
} from "../utils/types/models";
import { IDossierColorCount } from "./statistics";

export interface IEntityState<T> {
	isListLoading: boolean;
	list: T[];
	totalCount: number;
	listLoadError: AxiosError;

	areEntitiesLoading: boolean;
	entities: T[];
	entitiesLoadError: AxiosError;

	isEntityLoading: boolean;
	entity: T;
	entityLoadError: AxiosError;

	addedEntity: T;
	isAdding: boolean;
	addError: AxiosError;

	updatedEntity: T;
	isUpdating: boolean;
	updateError: AxiosError;

	isDeleting: boolean;
	deleteError: AxiosError;
}

export function getInitialState<T>(): IEntityState<T> {
	return {
		isListLoading: false,
		list: [],
		totalCount: 0,
		listLoadError: null,

		areEntitiesLoading: false,
		entities: [],
		entitiesLoadError: null,

		isEntityLoading: false,
		entity: null,
		entityLoadError: null,

		addedEntity: null,
		isAdding: false,
		addError: null,

		updatedEntity: null,
		isUpdating: false,
		updateError: null,

		isDeleting: false,
		deleteError: null
	};
}

export interface IAuthenticationState {
	authenticated: boolean;
	// tslint:disable-next-line: no-any
	loginError: any;
	userInfo: UserInfo;

	loadingUser: boolean;
	currentUser: IUser;
	savingCurrentUser: boolean;
	savingCurrentUserError: string;
}

export interface IFileState {
	isDownloading: boolean;
	downloadFile: Blob;
	downloadError: AxiosError;
	filename: string;
}

export const initialFileState: IFileState = {
	isDownloading: false,
	downloadFile: null,
	downloadError: null,
	filename: null
};

export interface IConstructionYardState extends IEntityState<IConstructionYard> {
	isCopying: boolean;
	copiedEntity: IConstructionYard;
	copyError: AxiosError;
}

export const initialConstructionYardState: IConstructionYardState = {
	...getInitialState(),
	isCopying: false,
	copiedEntity: null,
	copyError: null
};

export interface IDossierState extends IEntityState<IDossier> {
	isCopying: boolean;
	copiedEntity: IDossier;
	copyError: AxiosError;

	isPickingDossiersLoading: boolean;
	pickingDossiers: IDossier[];
	pickingDossiersError: AxiosError;

	isDossiersByColorLoading: boolean;
	dossiersByColorTotal: number;
	dossiersByColor: IDossier[];
	dossiersByColorError: AxiosError;
}

export const initialDossierState: IDossierState = {
	...getInitialState(),
	isCopying: false,
	copiedEntity: null,
	copyError: null,

	isPickingDossiersLoading: false,
	pickingDossiers: [],
	pickingDossiersError: null,

	isDossiersByColorLoading: false,
	dossiersByColorTotal: 0,
	dossiersByColor: [],
	dossiersByColorError: null
};

export interface IUserState extends IEntityState<IUser> {
	isLeadsLoading: boolean;
	leadsEntities: IUser[];
	leadsError: AxiosError;

	isColleaguesLoading: boolean;
	colleaguesEntities: IUser[];
	colleaguesError: AxiosError;
}

export const initalUserState: IUserState = {
	...getInitialState(),
	isLeadsLoading: false,
	leadsEntities: [],
	leadsError: null,

	isColleaguesLoading: false,
	colleaguesEntities: [],
	colleaguesError: null
};

export interface ITeamState extends IEntityState<ITeam> {
	isColleagueLoading: boolean;
	colleague: IUser;
	colleagueError: AxiosError;
}

export const initialTeamState: ITeamState = {
	...getInitialState(),
	isColleagueLoading: false,
	colleague: null,
	colleagueError: null
};

export interface ILoaderState {
	showLoader: boolean;
}

export interface IPermssionState {
	permissions: {
		[key: string]: {
			[key: string]: string[];
		};
	};
	loading: boolean;
}

export const initialPermissionState: IPermssionState = {
	permissions: {},
	loading: false
};

export interface IPriceState {
	isExecuting: boolean;
	priceResult: IPriceCalculationResult;
	error: string;
}

export const initialPriceState: IPriceState = {
	isExecuting: false,
	priceResult: null,
	error: null
};

export interface IPriceTotalState {
	isExecuting: boolean;
	priceResult: IPriceTotalCalculationResult;
	error: string;
}

export const initialPriceTotalState: IPriceTotalState = {
	isExecuting: false,
	priceResult: null,
	error: null
};

export const initialTotalState: ITotalState = {
	isExecuting: false,
	totalResult: null,
	error: null
};

export interface ITotalState {
	isExecuting: boolean;
	totalResult: object;
	error: string;
}

export interface IProductState extends IEntityState<IProduct> {
	isBarcodeLookupLoading: boolean;
	barcodeLookupResult: IExternalProductInformation;
	barcodeLookupError: AxiosError;
}

export const initialProductState: IProductState = {
	...getInitialState(),
	isBarcodeLookupLoading: false,
	barcodeLookupResult: null,
	barcodeLookupError: null
};

export interface IRenderConfig {
	key: string;
	component: React.ComponentType;
	props?: object;
}

export interface IRenderState {
	components: IRenderConfig[];
}

export interface ISystemEntityState<T> {
	isLoading: boolean;
	entities: T[];
	loadError: string;
}

export function getInitialSystemState<T>(): ISystemEntityState<T> {
	return {
		isLoading: false,
		entities: [],
		loadError: null
	};
}

export interface IProcurementState {
	isListLoading: boolean;
	list: IProcurementItem[];
	totalCount: number;
	listLoadError: AxiosError;

	isUpdating: boolean;
	updated: boolean;
	updateError: AxiosError;

	tradeplaceFile: string | ArrayBuffer | ArrayBufferView | Blob;
}

export const initialProcurementState: IProcurementState = {
	isListLoading: false,
	list: [],
	totalCount: 0,
	listLoadError: null,

	isUpdating: false,
	updated: false,
	updateError: null,

	tradeplaceFile: null
};

export interface IInvoicingState {
	isLoading: boolean;
	entities: IInvoiceLine[];
	loadError: AxiosError;
}

export const initialInvoicingState: IInvoicingState = {
	isLoading: false,
	entities: [],
	loadError: null
};

export interface IStatisticsState {
	runningDossiersByColorLoading: boolean;
	runningDossiersByColorResult: IDossierColorCount[];
	runningDossiersByColorError: AxiosError;
}

export const initialStatisticsState: IStatisticsState = {
	runningDossiersByColorLoading: false,
	runningDossiersByColorResult: [],
	runningDossiersByColorError: null
};

export interface IDossierManagementFilterState {
	filter: CompositeFilterDescriptor;
	debounce: boolean;
}
