import { IDictionary, IEndpointType } from "../../utils/types/types";
import Endpoint from "./endpoint";

let endpoints: IDictionary<IEndpointType>;
endpoints = {};
endpoints[Endpoint.Roles] = {
	href: "/api/roles/:id",
	authNeeded: true
};
endpoints[Endpoint.RolesList] = {
	href: "/api/roles/list",
	authNeeded: true
};
endpoints[Endpoint.Users] = {
	href: "/api/users/:id",
	authNeeded: true
};
endpoints[Endpoint.UserLeadInstallers] = {
	href: "/api/users/leadInstallers",
	authNeeded: true
};
endpoints[Endpoint.UserColleagueInstallers] = {
	href: "/api/users/colleagueInstallers",
	authNeeded: true
};
endpoints[Endpoint.UsersList] = {
	href: "/api/users/list",
	authNeeded: true
};
endpoints[Endpoint.CurrentUser] = {
	href: "/api/users/currentuser",
	authNeeded: true
};
endpoints[Endpoint.Installers] = {
	href: "api/users/installers",
	authNeeded: true
};
endpoints[Endpoint.Permissions] = {
	href: "/api/permissions",
	authNeeded: true
};
endpoints[Endpoint.Companies] = {
	href: "/api/companies",
	authNeeded: true
};
endpoints[Endpoint.Municipalities] = {
	href: "/api/municipalities",
	authNeeded: true
};
endpoints[Endpoint.Suppliers] = {
	href: "/api/suppliers/:id",
	authNeeded: true
};
endpoints[Endpoint.SuppliersList] = {
	href: "/api/suppliers/list",
	authNeeded: true
};
endpoints[Endpoint.Products] = {
	href: "/api/products/:id",
	authNeeded: true
};
endpoints[Endpoint.ProductsList] = {
	href: "/api/products/list",
	authNeeded: true
};
endpoints[Endpoint.ProductStock] = {
	href: "/api/products/:productId/stock/:id",
	authNeeded: true
};
endpoints[Endpoint.ProductStockList] = {
	href: "/api/products/:productId/stock/list",
	authNeeded: true
};
endpoints[Endpoint.Stock] = {
	href: "/api/stock",
	authNeeded: true
};
endpoints[Endpoint.StockList] = {
	href: "/api/stock/list",
	authNeeded: true
};
endpoints[Endpoint.ProductsBarcodeLookup] = {
	href: "/api/products/barcodelookup",
	authNeeded: true
};
endpoints[Endpoint.Customers] = {
	href: "/api/customers/:id",
	authNeeded: true
};
endpoints[Endpoint.CustomersList] = {
	href: "/api/customers/list",
	authNeeded: true
};
endpoints[Endpoint.Contractors] = {
	href: "/api/contractors/:id",
	authNeeded: true
};
endpoints[Endpoint.ContractorsList] = {
	href: "/api/contractors/list",
	authNeeded: true
};
endpoints[Endpoint.ConstructionYards] = {
	href: "/api/constructionyards/:id",
	authNeeded: true
};
endpoints[Endpoint.ConstructionYardsList] = {
	href: "/api/constructionyards/list",
	authNeeded: true
};
endpoints[Endpoint.ConstructionYardsCopyFromCustomer] = {
	href: "/api/constructionyards/copyfromcustomer/:customerId",
	authNeeded: true
};
endpoints[Endpoint.Dossiers] = {
	href: "/api/dossiers/:id",
	authNeeded: true
};
endpoints[Endpoint.DossiersList] = {
	href: "/api/dossiers/list",
	authNeeded: true
};
endpoints[Endpoint.DossiersCopy] = {
	href: "/api/dossiers/:id/copy",
	authNeeded: true
};
endpoints[Endpoint.DossiersToBePlanned] = {
	href: "/api/dossiers/listToBePlanned",
	authNeeded: true
};
endpoints[Endpoint.DossiersPicking] = {
	href: "/api/dossiers/forpicking",
	authNeeded: true
};
endpoints[Endpoint.DossiersByColor] = {
	href: "/api/dossiers/listbycolor/:color",
	authNeeded: true
};
endpoints[Endpoint.Projects] = {
	href: "/api/projects/:id",
	authNeeded: true
};
endpoints[Endpoint.ProjectsList] = {
	href: "/api/projects/list",
	authNeeded: true
};
endpoints[Endpoint.Brands] = {
	href: "/api/brands/:id",
	authNeeded: true
};
endpoints[Endpoint.BrandsList] = {
	href: "/api/brands/list",
	authNeeded: true
};
endpoints[Endpoint.ProductTypes] = {
	href: "/api/producttypes/:id",
	authNeeded: true
};
endpoints[Endpoint.ProductTypesList] = {
	href: "/api/producttypes/list",
	authNeeded: true
};
endpoints[Endpoint.Shops] = {
	href: "/api/shops/:id",
	authNeeded: true
};
endpoints[Endpoint.ShopsList] = {
	href: "/api/shops/list",
	authNeeded: true
};
endpoints[Endpoint.CommunicationTemplates] = {
	href: "/api/communicationtemplates/:id",
	authNeeded: true
};
endpoints[Endpoint.CommunicationTemplatesList] = {
	href: "/api/communicationtemplates/list",
	authNeeded: true
};
endpoints[Endpoint.ContactTypes] = {
	href: "/api/contacttypes/:id",
	authNeeded: true
};
endpoints[Endpoint.ContactTypesList] = {
	href: "/api/contacttypes/list",
	authNeeded: true
};
endpoints[Endpoint.Prices] = {
	href: "/api/prices",
	authNeeded: true
};
endpoints[Endpoint.PriceTotal] = {
	href: "api/prices/total",
	authNeeded: true
};
endpoints[Endpoint.Remarks] = {
	href: "/api/dossiers/:dossierId/remarks",
	authNeeded: true
};
endpoints[Endpoint.MyRemarksList] = {
	href: "/api/remarks/mine",
	authNeeded: true
};
endpoints[Endpoint.MyRemarks] = {
	href: "/api/remarks/:id",
	authNeeded: true
};
endpoints[Endpoint.Tasks] = {
	href: "/api/dossiers/:dossierId/tasks",
	authNeeded: true
};
endpoints[Endpoint.TaskUpdate] = {
	href: "/api/tasks/:id",
	authNeeded: true
};
endpoints[Endpoint.PlannedTasks] = {
	href: "/api/tasks/planned/:start/:end",
	authNeeded: true
};
endpoints[Endpoint.ProcurementList] = {
	href: "/api/procurement/list",
	authNeeded: true
};
endpoints[Endpoint.ProcurementListByPok] = {
	href: "/api/procurement/list/searchByPOK",
	authNeeded: true
};
endpoints[Endpoint.ProcurementCreatePurchaseOrders] = {
	href: "/api/procurement/:dossierId/:supplierId/createpurchaseorders",
	authNeeded: true
};
endpoints[Endpoint.ProcurementOrderPurchaseOrdersViaMail] = {
	href: "/api/procurement/:dossierId/:supplierId/orderpurchaseordersviamail",
	authNeeded: true
};
endpoints[Endpoint.ProcurementResentPurchaseOrderMail] = {
	href: "/api/procurement/:dossierId/:supplierId/resentpurchaseordermail",
	authNeeded: true
};
endpoints[Endpoint.ProcurementOrderPurchaseOrdersViaTradePlace] = {
	href: "/api/procurement/:dossierId/:supplierId/orderpurchaseordersviatradeplace",
	authNeeded: true
};
endpoints[Endpoint.ProcurementSetPurchaseOrdersChecked] = {
	href: "/api/procurement/:dossierId/:supplierId/setpurchaseorderschecked",
	authNeeded: true
};
endpoints[Endpoint.PrintLabels] = {
	href: "/api/printlabels",
	authNeeded: true
};
endpoints[Endpoint.Invoicing] = {
	href: "/api/invoicing",
	authNeeded: true
};
endpoints[Endpoint.StatisticsRunningDossiersByColor] = {
	href: "/api/statistics/bla/:companyId",
	authNeeded: true
};
endpoints[Endpoint.StatisticsRunningDossiersByColor] = {
	href: "/api/statistics/bla/:companyId",
	authNeeded: true
};
endpoints[Endpoint.ArticleDeliveries] = {
	href: "/api/dossiers/:dossierId/articles/:articleId/delivery",
	authNeeded: true
};
endpoints[Endpoint.ArticleDeliveriesDelete] = {
	href: "/api/ArticleDelivery/:id",
	authNeeded: true
};
endpoints[Endpoint.Teams] = {
	href: "/api/teams/:id",
	authNeeded: true
};
endpoints[Endpoint.TeamsList] = {
	href: "/api/teams/list",
	authNeeded: true
};
endpoints[Endpoint.TeamsGetColleague] = {
	href: "/api/teams/:id/getColleague",
	authNeeded: true
};
endpoints[Endpoint.FutureTasksTeamChange] = {
	href: "/api/tasks/futureTasksTeamChange",
	authNeeded: true
};
endpoints[Endpoint.Settings] = {
	href: "/api/settings",
	authNeeded: true
};
endpoints[Endpoint.SettingsByKey] = {
	href: "/api/settings/byKey/:key",
	authNeeded: true
};
endpoints[Endpoint.TotalConfigurationField] = {
	href: "/api/dossiers/listTotal",
	authNeeded: true
};
endpoints[Endpoint.DownloadDossierExcel] = {
	href: "/api/dossiers/DownloadDossierExcel",
	authNeeded: true
};

export default endpoints;
