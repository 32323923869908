import React from "react";

import style from "./privacy.module.scss";

const Privacy: React.FC = () => {
	return (
		<div className={"container " + style.privacy}>
			<div className="row">
				<div className="col">
					<h1>Privacy Statement for Kovera ERP (&ldquo;Platform&rdquo;)</h1>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p className={style.p}>Last revised: 12 November 2019</p>
					<p className={style.p}>
						Selas, BVBA., including its subsidiaries and affiliates (collectively, "Selas," "we," or "us") respects the privacy of its customers, business partners,
						event attendees, job applicants and Website (as defined below) visitors.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p className={style.p}>
						The following Privacy Statement explains who we are and the different ways in which we collect, use, and share personal information about you, and how you
						can exercise your privacy rights. Please take the time to read this Privacy Statement and the related statements in their entirety to ensure you are fully
						informed. To access a specific policy, you may click on the relevant link below. Note that we may provide additional information about our privacy practices
						that are specific to one of our websites, events, products, or services.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p className={style.p}>
						If you have any questions or concerns about our use of your personal information, please contact us using the contact details provided at the bottom of this Privacy Statement.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p className={style.p}>
						PRIVACY STATEMENT. Our Privacy Statement covers our privacy practices with respect to the collection, use, and disclosure of information obtained:
						(i) through the Selas websites that link to this Privacy Statement (our "Website"); and
						(ii) events hosted by Selas or our business partners and sponsors where we collect information from attendees (“Events”).
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p className={style.p}>
						COOKIES POLICY. Our Cookies Policy addresses how we use cookies and other similar tracking technologies when you visit our websites or use our products,
						services, and mobile applications.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					SERVICES PRIVACY STATEMENT. Our Services Privacy Statement covers our privacy practices in connection with the use of our hosted software applications and related support services,
					as well as expert services, including professional services, trainings and certifications that we provide to customers. This Services Privacy Statement also describes our privacy
					practices with respect to customer and partner contact information we process in connection with the purchase and use of our products and services or services or technology provided
					by our partners to our customers.
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p className={style.p}>
						APPLICANT PRIVACY STATEMENT. Our Applicant Privacy Statement covers our privacy practices in connection with an individual’s application for employment at Selas.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<h2 className={style.h2}>Purpose</h2>
					<p className={style.p}>
						Selas, bvba, including its subsidiaries and affiliates(collectively, "Selas," "we," or "us") respects the privacy of its Website(as defined
						below) and Events(as defined below) registrants and attendees.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<h2 className={style.h2}>About Us</h2>
					<p className={style.p}>
						Selas makes work, work better for people.Our cloud‑based platform and products streamline and simplify how work gets done.Our solutions, and
						the custom solutions built by our customers and partners, help people do their best work.We are headquartered in Santa Clara, California but
						have subsidiaries and affiliates around the world.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p className={style.p}>
						For more information about Selas, please see the About Us section of our Website.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<h2 className={style.h2}>Scope</h2>
					<p className={style.p}>
						This Privacy Statement only covers our privacy practices with respect to the collection, use, and disclosure of information obtained:
						(i) through the Selas websites that link to this Privacy Statement("Website"); and
						(ii) event registration or attendance information for events hosted by us or our business partners where we collect information from registrants
						and attendees(“Events”).
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<h2 className={style.h2}>How We Obtain Information</h2>
					<p className={style.p}>As further described below, we collect several types of information from and about you that broadly falls into the following categories:</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<h3 className={style.h3}>1. Information you voluntarily provide to us:</h3>
					<p className={style.p}>
						<ul>
							<li>When filling out forms on our Website(including without limitation, name, mailing address, email address, and telephone number).</li>
							<li>
								When you post material to our Website, participate in bulletin boards, chat rooms, blogs, comment threads, forums, or other interactive
								features of our Website, register, or request further information or services from us.
							</li>
							<li>When you register to use a demo or developer instance of our cloud service.</li>
							<li>When you enter a contest or promotion we sponsor.</li>
							<li>When you report a problem with our Website.</li>
							<li>When you contact us.</li>
							<li>When you complete our surveys.</li>
							<li>When you register for or attend an Event.</li>
							<li>Other information you may submit to us related to your use of our Website or attendance to an Event, including without limitation, billing information.</li>
						</ul>
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<h3 className={style.h3}>2. Information from third parties.</h3>
					<p className={style.p}>
						From time to time, we may collect and use information we receive about you from third parties in connection with your use of the Website or attendance to an Event.
						For instance, we may receive information about you from our business partners related to your registration information for Events.We also use third parties for reporting
						and analytics to measure the effectiveness of our Website and marketing efforts, and to identify areas for improvement.
					</p>
					<p className={style.p}>
						We may combine information we collect from third parties with information you provide us to update, expand or provide you with tailored information regarding our products,
						services and Events.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<h3 className={style.h3}>3. Information we collect as you navigate through the Website.</h3>
					<p className={style.p}>
						As you navigate through the Website, we may also automatically collect details about your visits Website, including your IP address, usage patterns, traffic data,
						location data, logs and other communication data and the resources that you access, as well as information about your computer and internet connection, including your
						operating system and browser type.
					</p>
					<p className={style.p}>We also collect some of this information using cookies and similar tracking technologies.For detailed information, please see our Cookies Policy.</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<h2 className={style.h2}>How We Use Information Collected</h2>
					<p className={style.p}>We may use information that we collect about you for the following purposes:</p>
					<p className={style.p}>
						<ul>
							<li>To operate, maintain and improve Website.</li>
							<li>To register you for Events you sign up for with us and populate profiles for you in our records.</li>
							<li>To provide you with information, products or services that you request from us.</li>
							<li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including without limitation, our Website Terms of Use.</li>
							<li>To notify you about changes to our Website or obtain any required consent.</li>
							<li>To allow you to participate in interactive features of our Website, when you choose to do so.</li>
							<li>For industry analysis, benchmarking, analytics, marketing, and other business purposes.</li>
							<li>To track your browsing behavior, such as the pages you visited over time, for analytics and advertising purposes.</li>
							<li>To communicate with you about Events.</li>
							<li>
								To contact you for marketing purposes, in accordance with your marketing preferences(for example, we may use your information to contact you to further discuss your
								interests in our services and products or other Events).
							</li>
						</ul>
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p className={style.p}>
						By registering for Kovera ERP, or other future sites within the Selas ecosystem, Selas will create a  Profile on your behalf that will allow you to access any of the other
						listed sites using the same username and password for all sites.The personal information that may be collected during registration and / or use of any one of these sites
						that you choose to make public, may be visible to other users of the sites.In addition, your personal information may be used by the other sites for the purposes of enhancing
						your experience and improving the quality of our services.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p className={style.p}>
						If you ask us to contact you about goods and services, we may use your personal information or permit selected third parties to use your personal information to
						contact you about such goods and services.You can withdraw consent at a later time by clicking on the “unsubscribe” link located in the emails we send you.For more information,
						see the section on “Your Privacy Rights” below.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p className={style.p}>
						Additional information about our privacy practices with respect to our collection and use of information from Events may be found in additional privacy statements on
						the registration sites for Events hosted by us.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<h2 className={style.h2}>How We Share Information Collected</h2>
					<p className={style.p}>We may disclose personal information that you provide to us to the following categories of recipients:</p>
					<p className={style.p}>
						<ul>
							<li>
								To our subsidiaries and affiliates(including those located outside the European Economic Area and Switzerland) who will use it only for the purposes described
								in this Privacy Statement.
							</li>
							<li>
								To our contractors, business partners and service providers we use to support our business or who provide services on our behalf.For clarity, Selas does
								not share Event attendee data with business partners or sponsors unless you opt in to such sharing via an Event registration form or you attend an Event hosted by
								Selas and allow our business partners or sponsors to scan your attendee badge.If you do not wish for your information to be shared with our business partners or
								sponsors, you may choose not to opt in via the event registration forms and elect not to have your badge scanned by a partner at an Event.If you choose to share your
								information with business partners or sponsors, your information will be subject to the respective business partner or sponsor’s privacy statement.
							</li>
							<li>
								To a potential buyer(and its agents and advisors) in connection with any proposed merger, acquisition, or any form of sale or transfer of some or all of our
								assets(including in the event of a reorganization, dissolution or liquidation), in which case personal information held by us about you will be among the assets transferred
								to the buyer or acquirer.
							</li>
							<li>
								To any competent law enforcement body, regulatory, government agency, court or other third party to: (i) comply with any court order, a request from any competent
								law enforcement agency, or any other legal obligation; (ii) enforce or apply our Website Terms of Use or terms of any other agreement; (iii) and protect the rights, property,
								or safety of Selas or others.
							</li>
							<li>To any other person with your consent to the disclosure.</li>
						</ul>
					</p>
					<p className={style.p}>We do not sell, rent or trade information collected through the Website with third parties for their promotional purposes.</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<h2 className={style.h2}>Legal Basis for Processing</h2>
					<p className={style.p}>
						If you are from the European Economic Area, our legal basis for collecting and using the personal information described above will depend on the personal information concerned
						and the specific context in which we collect it.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p className={style.p}>
						However, we will normally collect personal information from you only where we have your consent to do so, where we need the personal information to perform a contract with
						you, or where the processing is in our legitimate interests and not overridden by your data protection interests or fundamental rights and freedoms.In some cases, we may also have
						a legal obligation to collect personal information from you.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p className={style.p}>
						If you have questions about or need further information concerning the legal basis on which we collect and use your personal information, please contact us using the contact
						details provided in the "Contact Information” section below.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<h2 className={style.h2}>International Transfer of Data</h2>
					<p className={style.p}>
						Selas stores and processes any information collected in connection with the Website or an Event in:
						(i) any country where we have facilities,
						(ii) any country in which we engage service providers; or
						(iii) any country where Events are held.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p className={style.p}>
						If you are a visitor from the European Economic Area("EEA"), we will protect your personal information when it is transferred outside of the EEA by:
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p className={style.p}>
						Processing it in a territory which the European Commission has determined provides an adequate level of protection for personal information; or
						Otherwise implementing appropriate safeguards to protect your personal information, including through the use of Standard Contractual Clauses or another
						lawful transfer mechanism approved by the European Commission.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p className={style.p}>
						If you require further information about our international transfers of personal information, please contact us using the contract details in the “Contact Information”
						section further below.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<h2 className={style.h2}>Security and Confidentiality</h2>
					<p className={style.p}>
						Selas maintains appropriate technical and organizational measures to protect the personal information obtained through the Website and in connection with Events from
						accidental or unlawful destruction, loss, alteration, unauthorized disclosure and access.Selas personnel and service providers with access to personal information
						collected through our Websites or from Event attendees are required to keep such information confidential and secure.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<h2 className={style.h2}>Retention</h2>
					<p className={style.p}>
						We will retain your personal information if we have a business need to do so or for as long as is needed to fulfill the purposes outlined in this Privacy Statement, unless a
						longer retention period is required by law(for example, to comply with applicable legal, tax or accounting requirements).
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p className={style.p}>
						When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it or, if this is not possible(for example, because
						your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<h2 className={style.h2}>Your Privacy Rights</h2>
					<p className={style.p}>We provide you with certain choices regarding the information you provide to us.In particular:</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p className={style.p}>
						If you wish to access, correct, update, or request deletion of your personal information, you can send us an e‑mail at <a href="mailto:support@selas.com">support@selas.com</a>.
						Event registrants and attendees may update their user profiles for Events we host by logging into the applicable event website or registration page.
						Certain jurisdictions, for example the European Economic Area, provide their residents specific privacy rights under applicable law.
						We will process your requests to exercise such rights, including if you object to processing of your personal information, ask us to restrict processing of your personal information
						or request portability of your personal information, in accordance with applicable data protection laws.You may send us an e‑mail at
						<a href="mailto:support@selas.com">support@selas.com</a> or using the contact details under the “Contact Us” heading below.
						If you do not wish to receive our email marketing communication for promotional purposes, you may opt‑out by clicking on the “unsubscribe” or “opt‑out” link in the marketing
						e‑mails we send you. To opt‑out of other forms of marketing(such as telemarketing), please contact us using the contact details provided under the “Contact Information” section below.
						In addition, if we process your personal information in reliance upon your consent, you can contact us at any time to withdraw your consent.
						You have the right to complain to a data protection authority if you are unhappy about our collection and use of your personal information.For more information,
						please contact your local data protection authority. Contact details for data protection authorities in the European Economic Area, Switzerland, and certain non‑European
						countries(including the US and Canada) are available here.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p className={style.p}>
						We will respond to such requests in accordance with the requirements of applicable data protection laws.Please note that in order to fulfil your request, we may need you to provide
						certain information to verify your identity.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<h2 className={style.h2}>General</h2>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<h3 className={style.h3}>Do Not Track</h3>
					<p className={style.p}>
						While Selas attempts to honor do not track(“DNT”) instructions we receive from a Visitor’s browser, we cannot guarantee that Selas will always respond to such signals,
						in part, because of the lack of common industry standard for DNT technology. We continue to monitor developments in DNT technology and stay apprised of DNT
						industry standards as they evolve.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<h3 className={style.h3}>Third Party Websites and Applications</h3>
					<p className={style.p}>
						This Website may link to websites that are not owned or controlled by Selas.As such, this Privacy Statement does not apply to information collected on any third‑party
						site or by any third‑party application that may link to or be accessible from the Website.This Privacy Statement does not apply to information collected by customers,
						our business partners, and other third parties or third‑party applications or services, even if this information is collected using our Website or at Events.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<h3 className={style.h3}>Public Forums and Blogs</h3>
					<p className={style.p}>
						Please note that any information you post or disclose in public forums will become public information, and may be available to users and the general public.
						We urge you to be very careful when deciding to disclose your personal information, or any other information in our public forums.Proper access and use of information provided on
						the Website, including contributions to our public forums, is governed by our Website Terms of Use and known or suspected violations should be reported to our Privacy team via
						e‑mail at <a href="mailto:support@selas.com">support@selas.com</a>.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<h3 className={style.h3}>Children</h3>
					<p className={style.p}>
						We do not knowingly collect personal information from anyone under the age of 16. If you are under 16, please do not attempt to fill out our forms or send any personal information
						about yourself to us. If we learn that we have collected Personal Information from a child under age 16, we will delete that information promptly.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<h3 className={style.h3}>Changes to Our Privacy Statement</h3>
					<p className={style.p}>
						Selas reserves the right to update or change this Privacy Statement from time to time.If we make material changes to this Privacy Statement, we will notify you through an
						appropriate online notice or other means(which may include, if we have your contact details, notification by e‑mail) and obtain your consent if and where required by
						applicable law. We ask that you review the Privacy Statement periodically to stay informed about any updates or changes that we may have made.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<h3 className={style.h3}>Changes to Our Privacy Statement</h3>
					<p className={style.p}>
						Selas reserves the right to update or change this Privacy Statement from time to time.If we make material changes to this Privacy Statement, we will notify you through an
						appropriate online notice or other means(which may include, if we have your contact details, notification by e‑mail) and obtain your consent if and where required by
						applicable law. We ask that you review the Privacy Statement periodically to stay informed about any updates or changes that we may have made.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p className={style.p}>You can see when this Privacy Statement was last updated by checking the “Last revised” date displayed at the top of this Privacy Statement.</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<h2 className={style.h2}>Contact Information</h2>
					<p className={style.p}>
						To ask questions or comment about this Privacy Statement and our privacy practices or if you need to update, change or remove your information or exercise any other rights,
						contact us at: <a href="mailto:support@selas.com">support@selas.com</a>.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p className={style.p}>
						Or
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p className={style.p}>
						Selas, bvba.<br />
						Achterstenhoek 46/2<br />
						2275 Lille
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p className={style.p}>
						All other feedback, comments, requests for technical support, and other communications relating to the Website should be
						directed to: <a href="mailto:support@selas.com">support@selas.com</a>.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p className={style.p}>
						This website is operated by Selas, bvba. (the data controller), which located at Achterstenhoek 46/2, 2275 Lille. Selas, bvba.'s data protection representative in the European
						Economic Area is:
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<p className={style.p}>
						Selas, bvba.<br />
						Achterstenhoek 46/2<br />
						2275 Lille<br /><br />
						Email: <a href="mailto:support@selas.com">support@selas.com</a>.<br /><br />
					</p>
				</div>
			</div>
		</div>
	);
};

export default Privacy;
