import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Endpoint from "../../../../services/api/endpoint";
import { IApplicationState } from "../../../../store";
import { Permission } from "../../../../utils/enums";
import { IUser } from "../../../../utils/types/models";
import GridPanel from "../../../global/gridpanel";
import { checkBoxCell, customCell } from "../../../global/gridpanel/customCells/gridCells";
import { IRoutedTabProps } from "../../../global/routertabpanel";
import ConstructionYardEditor from "./editor";

const ConstructionYards: React.FC<IRoutedTabProps> = () => {
	const { t } = useTranslation();
	const currentUser: IUser = useSelector((applicationState: IApplicationState) => applicationState.authenticationState.currentUser);

	return (
		<GridPanel
			listEndpoint={Endpoint.ConstructionYardsList}
			listUrlArguments={{ companyId: currentUser.currentCompanyId }}
			endpoint={Endpoint.ConstructionYards}
			addScreen={ConstructionYardEditor}
			editScreen={ConstructionYardEditor}
			showDelete
			addPermission={Permission.ConstructionYardsAdd}
			editPermission={Permission.ConstructionYardsUpdate}
			deletePermission={Permission.ConstructionYardsDelete}
			filter={{
				logic: "and",
				filters: [
					{
						field: "active",
						operator: "eq",
						value: true
					}
				]
			}}
		>
			<GridColumn field="name" title={t("name")} />
			<GridColumn field="address.street" title={t("street")} />
			<GridColumn field="address.number" title={t("number")} />
			<GridColumn field="address.postalCode" title={t("postalCode")} />
			<GridColumn field="address.municipality" title={t("municipality")} />
			<GridColumn field="address.province" title={t("province")} />
			<GridColumn field="address.country" title={t("country")} />
			<GridColumn field="active" title={t("active")} filter="boolean" width="160px" cell={customCell(checkBoxCell(false))} />
		</GridPanel>
	);
};

export default ConstructionYards;