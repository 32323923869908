/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from "react";

import { DatePicker, DatePickerChangeEvent, TimePicker, TimePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBox, ComboBoxChangeEvent, ComboBoxCloseEvent, ComboBoxFilterChangeEvent, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { UploadResponse } from "@progress/kendo-react-upload";
import indexOf from "lodash/indexOf";
import remove from "lodash/remove";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { filterBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import dayjs from "dayjs";
import cloneDeep from "lodash/cloneDeep";
import callApi from "../../../../../../services/api/callApi";
import Endpoint from "../../../../../../services/api/endpoint";
import { initalUserState, initialTeamState } from "../../../../../../state";
import { teamReducer, userReducer } from "../../../../../../state/reducers";
import { IApplicationState } from "../../../../../../store";
import { getDate, handleChange } from "../../../../../../utils/editorUtils";
import { DossierTaskAbsenceReason, DossierTaskType } from "../../../../../../utils/enums";
import { IDossierTask, IFileData, IUser } from "../../../../../../utils/types/models";
import { IDictionary } from "../../../../../../utils/types/types";
import { ApiFileUpload, EnumDropDownList, TextArea, YesNoSwitch } from "../../../../../global/editor";
import ImageGallery from "../../../../../global/imageGallery";
import PageEditor from "../pageEditor";

interface IProps {
	record?: IDossierTask;
	onClose: (record?: IDossierTask) => void;
	readOnly: boolean;
}
const TaskEditor: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const [state, dispatch] = useReducer(userReducer, initalUserState);
	const initialTask: IDossierTask = {
		id: null,
		dossierId: null,
		dateIsFixed: false,
		type: null,
		startTime: new Date(new Date().getFullYear(), 1, 1, 9, 0),
		assignee1Id: null,
		assignee1: null,
		description: "",
		planned: false,
		completed: false,
		absent: false,
		absenceReason: null,
		photos: []
	};
	const [task, setTask] = useState(props.record || initialTask);
	const [dataChanged, setDataChanged] = useState(false);
	const [userEntities, setUserEntities] = useState<IDictionary<IUser[]>>({});
	const [currentUserCombobox, setCurrentUserCombobox] = useState<string>();
	const [uploadError, setUploadError] = useState(false);
	const currentUser: IUser = useSelector((applicationState: IApplicationState) => applicationState.authenticationState.currentUser);
	const [teamState, teamsDispatch] = useReducer(teamReducer, initialTeamState);

	const [leadUsers, setLeadUsers] = useState([]);
	const [colleagueUsers, setColleagueUsers] = useState([]);

	useEffect(() => {
		if (state.entities) {
			userEntities[currentUserCombobox] = [...state.entities];
			setUserEntities(userEntities);
		}
	}, [state.entities]);

	useEffect(() => {
		if (teamState.colleague) {
			task.assignee2 = teamState.colleague;
			task.assignee2Id = teamState.colleague.id;
			setTask(task);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [teamState.colleague]);

	useEffect(() => {
		callApi(dispatch, Endpoint.UserLeadInstallers, "GET", { companyId: currentUser.currentCompanyId });
		callApi(dispatch, Endpoint.UserColleagueInstallers, "GET", { companyId: currentUser.currentCompanyId });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (state.leadsEntities) {
			userEntities["assignee1Id"] = [...state.leadsEntities];
			setLeadUsers([...state.leadsEntities]);
			setUserEntities(userEntities);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.leadsEntities]);

	useEffect(() => {
		if (state.colleaguesEntities) {
			userEntities["assignee2Id"] = [...state.colleaguesEntities];
			setColleagueUsers([...state.colleaguesEntities]);
			setUserEntities(userEntities);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.colleaguesEntities]);

	useEffect(() => {
		if (!task.stopDate && !task.stopTime) {
			const newTask: IDossierTask = { ...task };
			newTask.stopDate = newTask.startDate;
			newTask.stopTime = new Date(new Date().getFullYear(), 1, 1, 17, 0);
			setTask(newTask);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [task.startDate]);

	useEffect(() => {
		if (!task.absent) {
			const newTask: IDossierTask = { ...task };
			newTask.absenceReason = null;
			setTask(newTask);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [task.absent]);

	useEffect(() => {
		if (task.assignee1 && dataChanged && !task.absent) {
			callApi(teamsDispatch, Endpoint.TeamsGetColleague, "GET", { id: task.assignee1.id, companyId: currentUser.currentCompanyId });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [task.assignee1]);

	function onChange(event: TimePickerChangeEvent | DatePickerChangeEvent | SwitchChangeEvent | DropDownListChangeEvent | ComboBoxCloseEvent | React.ChangeEvent<HTMLTextAreaElement>): void {
		const newTask: IDossierTask = handleChange(task, event);

		if (event.target.name === "startTime" && event.target.value) {
			newTask.startTime = dayjs(`${new Date().getFullYear()}-1-1T${event.target.value.getHours()}:${event.target.value.getMinutes()}:00`).toDate();
			if (newTask.stopTime) {
				newTask.stopTime = dayjs(`${new Date().getFullYear()}-1-1T${getDate(newTask.stopTime).getHours()}:${getDate(newTask.stopTime).getMinutes()}:00`).toDate();
			}
		} else if (event.target.name === "stopTime" && event.target.value) {
			newTask.stopTime = dayjs(`${new Date().getFullYear()}-1-1T${event.target.value.getHours()}:${event.target.value.getMinutes()}:00`).toDate();
			if (newTask.startTime) {
				newTask.startTime = dayjs(`${new Date().getFullYear()}-1-1T${getDate(newTask.startTime).getHours()}:${getDate(newTask.startTime).getMinutes()}:00`).toDate();
			}
		}

		setTask(newTask);
		setDataChanged(true);
	}

	function onChangeLead(event: ComboBoxChangeEvent): void {
		const newTask: IDossierTask = { ...task };
		// @ts-ignore
		if (event.value) {
			// @ts-ignore
			newTask.assignee1 = event.value;
			// @ts-ignore
			newTask.assignee1Id = event.value.id;
		} else {
			newTask.assignee1 = null;
			newTask.assignee1Id = null;
		}
		setTask(newTask);
		setDataChanged(true);
	}

	function onChangeColleague(event: ComboBoxChangeEvent): void {
		const newTask: IDossierTask = { ...task };
		// @ts-ignore
		if (event.value) {
			// @ts-ignore
			newTask.assignee2 = event.value;
			// @ts-ignore
			newTask.assignee2Id = event.value.id;
		} else {
			newTask.assignee2 = null;
			newTask.assignee2Id = null;
		}
		setTask(newTask);
		setDataChanged(true);
	}

	function deletePhoto(index: number): void {
		const newTask: IDossierTask = { ...task };

		remove(newTask.photos, (p: IFileData) => {
			return indexOf(newTask.photos, p) === index;
		});

		setTask(newTask);
		setDataChanged(true);
	}

	function onTeamFilterChange(event: ComboBoxFilterChangeEvent): void {
		if (event.target.name === "assignee1Id") {
			setLeadUsers(filterBy(userEntities[event.target.name], event.filter));
		} else if (event.target.name === "assignee2Id") {
			setColleagueUsers(filterBy(userEntities[event.target.name], event.filter));
		}
	}

	function onBlur(): void {
		setCurrentUserCombobox(null);
	}

	function swapAssignees(): void {
		const newTask: IDossierTask = cloneDeep(task);
		newTask.assignee1Id = task.assignee2Id;
		newTask.assignee1 = task.assignee2;
		newTask.assignee2Id = task.assignee1Id;
		newTask.assignee2 = task.assignee1;
		setTask(newTask);
		setDataChanged(true);
	}

	function fileUploaded(response: UploadResponse): void {
		const newPhoto: IFileData = {
			filename: response.response.filename,
			internalFilename: response.response.internalFilename,
			size: response.response.size,
			type: response.response.type
		};
		const newTask: IDossierTask = {
			...task,
			photos: [...task.photos, newPhoto]
		};
		setTask(newTask);
		setDataChanged(true);
		setUploadError(false);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (!task.type) {
			messages.push(t("fill_in_required_field", { field: t("type").toLowerCase() }));
		}
		if (task.planned && !task.startDate) {
			messages.push(t("fill_in_required_field_for_planned", { field: t("startDate").toLowerCase() }));
		}
		if (task.planned && !task.stopDate) {
			messages.push(t("fill_in_required_field_for_planned", { field: t("stopDate").toLowerCase() }));
		}
		if (task.planned && !task.startTime) {
			messages.push(t("fill_in_required_field_for_planned", { field: t("startTime").toLowerCase() }));
		}
		if (task.planned && !task.stopTime) {
			messages.push(t("fill_in_required_field_for_planned", { field: t("stopTime").toLowerCase() }));
		}
		if (task.planned && !task.assignee1Id && !task.absent) {
			messages.push(t("fill_in_required_field_for_planned", { field: t("lead").toLowerCase() }));
		}
		if (task.dateIsFixed && !task.startDate) {
			messages.push(t("fill_in_required_field_for_fixedDate", { field: t("startDate").toLowerCase() }));
		}
		if (task.absent && !task.absenceReason) {
			messages.push(t("fill_in_required_field", { field: t("absenceReason").toLowerCase() }));
		}
		if (task.absent && !task.assignee1Id && !task.assignee2Id) {
			messages.push(t("fill_in_required_field_absent"));
		}
		if (task.absent && !task.startDate && !task.stopDate) {
			messages.push(t("fill_in_required_field_absent_date"));
		}
		if (getDate(task.stopDate) < getDate(task.startDate) || (task.stopDate === task.startDate && task.startDate && task.stopDate && getDate(task.stopTime) < getDate(task.startTime))) {
			messages.push(t("fill_in_correct_date_values"));
		}

		return messages;
	}

	const action: string = props.record ? t("edit") : t("add");
	return (
		<PageEditor
			width="70%"
			title={`${action} ${t("task")}`.trim()}
			close={props.onClose}
			dataChanged={dataChanged}
			getErrorMessages={getErrorMessages}
			action={action}
			record={task}
			readOnly={props.readOnly}
		>
			<div className="k-form">
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>
								{t("startDate")}
								{task.absent ? " *" : ""}
							</span>
							<DatePicker
								name="startDate"
								className="full-width-field"
								value={getDate(task.startDate)}
								onChange={onChange}
								formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
								disabled={props.readOnly}
								required={task.absent || task.planned}
							/>
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>
								{t("startTime")}
								{task.absent ? " *" : ""}
							</span>
							<TimePicker
								name="startTime"
								className="full-width-field"
								value={getDate(task.startTime)}
								onChange={onChange}
								disabled={props.readOnly}
								required={task.absent || task.planned}
							/>
						</label>
					</div>
					<div className="col-1">
						<div className="k-form-field">
							<span>{t("fixedDate")}</span>
							<YesNoSwitch name="dateIsFixed" checked={task.dateIsFixed} onChange={onChange} disabled={props.readOnly} />
						</div>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("type")} *</span>
							<EnumDropDownList name="type" className="full-width-field" value={task.type} enum={DossierTaskType} onChange={onChange} required disabled={props.readOnly} />
						</label>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>
								{t("stopDate")}
								{task.absent ? " *" : ""}
							</span>
							<DatePicker
								name="stopDate"
								className="full-width-field"
								value={getDate(task.stopDate)}
								onChange={onChange}
								required={task.absent || task.planned}
								formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
								disabled={props.readOnly}
							/>
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>
								{t("stopTime")}
								{task.absent ? " *" : ""}
							</span>
							<TimePicker
								name="stopTime"
								className="full-width-field"
								value={getDate(task.stopTime)}
								onChange={onChange}
								disabled={props.readOnly}
								required={task.absent || task.planned}
							/>
						</label>
					</div>
					<div className="col-1">
						<div className="k-form-field">
							<span>{t("absent")}</span>
							<YesNoSwitch name="absent" checked={task.absent} onChange={onChange} disabled={props.readOnly} />
						</div>
					</div>
					<div className="col">
						{task.absent && (
							<label className="k-form-field">
								<span>
									{t("absenceReason")}
									{task.absent ? " *" : ""}
								</span>
								<EnumDropDownList
									name="absenceReason"
									className="full-width-field"
									value={task.absenceReason}
									enum={DossierTaskAbsenceReason}
									onChange={onChange}
									required={task.absent}
									disabled={props.readOnly}
								/>
							</label>
						)}
					</div>
				</div>
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>
								{t("lead")} {(task.planned && !task.absent) || (task.absent && !task.assignee2Id) ? "*" : ""}
							</span>
							<ComboBox
								name="assignee1Id"
								data={leadUsers}
								value={task.assignee1}
								textField="fullName"
								onFilterChange={onTeamFilterChange}
								onChange={onChangeLead}
								suggest
								filterable
								allowCustom={false}
								onBlur={onBlur}
								style={{ width: "100%" }}
								required={(task.planned && !task.absent) || (task.absent && !task.assignee2Id)}
							/>
						</label>
					</div>
					<div className="col-1">
						<label className="k-form-field">
							<span>&nbsp;</span>
							<Button primary onClick={swapAssignees} style={{ width: "100%" }}>
								{t("swap")}&nbsp;
								<i className="las la-exchange-alt" />
							</Button>
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>
								{t("colleague")} {task.absent && !task.assignee1Id ? "*" : ""}
							</span>
							<ComboBox
								name="assignee2Id"
								data={colleagueUsers}
								style={{ width: "100%" }}
								value={task.assignee2}
								textField="fullName"
								onFilterChange={onTeamFilterChange}
								onBlur={onBlur}
								onChange={onChangeColleague}
								required={task.absent && !task.assignee1Id}
								suggest
								filterable
								allowCustom={false}
							/>
						</label>
					</div>
				</div>
				<label className="k-form-field">
					<span>{t("task")}</span>
					<TextArea name="description" className="full-width-field" value={task.description} onChange={onChange} disabled={props.readOnly} />
				</label>
				{task.signature && (
					<label className="k-form-field">
						<span>{t("signature")}</span>
						<img src={"/api/files/" + task.signature?.internalFilename + "/" + task.signature?.filename} alt="signature" />
					</label>
				)}
				<div className="row">
					<div className="col-1">
						<div className="k-form-field">
							<span>{t("planned")}</span>
							<YesNoSwitch name="planned" checked={task.planned} onChange={onChange} disabled={props.readOnly} />
						</div>
					</div>
					<div className="col-1">
						<div className="k-form-field">
							<span>{t("taskComplete")}</span>
							<YesNoSwitch name="completed" checked={task.completed} onChange={onChange} disabled={props.readOnly} />
						</div>
					</div>
					{!props.readOnly && (
						<div className="col">
							<div className="k-form-field">
								<span>
									{t("photos")}
									{uploadError && <i className="las la-exclamation-triangle" style={{ color: "red", fontSize: "15px" }} />}
								</span>
								<ApiFileUpload multiple showFileList={false} onError={() => setUploadError(true)} fileUploaded={fileUploaded} accept="image/*" />
							</div>
						</div>
					)}
				</div>
				<ImageGallery images={task.photos} imageTileSize={300} deletePhoto={deletePhoto} />
			</div>
		</PageEditor>
	);
};

export default TaskEditor;
