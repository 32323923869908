import ITranslation from "../../utils/types/translation";

const translationsEN: ITranslation = {
	show: "Show",
	searchDossierNumber: "Search dossiernummer",
	dossierDescription: "Dossier description",
	taskDescription: "Task description",
	taskPhotos: "Task photos",
	home: "Home",
	gamma: "Gamma",
	masterData: "Master data",
	security: "Security",
	settings: "Settings",
	startSequenceNumberDossier: "Start at this sequence number when new task via scheduling",
	schedule: "Schedule",
	help: "Help",
	privacy: "Privacy",
	yourprofile: "Your profile",
	logout: "Log out",
	work: "Work",
	runningDossiers: "Running dossiers",
	todaysTasks: "Today's tasks",
	dossierManagement: "Dossier management",
	planningManagementService: "Planning management - Service",
	planningManagementInstallation: "Planning management - Installation",
	planningManagementDelivery: "Planning management - Delivery",
	warehouse: "Warehouse",
	procurement: "Procurement",
	firstOrderDate: "First orderdate",
	deliveryWeek: "Deliveryweek",
	searchByPok: "Search by POKnr",
	suppliers: "Suppliers",
	products: "Products",
	customers: "Customers",
	constructionYards: "Construction Yards",
	createNewTask: "Create new task",
	shops: "Shops",
	swap: "Swap",
	projects: "Projects",
	contactTypes: "Contact types",
	communicationTemplates: "Communication Templates",
	communicationTemplatesSplitted: "Communication Templates",
	users: "Users",
	roles: "Roles",
	switchcompany: "Switch company",
	role: "Role",
	companies: "Companies",
	company: "Company",
	project: "Project",
	unit: "Unit",
	shop: "Shop",
	welcome_message: "Welcome ",
	en: "English",
	nl: "Dutch",
	active: "Active",
	actions: "Actions",
	firstName: "First name",
	lastName: "Last name",
	fullName: "Full name",
	email: "Email",
	language: "Language",
	name: "Name",
	permission: "Permission",
	address: "Address",
	street: "Street",
	number: "Number",
	dossierNumber: "Dossier number",
	postalCode: "Postal Code",
	municipality: "Municipality",
	province: "Province",
	country: "Country",
	modelNumber: "Model number",
	contractor: "Contractor",
	contractors: "Contractors",
	barcode: "Barcode (EAN or UPC)",
	dossierCannotBeDeleted: "This dossier cannot be delete. Please first clear the following items from this dossier:",
	fetchEanInformation: "Fetch EAN information",
	code: "Code",
	type: "Type",
	productTypes: "Types",
	supplier: "Supplier",
	description: "Description",
	salesPriceExclVat: "€ Sales price excl. VAT",
	discount: "Discount",
	replacementProduct: "Replacement Product",
	eanInformation: "EAN Information",
	brand: "Brand",
	brands: "Merken",
	manufacturer: "Manufacturer",
	vatNumber: "VAT number",
	measured: "Measured",
	writtenOut: "Written out",
	general: "General",
	documents: "Documents",
	articles: "Articles",
	putArticleInStock: "Do you which to place the removed article in stock?",
	contacts: "Contacts",
	remarks: "Remarks",
	communication: "Communication",
	invoicing: "Invoicing",
	planning: "Planning",
	tasks: "Tasks",
	aftersales: "After sales",
	dossier: "Dossier",
	soldOn: "Sold on",
	createdOn: "Created on",
	customer: "Customer",
	copy: "Copy",
	constructionYard: "Construction Yard",
	dossierManager: "Dossier manager",
	salesRep: "Sales representative",
	vat: "VAT",
	binNumber: "Bin number",
	checkInAtWork: "CheckIN@Work",
	material: "Material",
	cupboards: "Cupboards",
	extraInformation: "Additional information",
	parking: "Parking",
	floor: "Floor",
	isLiftNeeded: "Lift needed?",
	hasSimpleAccess: "Simple Access?",
	measurement: "Measurement",
	writeOut: "Write Out",
	date: "Date",
	executedBy: "Executed by",
	complete: "Complete",
	readyForPicking: "Ready for picking",
	closed: "Closed",
	fullDescriptionVat0: "Co-contractor - 0%",
	fullDescriptionVat6: "Renovation - 6%",
	fullDescriptionVat21: "New building project - 21%",
	Vat0: "0%",
	Vat6: "6%",
	Vat21: "21%",
	phone: "Phone",
	contactName: "Contact name",
	dossierContact: "Dossier contact",
	constructionYardContact: "Construction yard contact",
	file: "File",
	subject: "Subject",
	unable_to_display: "Unable to display. Download <3>here</3>.",
	lastUploadedBy: "Last uploaded by",
	lastUploadedDate: "Last uploaded on",
	addRemark: "Add remark",
	createPurchaseOrders: "Create purchase orders",
	dossierMustBeComplete: "Dossier must be complete before you can perform this action.",
	pleaseSaveFirst: "You can only do this action for saved dossiers.<1 />Please save you work before performing this action.",
	product: "Product",
	group: "Group",
	count: "Count",
	salesPriceInclVat: "€ Sales price incl. VAT",
	salesPrice: "Sales price",
	purchasePriceExclVat: "€ Purchase/piece price excl. VAT",
	pokNumber: "POKNr.",
	deliveries: "Deliveries",
	pickings: "Pickings",
	orderDate: "Ordered",
	deliverDate: "Delivered",
	checkDate: "Checked",
	pickDate: "Picked",
	Furniture: "Furniture",
	Worktop: "Worktop",
	Worktops: "Worktops",
	Plumbing: "Plumbing",
	Appliance: "Appliance",
	Accessory: "Accessory",
	Other: "Other",
	netPrice: "Net price",
	unitPrice: "Unit price",
	lineTotal: "Line total",
	lineTotalIncl: "incl. VAT (incl. discount)",
	lineTotalExcl: "excl. VAT (incl. discount)",
	lineTotalVat: "VAT € (incl. discount)",
	totalExclVat: "Total excl. VAT: € ",
	totalInclVat: "Total incl. VAT: € ",
	totalVat: "Total VAT: € ",
	copyDossier: "Copy dossier",
	newDossierNumber: "New dossier number",
	dossierCopied: "Dossier copied",
	showNewDossier: "Do you wish to navigate to the new dossier?",
	save: "Save",
	cancel: "Cancel",
	ok: "Ok",
	close: "Close",
	add: "Add",
	edit: "Edit",
	remove: "Remove",
	previous: "Previous",
	next: "Next",
	confirmLeave: "You have unsaved data, are you sure you want to leave without saving your changes?",
	confirmChangeFixedDate: "This is a task with fixed date. Are you sure you want to move this task?",
	selectDossierMessage: "Select a dossier to view tasks.",
	dossierViewCheck: "Only show dossiers with to be planned tasks.",
	details: "Details",
	selectAll: "Select all",
	off: "Off",
	on: "On",
	yes: "Yes",
	no: "No",
	year: "year",
	month: "month",
	day: "day",
	prevWeek: "Previous Week",
	currentWeek: "This Week",
	nextWeek: "Next Week",
	monday: "Monday",
	tuesday: "Tuesday",
	thursday: "Thursday",
	wednesday: "Wednesday",
	friday: "Friday",
	saturday: "Saturday",
	related: "Related To",
	user: "User",
	remark: "Remark",
	installer: "Installer",
	color: "Color",
	toBeAsked: "To be asked",
	relatedToArticlePhase: "Article Phase",
	article: "Article",
	photos: "photos",
	Green: "Green",
	Orange: "Orange",
	Red: "Red",
	General: "General",
	Documents: "Documents",
	ArticleFurniture: "Article-Furniture",
	ArticleWorktops: "Article-Worktop",
	ArticlePlumbing: "Article-Plumbing",
	ArticleAppliance: "Article-Appliance",
	ArticleAccessory: "Article-Accessory",
	ArticleOther: "Article-Other",
	Communication: "Communication",
	Contacts: "Contacts",
	Invoicing: "Invoicing",
	Installation: "Installation",
	AfterSales: "AfterSales",
	NotApplicable: "N.A.",
	Ordered: "Ordered",
	Delivered: "Delivered",
	delivered: "Delivered",
	Picked: "Picked",
	template: "Template",
	to: "To",
	body: "Body",
	reminderBody: "Reminder",
	reminderDate: "Send reminder on",
	status: "Status",
	Email: "Email",
	New: "New",
	Sending: "Sending",
	AwaitingReminder: "Awaiting reminder",
	SendingReminder: "Sending reminder",
	Sent: "Sent",
	Error: "Error",
	ReminderError: "Reminder error",
	invoiceDepositOn: "Invoice deposit on",
	depositAmountExclVat: "Deposit amount excl. VAT",
	vatOnDeposit: "VAt% on deposit",
	depositInvoiced: "Deposit invoiced",
	depositPayed: "Deposit payed",
	None: "Geen",
	Now: "Nu",
	AfterMeasurement: "Na opmenting",
	AfterDossierCompletion: "Dossier volledig",
	created: "Created",
	lastUpdated: "Last update",
	exportExcel: "Export Excel",
	sellingDate: "Selling date",
	picked: "Picked",
	passOnPickedDate: "Pass on date",
	pickedDateChanged: "Picked date has changed",
	confirmPassonPickedDate: "Do you wish to pass this date to all the articles?",
	installationWeek: "Installation week",
	productionWeek: "Production week",
	scanLocation: "Scan location",
	fixedDate: "Fixed date",
	colleague: "Colleague",
	task: "Task",
	planned: "Planned",
	deliveryDate: "Delivery date",
	Service: "Service",
	taskComplete: "Complete",
	showCompletedTasks: "Show completed tasks",
	Delivery: "Delivery",
	loggedBy: "Logged by",
	Pending: "Pending",
	Closed: "Closed",
	stock: "Stock",
	stockMovement: "Stock movement",
	orderViaEMail: "Order via email",
	downloadTradeplaceFile: "Download Tradeplace file",
	setPurchaseOrdersChecked: "Set purchase orders checked",
	printLabels: "Print labels",
	invoiceSelected: "Invoice selected lines",
	amount: "€ Amount",
	invoiced: "Invoiced",
	amountInclVat: "€ Amount incl. VAT",
	amountExclVat: "€ Amount excl. VAT",
	payed: "Payed",
	Deposit: "Deposit",
	OnDelivery: "To be payed at delivery",
	AfterInstallation: "To be payed after installation",
	labelPrinted: "The printing of your label has been requested",
	labelsPrinted: "The printing of yaur labels has been requested",
	back: "Back",
	success: "Success",
	error: "Error",
	errorInvoicing: "Invoicing error. If 'Now' is selected you must enter an amount greater than zero.",
	pending_changes: "Changes",
	ask_save: "There are still unsaved changes. Do you wish to save them?",
	confirm_title: "Confirm {{action}}",
	confirm_content: "Are you sure you wish to {{action}} this record?",
	cancelling: "Cancel",
	notFound: "{{entity}} not found",
	createNew: "This <1 /> is not found.<3 />Do you wish to create a new one?",
	select_at_least_one: "Please select at least one {{entity}}.",
	fill_in_required_field: "Please fill in the required field '{{field}}'.",
	fill_in_required_field_for_planned: "Please fill in the field '{{field}}' to set a task as planned.",
	fill_in_required_field_for_fixedDate: "Please fill in the field '{{field}}' to set a fixed date.",
	fill_in_required_field_absent: "Please fill in a lead or colleague as person to be absent.",
	replacement_product_cannot_be_the_same: "The replacement product cannot be the same.",
	fill_in_required_field_absent_date: "Please fill in a start and stop date for the given absence",
	fill_in_correct_format: "Please fill in a correct value for the field '{{field}}'. Ex: '{{example}}'.",
	fill_in_correct_date_values: "Please fill in correct values for date and time fields. (Start > Stop)",
	add_at_least_one: "Please add at lease 1 {{entity}}.",
	please_calculate: "Please run the price calculation.",
	or: "or",
	theseAreTheServerErrorMessages: "These are the server validation errors:",
	ourCustomerNumber: "Our customer number",
	resentOrderEmail: "Resent order mail",
	reset: "Reset",
	yourRemarks: "Your remarks",
	assignee: "Assignee",
	finished: "Finished",
	phone1: "Phone 1",
	phone2: "Phone 2",
	email1: "Email 1",
	email2: "Email 2",
	createdBy: "Created by",
	by: "By",
	confirmDeliveryTitle: "Confirm delivery",
	confirmDelivery: "Are you sure you want to set 1 item of this article delivered?",
	displayedUsers: "Displayed users",
	assign: "Assign",
	lead: "Lead",
	leadInstaller: "Lead installer",
	print: "Print",
	permissions: {
		RolesRead: "Read",
		RolesAdd: "Add",
		RolesUpdate: "Update",
		RolesDelete: "Delete",
		UsersRead: "Read",
		UsersAdd: "Add",
		UsersUpdate: "Update",
		UsersDelete: "Delete",
		SuppliersRead: "Read",
		SuppliersAdd: "Add",
		SuppliersUpdate: "Update",
		SuppliersDelete: "Delete",
		ProductsRead: "Read",
		ProductsAdd: "Add",
		ProductsUpdate: "Update",
		ProductsDelete: "Delete",
		CustomersRead: "Read",
		CustomersAdd: "Add",
		CustomersUpdate: "Update",
		CustomersDelete: "Delete",
		ContractorsRead: "Read",
		ContractorsAdd: "Add",
		ContractorsUpdate: "Update",
		ContractorsDelete: "Delete",
		ConstructionYardsRead: "Read",
		ConstructionYardsAdd: "Add",
		ConstructionYardsUpdate: "Update",
		ConstructionYardsDelete: "Delete",
		ProjectsRead: "Read",
		ProjectsAdd: "Add",
		ProjectsUpdate: "Update",
		ProjectsDelete: "Delete",
		BrandsRead: "Read",
		BrandsAdd: "Add",
		BrandsUpdate: "Update",
		BrandsDelete: "Delete",
		ProductTypesRead: "Read",
		ProductTypesAdd: "Add",
		ProductTypesUpdate: "Update",
		ProductTypesDelete: "Delete",
		ShopsRead: "Read",
		ShopsAdd: "Add",
		ShopsUpdate: "Update",
		ShopsDelete: "Delete",
		CommunicationTemplatesRead: "Read",
		CommunicationTemplatesAdd: "Add",
		CommunicationTemplatesUpdate: "Update",
		CommunicationTemplatesDelete: "Delete",
		ContactTypesRead: "Read",
		ContactTypesAdd: "Add",
		ContactTypesUpdate: "Update",
		ContactTypesDelete: "Delete",
		DossiersRead: "Read",
		DossiersAdd: "Add",
		DossiersUpdate: "Update",
		DossiersDelete: "Delete",
		StockRead: "Read",
		StockAdd: "Add",
		ProcurementView: "Procurement view",
		PlanningView: "Planning view",
		WarehouseView: "Warehouse view",
		InvoicingView: "Invoicing view",
		PrintLabels: "Allowed",
		Settings: "Allowed",
		Home: "Allowed",
		TeamsRead: "Read",
		TeamsAdd: "Add",
		TeamsUpdate: "Update",
		TeamsDelete: "Delete",
		SettingsRead: "Read",
		SettingsUpdate: "Update"
	},
	time: "Time",
	timepicker: {
		cancel: "Cancel",
		now: "NOW",
		selectNow: "Select Now",
		set: "Set",
		toggleClock: "Toggle Clock",
		toggleTimeSelector: "Toggle TimeSelector"
	},
	errors: {
		server_error: 'The server returned a "{{statuscode}}" response.',
		error_duplicate: "This entity allready exists",
		error_dbconcurrency: "This record is changed or deleted by someone else. Please refresh.",
		error_entityused: "This entity is still used in the application. If you don't want to see it anymore, please deactivate it",
		error_constraintfailure: "A database contstraint prevented this action",
		error_sqlconnection: "Unable to make a database connection",
		error_sqlgeneral: "A database error occured",
		error_validation: "The server side validation failed",
		error_connection: "Unable to make an external connection",
		error_argumentnull: "An {{argument}} was empty in the serverside",
		error_productwrongreplacement: "A product cannot be its own replacement",
		error_general: "An error on the servers side has occured",
		error_server_validation: "Server validation error",
		error_idfilledin: "The ID cannot be filled in while adding an entity",
		error_routeiddoesnotmatch: "The ID in the route does not match the ID in the entity",
		error_idnotfilledin: "The ID must be filled in when deleteing",
		error_toomanyresults: "Too many result",
		error_nofile: "No file provided",
		error_nobarcodeorsearch: "No barcode or search provided",
		error_constructionyardexistsbutnotactive: "Construnction yard found, but it is not active",
		not_authenticated: "You are not logged in at the server",
		not_found: "The server did not find a result",
		forbidden: "You don't have access to this part of the server application"
	},
	grid: {
		noRecords: "No records available",
		groupPanelEmpty: "Drag a column header and drop it here to group by that column",
		filterClearButton: "Clear",
		filterSubmitButton: "Filter",
		filterTitle: "Filter",
		sortAscending: "Sort Ascending",
		sortDescending: "Sort Descending",
		pagerItemsPerPage: "items per page",
		pagerInfo: "{0} - {1} of {2} items",
		pagerFirstPage: "Go to the first page",
		pagerPreviousPage: "Go to the previous page",
		pagerNextPage: "Go to the next page",
		pagerLastPage: "Go to the last page",
		pagerPage: "Page",
		pagerOf: "of",
		filterEqOperator: "Is equal to",
		filterNotEqOperator: "Is not equal to",
		filterIsNullOperator: "Is null",
		filterIsNotNullOperator: "Is not null",
		filterIsEmptyOperator: "Is empty",
		filterIsNotEmptyOperator: "Is not empty",
		filterStartsWithOperator: "Starts with",
		filterContainsOperator: "Contains",
		filterNotContainsOperator: "Does not contain",
		filterEndsWithOperator: "Ends with",
		filterGteOperator: "Is greater than or equal to",
		filterGtOperator: "Is greater than",
		filterLteOperator: "Is less than or equal to",
		filterLtOperator: "Is less than",
		filterIsTrue: "Yes",
		filterIsFalse: "No",
		filterBooleanAll: "(All)",
		filterAfterOrEqualOperator: "Is after or equal to",
		filterAfterOperator: "Is after",
		filterBeforeOperator: "Is before",
		filterBeforeOrEqualOperator: "Is before or equal to",
		filterAndLogic: "And",
		filterOrLogic: "Or"
	},
	upload: {
		cancel: "Cancel",
		clearSelectedFiles: "Clear",
		dropFilesHere: "Drop files here to upload",
		headerStatusUploaded: "Done",
		headerStatusUploading: "Uploading...",
		invalidFileExtension: "File type not allowed.",
		invalidFiles: "Invalid file(s). Please check file upload requirements.",
		invalidMaxFileSize: "File size too large.",
		invalidMinFileSize: "File size too small.",
		remove: "Remove",
		retry: "Retry",
		select: "Select files...",
		uploadSelectedFiles: "Upload",
		total: "Total",
		files: "files"
	},
	dateinput: {
		increment: "Increase value",
		decrement: "Decrease value"
	},
	calendar: {
		today: "TODAY"
	},
	datepicker: {
		toggleCalendar: "Toggle calendar"
	},
	multiviewcalendar: {
		prevView: "Navigate to previous view",
		nextView: "Navigate to next view"
	},
	changeFutureTasks: "Change future tasks",
	changeFutureTasksQuestion: "Do you want this lead-colleague combination to be applied in all future tasks?",
	team: "Team",
	teams: "Teams",
	startDate: "Start date",
	startTime: "Start time",
	stopDate: "Stop date",
	stopTime: "Stop time",
	absent: "Absent",
	absenceReason: "Reason of absence",
	Illness: "Illness",
	Vacation: "Vacation",
	sequenceNumber: "Sequence number",
	fixedDeliveryDate: "Fixed delivery date"
};

export default translationsEN;
