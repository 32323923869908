import React from "react";
import { Route, RouteProps } from "react-router-dom";

import { hasPermission } from "../../../services/authentication";
import { Permission } from "../../../utils/enums";

interface IProps extends RouteProps {
	permissions: Permission[];
}

const ProtectedRoute: React.FC<IProps> = (props: IProps) => {
	return (
		<React.Fragment>
			{hasPermission(...props.permissions) && <Route {...props} />}
		</React.Fragment>
	);
};

export default ProtectedRoute;