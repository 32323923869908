import React, { Dispatch, useEffect, useReducer, useState } from "react";

import { filterBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { DatePicker, DatePickerChangeEvent, TimePicker, TimePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ComboBox, ComboBoxChangeEvent, ComboBoxCloseEvent, ComboBoxFilterChangeEvent, ComboBoxFocusEvent, DropDownListChangeEvent, DropDownListFilterChangeEvent, DropDownListFocusEvent } from "@progress/kendo-react-dropdowns";
import { Input, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import cloneDeep from "lodash/cloneDeep";
import each from "lodash/each";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch as ReduxDispatch } from "redux";

import callApi from "../../../../services/api/callApi";
import Endpoint from "../../../../services/api/endpoint";
import { notify } from "../../../../services/notification";
import { getInitialState, initalUserState, initialConstructionYardState, initialDossierState, initialTeamState } from "../../../../state";
import { hideLoader, showLoader } from "../../../../state/actions/loaderActions";
import { constructionYardReducer, customerReducer, dossierReducer, teamReducer, userReducer } from "../../../../state/reducers";
import settingReducer from "../../../../state/reducers/settingsReducer";
import { IApplicationState } from "../../../../store";
import { getDate, handleChange, setEntity } from "../../../../utils/editorUtils";
import { DossierTaskAbsenceReason, DossierTaskType, Permission, SettingKey, VatType } from "../../../../utils/enums";
import { IConstructionYard, ICustomer, IDossier, IDossierTask, initialSettingState, IUser } from "../../../../utils/types/models";
import { IAction, IDictionary } from "../../../../utils/types/types";
import { dossierNumberRegex, isNullOrEmpty } from "../../../../utils/utils";
import Confirm from "../../../global/confirm";
import { EnumDropDownList, ManageableField, SearchBox, TextArea, YesNoSwitch } from "../../../global/editor";
import Messages from "../../../global/messages";
import ConstructionYardEditor from "../../masterdata/constructionyards/editor";
import CustomerEditor from "../../masterdata/customers/editor";
import { getInitialDossierState } from "../dossiermanagement/editor/dossierEditorReducer";

interface IProps {
	record?: IDossierTask;
	taskType?: DossierTaskType;
	close: (record?: IDossier) => void;
}

const CreateTask: React.FC<IProps> = ({ close, taskType }: IProps) => {
	const { t } = useTranslation();
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);
	const [currentNewId, setCurrentNewId] = useState(-1);

	const initialTask: IDossierTask = {
		id: 0,
		dossierId: null,
		dateIsFixed: false,
		startDate: null,
		startTime: new Date(new Date().getFullYear(), 1, 1, 9, 0),
		type: taskType ? taskType : DossierTaskType.Service,
		assignee1Id: null,
		assignee1: null,
		description: "",
		planned: false,
		completed: false,
		absenceReason: null,
		absent: false,
		photos: []
	};

	const [isSequenceDossierNumber, setIsSequenceDossierNumber] = useState(true);

	const [task, setTask] = useState(initialTask);
	const [dossier, setDossier] = useState(getInitialDossierState(null, currentUser).dossier);
	const [dataChanged, setDataChanged] = useState(false);
	const [askSaveChange, setAskSaveChange] = useState(false);

	const [userState, userDispatch] = useReducer(userReducer, initalUserState);

	const [leadUsers, setLeadUsers] = useState([]);
	const [colleagueUsers, setColleagueUsers] = useState([]);

	const [teamState, teamsDispatch] = useReducer(teamReducer, initialTeamState);
	const [dossierState, dossierDispatch] = useReducer(dossierReducer, initialDossierState);
	const [customerState, customerDispatch] = useReducer(customerReducer, getInitialState<ICustomer>());
	const [constructionYardState, constructionYardDispatch] = useReducer(constructionYardReducer, initialConstructionYardState);
	const [settingsState, settingsDispatch] = useReducer(settingReducer, initialSettingState);

	const [userEntities, setUserEntities] = useState<IDictionary<IUser[]>>({});
	const [currentUserCombobox, setCurrentUserCombobox] = useState<string>();
	const reduxDispatch: ReduxDispatch = useDispatch();

	useEffect(() => {
		callApi(settingsDispatch, Endpoint.SettingsByKey, "GET", { key: SettingKey.SequenceNumberDossier});
	}, []);

	useEffect(() => {
		if (!settingsState.settingsLoading && settingsState.setting) {
			const newDossier: IDossier = cloneDeep(dossier);
			newDossier.dossierNumber = settingsState.setting.value;
			setDossier(newDossier);
		}
		// eslint-disable-next-line
	}, [settingsState.setting, settingsState.settingsLoading]);

	useEffect(() => {
		if (userState.entities) {
			userEntities[currentUserCombobox] = [...userState.entities];
			setUserEntities(userEntities);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userState.entities]);

	useEffect(() => {
		if (teamState.colleague) {
			task.assignee2 = teamState.colleague;
			task.assignee2Id = teamState.colleague.id;
			setTask(task);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [teamState.colleague]);

	useEffect(() => {
		if (!task.stopDate && !task.stopTime) {
			const newTask: IDossierTask = {...task};
			newTask.stopDate = newTask.startDate;
			newTask.stopTime = new Date(new Date().getFullYear(), 1, 1, 17, 0);
			setTask(newTask);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [task.startDate]);

	useEffect(() => {
		if (task.assignee1 && dataChanged && !task.absent) {
			callApi(teamsDispatch, Endpoint.TeamsGetColleague, "GET", { id: task.assignee1.id, companyId: currentUser.currentCompanyId });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [task.assignee1]);

	useEffect(() => {
		if ( userState.leadsEntities) {
			userEntities["assignee1Id"] = [...userState.leadsEntities];
			setLeadUsers([...userState.leadsEntities]);
			setUserEntities(userEntities);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userState.leadsEntities]);

	useEffect(() => {
		if ( userState.colleaguesEntities) {
			userEntities["assignee2Id"] = [...userState.colleaguesEntities];
			setColleagueUsers([...userState.colleaguesEntities]);
			setUserEntities(userEntities);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userState.colleaguesEntities]);

	useEffect(() => {
		callApi(userDispatch, Endpoint.UserLeadInstallers, "GET", { companyId: currentUser.currentCompanyId });
		callApi(userDispatch, Endpoint.UserColleagueInstallers, "GET", { companyId: currentUser.currentCompanyId });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (dossierState.isUpdating) {
			reduxDispatch(showLoader());
		} else if (!dossierState.isUpdating && dossierState.updatedEntity && !dossierState.updateError) {
			reduxDispatch(hideLoader());
			close(dossierState.updatedEntity);
		} else if (dossierState.updateError) {
			reduxDispatch(hideLoader());
		}
	}, [dossierState.isUpdating, dossierState.updatedEntity, dossierState.updateError, close, reduxDispatch]);

	useEffect(() => {
		if (dossierState.isAdding) {
			reduxDispatch(showLoader());
		} else if (!dossierState.isAdding && dossierState.addedEntity && !dossierState.addError) {
			reduxDispatch(hideLoader());
			close(dossierState.addedEntity);
		} else if (dossierState.addError) {
			reduxDispatch(hideLoader());
		}
	}, [dossierState.isAdding, dossierState.addedEntity, dossierState.addError, close, reduxDispatch]);

	useEffect(() => {
		if (constructionYardState.copiedEntity) {
			setDossier(setEntity(dossier, constructionYardState.copiedEntity, "constructionYard", "constructionYardId"));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [constructionYardState.copiedEntity]);

	useEffect(() => {
		if (!task.absent) {
			const newTask: IDossierTask = {...task};
			newTask.absenceReason = null;
			setTask(newTask);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [task.absent]);

	useEffect(() => {
		if (constructionYardState.isAdding || constructionYardState.isCopying) {
			reduxDispatch(showLoader());
		} else if (!(constructionYardState.isAdding || constructionYardState.isCopying)) {
			reduxDispatch(hideLoader());
		}
	}, [constructionYardState.isAdding, constructionYardState.isCopying, reduxDispatch]);

	function onChangeTask(event: DatePickerChangeEvent | TimePickerChangeEvent | SwitchChangeEvent | DropDownListChangeEvent | ComboBoxCloseEvent | React.ChangeEvent<HTMLTextAreaElement>): void {
		const newTask: IDossierTask = handleChange(task, event);

		if (event.target.name === "stopTime" && event.target.value) {
			newTask.stopTime = new Date(new Date().getFullYear(), 1, 1, event.target.value.getHours(), event.target.value.getMinutes());
		} else if (event.target.name === "stopTime" && !event.target.value) {
			newTask.stopTime = new Date(new Date().getFullYear(), 1, 1);
		}
		
		setTask(newTask);
		setDataChanged(true);
	}

	function onChangeLead(event: ComboBoxChangeEvent): void {
		const newTask: IDossierTask = {...task};
		// @ts-ignore
		if (event.value) {
			// @ts-ignore
			newTask.assignee1 = event.value;
			// @ts-ignore
			newTask.assignee1Id = event.value.id;
		} else {
			newTask.assignee1 = null;
			newTask.assignee1Id = null;
		}
		setTask(newTask);
		setDataChanged(true);
	}

	function onChangeColleague(event: ComboBoxChangeEvent): void {
		const newTask: IDossierTask = {...task};
		// @ts-ignore
		if (event.value) {
			// @ts-ignore
			newTask.assignee2 = event.value;
			// @ts-ignore
			newTask.assignee2Id = event.value.id;
		} else {
			newTask.assignee2 = null;
			newTask.assignee2Id = null;
		}
		setTask(newTask);
		setDataChanged(true);
	}

	function onChangeDossierInfo(event: React.ChangeEvent<HTMLInputElement> | DropDownListChangeEvent | DatePickerChangeEvent | ComboBoxCloseEvent): void {
		setDossier(handleChange(dossier, event));

		if (event.target.name === "customerId" && event.target.value) {
			callApi(constructionYardDispatch, Endpoint.ConstructionYardsCopyFromCustomer, "GET", { customerId: event.target.value.id });
		}
		if (event.target.name === "dossierNumber") {
			setIsSequenceDossierNumber(false);
		}
		setDataChanged(true);
	}

	function onChangeCustomer(record?: ICustomer): void {
		if (record) {
			setDossier(setEntity(dossier, record, "customer", "customerId"));
			callApi(constructionYardDispatch, Endpoint.ConstructionYardsCopyFromCustomer, "GET", { customerId: record.id });
		} else {
			let newDossier: IDossier = cloneDeep(dossier);
			newDossier = setEntity(dossier, null, "constructionYard", "constructionYardId");			
			newDossier = setEntity(dossier, null, "customer", "customerId");
			setDossier(newDossier);
		}
	}

	function onTeamFilterChange(event: ComboBoxFilterChangeEvent | DropDownListFilterChangeEvent): void {
		if (event.target.name === "assignee1Id") {
			setLeadUsers(filterBy(userEntities[event.target.name], event.filter));
		} else if (event.target.name === "assignee2Id") {
			setColleagueUsers(filterBy(userEntities[event.target.name], event.filter));
		}
	}

	function onFilterChange(event: ComboBoxFilterChangeEvent | DropDownListFilterChangeEvent): void {
		let endpoint: Endpoint = null;
		let dispatch: Dispatch<IAction>;
		// let installer: boolean = false;
		// let leadInstaller: boolean = false;

		switch (event.target.name) {
			case "customerId":
				dispatch = customerDispatch;
				endpoint = Endpoint.Customers;
				break;
			case "constructionYardId":
				dispatch = constructionYardDispatch;
				endpoint = Endpoint.ConstructionYards;
				break;
			case "dossierManagerId":
			case "salesRepId":
				dispatch = userDispatch;
				endpoint = Endpoint.Users;
				break;
		}

		if (endpoint && dispatch) {
			const query: object = {
				search: event.filter.value,
				companyId: currentUser.currentCompanyId
			};
			callApi(dispatch, endpoint, "GET", query);
		}
	}

	function onDossierFilterChange(event: ComboBoxFilterChangeEvent): void {
		callApi(dossierDispatch, Endpoint.Dossiers, "GET", { search: event.filter.value });
	}

	function onFocus(event: ComboBoxFocusEvent | DropDownListFocusEvent): void {
		setCurrentUserCombobox(event.target.name);
	}

	function onBlur(): void {
		setCurrentUserCombobox(null);
	}

	function save(): void {
		const messages: string[] = [];
		if (!task.type) {
			messages.push(t("fill_in_required_field", { field: t("type").toLowerCase() }));
		}
		if (task.planned && !task.startDate) {
			messages.push(t("fill_in_required_field_for_planned", { field: t("startDate").toLowerCase() }));
		}
		if (task.planned && !task.stopDate) {
			messages.push(t("fill_in_required_field_for_planned", { field: t("stopDate").toLowerCase() }));
		}
		if (task.planned && !task.startTime) {
			messages.push(t("fill_in_required_field_for_planned", { field: t("startTime").toLowerCase() }));
		}
		if (task.planned && !task.stopTime) {
			messages.push(t("fill_in_required_field_for_planned", { field: t("stopTime").toLowerCase() }));
		}
		if (task.planned && !task.assignee1Id && !task.absent) {
			messages.push(t("fill_in_required_field_for_planned", { field: t("lead").toLowerCase() }));
		}
		if ((task.dateIsFixed || task.planned) && !task.startDate) {
			messages.push(t("fill_in_required_field_for_fixedDate", { field: t("startDate").toLowerCase() }));
		}
		if (!isNullOrEmpty(dossier.dossierNumber) && !dossier.dossierNumber.match(dossierNumberRegex)) {
			messages.push(t("fill_in_correct_format", { field: t("dossierNumber").toLowerCase(), example: "1234_12" }));
		}
		if (isNullOrEmpty(dossier.dossierNumber)) {
			messages.push(t("fill_in_required_field", { field: t("dossierNumber").toLowerCase() }));
		}
		if (!dossier.sellingDate) {
			messages.push(t("fill_in_required_field", { field: t("soldOn").toLowerCase() }));
		}
		if (!dossier.creationDate) {
			messages.push(t("fill_in_required_field", { field: t("createdOn").toLowerCase() }));
		}
		if (!dossier.customerId) {
			messages.push(t("fill_in_required_field", { field: t("customer").toLowerCase() }));
		}
		if (!dossier.constructionYardId) {
			messages.push(t("fill_in_required_field", { field: t("constructionYard").toLowerCase() }));
		}
		if (!dossier.dossierManagerId) {
			messages.push(t("fill_in_required_field", { field: t("dossierManager").toLowerCase() }));
		}
		if (!dossier.salesRepId) {
			messages.push(t("fill_in_required_field", { field: t("salesRep").toLowerCase() }));
		}
		if (!dossier.vatType) {
			messages.push(t("fill_in_required_field", { field: t("vat").toLowerCase() }));
		}
		if (task.absent && !task.absenceReason) {
			messages.push(t("fill_in_required_field", { field: t("absenceReason").toLowerCase() }));
		}
		if (task.absent && (!task.assignee1Id && !task.assignee2Id)) {
			messages.push(t("fill_in_required_field_absent"));
		}
		if (task.absent && (!task.startDate && !task.stopDate)) {
			messages.push(t("fill_in_required_field_absent_date"));
		}
		if ((getDate(task.stopDate) < getDate(task.startDate)) || (task.stopDate === task.startDate && task.startDate && task.stopDate && getDate(task.stopTime) < getDate(task.startTime))) {
			messages.push(t("fill_in_correct_date_values"));
		}

		if (messages.length <= 0) {
			const newDossier: IDossier = {...dossier};
			newDossier.dossierNumber = dossier.dossierNumber.toUpperCase();
			newDossier.complete = true;
			const newTask: IDossierTask = cloneDeep(task);
			if (!task.id) {
				newTask.id = currentNewId;
				setCurrentNewId(currentNewId - 1);
			}
			newDossier.tasks.push(newTask);

			each(newDossier.tasks, (entity: IDossierTask) => {
				if (entity.id <= 0) {
					entity.id = 0;
				}
			});

			if (newDossier.id) {
				callApi(dossierDispatch, Endpoint.Dossiers, "PUT", { id: newDossier.id}, newDossier);
			} else {
				callApi(dossierDispatch, Endpoint.Dossiers, "POST", { sequenceDossierNumber: isSequenceDossierNumber}, newDossier);
			}
		} else {
			notify(t("error"), <Messages messages={messages} />, "error");
		}
	}

	function onDossierChange(d: IDossier): void {
		if (d) {
			setDossier(d);
			const newTask: IDossierTask = {
				...task,
				dossierId: d.id,
				dossier: d
			};
			setTask(newTask);
		}
		setDataChanged(true);
		setIsSequenceDossierNumber(false);
	}

	function handleClose(): void {
		if (dataChanged && !askSaveChange) {
			setAskSaveChange(true);
		} else if (dataChanged && askSaveChange) {
			setAskSaveChange(false);
		} else {
			close();
		}
	}

	function swapAssignees(): void {
		const newTask: IDossierTask = cloneDeep(task);
		newTask.assignee1Id = task.assignee2Id;
		newTask.assignee1 = task.assignee2;
		newTask.assignee2Id = task.assignee1Id;
		newTask.assignee2 = task.assignee1;
		setTask(newTask);
		setDataChanged(true);
	}

	return (
		<>
			<Dialog width="70%" title={t("createNewTask")} onClose={() => close()}>
				<div className="k-form">
					<div className="row">
						<div className="col-3">
							<label className="k-form-field">
								<span>{t("dossier")}</span>
								<SearchBox
									name="dossierId"
									entities={dossierState.entities}
									entityId={dossier?.id}
									entity={dossier}
									textField="dossierNumber"
									onFilterChange={onDossierFilterChange}
									onClose={(event: ComboBoxCloseEvent) => onDossierChange(event.target.value)}
									onClear={() => setDossier(getInitialDossierState(null, currentUser).dossier)}
								/>
							</label>
						</div>
					</div>
					<fieldset>
						<legend>{t("dossier")}</legend>
						<div className="row">
							<div className="col">
								<label className="k-form-field">
									<span>{t("dossierNumber")} *</span>
									<Input
										name="dossierNumber"
										value={dossier.dossierNumber}
										onChange={onChangeDossierInfo}
										required
										className={"full-width-field"}
										style={{ textTransform: "uppercase" }}
										pattern="[0-9a-zA-Z_]+"
										disabled={dossier.id > 0}
									/>
								</label>
							</div>
							<div className="col">
								<label className="k-form-field">
									<span>{t("soldOn")} *</span>
									<DatePicker
										name="sellingDate"
										value={getDate(dossier.sellingDate)}
										onChange={onChangeDossierInfo}
										required
										className="full-width-field"
										formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
										disabled={dossier.id > 0}
									/>
								</label>
							</div>
							<div className="col">
								<label className="k-form-field">
									<span>{t("createdOn")} *</span>
									<DatePicker
										name="creationDate"
										value={getDate(dossier.creationDate)}
										onChange={onChangeDossierInfo}
										required
										className="full-width-field"
										formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
										disabled={dossier.id > 0}
									/>
								</label>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<ManageableField
									fieldLabel={t("customer") + " *"}
									recordId={dossier.customerId}
									addScreen={CustomerEditor}
									editScreen={CustomerEditor}
									addPermission={Permission.CustomersAdd}
									editPermission={Permission.CustomersUpdate}
									setEntity={(record: ICustomer) => onChangeCustomer(record)}
									readOnly={dossier.id > 0}
								>
									<SearchBox
										name="customerId"
										entities={customerState.entities}
										entityId={dossier.customerId}
										entity={dossier.customer}
										required
										textField="name"
										onFilterChange={onFilterChange}
										onClose={onChangeDossierInfo}
										onClear={() => onChangeCustomer(null)}
										disabled={dossier.id > 0}
									/>
								</ManageableField>
							</div>
							<div className="col">
								<ManageableField
									fieldLabel={t("constructionYard") + " *"}
									recordId={dossier.constructionYardId}
									addScreen={ConstructionYardEditor}
									editScreen={ConstructionYardEditor}
									addPermission={Permission.ConstructionYardsAdd}
									editPermission={Permission.ConstructionYardsUpdate}
									setEntity={(record: IConstructionYard) => setDossier(setEntity(dossier, record, "constructionYard", "constructionYardId"))}
									readOnly={dossier.id > 0}
								>
									<SearchBox
										name="constructionYardId"
										entities={constructionYardState.entities}
										entityId={dossier.constructionYardId}
										entity={dossier.constructionYard}
										required
										textField="name"
										onFilterChange={onFilterChange}
										onClose={onChangeDossierInfo}
										onClear={() => setDossier(setEntity(dossier, null, "constructionYard", "constructionYardId"))}
										disabled={dossier.id > 0}
										/>
								</ManageableField>
							</div>
							<div className="col">
								<label className="k-form-field">
									<span>{t("vat")}% *</span>
									<EnumDropDownList
										name="vatType"
										translationPrefix="fullDescription"
										className="full-width-field"
										enum={VatType}
										value={dossier.vatType}
										onChange={onChangeDossierInfo}
										required
										disabled={dossier.id > 0}
									/>
								</label>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<label className="k-form-field">
									<span>{t("dossierManager")} *</span>
									<SearchBox
										name="dossierManagerId"
										entities={userEntities["dossierManagerId"]}
										entityId={dossier.dossierManagerId}
										entity={dossier.dossierManager}
										textField="fullName"
										onFilterChange={onFilterChange}
										onClose={onChangeDossierInfo}
										onFocus={onFocus}
										onBlur={onBlur}
										onClear={() => setDossier(setEntity(dossier, null, "dossierManager", "dossierManagerId"))}
										required
										disabled={dossier.id > 0}
									/>
								</label>
							</div>
							<div className="col">
								<label className="k-form-field">
									<span>{t("salesRep")} *</span>
									<SearchBox
										name="salesRepId"
										entities={userEntities["salesRepId"]}
										entityId={dossier.salesRepId}
										entity={dossier.salesRep}
										textField="fullName"
										onFilterChange={onFilterChange}
										onClose={onChangeDossierInfo}
										onFocus={onFocus}
										onBlur={onBlur}
										onClear={() => setDossier(setEntity(dossier, null, "salesRep", "salesRepId"))}
										required
										disabled={dossier.id > 0}
									/>
								</label>
							</div>
						</div>		
					</fieldset>
					<fieldset>
						<legend>{t("task")}</legend>
						<div className="row">
							<div className="col-1">
								<div className="k-form-field">
									<span>{t("schedule")}</span>
									<YesNoSwitch name="planned" checked={task.planned} onChange={onChangeTask} />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<label className="k-form-field">
									<span>{t("startDate")} {task.planned ? "*" : ""}</span>
									<DatePicker
										name="startDate"
										className="full-width-field"
										value={getDate(task.startDate)}
										onChange={onChangeTask}
										required={task.planned || task.absent}
										formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
									/>
								</label>
							</div>
							<div className="col">
								<label className="k-form-field">
									<span>{t("startTime")}{task.absent ? " *" : ""}</span>
									<TimePicker
										name="startTime"
										className="full-width-field"
										value={getDate(task.startTime)}
										onChange={onChangeTask}
										required={task.absent || task.planned}
									/>
								</label>
							</div>
							<div className="col-1">
								<div className="k-form-field">
									<span>{t("fixedDate")}</span>
									<YesNoSwitch name="dateIsFixed" checked={task.dateIsFixed} onChange={onChangeTask} />
								</div>
							</div>
							<div className="col">
								<label className="k-form-field">
									<span>{t("type")} *</span>
									<EnumDropDownList name="type" className="full-width-field" value={task.type} enum={DossierTaskType} onChange={onChangeTask} required />
								</label>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<label className="k-form-field">
									<span>{t("stopDate")}{task.absent ? " *" : ""}</span>
									<DatePicker
										name="stopDate"
										className="full-width-field"
										value={getDate(task.stopDate)}
										onChange={onChangeTask}
										required={task.absent || task.planned}
										formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
									/>
								</label>
							</div>
							<div className="col">
								<label className="k-form-field">
									<span>{t("stopTime")}{task.absent ? " *" : ""}</span>
									<TimePicker
										name="stopTime"
										className="full-width-field"
										value={getDate(task.stopTime)}
										required={task.absent || task.planned}
										onChange={onChangeTask}
									/>
								</label>
							</div>
							<div className="col-1">
								<div className="k-form-field">
									<span>{t("absent")}</span>
									<YesNoSwitch name="absent" checked={task.absent} onChange={onChangeTask} />
								</div>
							</div>
							<div className="col">
								{task.absent && (
									<label className="k-form-field">
										<span>{t("absenceReason")}{task.absent ? " *" : ""}</span>
										<EnumDropDownList 
											name="absenceReason" 
											className="full-width-field" 
											value={task.absenceReason} 
											enum={DossierTaskAbsenceReason} 
											onChange={onChangeTask} 
											required={task.absent} 
										/>
									</label>
								)}
							</div>							
						</div>
						<div className="row">
							<div className="col">
								<label className="k-form-field">
									<span>{t("lead")} {(task.planned && !task.absent ) || (task.absent && !task.assignee2Id) ? "*" : ""}</span>
									<ComboBox
										name="assignee1Id"
										data={leadUsers}
										value={task.assignee1}
										textField="fullName"
										onFilterChange={onTeamFilterChange}
										onChange={onChangeLead}
										suggest
										filterable
										allowCustom={false}
										onBlur={onBlur}
										style={ {width: "100%"}}
										// onClear={() => onClear("assignee1", "assignee1Id")}
										required={(task.planned && !task.absent ) || (task.absent && !task.assignee2Id)}
									/>
								</label>
							</div>
							<div className="col-1">
								<label className="k-form-field">
									<span>&nbsp;</span>
									<Button primary onClick={swapAssignees} style={{ width: "100%" }}>{t("swap")}&nbsp;<i className="las la-exchange-alt" /></Button>
								</label>
							</div>
							<div className="col">
								<label className="k-form-field">
									<span>{t("colleague")} {task.absent && !task.assignee1Id ? "*" : ""}</span>
									<ComboBox
										name="assignee2Id"
										data={colleagueUsers}
										style={ {width: "100%"}}
										value={task.assignee2}
										textField="fullName"
										onFilterChange={onTeamFilterChange}
										onBlur={onBlur}
										onChange={onChangeColleague}
										required={task.absent && !task.assignee1Id}
										suggest
										filterable
										allowCustom={false}
									/>
								</label>
							</div>
						</div>
						<label className="k-form-field">
							<span>{t("task")}</span>
							<TextArea name="description" className="full-width-field" value={task.description} onChange={onChangeTask} />
						</label>
					</fieldset>
				</div>
				<DialogActionsBar>
					<Button onClick={() => handleClose()} type="button">
						{t("cancel")}
					</Button>
					<Button type="submit" primary onClick={() => save()}>
						{t("save")}
					</Button>
				</DialogActionsBar>
			</Dialog>
			{askSaveChange && (
				<Confirm
					title={t("pending_changes")}
					onConfirm={() => {
						setAskSaveChange(false);
						save();
					}}
					onDecline={() => close()}
					onCancel={() => handleClose()}
				>
					{t("ask_save")}
				</Confirm>
			)}
		</>
	);
};

export default CreateTask;
