import React from "react";

import { DropDownList, DropDownListProps } from "@progress/kendo-react-dropdowns";
import { useTranslation } from "react-i18next";

import map from "lodash/map";
import values from "lodash/values";

import { DropDownListWithEmptyEntry } from ".";
import { isNullOrEmpty } from "../../../utils/utils";

interface IProps<T> extends DropDownListProps {
	// @ts-ignore
	enum: typeof T;
	value: T;
	translationPrefix?: string;
}

function EnumDropDownList<T>(props: IProps<T>): JSX.Element {
	const { t } = useTranslation();
	let valueTranslationKey: string = props.value?.toString();
	if (!isNullOrEmpty(props.translationPrefix)) {
		valueTranslationKey = props.translationPrefix + valueTranslationKey;
	}
	const allProps: DropDownListProps = {
		...props,
		data: map(values(props.enum), (value: string) => {
			let transLationKey: string = value;
			if (!isNullOrEmpty(props.translationPrefix)) {
				transLationKey = props.translationPrefix + transLationKey;
			}
			return {
				key: value,
				name: t(transLationKey)
			};
		}),
		value: props.value && {
			key: props.value,
			name: t(valueTranslationKey)
		},
		dataItemKey: "key",
		textField: "name"
	};

	if (props.required) {
		return <DropDownList {...allProps} />;
	} else {
		return <DropDownListWithEmptyEntry {...allProps} />;
	}
}

export default EnumDropDownList;
