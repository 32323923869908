import { Reducer } from "react";

import { AxiosError, AxiosResponse } from "axios";

import { IFileState, initialFileState } from "..";
import Endpoint from "../../services/api/endpoint";
import { IAction } from "../../utils/types/types";

const fileReducer: Reducer<IFileState, IAction> = (state: IFileState = initialFileState, action: IAction): IFileState => {
	   switch (action.type) {
		case "REQUEST_POST_" + Endpoint.DownloadDossierExcel:
			state = {
				...state,
				isDownloading: true
			};
			break;
		case "REQUEST_FAILURE_POST_" + Endpoint.DownloadDossierExcel: {
			const payload: { error: AxiosError } = action.payload as { error: AxiosError };
			state = {
				...state,
				downloadError: payload.error
			};
			break;
		}
		case "REQUEST_SUCCESS_POST_" + Endpoint.DownloadDossierExcel: {
			const payload: { response: AxiosResponse } = action.payload as { response: AxiosResponse };
			if (action.payload && payload.response && payload.response.data) {
				const contentDisposition: string = payload.response.headers["content-disposition"];
				const match: RegExpMatchArray = contentDisposition.match(/filename\s*=\s*(.+);/i);
				const filename: string = match[1];

				state = {
					...state,
					downloadFile: payload.response.data,
					filename
				};
			}
			break;
		}
		case "REQUEST_END_POST_" + Endpoint.DownloadDossierExcel:
			state = {
				...state,
				isDownloading: false
			};
			break;
	}

	   return state;
};

export default fileReducer;
