import React, { useEffect, useReducer, useRef, useState } from "react";

import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Endpoint from "../../../../services/api/endpoint";
import { initialConstructionYardState } from "../../../../state";
import { constructionYardReducer } from "../../../../state/reducers";
import { IApplicationState } from "../../../../store";
import { handleChange } from "../../../../utils/editorUtils";
import { IAddress, IConstructionYard, IUser } from "../../../../utils/types/models";
import { emailRegex, isNullOrEmpty, phoneRegex } from "../../../../utils/utils";
import EntityEditor, { AddressEdit, IEditorProps, IEditScreenProps, YesNoSwitch } from "../../../global/editor";

const ConstructionYardEditor: React.FC<IEditorProps<IConstructionYard>> = (props: IEditorProps<IConstructionYard>) => {
	const { t } = useTranslation();
	const [state, dispatch] = useReducer(constructionYardReducer, initialConstructionYardState);
	const currentUser: IUser = useSelector((reduxState: IApplicationState) => reduxState.authenticationState.currentUser);
	const initalConstructionYard: IConstructionYard = {
		id: (props as IEditScreenProps<IConstructionYard>).recordId || 0,
		name: "",
		address: {
			street: "",
			number: "",
			postalCode: "",
			municipality: "",
			province: "",
			country: "",
			fullAddress: ""
		},
		constructionYardContact: "",
		phone: "",
		email: "",
		active: true,
		companyId: currentUser.currentCompanyId
	};
	const [constructionYard, setConstructionYard] = useState<IConstructionYard>(initalConstructionYard);
	const [dataChange, setDataChanged] = useState<boolean>(false);
	const firstField: React.MutableRefObject<Input> = useRef();

	useEffect(() => {
		if (state.entity) {
			setConstructionYard(state.entity);
		}
	}, [state.entity]);

	function onChange(event: React.ChangeEvent<HTMLInputElement> | SwitchChangeEvent | ComboBoxChangeEvent): void {
		setConstructionYard(handleChange(constructionYard, event));
		setDataChanged(true);
	}

	function setAddress(address: IAddress): void {
		const newConstrunctionYard: IConstructionYard = { ...constructionYard };
		newConstrunctionYard.address = address;
		setConstructionYard(newConstrunctionYard);
		setDataChanged(true);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(constructionYard.name)) {
			messages.push(t("fill_in_required_field", { field: t("name").toLowerCase() }));
		}
		if (isNullOrEmpty(constructionYard.address.street)) {
			messages.push(t("fill_in_required_field", { field: t("street").toLowerCase() }));
		}
		if (isNullOrEmpty(constructionYard.address.number)) {
			messages.push(t("fill_in_required_field", { field: t("number").toLowerCase() }));
		}
		if (isNullOrEmpty(constructionYard.address.postalCode)) {
			messages.push(t("fill_in_required_field", { field: t("postalCode").toLowerCase() }));
		}
		if (isNullOrEmpty(constructionYard.address.municipality)) {
			messages.push(t("fill_in_required_field", { field: t("municipality").toLowerCase() }));
		}
		if (isNullOrEmpty(constructionYard.address.country)) {
			messages.push(t("fill_in_required_field", { field: t("country").toLowerCase() }));
		}
		if (isNullOrEmpty(constructionYard.constructionYardContact)) {
			messages.push(t("fill_in_required_field", { field: t("constructionYardContact").toLowerCase() }));
		}
		if (!isNullOrEmpty(constructionYard.phone) && !constructionYard.phone.match(phoneRegex)) {
			messages.push(t("fill_in_correct_format", { field: t("phone").toLowerCase(), example: "012345678' " + t("or") + " '0476123456" }));
		}
		if (!isNullOrEmpty(constructionYard.email) && !constructionYard.email.match(emailRegex)) {
			messages.push(t("fill_in_correct_format", { field: t("email").toLowerCase(), example: "user@test.com" }));
		}
		return messages;
	}

	const readonly: boolean = (props as IEditScreenProps<IConstructionYard>).readonly;
	return (
		<EntityEditor
			width="70%"
			record={constructionYard}
			endpoint={Endpoint.ConstructionYards}
			entityState={state}
			dispatch={dispatch}
			dataChanged={dataChange}
			readonly={readonly}
			recordName={constructionYard.name}
			entityType={t("constructionYard")}
			close={props.close}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<div className="k-form">
				<label className="k-form-field">
					<span>{t("name")} *</span>
					<Input name="name" ref={firstField} className="full-width-field" value={constructionYard.name} onChange={onChange} required disabled={readonly} />
				</label>
				<AddressEdit address={constructionYard.address} addressField="address" onChange={onChange} setAddress={setAddress} required readonly={readonly} />
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>{t("constructionYardContact")}</span>
							<Input name="constructionYardContact" className="full-width-field" value={constructionYard.constructionYardContact} onChange={onChange} required disabled={readonly} />
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("phone")}</span>
							<Input name="phone" className="full-width-field" value={constructionYard.phone} onChange={onChange} pattern={phoneRegex.source} disabled={readonly} />
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("email")}</span>
							<Input name="email" type="email" className="full-width-field" value={constructionYard.email} onChange={onChange} disabled={readonly} />
						</label>
					</div>
				</div>
				{!props.hideActive && (
					<div className="k-form-field">
						<span>{t("active")}</span>
						<YesNoSwitch name="active" checked={constructionYard.active} onChange={onChange} disabled={readonly} />
					</div>
				)}
			</div>
		</EntityEditor>
	);
};

export default ConstructionYardEditor;
