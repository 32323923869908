/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Button } from "@progress/kendo-react-buttons";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import find from "lodash/find";
import map from "lodash/map";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import { usePreventWindowUnload } from "../../../hooks";
import Endpoint from "../../../services/api/endpoint";
import { ILanguageConfiguration, languages } from "../../../services/translation/languages";
import { IAuthenticationState } from "../../../state";
import { setCurrentCompany, setCurrentLanguage } from "../../../state/actions/authActions";
import { hideLoader, showLoader } from "../../../state/actions/loaderActions";
import { IApplicationState } from "../../../store";
import API from "../../../store/actions/apiActions";
import ILanguageItem from "../../../utils/types/languageitem";
import { ICompany } from "../../../utils/types/models";
import PendingSavePrompt from "../../global/PendingSavePrompt";

import style from "./myprofile.module.scss";

const MyProfile: React.FC = () => {
	const { t } = useTranslation();
	const languageItems: ILanguageItem[] = languages.map((languageItem: ILanguageConfiguration) => {
		return {
			code: languageItem.code,
			translationKey: languageItem.translationKey,
			description: t(languageItem.translationKey)
		};
	});
	const authenticationState: IAuthenticationState = useSelector((state: IApplicationState) => state.authenticationState);
	const companyItems: ICompany[] = map(authenticationState.currentUser.userCompanies, "company");
	const reduxDispatch: Dispatch = useDispatch();
	const [profileChanged, setProfileChanged] = useState(false);
	const [language, setLanguage] = useState(find(languageItems, { code: authenticationState.currentUser.language }));
	const [company, setCompany] = useState(find(companyItems, { id: authenticationState.currentUser.currentCompanyId }));

	usePreventWindowUnload(profileChanged);

	useEffect(() => {
		if (!authenticationState.savingCurrentUser && !authenticationState.savingCurrentUserError) {
			reduxDispatch(setCurrentLanguage(language.code));
			reduxDispatch(setCurrentCompany(company));
			setLanguage({
				code: language.code,
				translationKey: language.translationKey,
				description: t(language.translationKey, {
					lng: language.code
				})
			});
			setProfileChanged(false);
		}

		if (authenticationState.savingCurrentUser) {
			reduxDispatch(showLoader());
		} else if (!authenticationState.savingCurrentUser) {
			reduxDispatch(hideLoader());
		}
	}, [authenticationState.savingCurrentUser, authenticationState.savingCurrentUserError]);

	function handleChange(event: DropDownListChangeEvent): void {
		if (event.target.value) {
			switch (event.target.name) {
				case "language":
					setLanguage(event.target.value);
					break;
				case "company":
					setCompany(event.target.value);
					break;
			}
			setProfileChanged(true);
		}
	}

	function save(setNavigationAllowed?: (navigationAllowed: boolean) => void): void {
		authenticationState.currentUser.language = language.code;
		authenticationState.currentUser.currentCompanyId = company.id;
		if (setNavigationAllowed) {
			reduxDispatch(
				API.getActions()[Endpoint.CurrentUser](
					"PUT",
					null,
					authenticationState.currentUser,
					null,
					(success: boolean) => {
						if (success) {
							setNavigationAllowed(true);
						}
					},
					false
				)
			);
		} else {
			reduxDispatch(API.getActions()[Endpoint.CurrentUser]("PUT", null, authenticationState.currentUser));
		}
	}

	return (
		<div className={"container " + style.myProfile}>
			<div style={{ padding: "20px" }}>
				<PendingSavePrompt when={profileChanged} title={t("pending_changes")} onConfirm={save}>
					{t("ask_save")}
				</PendingSavePrompt>
				<div className={"row " + style.myProfileTitle}>
					<div className="col">
						<h1>{t("yourprofile")}</h1>
					</div>
				</div>
				<div className={"row " + style.myProfileOverview}>
					<div className="col-md-3">
						<div className={"row " + style.myProfileImage}>
							<i className="la la-user" />
						</div>
					</div>
					<div className="col-md-9">
						<div className={"col-12 " + style.myProfileName}>
							{authenticationState.currentUser.firstName} {authenticationState.currentUser.lastName}
						</div>

						<div className={style.myProfileFields}>
							<div className={"col-12 " + style.myProfileField}>
								{t("email")}: <span className={style.myProfileFieldValue}>{authenticationState.currentUser.email}</span>
							</div>
							<div className={"col-12 " + style.myProfileField}>
								{t("language")}:
								<span className={style.myProfileFieldValue}>
									<DropDownList name="language" data={languageItems} dataItemKey="code" textField="description" value={language} onChange={handleChange} required />
								</span>
							</div>
							{companyItems.length > 1 && (
								<div className={"col-12 " + style.myProfileField}>
									{t("company")}:
									<span className={style.myProfileFieldValue}>
										<DropDownList name="company" data={companyItems} dataItemKey="id" textField="name" value={company} onChange={handleChange} required />
									</span>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-11" />
					<div className="col-md-1">
						<Button primary disabled={!profileChanged} onClick={() => save()}>
							{t("save")}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MyProfile;
