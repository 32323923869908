import React, { useReducer, useState } from "react";

import { DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBoxCloseEvent, ComboBoxFilterChangeEvent, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import callApi from "../../../../../../services/api/callApi";
import Endpoint from "../../../../../../services/api/endpoint";
import { IAuthenticationState, initalUserState } from "../../../../../../state";
import { userReducer } from "../../../../../../state/reducers";
import { IApplicationState } from "../../../../../../store";
import { getDate, handleChange, setEntity } from "../../../../../../utils/editorUtils";
import { ServiceItemStatus } from "../../../../../../utils/enums";
import { IDossier, IServiceItem } from "../../../../../../utils/types/models";
import { isNullOrEmpty } from "../../../../../../utils/utils";
import { EnumDropDownList, SearchBox, TextArea } from "../../../../../global/editor";
import PageEditor from "../pageEditor";

interface IProps {
	record?: IServiceItem;
	dossier: IDossier;
	onClose: (record?: IServiceItem) => void;
	readOnly: boolean;
}

const ServiceItemEditor: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const [userState, dispatch] = useReducer(userReducer, initalUserState);
	const authenticationState: IAuthenticationState = useSelector((state: IApplicationState) => state.authenticationState);
	const initialServiceItem: IServiceItem = {
		id: null,
		dossierId: null,
		status: ServiceItemStatus.New,
		loggerId: authenticationState.currentUser.id,
		logger: authenticationState.currentUser,
		logDate: new Date(),
		description: ""
	};
	const [serviceItem, setServiceItem] = useState<IServiceItem>(props.record || initialServiceItem);
	const [dataChanged, setDataChanged] = useState(false);

	function onChange(event: React.ChangeEvent<HTMLTextAreaElement> | DropDownListChangeEvent | ComboBoxCloseEvent | DatePickerChangeEvent): void {
		const newServiceItem: IServiceItem = { ...serviceItem };
		setServiceItem(handleChange(newServiceItem, event));
		setDataChanged(true);
	}

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		callApi(dispatch, Endpoint.Users, "GET", { search: event.filter.value });
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (!serviceItem.status) {
			messages.push(t("fill_in_required_field", { field: t("status").toLowerCase() }));
		}
		if (!serviceItem.loggerId) {
			messages.push(t("fill_in_required_field", { field: t("logger").toLowerCase() }));
		}
		if (!serviceItem.logDate) {
			messages.push(t("fill_in_required_field", { field: t("logDate").toLowerCase() }));
		}
		if (isNullOrEmpty(serviceItem.description)) {
			messages.push(t("fill_in_required_field", { field: t("subject").toLowerCase() }));
		}
		return messages;
	}

	const action: string = props.record ? t("edit") : t("add");

	return (
		<PageEditor
			width="70%"
			title={`${action} ${t("aftersales")}`.trim()}
			close={props.onClose}
			dataChanged={dataChanged}
			getErrorMessages={getErrorMessages}
			action={action}
			record={serviceItem}
			readOnly={props.readOnly}
		>
			<div className="k-form">
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>{t("status")} *</span>
							<EnumDropDownList
								name="status"
								className="full-width-field"
								enum={ServiceItemStatus}
								value={serviceItem.status}
								onChange={onChange}
								required
								disabled={props.readOnly}
							/>
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("loggedBy")} *</span>
							<SearchBox
								name="loggerId"
								entities={userState.entities}
								entityId={serviceItem.loggerId}
								entity={serviceItem.logger}
								textField="fullName"
								onFilterChange={onFilterChange}
								onClose={onChange}
								onClear={() => setServiceItem(setEntity(serviceItem, null, "loggerId", "logger"))}
								required
								disabled={props.readOnly}
							/>
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("date")}</span>
							<DatePicker
								name="logDate"
								value={getDate(serviceItem.logDate)}
								onChange={onChange}
								className="full-width-field"
								formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
								disabled={props.readOnly}
							/>
						</label>
					</div>
				</div>
				<label className="k-form-field">
					<span>{t("remark")} *</span>
					<TextArea name="description" className="full-width-field" value={serviceItem.description} onChange={onChange} disabled={props.readOnly} />
				</label>
			</div>
		</PageEditor>
	);
};

export default ServiceItemEditor;