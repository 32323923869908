import React, { useEffect, useReducer, useState } from "react";

import { Input } from "@progress/kendo-react-inputs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import callApi from "../../../../../../services/api/callApi";
import Endpoint from "../../../../../../services/api/endpoint";
import { getInitialState } from "../../../../../../state";
import { contactTypeReducer } from "../../../../../../state/reducers";
import { IApplicationState } from "../../../../../../store";
import { getEntity, handleChange, setEntity } from "../../../../../../utils/editorUtils";
import { Permission } from "../../../../../../utils/enums";
import { IContact, IContactType, IUser } from "../../../../../../utils/types/models";
import { emailRegex, isNullOrEmpty, phoneRegex } from "../../../../../../utils/utils";
import { ManageableField } from "../../../../../global/editor";
import ContactTypeEditor from "../../../../communication/contacttypes/editor";
import PageEditor from "../pageEditor";

interface IProps {
	record?: IContact;
	onClose: (record?: IContact) => void;
	readOnly: boolean;
}

const ContactEditor: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const [contactTypeState, dispatch] = useReducer(contactTypeReducer, getInitialState<IContactType>());
	const initialContact: IContact = {
		id: null,
		dossierId: null,
		typeId: null,
		type: null,
		name: "",
		email: "",
		phone: ""
	};
	const [contact, setContact] = useState<IContact>(props.record || initialContact);
	const [dataChanged, setDataChanged] = useState(false);
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);

	useEffect(() => {
		callApi(dispatch, Endpoint.ContactTypes, "GET", { companyId: currentUser.currentCompanyId });
	}, [currentUser.currentCompanyId]);

	function onChange(event: React.ChangeEvent<HTMLInputElement> | DropDownListChangeEvent): void {
		setContact(handleChange(contact, event));
		setDataChanged(true);
	}

	function setContactType(contactType: IContactType): void {
		callApi(dispatch, Endpoint.ContactTypes, "GET", { companyId: currentUser.currentCompanyId });
		setContact(setEntity(contact, contactType, "type", "typeId"));
		setDataChanged(true);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (!contact.typeId) {
			messages.push(t("fill_in_required_field", { field: t("type").toLowerCase() }));
		}
		if (isNullOrEmpty(contact.name)) {
			messages.push(t("fill_in_required_field", { field: t("name").toLowerCase() }));
		}
		if (!isNullOrEmpty(contact.phone) && !contact.phone.match(phoneRegex)) {
			messages.push(t("fill_in_correct_format", { field: t("phone").toLowerCase(), example: "012345678' " + t("or") + " '0476123456" }));
		}
		if (!isNullOrEmpty(contact.email) && !contact.email.match(emailRegex)) {
			messages.push(t("fill_in_correct_format", { field: t("email").toLowerCase(), example: "user@test.com" }));
		}

		return messages;
	}

	const action: string = props.record ? t("edit") : t("add");
	return (
		<React.Fragment>
			<PageEditor
				width="70%"
				title={`${action} ${t("Contact")} ${contact.name}`.trim()}
				close={props.onClose}
				dataChanged={dataChanged}
				getErrorMessages={getErrorMessages}
				action={action}
				record={contact}
				readOnly={props.readOnly}
			>
				<div className="k-form">
					<ManageableField
						addScreen={ContactTypeEditor}
						editScreen={ContactTypeEditor}
						fieldLabel={t("type") + " *"}
						recordId={contact.typeId}
						addPermission={Permission.ContactTypesAdd}
						editPermission={Permission.ContactTypesUpdate}
						setEntity={(record: IContactType) => setContactType(record)}
						readOnly={props.readOnly}
					>
						<DropDownList
							name="typeId"
							className="full-width-field"
							data={contactTypeState.entities}
							loading={contactTypeState.areEntitiesLoading}
							value={getEntity(contactTypeState.entities, contact.typeId, contact.type)}
							dataItemKey="id"
							textField="name"
							onChange={onChange}
							required
							disabled={props.readOnly}
						/>
					</ManageableField>
					<label className="k-form-field">
						<span>{t("name")} *</span>
						<Input name="name" className="full-width-field" value={contact.name} onChange={onChange} required minLength={1} disabled={props.readOnly} />
					</label>
					<label className="k-form-field">
						<span>{t("phone")}</span>
						<Input name="phone" className="full-width-field" value={contact.phone} onChange={onChange} pattern={phoneRegex.source} disabled={props.readOnly} />
					</label>
					<label className="k-form-field">
						<span>{t("email")}</span>
						<Input name="email" type="email" className="full-width-field" value={contact.email} onChange={onChange} disabled={props.readOnly} />
					</label>
				</div>
			</PageEditor>
		</React.Fragment>
	);
};

export default ContactEditor;