import React, { useState } from "react";

import map from "lodash/map";

import { Button } from "@progress/kendo-react-buttons";

import { IFileData } from "../../../utils/types/models";
import ImageGalleryModal from "./imageGalleryModal";

import style from "./imageGallery.module.scss";

interface IProps {
	images: (IFileData | string)[];
	imageTileSize: number;
	deletePhoto?: (index: number) => void;
}

const ImageGallery: React.FC<IProps> = (props: IProps) => {
	const [selectedImageIndex, setSelectedImageIndex] = useState<number>(null);

	function isFileData(toBeDetermined: IFileData | string): toBeDetermined is IFileData {
		if ((toBeDetermined as IFileData).internalFilename) {
			return true;
		}
		return false;
	}

	function getSelectedImageUrl(): string {
		const selectedImage: IFileData | string = props.images[selectedImageIndex];
		return isFileData(selectedImage) ? "/api/files/" + selectedImage.internalFilename + "/" + selectedImage.filename : selectedImage;
	}

	return (
		<div className={style.galleryContainer}>
			{props.images && props.images.length > 0 && (
				<div className={style.galleryGrid}>
					{map(props.images, (image: IFileData | string, index: number) => {
						const url: string = isFileData(image) ? "/api/files/" + image.internalFilename + "/" + image.filename : image;
						return (
							<div key={index}>
								{props.deletePhoto && (
									<Button onClick={() => props.deletePhoto(index)}>
										<i className="las la-trash" />
									</Button>
								)}
								<div className={style.tile} style={{ height: props.imageTileSize + "px", width: props.imageTileSize + "px" }} onClick={() => setSelectedImageIndex(index)}>
									<div className={style.thumbnail} style={{ backgroundImage: "url('" + url + "')" }} key={url} />
									<i className="las la-search" style={{ fontSize: Math.round(props.imageTileSize * 0.27) + "px" }} />
								</div>
							</div>
						);
					})}
				</div>
			)}
			{selectedImageIndex !== null && (
				<ImageGalleryModal
					clickClose={() => setSelectedImageIndex(null)}
					clickNext={() => setSelectedImageIndex(selectedImageIndex + 1)}
					clickPrevious={() => setSelectedImageIndex(selectedImageIndex - 1)}
					first={selectedImageIndex === 0}
					last={selectedImageIndex === props.images.length - 1}
					src={getSelectedImageUrl()}
				/>
			)}
		</div>
	);
};

export default ImageGallery;
