import React, { useState } from "react";

import { DatePicker } from "@progress/kendo-react-dateinputs";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { UploadResponse } from "@progress/kendo-react-upload";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { IApplicationState } from "../../../../../../store";
import { getDate, handleChange } from "../../../../../../utils/editorUtils";
import { IDocument, IUser } from "../../../../../../utils/types/models";
import { isNullOrEmpty } from "../../../../../../utils/utils";
import { ApiFileUpload } from "../../../../../global/editor";
import PageEditor from "../pageEditor";

interface IProps {
	record?: IDocument;
	onClose: (record?: IDocument) => void;
	readOnly: boolean;
}

const DocumentEditor: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const initialDocument: IDocument = {
		id: null,
		dossierId: null,
		subject: "",
		file: null,
		lastUploadedBy: "",
		lastUploadedDate: null
	};
	const [document, setDocument] = useState<IDocument>(props.record || initialDocument);
	const [dataChanged, setDataChanged] = useState(false);
	const [uploadError, setUploadError] = useState(false);
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);

	function onChange(event: React.ChangeEvent<HTMLInputElement> | ComboBoxChangeEvent): void {
		setDocument(handleChange(document, event));
		setDataChanged(true);
	}

	function fileUploaded(response: UploadResponse): void {
		const newDocument: IDocument = {
			...document,
			lastUploadedDate: new Date(),
			lastUploadedBy: currentUser.fullName + " (" + currentUser.email + ")",
			file: {
				filename: response.response.filename,
				internalFilename: response.response.internalFilename,
				size: response.response.size,
				type: response.response.type
			}
		};
		if (isNullOrEmpty(newDocument.subject)) {
			newDocument.subject = response.response.filename;
		}
		setDocument(newDocument);
		setDataChanged(true);
		setUploadError(false);
	}

	function onUploadError(): void {
		setUploadError(true);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (!document.subject) {
			messages.push(t("fill_in_required_field", { field: t("type").toLowerCase() }));
		}
		if (!document.file || isNullOrEmpty(document.file.internalFilename)) {
			messages.push(t("fill_in_required_field", { field: t("file").toLowerCase() }));
		}
		if (uploadError) {
			messages.push(t("please_fix_upload"), "error");
		}
		return messages;
	}

	const action: string = props.record ? t("edit") : t("add");
	const extensions: string[] = [
		"image/*",
		"application/pdf",
		"application/msword",
		"application/vnd.ms-excel",
		"application/vnd.ms-powerpoint",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		"application/vnd.openxmlformats-officedocument.presentationml.presentation"];
	return (
		<PageEditor
			width="70%"
			title={`${action} ${t("Document")} ${document.subject}`.trim()}
			close={props.onClose}
			dataChanged={dataChanged}
			getErrorMessages={getErrorMessages}
			action={action}
			record={document}
			readOnly={props.readOnly}
		>
			<div className="k-form">
				{!props.readOnly && (
					<label className="k-form-field">
						<span>{t("file")} * {uploadError && <i className="las la-exclamation-triangle" style={{ color: "red", fontSize: "15px" }} />}</span>
						<ApiFileUpload
							showFileList
							initialFiles={document.file ? [document.file] : []}
							onError={onUploadError}
							fileUploaded={fileUploaded}
							accept={extensions.join(",")}
							multiple={false}
						/>
					</label>
				)}
				<label className="k-form-field">
					<span>{t("subject")} *</span>
					<Input name="subject" className="full-width-field" value={document.subject} onChange={onChange} required disabled={props.readOnly} />
				</label>
				<label className="k-form-field">
					<span>{t("lastUploadedBy")}</span>
					<Input className="full-width-field" value={document.lastUploadedBy} disabled />
				</label>
				<label className="k-form-field">
					<span>{t("lastUploadedDate")}</span>
					<DatePicker
						className="full-width-field"
						value={getDate(document.lastUploadedDate)}
						disabled
						formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
					/>
				</label>
			</div>
		</PageEditor>
	);
};

export default DocumentEditor;