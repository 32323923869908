import React, { } from "react";

import { Button } from "@progress/kendo-react-buttons";
import { IntlService, useInternationalization } from "@progress/kendo-react-intl";
import { Card, CardHeader, CardSubtitle, CardTitle } from "@progress/kendo-react-layout";
import toString from "lodash/toString";
import { useTranslation } from "react-i18next";

import { IDossierTask } from "../../../../utils/types/models";
import { dateToString } from "../../../../utils/utils";

import { History } from "history";
import { useHistory } from "react-router-dom";
import style from "./planningView.module.scss";

interface IProps {
	task: IDossierTask;
	drag: (draggedTask: IDossierTask, event: React.DragEvent<HTMLDivElement>) => void;
	cancelTask?: (task: IDossierTask) => void;
	weekCard: boolean;
	installationWeek?: Date;
}

const DraggableTask: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const intl: IntlService = useInternationalization();
	const history: History = useHistory();

	function drag(draggedTask: IDossierTask, event: React.DragEvent<HTMLDivElement>): void {
		props.drag(draggedTask, event);
	}

	function noAllowDrop(event: React.DragEvent<HTMLDivElement>): void {
		event.stopPropagation();
	}

	function colorCard(): string {
		let returnColor: string = "";
		if (props.weekCard) {
			return "white";
		} else {
			if (props.task.dateIsFixed && (!props.task.startDate || !props.task.assignee1Id)) {
				returnColor = "red";
			} else if (props.task.planned && props.task.assignee1Id) {
				returnColor = "green";
			} else if (!props.task.planned || !props.task.assignee1Id) {
				returnColor = "orange";
			}
		}
		return returnColor;
	}

	function isDraggable(): boolean {
		if ((props.weekCard && !props.task.completed) || (!props.weekCard && !props.task.planned)) {
			return true;
		} else {
			return false;
		}
	}

	function onDoubleClickTask(): void {
		history.push("/work/dossiermanagement/" + props.task.dossierId.toString());
	}

	const mouse: string = isDraggable() ? "grab" : "auto";
	const cardDescription: string = props.task.absent ? t(props.task.absenceReason) : !props.weekCard ? dateToString(props.task.startDate, intl) : props.task.dossier.dossierNumber;

	// if (props.task.absent) {
	// 	cardDescription = ;
	// } else {
	// 	cardDescription = !props.weekCard ? dateToString(props.task.startDate, intl) : props.task.dossier.dossierNumber;
	// }
	
	return (
		<div 
			className={style.card}
			id={toString(props.task.id)} 
			draggable={isDraggable()} 
			onDragStart={(event: React.DragEvent<HTMLDivElement>) => drag(props.task, event)} 
			onDragOver={noAllowDrop} 
			onDoubleClick={onDoubleClickTask}
		>
			<Card key={props.task.id} style={props.weekCard ? { marginTop: "5px", cursor: mouse } : { cursor: mouse }}>
				<CardHeader className={colorCard()}>
					<div>
						<CardTitle style={{ fontSize: "16px" }}>
							<div className="row">
								<div className="col">{cardDescription}</div>
								<div className="col-1" style={{ marginRight: "5px" }}>
									{props.task.completed && (
										<span>
											{" "}
											<i className="las la-check" />{" "}
										</span>
									)}
									{!props.task.completed && props.task.dateIsFixed && (
										<span>
											<i className="las la-thumbtack" />
										</span>
									)}
								</div>
							</div>
							<div className="row">
								<div className="col">{props.task.absent ? "" : props.task.dossier?.customer?.name}</div>
							</div>
						</CardTitle>
						<CardSubtitle style={{ fontSize: "13px" }}>
							<pre className={style.multiline}>{props.task.description}</pre>
							{props.task.startDate && props.task.startDate !== props.task.stopDate && (
								<>
									<pre className={style.time} style={{ marginTop: "5px" }}>{intl.formatDate(new Date(props.task.startDate), "dd-MM-yyyy") + " " + intl.formatDate(new Date(props.task.startTime), "HH:mm")}</pre>
									<pre className={style.time}>{intl.formatDate(new Date(props.task.stopDate), "dd-MM-yyyy") + " " + intl.formatDate(new Date(props.task.stopTime), "HH:mm")}</pre>
								</>
							)}
							{props.task.startDate && props.task.startDate === props.task.stopDate && props.task.startTime === props.task.stopTime && (
								<pre className={style.time}>{intl.formatDate(new Date(props.task.startTime), "HH:mm")}</pre>
							)}
							{props.task.startDate && props.task.startDate === props.task.stopDate && props.task.startTime !== props.task.stopTime && (
								<pre className={style.time}>{intl.formatDate(new Date(props.task.startTime), "HH:mm") + " - " + intl.formatDate(new Date(props.task.stopTime), "HH:mm")}</pre>
							)}
							{!props.weekCard && <p>{`${t("createdOn")}: ${dateToString(props.task.createdDate, intl)} ${t("by")} ${props.task.createdBy}`}</p>}
							{!props.weekCard && props.task.assignee1 && <p>{`${t("lead")}: ${props.task.assignee1.fullName}`}</p>}
							<p>{props.task.completed && `(${t("taskComplete")})`}</p>
						</CardSubtitle>
					</div>
				</CardHeader>
				{props.weekCard && (
					<Button disabled={props.task.completed} onClick={(event: React.MouseEvent<HTMLButtonElement>) => props.cancelTask(props.task)}>
						<span>
							<i className="lar la-calendar-times" />
							{" " + t("cancel")}
						</span>
					</Button>
				)}
			</Card>
		</div>
	);
};

export default DraggableTask;
