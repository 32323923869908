import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { clearUserCache } from "../../services/authentication";
import { IAuthenticationState } from "../../state";
import { checkLoggedInUser } from "../../state/actions/authActions";
import { IApplicationState } from "../../store";

const LoginError: React.FC = () => {
	const authenticationState: IAuthenticationState = useSelector((state: IApplicationState) => state.authenticationState);
	const dispatch: Dispatch = useDispatch();

	function close(): void {
		clearUserCache();
		dispatch(checkLoggedInUser());
	}

	let dialogContent: JSX.Element = <div>{`The following error occured login in: ${authenticationState.loginError.toString()}.`}</div>;
	if (authenticationState.loginError.response && authenticationState.loginError.response.status === 403) {
		dialogContent = (
			<div>
				You don't have an account on this application.<br />
				Please contact <a href="mailto:support@selas.be">support@selas.be</a>.
			</div>
		);
	}

	return (
		<Dialog title="Login Error" onClose={close}>
			<div style={{ margin: "25px", textAlign: "center" }}>
				Dear {authenticationState.userInfo.profile.name}, <br /><br />
				{dialogContent}.
			</div>
			<DialogActionsBar>
				<button className="k-button" onClick={close}>Close</button>
			</DialogActionsBar>
		</Dialog>
	);
};

export default LoginError;