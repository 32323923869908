import React, { useEffect, useReducer, useState } from "react";

import { DataResult, process, State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn, GridDataStateChangeEvent, GridHeaderSelectionChangeEvent, GridRowClickEvent, GridSelectionChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import every from "lodash/every";
import findIndex from "lodash/findIndex";
import map from "lodash/map";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import callApi from "../../../../services/api/callApi";
import Endpoint from "../../../../services/api/endpoint";
import { initialInvoicingState } from "../../../../state";
import { hideLoader, showLoader } from "../../../../state/actions/loaderActions";
import invoicingReducer from "../../../../state/reducers/invoicingReducer";
import { IApplicationState } from "../../../../store";
import { IInvoiceLine, IUser } from "../../../../utils/types/models";
import { checkBoxCell, customCell, translatedCell, weekCell } from "../../../global/gridpanel/customCells/gridCells";
import { IRoutedTabProps } from "../../../global/routertabpanel";

const Invoicing: React.FC<IRoutedTabProps> = () => {
	const { t } = useTranslation();
	const [lastSelectedIndex, setLastSelectedIndex] = useState(0);
	const [invoiceLines, setInvoiceLines] = useState<IInvoiceLine[]>([]);
	const [gridState, setGridState] = useState<State>({
		sort: [
			{ field: "type", dir: "asc" },
			{ field: "dossier.installationWeek", dir: "asc" }
		]
	});
	const [state, dispatch] = useReducer(invoicingReducer, initialInvoicingState);
	const currentUser: IUser = useSelector((applicationState: IApplicationState) => applicationState.authenticationState.currentUser);
	const reduxDispatch: Dispatch = useDispatch();

	useEffect(() => {
		callApi(dispatch, Endpoint.Invoicing, "GET", { companyId: currentUser.currentCompanyId });
	}, [currentUser.currentCompanyId]);

	useEffect(() => {
		if (state.isLoading) {
			reduxDispatch(showLoader());
		} else {
			reduxDispatch(hideLoader());
		}
	}, [state.isLoading, reduxDispatch]);

	useEffect(() => {
		setInvoiceLines(state.entities);
	}, [state.entities]);

	function onHeaderSelectionChange(event: GridHeaderSelectionChangeEvent): void {
		const checked: boolean = (event.syntheticEvent.target as HTMLInputElement).checked;
		const data: IInvoiceLine[] = map(state.entities, (item: IInvoiceLine) => {
			// @ts-ignore
			item.selected = checked;
			return item;
		});
		setInvoiceLines(data);
	}

	function onSelectionChange(event: GridSelectionChangeEvent): void {
		const data: IInvoiceLine[] = map(invoiceLines, (item: IInvoiceLine) => {
			if (item.dossier.id === event.dataItem.dossier.id && item.type === event.dataItem.type) {
				// @ts-ignore
				item.selected = !event.dataItem.selected;
			}
			return item;
		});
		setInvoiceLines(data);
	}

	function onRowClick(event: GridRowClickEvent): void {
		let last: number = lastSelectedIndex;
		const dataResult: DataResult = process([...invoiceLines], gridState);
		let data: IInvoiceLine[] = dataResult.data;
		const current: number = findIndex(data, { dossier: { id: event.dataItem.dossier.id }, type: event.dataItem.type });
		if (!event.nativeEvent.shiftKey) {
			setLastSelectedIndex((last = current));
		}

		if (!event.nativeEvent.ctrlKey) {
			data = map(data, (item: IInvoiceLine) => {
				// @ts-ignore
				item.selected = false;
				return item;
			});
		}
		const select: boolean = !event.dataItem.selected;
		for (let i: number = Math.min(last, current); i <= Math.max(last, current); i++) {
			// @ts-ignore
			data[i].selected = select;
		}
		setInvoiceLines(data);
	}

	function refreshGrid(): void {
		callApi(dispatch, Endpoint.Invoicing, "GET", { companyId: currentUser.currentCompanyId });
	}

	return (
		<Grid
			className="noTopBorder h-100"
			{...gridState}
			total={invoiceLines.length}
			onDataStateChange={(event: GridDataStateChangeEvent) => setGridState(event.dataState)}
			onHeaderSelectionChange={onHeaderSelectionChange}
			onSelectionChange={onSelectionChange}
			onRowClick={onRowClick}
			data={process(invoiceLines, gridState)}
			selectedField="selected"
			sortable
		>
			<GridToolbar>
				<div className="toolbarButtonContainer d-flex width-100 align-items-center">
					<Button primary>{t("invoiceSelected")}</Button>
					<div className="flex-grow-1" />
					<i className="refreshButton las la-sync" onClick={refreshGrid} />
				</div>
			</GridToolbar>
			<GridColumn field="selected" width="50px" headerSelectionValue={every(invoiceLines, { selected: true })} />
			<GridColumn field="dossier.dossierNumber" title={t("dossierNumber")} />
			<GridColumn field="customer.name" title={t("customer")} />
			<GridColumn field="constructionYard.name" title={t("constructionYard")} />
			<GridColumn field="type" title={t("type")} cell={customCell(translatedCell())} />
			<GridColumn field="dossier.measurementDate" title={t("measured")} width="160px" cell={customCell(checkBoxCell(true))} />
			<GridColumn field="dossier.installationWeek" title={t("installationWeek")} cell={customCell(weekCell())} />
			<GridColumn field="amount" title={t("amount")} format="{0:n2}" />
		</Grid>
	);
};

export default Invoicing;
