import React, { useEffect, useReducer, useState } from "react";

import { Button } from "@progress/kendo-react-buttons";
import { DatePicker, DatePickerChangeEvent, TimePicker, TimePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ComboBox, ComboBoxChangeEvent, ComboBoxCloseEvent, ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { IntlService, useInternationalization } from "@progress/kendo-react-intl";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { filterBy } from "@progress/kendo-data-query";
import callApi from "../../../../services/api/callApi";
import Endpoint from "../../../../services/api/endpoint";
import { initalUserState, initialTeamState } from "../../../../state";
import { teamReducer, userReducer } from "../../../../state/reducers";
import { IApplicationState } from "../../../../store";
import { getDate, handleChange } from "../../../../utils/editorUtils";
import { IDossierTask, IUser } from "../../../../utils/types/models";
import { IDictionary } from "../../../../utils/types/types";
import { dateToString } from "../../../../utils/utils";

interface IProps {
	task: IDossierTask;
	assignee: IUser;
	setFirstAssignee: boolean;
	onClose: (record?: IDossierTask) => void;
}

function getInitialTask(task: IDossierTask, assignee: IUser, setFirstAssignee: boolean): IDossierTask {
	const newTask: IDossierTask = {
		...task,
		assignee1: setFirstAssignee ? assignee : task.assignee1,
		assignee1Id: setFirstAssignee ? assignee.id : task.assignee1Id,
		assignee2: !setFirstAssignee ? assignee : task.assignee2,
		assignee2Id: !setFirstAssignee ? assignee.id : task.assignee2Id,
		startTime: task.startTime ? task.startTime : new Date(new Date().getFullYear(), 1, 1, 9, 0),
		stopDate: task.startDate
	};
	if (newTask.assignee1Id === newTask.assignee2Id) {
		newTask.assignee2Id = null;
		newTask.assignee2 = null;
	}
	return newTask;
}

const AssigneePopup: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const [task, setTask] = useState(getInitialTask(props.task, props.assignee, props.setFirstAssignee));
	const [userEntities, setUserEntities] = useState<IDictionary<IUser[]>>({});
	const [currentUserCombobox, setCurrentUserCombobox] = useState<string>();
	const [teamState, teamsDispatch] = useReducer(teamReducer, initialTeamState);

	const [leadUsers, setLeadUsers] = useState([]);
	const [colleagueUsers, setColleagueUsers] = useState([]);

	const [state, dispatch] = useReducer(userReducer, initalUserState);
	const currentUser: IUser = useSelector((applicationState: IApplicationState) => applicationState.authenticationState.currentUser);
	const intl: IntlService = useInternationalization();

	useEffect(() => {
		if (state.entities) {
			userEntities[currentUserCombobox] = [...state.entities];
			setUserEntities(userEntities);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.entities]);

	function onChange(event: ComboBoxCloseEvent | TimePickerChangeEvent | DatePickerChangeEvent): void {
		setTask(handleChange(task, event));
	}

	function onChangeLead(event: ComboBoxChangeEvent): void {
		const newTask: IDossierTask = {...task};
		// @ts-ignore
		if (event.value) {
			// @ts-ignore
			newTask.assignee1 = event.value;
			// @ts-ignore
			newTask.assignee1Id = event.value.id;
		} else {
			newTask.assignee1 = null;
			newTask.assignee1Id = null;
		}
		setTask(newTask);
	}

	function onChangeColleague(event: ComboBoxChangeEvent): void {
		const newTask: IDossierTask = {...task};
		// @ts-ignore
		if (event.value) {
			// @ts-ignore
			newTask.assignee2 = event.value;
			// @ts-ignore
			newTask.assignee2Id = event.value.id;
		} else {
			newTask.assignee2 = null;
			newTask.assignee2Id = null;
		}
		setTask(newTask);
	}

	function onTeamFilterChange(event: ComboBoxFilterChangeEvent): void {
		if (event.target.name === "assignee1Id") {
			setLeadUsers(filterBy(userEntities[event.target.name], event.filter));
		} else if (event.target.name === "assignee2Id") {
			setColleagueUsers(filterBy(userEntities[event.target.name], event.filter));
		}
	}

	useEffect(() => {
		callApi(dispatch, Endpoint.UserLeadInstallers, "GET", { companyId: currentUser.currentCompanyId });
		callApi(dispatch, Endpoint.UserColleagueInstallers, "GET", { companyId: currentUser.currentCompanyId });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if ( state.leadsEntities) {
			userEntities["assignee1Id"] = [...state.leadsEntities];
			setLeadUsers([...state.leadsEntities]);
			setUserEntities(userEntities);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.leadsEntities]);

	useEffect(() => {
		if ( state.colleaguesEntities) {
			userEntities["assignee2Id"] = [...state.colleaguesEntities];
			setColleagueUsers([...state.colleaguesEntities]);
			setUserEntities(userEntities);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.colleaguesEntities]);

	function onBlur(): void {
		setCurrentUserCombobox(null);
	}

	function save(): void {
		props.onClose(task);
	}

	useEffect(() => {
		if (teamState.colleague) {
			task.assignee2 = teamState.colleague;
			task.assignee2Id = teamState.colleague.id;
			setTask(task);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [teamState.colleague]);

	useEffect(() => {
		if (task.stopDate && !task.stopTime) {
			const newTask: IDossierTask = {...task};
			newTask.stopTime = new Date(new Date().getFullYear(), 1, 1, 17, 0);
			setTask(newTask);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [task.stopDate]);

	useEffect(() => {
		if (task.assignee1) {
			callApi(teamsDispatch, Endpoint.TeamsGetColleague, "GET", { id: task.assignee1.id, companyId: currentUser.currentCompanyId });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [task.assignee1]);

	return (
		<Dialog title={t("assign")} width="400px" closeIcon={false}>
			<div className="k-form">
				<div className="k-form-field">
					<span>{`${t("startDate")}: ${dateToString(task.startDate, intl)}`}</span>
				</div>
				<div className="k-form-field">
					<span>{t("startTime")} *</span>
					<TimePicker
						name="startTime"
						value={getDate(task.startTime)}
						onChange={onChange}
						required
					/>
				</div>
				<div className="k-form-field">
					<span>{t("stopDate")} *</span>
					<DatePicker
						name="stopDate"
						className="full-width-field"
						value={getDate(task.stopDate)}
						onChange={onChange}
						formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
						required
					/>
				</div>
				<div className="k-form-field">
					<span>{t("stopTime")} *</span>
					<TimePicker
						name="stopTime"
						className="full-width-field"
						value={getDate(task.stopTime)}
						onChange={onChange}
						required
					/>
				</div>
				<div className="k-form-field">
					<span>{t("lead")} *</span>
					<ComboBox
						name="assignee1Id"
						data={leadUsers}
						value={task.assignee1}
						textField="fullName"
						onFilterChange={onTeamFilterChange}
						onChange={onChangeLead}
						suggest
						filterable
						allowCustom={false}
						onBlur={onBlur}
						style={ {width: "100%"}}
						required
					/>
				</div>
				<div className="k-form-field">
					<span>{t("colleague")}</span>
					<ComboBox
						name="assignee2Id"
						data={colleagueUsers}
						style={ {width: "100%"}}
						value={task.assignee2}
						textField="fullName"
						onFilterChange={onTeamFilterChange}
						onBlur={onBlur}
						onChange={onChangeColleague}
						required={task.absent && !task.assignee1Id}
						suggest
						filterable
						allowCustom={false}
					/>
				</div>
			</div>
			<DialogActionsBar>
				<Button primary onClick={() => props.onClose()}>
					{t("cancel")}
				</Button>
				<Button onClick={save}>{t("save")}</Button>
			</DialogActionsBar>
		</Dialog>
	);
};

export default AssigneePopup;
