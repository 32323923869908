import React from "react";

import { Trans } from "react-i18next";

import { IFileData } from "../../../utils/types/models";

import style from "./fileviewer.module.scss";

interface IProps {
	file: IFileData;
}

const FileViewer: React.FC<IProps> = (props: IProps) => {
	const url: string = "/api/files/" + props.file.internalFilename + "/" + encodeURIComponent(props.file.filename);
	if (props.file.type.startsWith("image/")) {
		return <img className={style.image} src={url} alt={props.file.filename} />;
	} else if (props.file.type === "application/pdf") {
		return <iframe className={"h-100 w-100 " + style.pdf} src={url} title="pdf" />;
	}
	return (
		<div className={style.unknownType}>
			<Trans i18nKey="unable_to_display">
				Unable to display.
				<br />
				Download <a href={url + "?download=true"}>here</a>.
			</Trans>
		</div>
	);
};

export default FileViewer;
