import React, { useEffect, useReducer, useState } from "react";

import { DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import each from "lodash/each";
import includes from "lodash/includes";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import callApi from "../../../../../../services/api/callApi";
import Endpoint from "../../../../../../services/api/endpoint";
import { getInitialState } from "../../../../../../state";
import { derender, render } from "../../../../../../state/actions/renderActions";
import { communicationTemplateReducer } from "../../../../../../state/reducers";
import { IApplicationState } from "../../../../../../store";
import { getDate, getEntity, handleChange, setEntity } from "../../../../../../utils/editorUtils";
import { CommunicationStatus, CommunicationType, Permission } from "../../../../../../utils/enums";
import { ICommunication, ICommunicationTemplate, IContact, IDossier, IUser } from "../../../../../../utils/types/models";
import { emailRegex, isNullOrEmpty, newKey } from "../../../../../../utils/utils";
import { EnumDropDownList, ManageableField, TextArea } from "../../../../../global/editor";
import CommunicationTemplateEditor from "../../../../communication/communicationtemplates/editor";
import ContactEditor from "../contacts/editor";
import PageEditor from "../pageEditor";

import style from "../../../../../global/editor/manageableField.module.scss";

interface IProps {
	record?: ICommunication;
	dossier: IDossier;
	onClose: (record?: ICommunication) => void;
	readOnly: boolean;
	addContact: (record: IContact) => void;
}

const CommunicationEditor: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const initialCommunication: ICommunication = {
		id: null,
		dossierId: null,
		type: null,
		templateId: null,
		template: null,
		to: "",
		subject: "",
		body: "",
		status: CommunicationStatus.New
	};
	const [communication, setCommunication] = useState<ICommunication>(props.record || initialCommunication);
	const [dataChanged, setDataChanged] = useState(false);
	const [state, dispatch] = useReducer(communicationTemplateReducer, getInitialState<ICommunicationTemplate>());
	const currentUser: IUser = useSelector((applicationState: IApplicationState) => applicationState.authenticationState.currentUser);
	const reduxDispatch: Dispatch = useDispatch();

	useEffect(() => {
		callApi(dispatch, Endpoint.CommunicationTemplates, "GET", { companyId: currentUser.currentCompanyId });
	}, [currentUser.currentCompanyId]);

	useEffect(() => {
		if (props.record) {
			setCommunication(props.record);
		}
	}, [props.record]);

	function onChange(event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> | DropDownListChangeEvent | DatePickerChangeEvent): void {
		const newCommunication: ICommunication = { ...communication };
		if (event.target.name === "templateId") {
			fillInTemplateValues(newCommunication, event.target.value);
		} else if (event.target.name === "reminderDate") {
			if ((event as DatePickerChangeEvent).value && newCommunication.status === CommunicationStatus.Sent) {
				newCommunication.status = CommunicationStatus.AwaitingReminder;
			} else if (!(event as DatePickerChangeEvent).value && newCommunication.status === CommunicationStatus.AwaitingReminder) {
				newCommunication.status = CommunicationStatus.Sent;
			}
		}

		setCommunication(handleChange(newCommunication, event));
		setDataChanged(true);
	}

	function setCommunicationTemplate(communicationTemplate: ICommunicationTemplate): void {
		const newCommunication: ICommunication = { ...communication };
		callApi(dispatch, Endpoint.CommunicationTemplates, "GET", { companyId: currentUser.currentCompanyId });
		fillInTemplateValues(newCommunication, communicationTemplate);
		setCommunication(setEntity(newCommunication, communicationTemplate, "template", "templateId"));
		setDataChanged(true);
	}

	function fillInTemplateValues(newCommunication: ICommunication, communicationTemplate: ICommunicationTemplate): void {
		newCommunication.subject = communicationTemplate.subject;
		newCommunication.body = communicationTemplate.body.replace(/#naam#/gi, currentUser.fullName);
		newCommunication.reminderBody = communicationTemplate.reminderBody;
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (!communication.type) {
			messages.push(t("fill_in_required_field", { field: t("type").toLowerCase() }));
		}
		if (!communication.templateId) {
			messages.push(t("fill_in_required_field", { field: t("template").toLowerCase() }));
		}
		if (isNullOrEmpty(communication.to) || !communication.to.match(emailRegex)) {
			messages.push(t("fill_in_correct_format", { field: t("to").toLowerCase(), example: "user@test.com" }));
		}
		if (isNullOrEmpty(communication.subject)) {
			messages.push(t("fill_in_required_field", { field: t("subject").toLowerCase() }));
		}
		if (isNullOrEmpty(communication.body)) {
			messages.push(t("fill_in_required_field", { field: t("body").toLowerCase() }));
		}
		if (messages.length <= 0) {
			communication.createdDate = new Date();
		}

		return messages;
	}

	function addRecord(): void {
		const key: string = newKey("add");
		reduxDispatch(render(key, ContactEditor, { record: null, readOnly: props.readOnly, onClose: (c?: IContact) => hideEditor(key, c) }));
	}

	function setContact(contact: IContact): void {
		props.addContact(contact);

		const newCommunication: ICommunication = { ...communication };
		newCommunication.to = contact.email;
		setCommunication(newCommunication);
	}

	function hideEditor(key: string, record?: IContact): void {
		if (record) {
			setContact(record);
		}
		reduxDispatch(derender(key));
	}

	const mailAddresses: string[] = [];
	function addMailAddress(mailAddress: string): void {
		if (mailAddress && !includes(mailAddresses, mailAddress)) {
			mailAddresses.push(mailAddress);
		}
	}

	const action: string = props.record ? t("edit") : t("add");

	addMailAddress(props.dossier.contractor?.email);
	addMailAddress(props.dossier.customer?.email1);
	addMailAddress(props.dossier.customer?.email2);
	addMailAddress(props.dossier.constructionYard?.email);
	each(props.dossier.contacts, (contact: IContact) => {
		addMailAddress(contact.email);
	});

	return (
		<PageEditor
			width="70%"
			title={`${action} ${t("communication")}`}
			close={props.onClose}
			dataChanged={dataChanged}
			getErrorMessages={getErrorMessages}
			action={action}
			record={communication}
			readOnly={props.readOnly}
		>
			<div className="k-form">
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>{t("type")} *</span>
							<EnumDropDownList name="type" className="full-width-field" enum={CommunicationType} value={communication.type} onChange={onChange} required disabled={props.readOnly} />
						</label>
					</div>
					<div className="col">
						<ManageableField
							addScreen={CommunicationTemplateEditor}
							editScreen={CommunicationTemplateEditor}
							fieldLabel={t("template") + " *"}
							recordId={communication.templateId}
							addPermission={Permission.CommunicationTemplatesAdd}
							editPermission={Permission.CommunicationTemplatesUpdate}
							setEntity={(record: ICommunicationTemplate) => setCommunicationTemplate(record)}
							readOnly={props.readOnly}
						>
							<DropDownList
								name="templateId"
								className="full-width-field"
								data={state.entities}
								loading={state.areEntitiesLoading}
								value={getEntity(state.entities, communication.templateId, communication.template)}
								dataItemKey="id"
								textField="name"
								onChange={onChange}
								required
								disabled={props.readOnly}
							/>
						</ManageableField>
					</div>
				</div>
				<div className="row" style={{ marginTop: "5px" }}>
					<div className="col">
						<span className={style.label}>
							{!props.readOnly && <i className="las la-plus" onClick={addRecord} />}
							{t("to")} *
						</span>
						<DropDownList name="to" className="full-width-field" data={mailAddresses} value={communication.to} onChange={onChange} required disabled={props.readOnly} />
					</div>
				</div>
				<label className="k-form-field">
					<span>{t("subject")}</span>
					<Input name="subject" className="full-width-field" value={communication.subject} required onChange={onChange} disabled={props.readOnly} />
				</label>
				<label className="k-form-field">
					<span>{t("body")}</span>
					<TextArea name="body" className="full-width-field" value={communication.body} required onChange={onChange} disabled={props.readOnly} />
				</label>
				<label className="k-form-field">
					<span>{t("reminderBody")}</span>
					<TextArea name="reminderBody" className="full-width-field" value={communication.reminderBody} onChange={onChange} disabled={props.readOnly} />
				</label>
				<label className="k-form-field">
					<span>{t("reminderDate")}</span>
					<DatePicker
						name="reminderDate"
						className="full-width-field"
						value={getDate(communication.reminderDate)}
						formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
						onChange={onChange}
						disabled={props.readOnly}
					/>
				</label>
				<label className="k-form-field">
					<span>{t("status")}</span>
					<EnumDropDownList name="status" className="full-width-field hiddenTrigger" enum={CommunicationStatus} value={communication.status} onChange={onChange} disabled />
				</label>
			</div>
		</PageEditor>
	);
};

export default CommunicationEditor;
