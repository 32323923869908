import React, { Dispatch, useEffect, useReducer, useState } from "react";

import { Button } from "@progress/kendo-react-buttons";
import { DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBoxCloseEvent, ComboBoxFilterChangeEvent, ComboBoxFocusEvent, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input, NumericTextBoxChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch as ReduxDispatch } from "redux";

import callApi from "../../../../../services/api/callApi";
import Endpoint from "../../../../../services/api/endpoint";
import { getInitialState, initalUserState, initialConstructionYardState } from "../../../../../state";
import { hideLoader, showLoader } from "../../../../../state/actions/loaderActions";
import { constructionYardReducer, contractorReducer, customerReducer, projectReducer, shopReducer, userReducer } from "../../../../../state/reducers";
import { IApplicationState } from "../../../../../store";
import { getDate, getEntity, handleChange, setEntity } from "../../../../../utils/editorUtils";
import { Permission, VatType } from "../../../../../utils/enums";
import { IConstructionYard, IContractor, ICustomer, IProject, IShop, IUser } from "../../../../../utils/types/models";
import { IAction, IDictionary } from "../../../../../utils/types/types";
import { isNullOrEmpty } from "../../../../../utils/utils";
import { EnumDropDownList, ManageableField, NumericInput, SearchBox, YesNoSwitch } from "../../../../global/editor";
import ConstructionYardEditor from "../../../masterdata/constructionyards/editor";
import ContractorEditor from "../../../masterdata/contractors/editor";
import CustomerEditor from "../../../masterdata/customers/editor";
import ProjectEditor from "../../../masterdata/projects/editor";
import ShopEditor from "../../../masterdata/shops/editor";
import { IDossierSectionProps } from "../editor";

import style from "./general.module.scss";

const General: React.FC<IDossierSectionProps> = (props: IDossierSectionProps) => {
	const { t } = useTranslation();
	const { onChange: propsOnchange } = props;
	const [customerState, customerDispatch] = useReducer(customerReducer, getInitialState<ICustomer>());
	const [contractorState, contractorDispatch] = useReducer(contractorReducer, getInitialState<IContractor>());
	const [constructionYardState, constructionYardDispatch] = useReducer(constructionYardReducer, initialConstructionYardState);
	const [projectState, projectDispatch] = useReducer(projectReducer, getInitialState<IProject>());
	const [shopState, shopDispatch] = useReducer(shopReducer, getInitialState<IShop>());
	const [userState, userDispatch] = useReducer(userReducer, initalUserState);
	const [userEntities, setUserEntities] = useState<IDictionary<IUser[]>>({});
	const [currentUserCombobox, setCurrentUserCombobox] = useState<string>();
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);
	const reduxDispatch: ReduxDispatch = useDispatch();

	const currentContractor: IContractor = getEntity(contractorState.entities, props.dossier.contractorId, props.dossier.contractor);
	const currentCustomer: ICustomer = getEntity(customerState.entities, props.dossier.customerId, props.dossier.customer);
	const currentConstructionYard: IConstructionYard = getEntity(constructionYardState.entities, props.dossier.constructionYardId, props.dossier.constructionYard);

	useEffect(() => {
		if (userState.entities) {
			setUserEntities((prevUserEntities: IDictionary<IUser[]>) => {
				return {
					...prevUserEntities,
					[currentUserCombobox]: [...userState.entities]
				};
			});
		}
	}, [currentUserCombobox, userState.entities]);

	useEffect(() => {
		if (constructionYardState.addedEntity) {
			propsOnchange(setEntity(props.dossier, constructionYardState.addedEntity, "constructionYard", "constructionYardId"));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [constructionYardState.addedEntity]);

	useEffect(() => {
		if (constructionYardState.copiedEntity) {
			propsOnchange(setEntity(props.dossier, constructionYardState.copiedEntity, "constructionYard", "constructionYardId"));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [constructionYardState.copiedEntity]);

	useEffect(() => {
		if (constructionYardState.isAdding || constructionYardState.isCopying) {
			reduxDispatch(showLoader());
		} else if (!(constructionYardState.isAdding || constructionYardState.isCopying)) {
			reduxDispatch(hideLoader());
		}
	}, [constructionYardState.isAdding, constructionYardState.isCopying, reduxDispatch]);

	function onChange(event: React.ChangeEvent<HTMLInputElement> | ComboBoxCloseEvent | DatePickerChangeEvent | DropDownListChangeEvent | SwitchChangeEvent | NumericTextBoxChangeEvent): void {
		if (props.dossier.id <= 0 && event.target.name === "vatType" && !props.dossier.depositVat) {
			props.dossier.depositVat = event.target.value.key;
		}
		propsOnchange(handleChange(props.dossier, event));
	}

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		let endpoint: Endpoint = null;
		let dispatch: Dispatch<IAction>;
		if (event.target.name === "customerId") {
			dispatch = customerDispatch;
			endpoint = Endpoint.Customers;
		} else if (event.target.name === "contractorId") {
			dispatch = contractorDispatch;
			endpoint = Endpoint.Contractors;
		} else if (event.target.name === "constructionYardId") {
			dispatch = constructionYardDispatch;
			endpoint = Endpoint.ConstructionYards;
		} else if (event.target.name === "dossierManagerId" || event.target.name === "salesRepId" || event.target.name === "measurementDoneById" || event.target.name === "writeOutDoneById") {
			dispatch = userDispatch;
			endpoint = Endpoint.Users;
		} else if (event.target.name === "projectId") {
			dispatch = projectDispatch;
			endpoint = Endpoint.Projects;
		} else if (event.target.name === "shopId") {
			dispatch = shopDispatch;
			endpoint = Endpoint.Shops;
		}

		if (endpoint) {
			callApi(dispatch, endpoint, "GET", { search: event.filter.value, companyId: currentUser.currentCompanyId });
		}
	}

	function copyCustomer(): void {
		callApi(constructionYardDispatch, Endpoint.ConstructionYardsCopyFromCustomer, "GET", { customerId: currentCustomer.id });
	}

	function onFocus(event: ComboBoxFocusEvent): void {
		setCurrentUserCombobox(event.target.name);
	}

	function onBlur(): void {
		setCurrentUserCombobox(null);
	}

	return (
		<React.Fragment>
			<div className="k-form">
				<div className="row">
					<div className="col">
						<h2 className={style.accentTitle}>{t("general")}</h2>
					</div>
					<div className="col" style={{ textAlign: "right" }}>
						<h3>
							{props.dossier?.dossierNumber} - {props.dossier?.customer?.name}
						</h3>
					</div>
				</div>
				<div>
					<div className="row">
						<div className="col">
							<label className="k-form-field">
								<span>{t("description")}</span>
								<Input name="description" value={props.dossier.description} onChange={onChange} className="full-width-field" disabled={props.readOnly} />
							</label>
						</div>
					</div>
					<div className="row">
						<div className="col-2">
							<label className="k-form-field">
								<span>{t("dossierNumber")} *</span>
								<Input
									name="dossierNumber"
									value={props.dossier.dossierNumber}
									onChange={onChange}
									required
									className={"full-width-field " + style.dossierNumber}
									disabled={props.readOnly}
									pattern="[0-9a-zA-Z_]+"
								/>
							</label>
						</div>
						<div className="col-2">
							<label className="k-form-field">
								<span>{t("binNumber")}</span>
								<Input name="binNumber" value={props.dossier.binNumber} onChange={onChange} className="full-width-field" disabled={props.readOnly} />
							</label>
						</div>
						<div className="col">
							<label className="k-form-field">
								<span>{t("soldOn")} *</span>
								<DatePicker
									name="sellingDate"
									value={getDate(props.dossier.sellingDate)}
									onChange={onChange}
									required
									className="full-width-field"
									formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
									disabled={props.readOnly}
								/>
							</label>
						</div>
						<div className="col">
							<label className="k-form-field">
								<span>{t("createdOn")} *</span>
								<DatePicker
									name="creationDate"
									value={getDate(props.dossier.creationDate)}
									onChange={onChange}
									required
									className="full-width-field"
									formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
									disabled={props.readOnly}
								/>
							</label>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<ManageableField
								fieldLabel={t("contractor")}
								recordId={props.dossier.contractorId}
								addScreen={ContractorEditor}
								editScreen={ContractorEditor}
								addPermission={Permission.ContractorsAdd}
								editPermission={Permission.ContractorsUpdate}
								setEntity={(record: IContractor) => props.onChange(setEntity(props.dossier, record, "contractor", "contractorId"))}
								readOnly={props.readOnly}
							>
								<SearchBox
									name="contractorId"
									entities={contractorState.entities}
									entityId={props.dossier.contractorId}
									entity={props.dossier.contractor}
									textField="name"
									onFilterChange={onFilterChange}
									onClose={onChange}
									onClear={() => props.onChange(setEntity(props.dossier, null, "contractor", "contractorId"))}
									disabled={props.readOnly}
								/>
							</ManageableField>
						</div>
						<div className="col">
							<div className="row">
								<div className="col">
									<ManageableField
										fieldLabel={t("customer") + " *"}
										recordId={props.dossier.customerId}
										addScreen={CustomerEditor}
										editScreen={CustomerEditor}
										addPermission={Permission.CustomersAdd}
										editPermission={Permission.CustomersUpdate}
										setEntity={(record: ICustomer) => props.onChange(setEntity(props.dossier, record, "customer", "customerId"))}
										readOnly={props.readOnly}
									>
										<SearchBox
											name="customerId"
											entities={customerState.entities}
											entityId={props.dossier.customerId}
											entity={props.dossier.customer}
											required
											textField="name"
											onFilterChange={onFilterChange}
											onClose={onChange}
											onClear={() => props.onChange(setEntity(props.dossier, null, "customer", "customerId"))}
											disabled={props.readOnly}
										/>
									</ManageableField>
								</div>
								{!props.readOnly && (
									<div className="col-3">
										<label className="k-form-field">
											<span>&nbsp;</span>
											<Button primary onClick={copyCustomer} style={{ width: "100%" }}>
												{t("copy")}&nbsp;
												<i className="las la-arrow-right" />
											</Button>
										</label>
									</div>
								)}
							</div>
						</div>
						<div className="col">
							<ManageableField
								fieldLabel={t("constructionYard") + " *"}
								addScreen={ConstructionYardEditor}
								editScreen={ConstructionYardEditor}
								recordId={props.dossier.constructionYardId}
								addPermission={Permission.ConstructionYardsAdd}
								editPermission={Permission.ConstructionYardsUpdate}
								setEntity={(record: IConstructionYard) => props.onChange(setEntity(props.dossier, record, "constructionYard", "constructionYardId"))}
								readOnly={props.readOnly}
							>
								<SearchBox
									name="constructionYardId"
									entities={constructionYardState.entities}
									entityId={props.dossier.constructionYardId}
									entity={props.dossier.constructionYard}
									required
									textField="name"
									onFilterChange={onFilterChange}
									onClose={onChange}
									onClear={() => props.onChange(setEntity(props.dossier, null, "constructionYard", "constructionYardId"))}
									disabled={props.readOnly}
								/>
							</ManageableField>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<ManageableField
								fieldLabel={t("project")}
								addScreen={ProjectEditor}
								editScreen={ProjectEditor}
								recordId={props.dossier.projectId}
								addPermission={Permission.ProjectsAdd}
								editPermission={Permission.ProjectsUpdate}
								setEntity={(record: IProject) => props.onChange(setEntity(props.dossier, record, "project", "projectId"))}
								readOnly={props.readOnly}
							>
								<SearchBox
									name="projectId"
									entities={projectState.entities}
									entityId={props.dossier.projectId}
									entity={props.dossier.project}
									textField="name"
									onFilterChange={onFilterChange}
									onClose={onChange}
									onClear={() => props.onChange(setEntity(props.dossier, null, "project", "projectId"))}
									disabled={props.readOnly}
								/>
							</ManageableField>
						</div>
						<div className="col">
							<label className="k-form-field">
								<span>{t("unit")}</span>
								<Input name="unit" value={props.dossier.unit} onChange={onChange} className="full-width-field" disabled={props.readOnly} />
							</label>
						</div>
						<div className="col">
							<label className="k-form-field">
								<span>{t("vat")}% *</span>
								<EnumDropDownList
									name="vatType"
									translationPrefix="fullDescription"
									className="full-width-field"
									enum={VatType}
									value={props.dossier.vatType}
									onChange={onChange}
									required
									disabled={props.readOnly}
								/>
							</label>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<label className="k-form-field">
								<span>{t("dossierManager")} *</span>
								<SearchBox
									name="dossierManagerId"
									entities={userEntities["dossierManagerId"]}
									entityId={props.dossier.dossierManagerId}
									entity={props.dossier.dossierManager}
									textField="fullName"
									onFilterChange={onFilterChange}
									onClose={onChange}
									onFocus={onFocus}
									onBlur={onBlur}
									onClear={() => props.onChange(setEntity(props.dossier, null, "dossierManager", "dossierManagerId"))}
									required
									disabled={props.readOnly}
								/>
							</label>
						</div>
						<div className="col">
							<label className="k-form-field">
								<span>{t("salesRep")} *</span>
								<SearchBox
									name="salesRepId"
									entities={userEntities["salesRepId"]}
									entityId={props.dossier.salesRepId}
									entity={props.dossier.salesRep}
									textField="fullName"
									onFilterChange={onFilterChange}
									onClose={onChange}
									onFocus={onFocus}
									onBlur={onBlur}
									onClear={() => props.onChange(setEntity(props.dossier, null, "salesRep", "salesRepId"))}
									required
									disabled={props.readOnly}
								/>
							</label>
						</div>
						<div className="col">
							<label className="k-form-field">
								<span>{t("checkInAtWork")}</span>
								<Input name="checkInAtWork" value={props.dossier.checkInAtWork} onChange={onChange} className="full-width-field" disabled={props.readOnly} />
							</label>
						</div>
						<div className="col">
							<ManageableField
								fieldLabel={t("shop")}
								addScreen={ShopEditor}
								editScreen={ShopEditor}
								recordId={props.dossier.shopId}
								addPermission={Permission.ShopsAdd}
								editPermission={Permission.ShopsUpdate}
								setEntity={(record: IShop) => {
									props.onChange(setEntity(props.dossier, record, "shop", "shopId"));
									callApi(shopDispatch, Endpoint.Shops, "GET");
								}}
								readOnly={props.readOnly}
							>
								<SearchBox
									name="shopId"
									entities={shopState.entities}
									entityId={props.dossier.shopId}
									entity={props.dossier.shop}
									textField="name"
									onFilterChange={onFilterChange}
									onClose={onChange}
									onClear={() => props.onChange(setEntity(props.dossier, null, "shop", "shopId"))}
									disabled={props.readOnly}
								/>
							</ManageableField>
						</div>
					</div>
				</div>
				<fieldset>
					<legend className={style.accentTitle}>{t("extraInformation")}</legend>
					<div className="row">
						<div className="col">
							<label className="k-form-field">
								<span>{t("material")}</span>
								<Input name="material" value={props.dossier.material} onChange={onChange} className="full-width-field" disabled={props.readOnly} />
							</label>
						</div>
						<div className="col">
							<label className="k-form-field">
								<span>{t("Worktop")}</span>
								<Input name="worktop" value={props.dossier.worktop} onChange={onChange} className="full-width-field" disabled={props.readOnly} />
							</label>
						</div>
						<div className="col">
							<label className="k-form-field">
								<span>{t("cupboards")}</span>
								<NumericInput
									name="cupboards"
									value={props.dossier.cupboards}
									onChange={onChange}
									className="full-width-field"
									format={{ maximumFractionDigits: 0 }}
									disabled={props.readOnly}
								/>
							</label>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<label className="k-form-field">
								<span>{t("parking")}</span>
								<Input name="parking" placeholder={t("toBeAsked")} value={props.dossier.parking} onChange={onChange} className="full-width-field" disabled={props.readOnly} />
							</label>
						</div>
						<div className="col">
							<label className="k-form-field">
								<span>{t("floor")}</span>
								<Input name="floor" placeholder={t("toBeAsked")} value={props.dossier.floor} onChange={onChange} className="full-width-field" disabled={props.readOnly} />
							</label>
						</div>
						<div className="col">
							<label className="k-form-field">
								<span>{t("isLiftNeeded")}</span>
								<Input name="isLiftNeeded" placeholder={t("toBeAsked")} value={props.dossier.isLiftNeeded} onChange={onChange} className="full-width-field" disabled={props.readOnly} />
							</label>
						</div>
						<div className="col">
							<div className={"k-form-field " + style.editorSwitches}>
								{/* <span>{t("hasSimpleAccess")}</span>
								<Input
									name="hasSimpleAccess"
									placeholder={t("toBeAsked")}
									value={props.dossier.hasSimpleAccess}
									onChange={onChange}
									className="full-width-field"
									disabled={props.readOnly}
								/> */}
								<span>{t("fixedDeliveryDate")}</span>
								<YesNoSwitch name="fixedDeliveryDate" className={style.switch} onChange={onChange} checked={props.dossier.fixedDeliveryDate} disabled={props.readOnly} />
							</div>
						</div>
					</div>
				</fieldset>
				<div className="row">
					<div className="col">
						<fieldset>
							<legend className={style.accentTitle}>{t("contractor")}</legend>
							{currentContractor && (
								<>
									{currentContractor.name}
									<br />
									{currentContractor.vatNumber}
									{!isNullOrEmpty(currentContractor.vatNumber) && <br />}
									{`${currentContractor.address.street} ${currentContractor.address.number}`}
									<br />
									{`${currentContractor.address.postalCode} ${currentContractor.address.municipality}`}
									<br />
									{currentContractor.address.province}
									<br />
									{currentContractor.contactName + ": "}
									{currentContractor.phone}
									{!isNullOrEmpty(currentContractor.phone) && !isNullOrEmpty(currentContractor.email) && " - "}
									{!isNullOrEmpty(currentContractor.email) && <a href={"mailto:" + currentContractor.email}>{currentContractor.email}</a>}
									<br />
									<br />
								</>
							)}
						</fieldset>
					</div>
					<div className="col">
						<fieldset>
							<legend className={style.accentTitle}>{t("customer")}</legend>
							{currentCustomer && (
								<>
									{currentCustomer.name}
									<br />
									{currentCustomer.vatNumber}
									{!isNullOrEmpty(currentCustomer.vatNumber) && <br />}
									{`${currentCustomer.address.street} ${currentCustomer.address.number}`}
									<br />
									{`${currentCustomer.address.postalCode} ${currentCustomer.address.municipality}`}
									<br />
									{currentCustomer.address.province}
									<br />
									{currentCustomer.dossierContact + ": "}
									{currentCustomer.phone1}
									{!isNullOrEmpty(currentCustomer.phone1) && !isNullOrEmpty(currentCustomer.email1) && " - "}
									{!isNullOrEmpty(currentCustomer.email1) && <a href={"mailto:" + currentCustomer.email1}>{currentCustomer.email1}</a>}
									<br />
									<br />
								</>
							)}
						</fieldset>
					</div>
					<div className="col">
						<fieldset>
							<legend className={style.accentTitle}>{t("constructionYard")}</legend>
							{currentConstructionYard && (
								<>
									{currentConstructionYard.name}
									<br />
									{`${currentConstructionYard.address.street} ${currentConstructionYard.address.number}`}
									<br />
									{`${currentConstructionYard.address.postalCode} ${currentConstructionYard.address.municipality}`}
									<br />
									{currentConstructionYard.address.province}
									<br />
									{currentConstructionYard.constructionYardContact + ": "}
									{currentConstructionYard.phone}
									{!isNullOrEmpty(currentConstructionYard.phone) && !isNullOrEmpty(currentConstructionYard.email) && " - "}
									{!isNullOrEmpty(currentConstructionYard.email) && <a href={"mailto:" + currentConstructionYard.email}>{currentConstructionYard.email}</a>}
									<br />
									<br />
								</>
							)}
						</fieldset>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<fieldset>
							<legend className={style.accentTitle}>{t("measurement")}</legend>
						</fieldset>
					</div>
					<div className="col">
						<fieldset>
							<legend className={style.accentTitle}>{t("writeOut")}</legend>
						</fieldset>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>{t("date")}</span>
							<DatePicker
								name="measurementDate"
								value={getDate(props.dossier.measurementDate)}
								onChange={onChange}
								className="full-width-field"
								formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
								disabled={props.readOnly}
							/>
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("executedBy")}</span>
							<SearchBox
								name="measurementDoneById"
								entities={userEntities["measurementDoneById"]}
								entityId={props.dossier.measurementDoneById}
								entity={props.dossier.measurementDoneBy}
								textField="fullName"
								onFilterChange={onFilterChange}
								onClose={onChange}
								onFocus={onFocus}
								onBlur={onBlur}
								onClear={() => props.onChange(setEntity(props.dossier, null, "measurementDoneBy", "measurementDoneById"))}
								disabled={props.readOnly}
							/>
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("date")}</span>
							<DatePicker
								name="writeOutDate"
								value={getDate(props.dossier.writeOutDate)}
								onChange={onChange}
								className="full-width-field"
								formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
								disabled={props.readOnly}
							/>
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("executedBy")}</span>
							<SearchBox
								name="writeOutDoneById"
								entities={userEntities["writeOutDoneById"]}
								entityId={props.dossier.writeOutDoneById}
								entity={props.dossier.writeOutDoneBy}
								textField="fullName"
								onFilterChange={onFilterChange}
								onClose={onChange}
								onFocus={onFocus}
								onBlur={onBlur}
								onClear={() => props.onChange(setEntity(props.dossier, null, "writeOutDoneBy", "writeOutDoneById"))}
								disabled={props.readOnly}
							/>
						</label>
					</div>
				</div>
			</div>
			<div className={style.editorSwitches}>
				<div className="k-form-field">
					<span>{t("complete")}</span>
					<YesNoSwitch name="complete" className={style.switch} onChange={onChange} checked={props.dossier.complete} disabled={props.readOnly} />
					<span>{t("readyForPicking")}</span>
					<YesNoSwitch name="readyForPicking" className={style.switch} onChange={onChange} checked={props.dossier.readyForPicking} disabled={props.readOnly} />
					<span>{t("active")}</span>
					<YesNoSwitch name="active" className={style.switch} onChange={onChange} checked={props.dossier.active} disabled={props.readOnly} />
				</div>
			</div>
		</React.Fragment>
	);
};

export default General;
