import React, { useEffect, useRef, useState } from "react";

import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import { useTranslation } from "react-i18next";

import { notify } from "../../../../services/notification";
import { dossierNumberRegex, isNullOrEmpty } from "../../../../utils/utils";

interface IProps {
	title: string;
	onClose: (newNumber?: string) => void;
}

const NewDossierNumberDialog: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const [newDossierNumber, setNewDossierNumber] = useState("");
	const [dataChanged, setDataChanged] = useState(false);
	const firstField: React.MutableRefObject<Input> = useRef();

	useEffect(() => {
		if (firstField && firstField.current) {
			firstField.current.focus();
		}
	}, [firstField]);

	function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
		setNewDossierNumber(event.target.value);
		setDataChanged(true);
	}

	function isValid(): boolean {
		let valid: boolean = true;
		if (isNullOrEmpty(newDossierNumber)) {
			valid = false;
			notify(t("error"), t("fill_in_required_field", { field: t("newDossierNumber").toLowerCase() }), "error");
		}
		if (!isNullOrEmpty(newDossierNumber) && !newDossierNumber.match(dossierNumberRegex)) {
			valid = false;
			notify(t("error"), t("fill_in_correct_format", { field: t("dossierNumber").toLowerCase(), example: "1234_12" }), "error");
		}
		return valid;
	}

	function onClick(): void {
		if (isValid()) {
			props.onClose(newDossierNumber);
		}
	}

	return (
		<Dialog width="70%" title={props.title} onClose={() => props.onClose()}>
			<div className="k-form">
				<label className="k-form-field">
					<span>{t("newDossierNumber")} *</span>
					<Input ref={firstField} className="full-width-field" value={newDossierNumber} onChange={onChange} pattern="[0-9_]+" required />
				</label>
			</div>
			<DialogActionsBar>
				<Button onClick={() => props.onClose()}>{t("cancel")}</Button>
				<Button primary disabled={!dataChanged} onClick={onClick}>{t("ok")}</Button>
			</DialogActionsBar>
		</Dialog>
	);
};

export default NewDossierNumberDialog;
