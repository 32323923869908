import React from "react";

import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useTranslation } from "react-i18next";

import style from "./confirm.module.scss";

interface IProps {
	title?: string;
	onDecline: () => void;
	onConfirm: () => void;
	onCancel?: () => void;
	children: string | React.ReactElement;
}

const Confirm: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();

	return (
		<Dialog title={props.title || t("confirm_title")} width="400px" closeIcon={false}>
			<div className={style.content}>
				{props.children}
			</div>
			<DialogActionsBar>
				<Button primary onClick={props.onConfirm}>{t("yes")}</Button>
				<Button onClick={props.onDecline}>{t("no")}</Button>
				{props.onCancel && <Button onClick={props.onCancel}>{t("cancel")}</Button>}
			</DialogActionsBar>
		</Dialog>
	);
};

export default Confirm;