import { Reducer } from "react";

import remove from "lodash/remove";

import { IRenderConfig, IRenderState } from "..";
import { IAction } from "../../utils/types/types";

const initialState: IRenderState = {
	components: []
};

const renderReducer: Reducer<IRenderState, IAction> = (state: IRenderState = initialState, action: IAction) => {
	const components: IRenderConfig[] = [...state.components];
	switch (action.type) {
		case "RENDER":
			components.push({ key: action.config.key, component: action.config.component, props: action.config.props });
			state = {
				...state,
				components
			};
			break;
		case "DERENDER":
			remove(components, { key: action.key });
			state = {
				...state,
				components
			};
			break;
		default:
			return state;
	}
	return state;
};
export default renderReducer;