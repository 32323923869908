import React from "react";

import { useTranslation } from "react-i18next";

import { Permission } from "../../../utils/enums";
import RoutedTabPanel from "../../global/routertabpanel";
import Roles from "./roles";
import Users from "./users";

import security from "../../../assets/users.png";
import Teams from "./teams";

const Security: React.FC = () => {
	const { t } = useTranslation();

	return (
		<RoutedTabPanel title={t("security")} image={security}>
			<Users reactKey="users" label={t("users")} url="/users" tileIcon="las la-users" permissions={[Permission.UsersRead]} />
			<Roles reactKey="roles" label={t("roles")} url="/roles" tileIcon="las la-lock-open" permissions={[Permission.RolesRead]} />
			<Teams reactKey="teams" label={t("teams")} url="/teams" tileIcon="las la-user-friends" permissions={[Permission.TeamsRead]} />
		</RoutedTabPanel>
	);
};

export default Security;