import { Notification } from "react-notification-system";
import { show } from "react-notification-system-redux";

import store from "../store";

export function notify(title: string | JSX.Element, message: string | JSX.Element, level: "success" | "error" | "warning" | "info"): void {
	const newConfig: Notification = {
		title,
		message,
		dismissible: true,
		position: "tl",
		autoDismiss: 7
	};
	store.dispatch(show(newConfig, level));
}
