import { Method } from "axios";
import { Dispatch } from "redux";

import callApi from "../../services/api/callApi";
import Endpoint from "../../services/api/endpoint";
import endpoints from "../../services/api/endpoints";
import { IAction, IDictionary } from "../../utils/types/types";

class ApiSingleton {
	public apiVersion: string;
	public apiUrl: string;
	// tslint:disable-next-line: ban-types
	private apiActions: IDictionary<Function>;

	constructor() {
		this.apiVersion = "";
		this.apiUrl = "";
		this.apiActions = {};
	}

	// tslint:disable-next-line: ban-types
	public createEndpointAction(endpointName: Endpoint): Function {
		return (method: Method, urlArguments: object, data: object, payloadOptions: object, actionAfterComplete?: () => void | IAction, dispatchActionAfterComplete?: boolean) => {
			return (dispatch: Dispatch): void => {
				callApi(dispatch, endpointName, method, urlArguments, data, payloadOptions, actionAfterComplete, dispatchActionAfterComplete);
			};
		};
	}

	public loadAPI(): void {
		for (const key of Object.keys(endpoints)) {
			const endpointName: Endpoint = key as Endpoint;
			this.apiActions[endpointName] = this.createEndpointAction(endpointName);
		}
	}

	// tslint:disable-next-line: ban-types
	public getActions(): IDictionary<Function> {
		if (!this.apiActions || Object.keys(this.apiActions).length === 0) {
			this.loadAPI();
		}
		return this.apiActions;
	}
}

export default new ApiSingleton();
