import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import ITranslation from "../../utils/types/translation";
import { IDictionary } from "../../utils/types/types";
import { ILanguageConfiguration, languages } from "./languages";

// the translations
function resources(): IDictionary<{ translation: ITranslation }> {
	const result: IDictionary<{ translation: ITranslation }> = {};
	languages.forEach((language: ILanguageConfiguration) => {
		result[language.code] = { translation: language.translations };
	});
	return result;
}

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: resources(),
		lng: "nl",
		interpolation: {
			escapeValue: false // react already safes from xss
		}
	});

export default i18n;