import React from "react";

function handleBeforeUnload(event: BeforeUnloadEvent): void {
	event.preventDefault();
}

export default function usePreventWindowUnload(preventDefault: boolean): void {
	React.useEffect(() => {
		if (!preventDefault) {
			return;
		}
		window.addEventListener("beforeunload", handleBeforeUnload);
		return () => window.removeEventListener("beforeunload", handleBeforeUnload);
	}, [preventDefault]);
}