import { Reducer } from "react";

import { IDossierState, initialDossierState } from "..";
import Endpoint from "../../services/api/endpoint";
import { IDossier } from "../../utils/types/models";
import { IAction } from "../../utils/types/types";
import { createEntityReducer } from "./entityReducer";

const dossierReducer: Reducer<IDossierState, IAction> = (state: IDossierState = initialDossierState, action: IAction) => {
	switch (action.type) {
		case "REQUEST_POST_DOSSIERS_COPY":
			state = {
				...state,
				isCopying: true,
				copiedEntity: null,
				copyError: null
			};
			break;
		case "REQUEST_SUCCESS_POST_DOSSIERS_COPY":
			if (action.payload && action.payload.response && action.payload.response.data) {
				state = {
					...state,
					copiedEntity: action.payload.response.data
				};
			}
			break;
		case "REQUEST_FAILURE_POST_DOSSIERS_COPY":
			state = {
				...state,
				copyError: action.payload.error
			};
			break;
		case "REQUEST_END_POST_DOSSIERS_COPY":
			state = {
				...state,
				isCopying: false
			};
			break;
		case "CLEAR_POST_DOSSIERS_COPY":
			state = {
				...state,
				isCopying: false,
				copiedEntity: null,
				copyError: null
			};
			break;
		case "REQUEST_GET_DOSSIERS_PICKING":
			state = {
				...state,
				isPickingDossiersLoading: true,
				pickingDossiers: null
			};
			break;
		case "REQUEST_SUCCESS_GET_DOSSIERS_PICKING":
			if (action.payload && action.payload.response && action.payload.response.data) {
				state = {
					...state,
					pickingDossiers: action.payload.response.data
				};
			}
			break;
		case "REQUEST_FAILURE_GET_DOSSIERS_PICKING":
			state = {
				...state,
				pickingDossiersError: action.payload.error
			};
			break;
		case "REQUEST_END_GET_DOSSIERS_PICKING":
			state = {
				...state,
				isPickingDossiersLoading: false
			};
			break;
		case "CLEAR_GET_DOSSIERS_PICKING":
			state = {
				...state,
				isPickingDossiersLoading: false,
				pickingDossiers: null,
				pickingDossiersError: null
			};
			break;
		case "REQUEST_POST_" + Endpoint.DossiersByColor:
			state = {
				...state,
				isDossiersByColorLoading: true,
				dossiersByColorError: null
			};
			break;
		case "REQUEST_SUCCESS_POST_" + Endpoint.DossiersByColor:
			if (action.payload && action.payload.response && action.payload.response.data) {
				let results: IDossier[] = action.payload.response.data.data;
				if (action.payload.response.data.group) {
					results = action.payload.response.data.group;
				}
				state = {
					...state,
					dossiersByColor: results || [],
					dossiersByColorTotal: action.payload.response.data.total || 0
				};
			}
			break;
		case "REQUEST_FAILURE_POST_" + Endpoint.DossiersByColor:
			state = {
				...state,
				dossiersByColorError: action.payload.error
			};
			break;
		case "REQUEST_END_POST_" + Endpoint.DossiersByColor:
			state = {
				...state,
				isDossiersByColorLoading: false
			};
			break;
		case "CLEAR_POST_" + Endpoint.DossiersByColor:
			state = {
				...state,
				dossiersByColor: [],
				totalCount: 0,
				isDossiersByColorLoading: false,
				dossiersByColorError: null
			};
			break;
		default:
			return state;
	}
	return state;
};

export default createEntityReducer(Endpoint.Dossiers, Endpoint.DossiersList, dossierReducer, initialDossierState);