enum RelatedTo {
	General = "General",
	Documents = "Documents",
	ArticleFurniture = "ArticleFurniture",
	ArticleWorktops = "ArticleWorktops",
	ArticlePlumbing = "ArticlePlumbing",
	ArticleAppliance = "ArticleAppliance",
	ArticleAccessory = "ArticleAccessory",
	ArticleOther = "ArticleOther",
	Communication = "Communication",
	Contacts = "Contacts",
	Invoicing = "Invoicing",
	Planning = "Planning",
	AfterSales = "AfterSales"
}

export default RelatedTo;