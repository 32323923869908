import { AxiosError } from "axios";
import {
	ArticleGroup,
	CommunicationStatus,
	CommunicationType,
	DepositType,
	DossierTaskAbsenceReason,
	DossierTaskType,
	InvoiceLineType,
	RelatedTo,
	RelatedToArticlePhase,
	RemarkType,
	ServiceItemStatus,
	SettingType,
	VatType
} from "../enums";
import { IDictionary } from "./types";

export interface IEntity {
	id: number;
	timestamp?: string;
	createdDate?: Date;
	createdBy?: string;
	updatedDate?: Date;
	updatedBy?: string;
}

export interface IMunicipality {
	id: number;
	code: string;
	name: string;
	province: string;
	country: string;
}

export interface IAddress {
	street: string;
	number: string;
	postalCode: string;
	municipality: string;
	province: string;
	country: string;
	fullAddress: string;
}

export interface IRole extends IEntity {
	name: string;
	active: boolean;
	permissions: {
		[key: string]: boolean;
	};
}

export interface IUserCompany {
	userId: number;
	user?: IUser;
	companyId: number;
	company?: ICompany;
}

export interface ITeam extends IEntity {
	name: string;
	leadId: number;
	lead: IUser;
	colleagueId: number;
	colleague: IUser;
	currentCompanyId: number;
	currentCompany: ICompany;
	active: boolean;
}

export interface IUserRole {
	userId: number;
	user?: IUser;
	roleId: number;
	role?: IRole;
}

export interface IUser extends IEntity {
	firstName: string;
	lastName: string;
	fullName?: string;
	email: string;
	language: string;
	active: boolean;
	installer: boolean;
	leadInstaller: boolean;
	sequenceNumber?: number;
	userRoles: IUserRole[];
	userCompanies: IUserCompany[];
	currentCompanyId: number;
	currentCompany?: ICompany;
	permissions?: {
		[key: string]: boolean;
	};
}

export interface ICompany extends IEntity {
	code: string;
	name: string;
	vatNumber: string;
	address: IAddress;
	active: boolean;
	companyId: number;
}

export interface ISupplier extends IEntity {
	name: string;
	address: IAddress;
	active: boolean;
	companyId: number;
	ourCustomerNumber: string;
}

export interface IProduct extends IEntity {
	modelNumber: string;
	barcode?: string;
	supplierId: number;
	supplier: ISupplier;
	brandId: number;
	brand: IBrand;
	productTypeId?: number;
	productType?: IProductType;
	description: string;
	salesPriceExclVat: number;
	replacementProductId?: number;
	replacementProduct?: IProduct;
	active: boolean;
	eanInformation: IExternalProductInformation;
	companyId: number;
}

export interface IStockLine extends IEntity {
	productId: number;
	product?: IProduct;
	count: number;
}

export interface IExternalProductInformation {
	barcode: string;
	model: string;
	name: string;
	category: string;
	brand: string;
	manufacturer: string;
	description: string;
	images: string[];
	originalResponse: object;
}

export interface ICustomer extends IEntity {
	name: string;
	vatNumber?: string;
	address: IAddress;
	dossierContact: string;
	phone1: string;
	email1: string;
	phone2: string;
	email2: string;
	active: boolean;
	companyId: number;
}

export interface IContractor extends IEntity {
	name: string;
	vatNumber?: string;
	address: IAddress;
	contactName: string;
	phone: string;
	email: string;
	active: boolean;
	companyId: number;
}

export interface IConstructionYard extends IEntity {
	name: string;
	address: IAddress;
	constructionYardContact: string;
	phone: string;
	email: string;
	companyId: number;
	active: boolean;
}

export interface IProject extends IEntity {
	name: string;
	active: boolean;
	companyId: number;
}

export interface IBrand extends IEntity {
	name: string;
	active: boolean;
	companyId: number;
}

export interface IProductType extends IEntity {
	name: string;
	active: boolean;
	companyId: number;
}

export interface IShop extends IEntity {
	name: string;
	address: IAddress;
	active: boolean;
	companyId: number;
}

export interface IFileData {
	filename: string;
	internalFilename: string;
	size: number;
	type: string;
}

export interface IDocument extends IEntity {
	dossierId: number;
	dossier?: IDossier;
	subject: string;
	file: IFileData;
	lastUploadedBy: string;
	lastUploadedDate: Date;
}
export interface IArticleDelivery extends IEntity {
	articleId: number;
	article?: IArticle;
	count: number;
}

export interface IArticlePicking extends IEntity {
	articleId: number;
	article: IArticle;
	count: number;
}

export interface IArticle extends IEntity {
	group: ArticleGroup;
	productId: number;
	product?: IProduct;
	dossierId: number;
	dossier?: IDossier;
	description: string;
	count: number;
	salesPriceInclVat: number;
	purchasePriceExclVat: number;
	salesPriceExclVat: number;
	discount: number;
	discountPrice: number;
	lineTotalIncl: number;
	lineTotalExcl: number;
	lineTotalVat: number;
	vatType: VatType;
	vat: number;
	pokNumber?: string;
	procurementArticle?: boolean;
	orderDate?: Date;
	deliverDate?: Date;
	checkDate?: Date;
	pickDate?: Date;
	deliveries: IArticleDelivery[];
	pickings: IArticlePicking[];
}

export interface IPriceCalculationResult {
	lineTotalIncl: number;
	vat: number;
	salesPriceExclVat: number;
	salesPriceInclVat: number;
	discountPrice: number;
	lineTotalExcl: number;
	lineTotalVat: number;
}

export interface IPriceTotalCalculationResult {
	totalExclVat: number;
	totalInclVat: number;
	totalVat: number;
}

export interface IContactType extends IEntity {
	code: string;
	name: string;
	active: boolean;
	companyId: number;
}

export interface IContact extends IEntity {
	dossierId: number;
	dossier?: IDossier;
	typeId: number;
	type?: IContactType;
	name: string;
	phone: string;
	email: string;
}

export interface IRemark extends IEntity {
	dossierId: number;
	dossier?: IDossier;
	type: RemarkType;
	relatedTo: RelatedTo;
	relatedToArticlePhase?: RelatedToArticlePhase;
	relatedArticleId?: number;
	relatedArticle?: IArticle;
	assigneeId?: number;
	assignee?: IUser;
	finished: boolean;
	content: string;
	photos: IFileData[];
}

export interface ICommunicationTemplate extends IEntity {
	name: string;
	subject: string;
	body: string;
	reminderBody?: string;
	active: boolean;
	companyId: number;
}

export interface ICommunication extends IEntity {
	dossierId: number;
	dossier?: IDossier;
	type: CommunicationType;
	templateId: number;
	template?: ICommunicationTemplate;
	to: string;
	subject: string;
	body: string;
	reminderBody?: string;
	reminderDate?: Date;
	status: CommunicationStatus;
}

export interface IDossierTask extends IEntity {
	dossierId: number;
	dossier?: IDossier;
	startDate?: Date;
	startTime?: Date;
	stopDate?: Date;
	stopTime?: Date;
	dateIsFixed: boolean;
	type: DossierTaskType;
	assignee1Id: number;
	assignee1: IUser;
	assignee2Id?: number;
	assignee2?: IUser;
	description: string;
	signature?: IFileData;
	planned: boolean;
	completed: boolean;
	absent: boolean;
	absenceReason?: DossierTaskAbsenceReason;
	photos: IFileData[];
}

export interface IServiceItem extends IEntity {
	dossierId: number;
	dossier?: IDossier;
	status: ServiceItemStatus;
	loggerId: number;
	logger?: IUser;
	logDate: Date;
	description: string;
}

export interface ISettingState {
	settingsLoading: boolean;
	settings: IDictionary<ISetting>;
	settingsLoadError: AxiosError;

	setting: ISetting;

	isUpdating: boolean;
	updateError: AxiosError;
}

export const initialSettingState: ISettingState = {
	settingsLoading: false,
	settings: {},
	settingsLoadError: null,

	setting: null,

	isUpdating: false,
	updateError: null
};

export interface ISetting {
	key: string;
	type: SettingType;
	value: string;
}

export interface IProcurementItem {
	dossierId: number;
	dossierNumber: string;
	supplierId: number;
	supplierName: string;
	articles: IArticle[];
	firstOrderDate?: Date;
	deliveryWeek?: Date;
}

export interface IInvoiceLine {
	dossier: IDossier;
	customer: ICustomer;
	constructionYare: IConstructionYard;
	type: InvoiceLineType;
	amount: number;
}

export interface IDossier extends IEntity {
	dossierNumber: string;
	sellingDate: Date;
	creationDate: Date;
	customerId: number;
	customer: ICustomer;
	contractorId?: number;
	contractor?: IContractor;
	projectId?: number;
	project?: IProject;
	unit: string;
	constructionYardId: number;
	constructionYard: IConstructionYard;
	dossierManagerId: number;
	dossierManager: IUser;
	salesRepId: number;
	salesRep: IUser;
	shopId?: number;
	shop?: IShop;
	vatType: VatType;
	binNumber: string;
	checkInAtWork: string;
	description: string;
	material: string;
	worktop: string;
	cupboards: number;
	parking: string;
	floor: string;
	isLiftNeeded: string;
	hasSimpleAccess: string;
	fixedDeliveryDate: boolean;
	measurementDate?: Date;
	measurementDoneById?: number;
	measurementDoneBy?: IUser;
	writeOutDate?: Date;
	writeOutDoneById?: number;
	writeOutDoneBy?: IUser;
	complete: boolean;
	readyForPicking: boolean;
	active: boolean;
	documents: IDocument[];
	articles: IArticle[];
	totalArticleInclVat: number;
	totalArticleExclVat: number;
	totalArticleVat: number;
	contacts: IContact[];
	remarks: IRemark[];
	communications: ICommunication[];
	depositType: DepositType;
	depositAmountExclVat: number;
	depositVat: VatType;
	depositInvoiced: boolean;
	depositPayed: boolean;
	depositPayementDate?: Date;
	amountAtDelivery: number;
	amountAtDeliveryPayed: boolean;
	amountAfterInstallationPayed: boolean;
	amountAfterInstallationInvoiced: boolean;
	amountAtDeliveryInvoiced: boolean;
	amountAfterInstallation: number;
	installationWeek?: Date;
	productionWeek?: Date;
	pickedDate?: Date;
	deliveryDate?: Date;
	scanLocation: string;
	tasks: IDossierTask[];
	serviceItems: IServiceItem[];
}

export interface IDossierViewDto extends IEntity {
	dossierNumber: string;
	binNumber: string;
	contractor: string;
	customer: string;
	project: string;
	unit: string;
	salesRep: string;
	description: string;
	cupboards: number;
	material: string;
	totalArticleExclVat: number;
	installationWeek?: Date;
	productionWeek?: Date;
	measurementDate?: Date;
	writeOutDate?: Date;
	deliveryDate?: Date;
	complete: boolean;
	active: boolean;
	hasRemarks: boolean;
	hasRedRemarks: boolean;
	hasOrangeRemarks: boolean;
	hasTasks: boolean;
}
export interface IPrintLabel extends IEntity {
	articleId: number;
	dossierNumber: string;
	pokNumber: string;
	articleCode: string;
}

export interface IDossierPrintData {
	dossierNumber: string;
	description: string;
	customerName: string;
}
