import React, { FormEvent, useState } from "react";

import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar, DialogProps } from "@progress/kendo-react-dialogs";
import { useTranslation } from "react-i18next";

import { notify } from "../../../../../services/notification";
import { IEntity } from "../../../../../utils/types/models";
import Confirm from "../../../../global/confirm";
import Messages from "../../../../global/messages";

interface IProps<T extends IEntity> extends DialogProps {
	dataChanged: boolean;
	close: (record?: T) => void;
	record: T;
	action: string;
	getErrorMessages: () => string[];
	readOnly: boolean;
}

function PageEditor<T extends IEntity>(props: IProps<T>): JSX.Element {
	const { t } = useTranslation();
	const { dataChanged, close, record, action, getErrorMessages } = props;

	const [askSaveChange, setAskSaveChange] = useState(false);

	function handleClose(): void {
		if (dataChanged && !askSaveChange) {
			setAskSaveChange(true);
		} else if (dataChanged && askSaveChange) {
			setAskSaveChange(false);
		} else {
			close();
		}
	}

	function save(): void {
		const errorMessages: string[] = getErrorMessages();
		if (!errorMessages || errorMessages.length <= 0) {
			close(record);
		} else {
			notify(t("error"), <Messages messages={errorMessages} />, "error");
		}
	}

	function onSubmit(event: FormEvent): void {
		event.stopPropagation();
		event.preventDefault();
	}

	return (
		<React.Fragment>
			<Dialog {...props} onClose={handleClose}>
				<form noValidate onSubmit={onSubmit}>
					{props.children}
				</form>
				<DialogActionsBar>
					<Button onClick={handleClose}>{props.readOnly ? t("close") : t("cancel")}</Button>
					{!props.readOnly && (
						<Button primary onClick={save} disabled={!dataChanged}>
							{action}
						</Button>
					)}
				</DialogActionsBar>
			</Dialog>
			{askSaveChange && (
				<Confirm
					title={t("pending_changes")}
					onDecline={() => close()}
					onConfirm={() => {
						setAskSaveChange(false);
						save();
					}}
					onCancel={() => handleClose()}
				>
					{t("ask_save")}
				</Confirm>
			)}
		</React.Fragment>
	);
}

export default PageEditor;
