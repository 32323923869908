import React, { useReducer, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import Endpoint from "../../../../services/api/endpoint";
import { getInitialState } from "../../../../state";
import { remarkReducer } from "../../../../state/reducers";
import { IArticle, IRemark } from "../../../../utils/types/models";
import EntityEditor, { IEditorProps, IEditScreenProps } from "../../../global/editor";
import RemarkEditorContent, { IRemarkEditorContentRef } from "../dossiermanagement/editor/remarks/editorContent";

interface IProps {
	dossierId?: number;
	articles?: IArticle[];
}

const RemarkEditor: React.FC<IEditorProps<IRemark> & IProps> = (props: IEditorProps<IRemark> & IProps) => {
	const { t } = useTranslation();
	const [dataChanged, setDataChanged] = useState(false);
	const initalRemark: IRemark = {
		id: (props as IEditScreenProps<IRemark>).recordId,
		dossierId: props.dossierId,
		type: null,
		relatedTo: null,
		finished: false,
		content: "",
		photos: []
	};
	const [remark, setRemark] = useState<IRemark>(initalRemark);
	const [remarkState, remarkDispatch] = useReducer(remarkReducer, getInitialState<IRemark>());
	const contentRef: React.MutableRefObject<IRemarkEditorContentRef> = useRef<IRemarkEditorContentRef>(null);

	function updateRemark(newRemark: IRemark): void {
		setRemark(newRemark);
		setDataChanged(true);
	}

	const action: string = (props as IEditScreenProps<IRemark>).recordId ? t("edit") : t("add");
	const readonly: boolean = (props as IEditScreenProps<IRemark>).readonly;
	return (
		<EntityEditor
			width="70%"
			endpoint={Endpoint.Remarks}
			additionalUrlParameters={{ dossierId: remark.dossierId }}
			entityState={remarkState}
			dispatch={remarkDispatch}
			title={`${action} ${t("remark")}`.trim()}
			close={props.close}
			dataChanged={dataChanged}
			getErrorMessages={contentRef.current?.getErrorMessages}
			record={remark}
			readonly={readonly}
			recordName=""
			entityType={t("remark")}
		>
			<RemarkEditorContent ref={contentRef} remark={remark} articles={props.articles} onRemarkChange={updateRemark} />
		</EntityEditor>
	);
};

export default RemarkEditor; 