import React, { useEffect, useReducer, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { FilterDescriptor } from "@progress/kendo-data-query";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { History } from "history";
import find from "lodash/find";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch as ReduxDispatch } from "redux";

import callApi from "../../../../services/api/callApi";
import Endpoint from "../../../../services/api/endpoint";
import { hasPermission } from "../../../../services/authentication";
import { getInitialState, IDossierManagementFilterState, initialDossierState } from "../../../../state";
import { clearEntity } from "../../../../state/actions/entityActions";
import { hideLoader, showLoader } from "../../../../state/actions/loaderActions";
import { derender, render } from "../../../../state/actions/renderActions";
import { dossierReducer, remarkReducer, taskReducer } from "../../../../state/reducers";
import { IApplicationState } from "../../../../store";
import { Permission, RemarkType } from "../../../../utils/enums";
import { IDossierTask, IDossierViewDto, IRemark, IUser } from "../../../../utils/types/models";
import { newKey } from "../../../../utils/utils";
import Confirm from "../../../global/confirm";
import { EnumDropDownList } from "../../../global/editor";
import GridPanel, { IGridPanelRef } from "../../../global/gridpanel";
import { checkValueExistencyFilterCell, weekPickerFilterCell } from "../../../global/gridpanel/customCells/filterCells";
import { checkBoxCell, customCell, dateCell, weekCell } from "../../../global/gridpanel/customCells/gridCells";
import { IFunctionCommand } from "../../../global/gridpanel/customCells/gridCells/commandCell";
import Loader from "../../../global/loader";
import { IRoutedTabProps } from "../../../global/routertabpanel";
import ToolbarFilter from "../../../global/toolbarFilter";
import TasksGrid from "./editor/planning/tasksGrid";
import RemarksGrid from "./editor/remarks/remarksGrid";
import NewDossierNumberDialog from "./newDossierNumberDialog";

import "../../../../assets/masterDetail.scss";

const DossierManagement: React.FC<IRoutedTabProps> = () => {
	const { t } = useTranslation();
	const history: History = useHistory();
	const [dossierState, dossierDispatch] = useReducer(dossierReducer, initialDossierState);
	const [remarkState, remarkDispatch] = useReducer(remarkReducer, getInitialState<IRemark>());
	const [taskState, taskDispatch] = useReducer(taskReducer, getInitialState<IDossierTask>());
	const [hideBottom, setHideBottom] = useState(true);
	const currentUser: IUser = useSelector((applicationState: IApplicationState) => applicationState.authenticationState.currentUser);
	const filterState: IDossierManagementFilterState = useSelector((appState: IApplicationState) => appState.dossierManagementFilterState);
	const reduxDispatch: ReduxDispatch = useDispatch();
	const gridRef: React.MutableRefObject<IGridPanelRef<IDossierViewDto>> = useRef<IGridPanelRef<IDossierViewDto>>(null);
	const [colorFilter, setColorFilter] = useState(null);

	useEffect(() => {
		if (dossierState.copiedEntity && !dossierState.isCopying) {
			const copiedKey: string = newKey("copied");
			reduxDispatch(
				render(copiedKey, Confirm, {
					title: t("dossierCopied"),
					children: t("showNewDossier"),
					onDecline: () => {
						reduxDispatch(derender(copiedKey));
						if (gridRef && gridRef.current) {
							gridRef.current.refresh();
						}
					},
					onConfirm: () => {
						reduxDispatch(derender(copiedKey));
						history.push("/work/dossiermanagement/" + dossierState.copiedEntity.id.toString());
					}
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dossierState.copiedEntity, dossierState.isCopying]);

	useEffect(() => {
		if (dossierState.isCopying) {
			reduxDispatch(showLoader());
		} else if (!dossierState.isCopying) {
			reduxDispatch(hideLoader());
		}
	}, [dossierState.isCopying, reduxDispatch]);

	useEffect(() => {
		if (gridRef && gridRef.current) {
			gridRef.current.refresh();
		}
	}, [colorFilter]);

	function getCellColorClass(gridCellProps: GridCellProps): string {
		let className: string = "green";
		if (gridCellProps.dataItem.hasRedRemarks) {
			className = "red";
		} else if (gridCellProps.dataItem.hasOrangeRemarks) {
			className = "orange";
		}
		return className;
	}

	function copyDossier(id: number): void {
		const key: string = newKey("CopyDossier");
		reduxDispatch(
			render(key, NewDossierNumberDialog, {
				title: t("copyDossier"),
				onClose: (newNumber?: string) => {
					if (newNumber) {
						callApi(dossierDispatch, Endpoint.DossiersCopy, "POST", { id }, "'" + newNumber + "'");
					}
					reduxDispatch(derender(key));
				}
			})
		);
	}

	let commands: IFunctionCommand<IDossierViewDto>[] = null;
	if (hasPermission(Permission.DossiersAdd)) {
		commands = [
			{
				tooltip: t("copy"),
				iconClass: "las la-copy",
				idAction: copyDossier
			}
		];
	}

	function onChangeColor(event: DropDownListChangeEvent): void {
		setColorFilter(event.value?.key || null);
	}

	return (
		<div className="d-flex flex-column" style={{ overflowX: "auto" }}>
			{(remarkState.areEntitiesLoading || taskState.areEntitiesLoading) && <Loader />}
			<GridPanel
				ref={gridRef}
				className={`masterGrid dossierManagementGrid ${hideBottom ? " detailClosed" : ""}`}
				listEndpoint={Endpoint.DossiersList}
				listUrlArguments={{ companyId: currentUser.currentCompanyId, color: colorFilter }}
				endpoint={Endpoint.Dossiers}
				addLink={"/work/dossiermanagement/new"}
				addPermission={Permission.DossiersAdd}
				editLink={"/work/dossiermanagement/:id"}
				showDelete
				resizable={true}
				frontActions={true}
				disableDoubleClick={true}
				editPermission={Permission.DossiersUpdate}
				deletePermission={Permission.DossiersDelete}
				take={100}
				useReduxFilter
				onSelectionChange={(entity: IDossierViewDto) => {
					if (entity && (entity.hasRemarks || entity.hasTasks)) {
						setHideBottom(false);
						if (entity.hasRemarks) {
							callApi(remarkDispatch, Endpoint.Remarks, "GET", { dossierId: entity.id });
						}
						if (entity.hasTasks) {
							callApi(taskDispatch, Endpoint.Tasks, "GET", { dossierId: entity.id });
						}
					}
					if (!entity || !entity.hasRemarks || !entity.hasTasks) {
						if (!entity || (!entity.hasRemarks && !entity.hasTasks)) {
							setHideBottom(true);
						}
						if (!entity || !entity.hasRemarks) {
							remarkDispatch(clearEntity("GET", Endpoint.Remarks));
						}
						if (!entity || !entity.hasTasks) {
							taskDispatch(clearEntity("GET", Endpoint.Tasks));
						}
					}
				}}
				extraCommands={commands}
				totalFields={[
					{ field: "totalArticleExclVat", label: t("totalExclVat") },
					{ field: "cupboards", label: t("cupboards") }
				]}
				totalConfigurationFieldEndpoint={Endpoint.TotalConfigurationField}
				getRowClass={(record: IDossierViewDto) => (record.complete ? "complete" : "")}
				exportExcel={Endpoint.DownloadDossierExcel}
				extraToolbarItems={[
					<ToolbarFilter
						key="dossierNumberFilter"
						placeholder={t("searchDossierNumber")}
						field="dossierNumber"
						initalValue={(find(filterState.filter?.filters, { field: "dossierNumber" }) as FilterDescriptor)?.value}
					/>,
					<EnumDropDownList style={{ marginLeft: "10px" }} key="colorFilter" enum={RemarkType} value={colorFilter} onChange={onChangeColor} />
				]}
				sort={[
					{
						field: "dossierNumber",
						dir: "asc"
					}
				]}
			>
				<GridColumn width="170px" field="dossierNumber" title={t("dossierNumber")} filterable={false} cell={customCell(undefined, getCellColorClass)} />
				<GridColumn width="170px" field="binNumber" title={t("binNumber")} />
				<GridColumn width="170px" field="contractor" title={t("contractor")} />
				<GridColumn width="170px" field="customer" title={t("customer")} />
				<GridColumn width="170px" field="project" title={t("project")} />
				<GridColumn width="150px" field="unit" title={t("unit")} />
				<GridColumn width="170px" field="leadInstallerLastTask" title={t("lead")} />
				<GridColumn width="170px" field="salesRep" title={t("salesRep")} />
				<GridColumn width="170px" field="description" title={t("description")} />
				<GridColumn width="150px" field="cupboards" filter="numeric" title={t("cupboards")} />
				<GridColumn width="170px" field="material" title={t("material")} />
				<GridColumn width="170px" field="installationWeek" title={t("installationWeek")} filterCell={weekPickerFilterCell()} cell={customCell(weekCell())} />
				<GridColumn width="170px" field="productionWeek" title={t("productionWeek")} filterCell={weekPickerFilterCell()} cell={customCell(weekCell())} />
				<GridColumn width="150px" field="measurementDate" title={t("measured")} filterCell={checkValueExistencyFilterCell()} cell={customCell(checkBoxCell(true))} />
				<GridColumn width="150px" field="writeOutDate" title={t("writtenOut")} filterCell={checkValueExistencyFilterCell()} cell={customCell(checkBoxCell(true))} />
				<GridColumn width="150px" field="deliveryDate" title={t("deliveryDate")} filterCell={checkValueExistencyFilterCell()} cell={customCell(dateCell())} />
				<GridColumn width="170px" field="street" title={t("street")} />
				<GridColumn width="170px" field="municipality" title={t("municipality")} />
				<GridColumn width="150px" field="complete" title={t("complete")} filter="boolean" cell={customCell(checkBoxCell(false))} />
				<GridColumn width="150px" field="active" title={t("active")} filter="boolean" cell={customCell(checkBoxCell(false))} />
			</GridPanel>

			<div className={`detailGrid d-flex${hideBottom ? " closed" : ""}`}>
				<div>
					<div className="header">{t("remarks")}</div>
					<RemarksGrid remarks={remarkState.entities} />
				</div>
				<div>
					<div className="header d-flex">
						{t("tasks")}
						<div className="flex-grow-1" />
						<i className="las la-times" style={{ cursor: "pointer" }} onClick={() => setHideBottom(true)} title={t("close")} />
					</div>
					<TasksGrid tasks={taskState.entities} />
				</div>
			</div>
		</div>
	);
};

export default DossierManagement;
