import React, { useState } from "react";

import { process, State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import { GridRowDoubleClickEvent } from "@progress/kendo-react-grid/dist/npm/interfaces/events";
import remove from "lodash/remove";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { derender, render } from "../../../../../../state/actions/renderActions";
import { RemarkType } from "../../../../../../utils/enums";
import { IDossierPrintData, IRemark } from "../../../../../../utils/types/models";
import { newKey } from "../../../../../../utils/utils";
import Confirm from "../../../../../global/confirm";
import { getGridCommandColumn } from "../../../../../global/gridpanel";
import { customCell, dateCell, multilineCell, translatedCell } from "../../../../../global/gridpanel/customCells/gridCells";
import { IFunctionCommand, IRecordCommand } from "../../../../../global/gridpanel/customCells/gridCells/commandCell";
import PrintDossierRemark from "../../dossierRemarkPrintView";

interface IProps {
	remarks: IRemark[];
	remarkPrintData?: IDossierPrintData;
	showEditor?: (record?: IRemark) => void;
	onChange?: (remarks: IRemark[]) => void;
	readOnly?: boolean;
}

export function getRemarkCellClassName(gridCellProps: GridCellProps): string {
	let className: string = "green";
	switch (gridCellProps.dataItem.type) {
		case RemarkType.Orange:
			className = "orange";
			break;
		case RemarkType.Red:
			className = "red";
			break;
	}
	return className;
}

const RemarksGrid: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const [gridState, setGridState] = useState<State>({ sort: [{ field: "createdDate", dir: "desc" }] });
	const dispatch: Dispatch = useDispatch();

	function onDataStateChange(event: GridDataStateChangeEvent): void {
		setGridState(event.dataState);
	}

	function onRowDoubleClick(event: GridRowDoubleClickEvent): void {
		if (props.showEditor) {
			props.showEditor(event.dataItem);
		}
	}

	function showRemarkPrintPage(record: IRemark) {
		const key: string = newKey("Remark_Print_Page");
		dispatch(
			render(key, PrintDossierRemark, {
				record,
				onClose: () => {
					dispatch(derender(key));
				},
				remarkPrintData: props.remarkPrintData,
				readOnly: props.readOnly
			})
		);
	}

	let commands: (IFunctionCommand<IRemark> | IRecordCommand<IRemark>)[];
	if (props.showEditor && !props.readOnly) {
		commands = [
			{
				tooltip: t("edit"),
				iconClass: "las la-pencil-alt",
				recordAction: (record: IRemark) => {
					props.showEditor(record);
				}
			},
			{
				tooltip: t("remove"),
				iconClass: "las la-times",
				idAction: (id: number) => {
					const confirmKey: string = newKey("confirm");
					dispatch(
						render(confirmKey, Confirm, {
							title: t("confirm_title", { action: t("remove").toLowerCase() }),
							children: t("confirm_content", { action: t("remove").toLowerCase() }),
							onDecline: () => dispatch(derender(confirmKey)),
							onConfirm: () => {
								dispatch(derender(confirmKey));
								const newRemarks: IRemark[] = [...props.remarks];
								remove(newRemarks, { id });
								props.onChange(newRemarks);
							}
						})
					);
				}
			},
			{
				tooltip: t("print"),
				iconClass: "las la-print",
				recordAction: showRemarkPrintPage
			}
		];
	}

	return (
		<Grid data={process(props.remarks, gridState)} {...gridState} sortable onDataStateChange={onDataStateChange} onRowDoubleClick={onRowDoubleClick} className="h-100">
			{props.showEditor && !props.readOnly && (
				<GridToolbar>
					<Button primary onClick={() => props.showEditor()}>
						{t("add")}
					</Button>
				</GridToolbar>
			)}
			<GridColumn field="type" title={t("type")} width="100px" cell={customCell(translatedCell(), getRemarkCellClassName)} />
			<GridColumn field="relatedTo" title={t("related")} cell={customCell(translatedCell())} />
			<GridColumn field="createdBy" title={t("user")} />
			<GridColumn field="createdDate" title={t("createdOn")} filter="date" cell={customCell(dateCell())} />
			<GridColumn field="content" title={t("remark")} cell={customCell(multilineCell())} />
			{commands && commands.length > 0 && getGridCommandColumn(t("actions"), commands)}
		</Grid>
	);
};

export default RemarksGrid;
