import React, { useEffect, useReducer, useState } from "react";

import { AutoCompleteProps, ComboBoxProps, DropDownListProps, MultiSelectProps } from "@progress/kendo-react-dropdowns";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { IAddscreenProps, IEditScreenProps } from ".";
import callApi from "../../../services/api/callApi";
import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { getInitialState } from "../../../state";
import { hideLoader, showLoader } from "../../../state/actions/loaderActions";
import { derender, render } from "../../../state/actions/renderActions";
import { createEntityReducer } from "../../../state/reducers/entityReducer";
import { Permission } from "../../../utils/enums";
import { IEntity } from "../../../utils/types/models";
import { newKey } from "../../../utils/utils";
import { ISearchBoxProps } from "./searchBox";

import style from "./manageableField.module.scss";

interface IProps<T extends IEntity> {
	addScreen: React.ComponentType<IAddscreenProps<T>>;
	editScreen: React.ComponentType<IEditScreenProps<T>>;
	fieldLabel: string;
	recordId: number;
	addPermission: Permission;
	editPermission: Permission;
	setEntity: (record: T) => void;
	getByIdEndpoint?: Endpoint;
	readOnly: boolean;
	children: React.ReactElement<AutoCompleteProps>
	| React.ReactElement<ComboBoxProps>
	| React.ReactElement<DropDownListProps>
	| React.ReactElement<MultiSelectProps>
	| React.ReactElement<ISearchBoxProps<T>>
	| React.ReactElement<DropDownListProps>;
}

function ManageableField<T extends IEntity>(props: IProps<T>): JSX.Element {
	const [editorKey, setEditorKey] = useState("");
	const [state, dispatch] = useReducer(createEntityReducer<T>(props.getByIdEndpoint), getInitialState<T>());
	const reduxDispatch: Dispatch = useDispatch();

	useEffect(() => {
		if (!state.isEntityLoading && state.entity) {
			props.setEntity(state.entity);
			reduxDispatch(derender(editorKey));
			reduxDispatch(hideLoader());
		} else if (state.isEntityLoading) {
			reduxDispatch(showLoader());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.isEntityLoading, state.entity]);

	function addRecord(): void {
		const key: string = newKey("add");
		reduxDispatch(render(key, props.addScreen, { close: (record?: T) => hideEditor(key, record), hideActive: true }));
	}

	function editRecord(): void {
		const key: string = newKey("edit");
		reduxDispatch(render(key, props.editScreen, { recordId: props.recordId, readonly: !hasPermission(props.editPermission), close: (record?: T) => hideEditor(key, record), hideActive: true }));
	}

	function hideEditor(key: string, record?: T): void {
		if (!props.getByIdEndpoint || !record) {
			if (record) {
				props.setEntity(record);
			}
			reduxDispatch(derender(key));
		} else {
			setEditorKey(key);
			callApi(dispatch, props.getByIdEndpoint, "GET", { id: record.id });
		}
	}

	return (
		<div className="k-form-field">
			<span className={style.label}>
				{!props.readOnly && hasPermission(props.addPermission) && <i className="las la-plus" onClick={addRecord} />}
				{!props.readOnly && hasPermission(props.editPermission) && props.recordId ? <i className="las la-pencil-alt" onClick={() => editRecord()} /> : undefined}
				{props.fieldLabel}
			</span>
			{props.children}
		</div>
	);
}

export default ManageableField;