import React from "react";

import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import { IDossierPrintData, IFileData, IRemark } from "../../../../../utils/types/models";

import style from "./printRemark.module.scss";

interface IProps {
	record?: IRemark;
	onClose: () => void;
	readOnly: boolean;
	remarkPrintData: IDossierPrintData;
}

const PrintDossierRemark: React.FC<IProps> = ({ record, onClose, readOnly, remarkPrintData }) => {
	const { t } = useTranslation();
	const componentRef: React.MutableRefObject<HTMLDivElement> = React.useRef(null);

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, [componentRef.current]);

	const reactToPrintTrigger = React.useCallback(() => {
		return <Button primary>Print</Button>;
	}, []);

	function getRemarkPictures(): JSX.Element {
		return (
			<>
				{record.photos.map((r: IFileData) => {
					return (
						<img
							key={"image_" + r.filename}
							src={"/api/files/" + r.internalFilename + "/" + r.filename}
							alt="Could not be found"
							style={{ width: "80%", marginLeft: "10px", marginBottom: "10px", pageBreakInside: "avoid" }}
						/>
					);
				})}
			</>
		);
	}

	return (
		<>
			<Dialog title="Afdrukken Opmerking" width={710} onClose={onClose}>
				<div ref={componentRef}>
					<p>
						<span className={style.spanStyle}>{t("customer")}:</span>
						<span>
							<i>{remarkPrintData.customerName}</i>
						</span>
					</p>

					<p>
						<span className={style.spanStyle}>{t("dossierNumber")}:</span>
						<span>
							<i>{remarkPrintData.dossierNumber}</i>
						</span>
					</p>

					<p>
						<span className={style.spanStyle}>{t("dossierDescription")}:</span>
						<span>
							<i>{remarkPrintData.description}</i>
						</span>
					</p>

					<br />

					<p>
						<span className={style.spanStyle}>{t("remark")}:</span>
					</p>
					<p>
						<span>
							<i style={{ whiteSpace: "pre-wrap" }}>{record.content}</i>
						</span>
					</p>

					<br />

					{record.photos?.length > 0 && (
						<>
							<p style={{ fontWeight: "bold" }}>Opmerking foto's:</p>
							{getRemarkPictures()}
						</>
					)}
				</div>

				<DialogActionsBar>
					<Button onClick={onClose}>{readOnly ? t("close") : t("cancel")}</Button>
					<ReactToPrint content={reactToPrintContent} documentTitle="AwesomeFileName" onAfterPrint={onClose} removeAfterPrint trigger={reactToPrintTrigger} />
				</DialogActionsBar>
			</Dialog>
		</>
	);
};

export default PrintDossierRemark;
