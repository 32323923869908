import React, { useState } from "react";

import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { addFilter, removeFilter } from "../../state/actions/filterActions";
import { isNullOrEmpty } from "../../utils/utils";

interface IPropsBase {
	placeholder: string;
}

interface IReduxProps extends IPropsBase {
	field: string;
	initalValue: string;
}

interface IProps extends IPropsBase {
	onSetFilter: (value: string) => void;
}

function isIProps(toBeDetirmined: IProps | IReduxProps): toBeDetirmined is IProps {
	return (toBeDetirmined as IProps).onSetFilter !== undefined;
}

const ToolbarFilter: React.FC<IProps | IReduxProps> = (props: IProps | IReduxProps) => {
	const [value, setValue] = useState(isIProps(props) ? "" : props.initalValue);
	const [filterApplied, setFilterApplied] = useState(isIProps(props) ? false : !isNullOrEmpty(props.initalValue));
	const reduxDispatch: Dispatch = useDispatch();

	function onKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void {
		if (event.key === "Enter") {
			onClickApplyFilter();
		}
	}

	function clearFilter(): void {
		setFilterApplied(false);
		if (isIProps(props)) {
			props.onSetFilter(null);
		} else {
			reduxDispatch(removeFilter(props.field));
		}
	}

	function onClickApplyFilter(): void {
		if (isNullOrEmpty(value)) {
			clearFilter();
		} else {
			setFilterApplied(true);
			if (isIProps(props)) {
				props.onSetFilter(value);
			} else {
				reduxDispatch(addFilter(props.field, "contains", value));
			}
		}
	}

	function onClickClearFilter(): void {
		setValue("");
		clearFilter();
	}

	return (
		<>
			<Input
				value={value}
				style={{ marginLeft: "10px" }}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
				onKeyPress={onKeyPress}
				placeholder={props.placeholder}
			/>
			<Button type="button" icon="filter" style={{ marginLeft: "4px" }} onClick={onClickApplyFilter} />
			{filterApplied && <Button type="button" icon="filter-clear" style={{ marginLeft: "0px" }} onClick={onClickClearFilter} />}
		</>
	);
};

export default ToolbarFilter;
