import { Reducer } from "react";
import { initialTeamState, ITeamState } from "..";
import Endpoint from "../../services/api/endpoint";
import { IUser } from "../../utils/types/models";
import { IAction } from "../../utils/types/types";
import { createEntityReducer } from "./entityReducer";

const teamReducer: Reducer<ITeamState, IAction> = (state: ITeamState = initialTeamState, action: IAction) => {
	switch (action.type) {
		case "REQUEST_GET_" + Endpoint.TeamsGetColleague:
			state = {
				...state,
				isColleagueLoading: true,
				colleagueError: null
			};
			break;
		case "REQUEST_SUCCESS_GET_" + Endpoint.TeamsGetColleague:
			if (action.payload && action.payload.response && action.payload.response.data) {
				const result: IUser = action.payload.response.data;
				state = {
					...state,
					colleague: result
				};
			}
			break;
		case "REQUEST_FAILURE_GET_" + Endpoint.TeamsGetColleague:
			state = {
				...state,
				colleagueError: action.payload.error
			};
			break;
		case "REQUEST_END_GET_" + Endpoint.TeamsGetColleague:
			state = {
				...state,
				isColleagueLoading: false
			};
			break;
		case "CLEAR_GET_" + Endpoint.TeamsGetColleague:
			state = {
				...state,
				colleague: null,
				isColleagueLoading: false,
				colleagueError: null
			};
			break;
		default:
			return state;
	}
	return state;
};

export default createEntityReducer(Endpoint.Teams, Endpoint.TeamsList, teamReducer, initialTeamState);