import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Endpoint from "../../../../services/api/endpoint";
import { IApplicationState } from "../../../../store";
import { Permission } from "../../../../utils/enums";
import { IProduct, IUser } from "../../../../utils/types/models";
import GridPanel from "../../../global/gridpanel";
import { customCell, dateCell } from "../../../global/gridpanel/customCells/gridCells";
import { IRoutedTabProps } from "../../../global/routertabpanel";
import StockEditor from "./editor";

interface IProps {
	product: IProduct;
}

const Stock: React.FC<IProps | IRoutedTabProps> = (props: IProps | IRoutedTabProps) => {
	const { t } = useTranslation();
	const currentUser: IUser = useSelector((applicationState: IApplicationState) => applicationState.authenticationState.currentUser);

	let listEndpoint: Endpoint = Endpoint.StockList;
	let listUrlArguments: object = { companyId: currentUser.currentCompanyId };
	let endpoint: Endpoint = Endpoint.Stock;
	let extraAddScreenProps: object;
	if ((props as IProps).product) {
		listEndpoint = Endpoint.ProductStockList;
		listUrlArguments = { productId: (props as IProps).product.id };
		endpoint = Endpoint.ProductStock;
		extraAddScreenProps = { product: (props as IProps).product };
	}

	let style: React.CSSProperties;
	if ((props as IProps).product) {
		style = { height: "400px" };
	}
	return (
		<GridPanel
			listEndpoint={listEndpoint}
			listUrlArguments={listUrlArguments}
			endpoint={endpoint}
			addScreen={StockEditor}
			extraAddScreenProps={extraAddScreenProps}
			showDelete={false}
			addPermission={Permission.StockAdd}
			style={style}
			sort={[{
				field: "createdDate",
				dir: "desc"
			}]}
		>
			<GridColumn field="count" title={t("count")} />
			{!(props as IProps).product && <GridColumn field="product.description" title={t("product")} />}
			<GridColumn field="createdBy" title={t("user")} />
			<GridColumn field="createdDate" title={t("date")} cell={customCell(dateCell())} />
		</GridPanel>
	);
};

export default Stock;