import React, { useEffect, useReducer, useRef, useState } from "react";

import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Endpoint from "../../../../services/api/endpoint";
import { getInitialState } from "../../../../state";
import { customerReducer } from "../../../../state/reducers";
import { IApplicationState } from "../../../../store";
import { handleChange } from "../../../../utils/editorUtils";
import { IAddress, ICustomer, IUser } from "../../../../utils/types/models";
import { emailRegex, isNullOrEmpty, phoneRegex } from "../../../../utils/utils";
import EntityEditor, { AddressEdit, IEditorProps, IEditScreenProps, YesNoSwitch } from "../../../global/editor";

const CustomerEditor: React.FC<IEditorProps<ICustomer>> = (props: IEditorProps<ICustomer>) => {
	const { t } = useTranslation();
	const [customerState, dispatch] = useReducer(customerReducer, getInitialState<ICustomer>());
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);
	const initalCustomer: ICustomer = {
		id: (props as IEditScreenProps<ICustomer>).recordId || 0,
		name: "",
		address: {
			street: "",
			number: "",
			postalCode: "",
			municipality: "",
			province: "",
			country: "",
			fullAddress: ""
		},
		dossierContact: "",
		phone1: "",
		email1: "",
		phone2: "",
		email2: "",
		active: true,
		companyId: currentUser.currentCompanyId
	};
	const [customer, setCustomer] = useState<ICustomer>(initalCustomer);
	const [dataChange, setDataChanged] = useState<boolean>(false);
	const firstField: React.MutableRefObject<Input> = useRef();

	useEffect(() => {
		if (customerState.entity) {
			setCustomer(customerState.entity);
		}
	}, [customerState.entity]);

	function onChange(event: React.ChangeEvent<HTMLInputElement> | SwitchChangeEvent | ComboBoxChangeEvent): void {
		setCustomer(handleChange(customer, event));
		setDataChanged(true);
	}

	function setAddress(address: IAddress): void {
		const newCustomer: ICustomer = { ...customer };
		newCustomer.address = address;
		setCustomer(newCustomer);
		setDataChanged(true);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(customer.name)) {
			messages.push(t("fill_in_required_field", { field: t("name").toLowerCase() }));
		}
		if (isNullOrEmpty(customer.address.street)) {
			messages.push(t("fill_in_required_field", { field: t("street").toLowerCase() }));
		}
		if (isNullOrEmpty(customer.address.number)) {
			messages.push(t("fill_in_required_field", { field: t("number").toLowerCase() }));
		}
		if (isNullOrEmpty(customer.address.postalCode)) {
			messages.push(t("fill_in_required_field", { field: t("postalCode").toLowerCase() }));
		}
		if (isNullOrEmpty(customer.address.municipality)) {
			messages.push(t("fill_in_required_field", { field: t("municipality").toLowerCase() }));
		}
		if (isNullOrEmpty(customer.address.country)) {
			messages.push(t("fill_in_required_field", { field: t("country").toLowerCase() }));
		}
		if (!isNullOrEmpty(customer.phone1) && !customer.phone1.match(phoneRegex)) {
			messages.push(t("fill_in_correct_format", { field: t("phone1").toLowerCase(), example: "012345678' " + t("or") + " '0476123456" }));
		}
		if (!isNullOrEmpty(customer.email1) && !customer.email1.match(emailRegex)) {
			messages.push(t("fill_in_correct_format", { field: t("email1").toLowerCase(), example: "user@test.com" }));
		}
		if (!isNullOrEmpty(customer.phone2) && !customer.phone2.match(phoneRegex)) {
			messages.push(t("fill_in_correct_format", { field: t("phone2").toLowerCase(), example: "012345678' " + t("or") + " '0476123456" }));
		}
		if (!isNullOrEmpty(customer.email2) && !customer.email2.match(emailRegex)) {
			messages.push(t("fill_in_correct_format", { field: t("email2").toLowerCase(), example: "user@test.com" }));
		}
		return messages;
	}

	const readonly: boolean = (props as IEditScreenProps<ICustomer>).readonly;
	return (
		<EntityEditor
			width="70%"
			record={customer}
			endpoint={Endpoint.Customers}
			entityState={customerState}
			dispatch={dispatch}
			dataChanged={dataChange}
			readonly={readonly}
			recordName={customer.name}
			entityType={t("customer")}
			close={props.close}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<div className="k-form">
				<label className="k-form-field">
					<span>{t("name")} *</span>
					<Input name="name" ref={firstField} className="full-width-field" value={customer.name} onChange={onChange} required disabled={readonly} />
				</label>
				<label className="k-form-field">
					<span>{t("vatNumber")}</span>
					<Input name="vatNumber" className="full-width-field" value={customer.vatNumber} onChange={onChange} disabled={readonly} />
				</label>
				<AddressEdit address={customer.address} addressField="address" onChange={onChange} setAddress={setAddress} required readonly={readonly} />
				<fieldset>
					<legend>{t("contact")}</legend>
					<div className="row">
						<div className="col">
							<label className="k-form-field">
								<span>{t("dossierContact")} *</span>
								<Input name="dossierContact" className="full-width-field" value={customer.dossierContact} onChange={onChange} required disabled={readonly} />
							</label>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<label className="k-form-field">
								<span>{t("phone1")}</span>
								<Input name="phone1" className="full-width-field" value={customer.phone1} onChange={onChange} pattern={phoneRegex.source} disabled={readonly} />
							</label>
						</div>
						<div className="col">
							<label className="k-form-field">
								<span>{t("email1")}</span>
								<Input name="email1" type="email" className="full-width-field" value={customer.email1} onChange={onChange} disabled={readonly} />
							</label>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<label className="k-form-field">
								<span>{t("phone2")}</span>
								<Input name="phone2" className="full-width-field" value={customer.phone2} onChange={onChange} pattern={phoneRegex.source} disabled={readonly} />
							</label>
						</div>
						<div className="col">
							<label className="k-form-field">
								<span>{t("email2")}</span>
								<Input name="email2" type="email" className="full-width-field" value={customer.email2} onChange={onChange} disabled={readonly} />
							</label>
						</div>
					</div>
				</fieldset>
				{!props.hideActive && (
					<div className="k-form-field">
						<span>{t("active")}</span>
						<YesNoSwitch name="active" checked={customer.active} onChange={onChange} disabled={readonly} />
					</div>
				)}
			</div>
		</EntityEditor>
	);
};

export default CustomerEditor;
