import React from "react";

import { useTranslation } from "react-i18next";

import { Permission } from "../../../utils/enums";
import RoutedTabPanel from "../../global/routertabpanel";
import Brands from "./brands";
import Products from "./products";
import ProductTypes from "./producttypes";
import Stock from "./stock";
import Suppliers from "./suppliers";

import content from "../../../assets/kitchen.png";

const Gamma: React.FC = () => {
	const { t } = useTranslation();

	return (
		<RoutedTabPanel title={t("gamma")} image={content}>
			<Suppliers reactKey="suppliers" label={t("suppliers")} url="/suppliers" tileIcon="las la-shipping-fast" permissions={[Permission.SuppliersRead]} />
			<Products reactKey="products" label={t("products")} url="/products" tileIcon="las la-box" permissions={[Permission.ProductsRead]} />
			<Stock reactKey="stock" label={t("stock")} url="/stock" tileIcon="las la-dolly" permissions={[Permission.StockRead]} />
			<Brands reactKey="brands" label={t("brands")} url="/brands" tileIcon="las la-tag" permissions={[Permission.BrandsRead]} />
			<ProductTypes reactKey="producttypes" label={t("productTypes")} url="/producttypes" tileIcon="las la-grip-horizontal" permissions={[Permission.ProductTypesRead]} />
		</RoutedTabPanel>
	);
};

export default Gamma;