import React, { useEffect, useReducer, useRef, useState } from "react";

import { Input, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import camelCase from "lodash/camelCase";
import { useTranslation } from "react-i18next";

import editorStyles from "../../../../assets/editor.module.scss";
import callApi from "../../../../services/api/callApi";
import Endpoint from "../../../../services/api/endpoint";
import { getInitialState, initialPermissionState } from "../../../../state";
import { permissionReducer, roleReducer } from "../../../../state/reducers";
import { handleChange } from "../../../../utils/editorUtils";
import { IRole } from "../../../../utils/types/models";
import { isNullOrEmpty } from "../../../../utils/utils";
import EntityEditor, { IEditorProps, IEditScreenProps, YesNoSwitch } from "../../../global/editor";

import roleEditorStyles from "./editor.module.scss";

const RoleEditor: React.FC<IEditorProps<IRole>> = (props: IEditorProps<IRole>) => {
	const { t } = useTranslation();
	const [roleState, roleDispatch] = useReducer(roleReducer, getInitialState<IRole>());
	const [permissionState, permissionDispatch] = useReducer(permissionReducer, initialPermissionState);
	const [role, setRole] = useState<IRole>({
		id: (props as IEditScreenProps<IRole>).recordId || 0,
		name: "",
		active: true,
		permissions: {}
	});
	const [dataChange, setDataChanged] = useState<boolean>(false);
	const firstField: React.MutableRefObject<Input> = useRef();

	useEffect(() => {
		callApi(permissionDispatch, Endpoint.Permissions, "GET");
	}, []);

	useEffect(() => {
		if (roleState.entity) {
			setRole(roleState.entity);
		}
	}, [roleState.entity]);

	function onChange(event: React.ChangeEvent<HTMLInputElement> | SwitchChangeEvent): void {
		setRole(handleChange(role, event));
		setDataChanged(true);
	}

	function onPermissionChange(event: React.ChangeEvent<HTMLInputElement>): void {
		const roleRecord: IRole = {
			...role,
			permissions: { ...role.permissions }
		};
		roleRecord.permissions[camelCase(event.target.id)] = event.target.checked;
		setRole(roleRecord);
		setDataChanged(true);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(role.name)) {
			messages.push(t("fill_in_required_field", { field: t("name").toLowerCase() }));
		}
		if (!role.permissions || Object.keys(role.permissions).length <= 0) {
			messages.push(t("select_at_least_one", { entity: t("permission").toLowerCase() }));
		}
		return messages;
	}

	const readonly: boolean = (props as IEditScreenProps<IRole>).readonly;

	return (
		<EntityEditor
			width="70%"
			record={role}
			endpoint={Endpoint.Roles}
			entityState={roleState}
			entityType={t("role")}
			dispatch={roleDispatch}
			dataChanged={dataChange}
			readonly={readonly}
			recordName={role.name}
			close={props.close}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<div className="k-form">
				<div className={"row " + roleEditorStyles.rolesRow}>
					<div className="col-md-12">
						<label className="k-form-field">
							<span>{t("name")}</span>
							<Input name="name" ref={firstField} value={role.name} onChange={onChange} disabled={readonly} required />
						</label>
						<div className="k-form-field">
							<span>{t("active")}</span>
							<YesNoSwitch name="active" checked={role.active} onChange={onChange} disabled={readonly} />
						</div>
					</div>
				</div>
				<div className={"row " + roleEditorStyles.rolesRow}>
					<div className="col-md-12">
						{Object.keys(permissionState.permissions).map((group: string) => {
							return (
								<div key={group}>
									<div className={roleEditorStyles.rolesGrouptitle}>{t(group)}</div>
									<div className={roleEditorStyles.rolesItems}>
										{Object.keys(permissionState.permissions[group]).map((subGroup: string) => {
											return (
												<div className={"row " + editorStyles.switchRow + " " + roleEditorStyles.rolesRow} key={subGroup}>
													<div className="col-md-2">
														<strong>{t(subGroup)}</strong>
													</div>
													{permissionState.permissions[group][subGroup].map((permission: string) => {
														const smallPermission: string = camelCase(permission);
														const checked: boolean = role && role.permissions && role.permissions[smallPermission] ? role.permissions[smallPermission] : false;
														return (
															<div className="col-md-2" key={permission}>
																<label className="k-form-field">
																	<input
																		type="checkbox"
																		id={permission}
																		checked={checked}
																		name={permission}
																		className="k-checkbox"
																		onChange={onPermissionChange}
																		disabled={readonly}
																	/>
																	<label className="k-checkbox-label" htmlFor={permission}>
																		{t("permissions." + permission)}
																	</label>
																</label>
															</div>
														);
													})}
												</div>
											);
										})}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</EntityEditor>
	);
};

export default RoleEditor;
