import { Reducer } from "react";

import { initialPermissionState, IPermssionState } from "..";
import { IAction } from "../../utils/types/types";

const permissionReducer: Reducer<IPermssionState, IAction> = (state: IPermssionState = initialPermissionState, action: IAction) => {
	switch (action.type) {
		case "REQUEST_GET_PERMISSIONS":
			state = {
				...state,
				loading: true
			};
			break;
		case "REQUEST_SUCCESS_GET_PERMISSIONS":
			if (action.payload && action.payload.response && action.payload.response.data) {
				state = {
					...state,
					permissions: action.payload.response.data || []
				};
			}
			break;
		case "REQUEST_FAILURE_GET_PERMISSIONS":
			state = {
				...state,
				loading: false
			};
			break;
		case "REQUEST_END_GET_PERMISSIONS":
			state = {
				...state,
				loading: false
			};
			break;
		default:
			return state;
	}
	return state;
};

export default permissionReducer;