import React from "react";

import { Grid, GridColumn, GridDetailRowProps } from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";
import { Dispatch } from "redux";

import { useDispatch } from "react-redux";
import { derender, render } from "../../../../../../state/actions/renderActions";
import { IArticleDelivery } from "../../../../../../utils/types/models";
import { newKey } from "../../../../../../utils/utils";
import Confirm from "../../../../../global/confirm";
import { getGridCommandColumn } from "../../../../../global/gridpanel";
import { customCell, dateCell } from "../../../../../global/gridpanel/customCells/gridCells";
import { IFunctionCommand, IRecordCommand } from "../../../../../global/gridpanel/customCells/gridCells/commandCell";
import TabPanel, { Tab } from "../../../../../global/tabpanel";

function getArticleSubRecords(showDeliveries: boolean, showPickings: boolean, readOnly: boolean, deleteSubArticle: (confirmKey: string, subArticle: IArticleDelivery)
	=> void): React.FC<GridDetailRowProps> {
	return (props: GridDetailRowProps) => {

		const reduxDispatch: Dispatch = useDispatch();
		const { t } = useTranslation();

		const commands: (IFunctionCommand<IArticleDelivery> | IRecordCommand<IArticleDelivery>)[] = [];

		if (!readOnly) {
			commands.push(
				{
					tooltip: t("remove"),
					iconClass: "las la-times",
					recordAction: (record: IArticleDelivery) => {

						const confirmKey: string = newKey("confirm_remove");

						reduxDispatch(
							render(confirmKey, Confirm, {
								title: t("confirm_title", { action: t("remove").toLowerCase() }),
								children: t("confirm_content", { action: t("remove").toLowerCase() }),
								onDecline: () => reduxDispatch(derender(confirmKey)),
								onConfirm: () => deleteSubArticle(confirmKey, record)
							})
						);
					}
				});
		}

		return (
			<TabPanel tabBarStyle={{ borderTop: "1px solid #ddd", borderLeft: "1px solid #ddd", borderRight: "1px solid #ddd" }} tabStyle={{ marginTop: "0" }}>
				{showDeliveries && (
					<Tab reactKey="deliveries" label={t("deliveries")} >
						<Grid className="h-100" data={props.dataItem.deliveries}>
							{getGridCommandColumn(t("actions"), commands)}
							<GridColumn field="count" title={t("count")} />
							<GridColumn field="createdDate" title={t("date")} cell={customCell(dateCell())} />
							<GridColumn field="createdBy" title={t("user")} />
						</Grid>
					</Tab>
				)}
				{showPickings && (
					<Tab reactKey="pickings" label={t("pickings")}>
						<Grid className="h-100" data={props.dataItem.pickings}>
							<GridColumn field="count" title={t("count")} />
							<GridColumn field="createdDate" title={t("date")} cell={customCell(dateCell())} />
							<GridColumn field="createdBy" title={t("user")} />
						</Grid>
					</Tab>
				)}
			</TabPanel>
		);
	};
}

export default getArticleSubRecords;