import { SelectionRange } from "@progress/kendo-react-dateinputs";
import { IntlService } from "@progress/kendo-react-intl";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import each from "lodash/each";
import includes from "lodash/includes";
import max from "lodash/max";

import { ArticleGroup } from "./enums";
import { IArticle, IArticleDelivery, IArticlePicking } from "./types/models";

dayjs.extend(isoWeek);

// tslint:disable-next-line: no-any
function stringFormat(str: string, ...arg: any[]): string {
	// tslint:disable-next-line: no-any
	const args: any[] = [].slice.call(arguments, 1);
	// tslint:disable-next-line: no-any
	return str.replace(/{(\d+)}/g, (m: string, i: any) => {
		return args[i] !== undefined ? args[i] : m;
	});
}

// tslint:disable-next-line: no-any
function parseUrlParams(urlParams: any): string {
	// tslint:disable-next-line: no-any
	const joinByEquals: (pair: any) => void = (pair: any) => {
		if (pair[1] !== null && pair[1] !== undefined) {
			let result: string = pair[0] + "=";
			if (typeof pair[1] === "object") {
				result += JSON.stringify(pair[1]);
			} else {
				result += pair[1];
			}
			return result;
		}
		return "";
	};
	const params: string = Object.entries(urlParams).map(joinByEquals).join("&");
	if (params) {
		return `?${params}`;
	} else {
		return "";
	}
}

// tslint:disable-next-line: no-any
function urlFormat(url: any, parameters: any): string {
	let href: string = typeof url === "object" ? url.href : url;
	const urlParameterRegex: RegExp = /\/:([^/]*)/;
	let match: RegExpExecArray | null = null;

	parameters = parameters || {};

	match = urlParameterRegex.exec(href);
	while (match) {
		const parameter: string = match[1];
		// tslint:disable-next-line: no-any
		const parameterValue: any = parameters[parameter] !== null && parameters[parameter] !== undefined ? parameters[parameter] : "";
		href = href.replace(":" + parameter, parameterValue);
		delete parameters[parameter];
		match = urlParameterRegex.exec(href);
	}

	if (Object.keys(parameters).length > 0) {
		href += decodeURIComponent(parseUrlParams(parameters));
	}

	return href;
}

function newKey(prefix?: string): string {
	let key: string = "";
	if (prefix) {
		key = prefix + "_";
	}
	for (let i: number = 0; i < 5; i++) {
		key += Math.random().toString(36).substring(7);
	}

	return key;
}

function isNullOrEmpty(str: string): boolean {
	return !str || str.match(/^\s*$/) !== null;
}

const emailRegex: RegExp = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const phoneRegex: RegExp = /^0[1-9][0-9]{7,8}$/;

const dossierNumberRegex: RegExp = /^[0-9a-zA-Z_]+$/;

function dateToString(date: Date, intl: IntlService, dateFormat: string = "d"): string {
	if (date) {
		return intl.formatDate(new Date(date), dateFormat);
		// return new Date(date).toLocaleDateString(locale, { year: "numeric", month: "2-digit", day: "2-digit" });
	}
	return "";
}

function dateToStringDayJs(date: Date, intl: IntlService): string {
	if (date) {
		return intl.formatDate(new Date(date), "yyyy-MM-dd");
	}
	return "";
}

function getWeekRange(value: Date, intl: IntlService): SelectionRange {
	dayjs.Ls.en.weekStart = intl.firstDay();
	const start: Date = dayjs(value).startOf("week").toDate();
	const end: Date = dayjs(value).endOf("week").toDate();
	start.setHours(0, 0, 0, 0);
	end.setHours(0, 0, 0, 0);
	return {
		start,
		end
	};
}

function getWeekstring(week: SelectionRange): string {
	let result: string = "";
	if (week) {
		const date: Date = week.start;
		const weekNo: number = dayjs(date).isoWeek();
		let year: number = date.getFullYear();
		if ((weekNo === 52 || weekNo === 53) && week.start.getFullYear() !== week.end.getFullYear()) {
			year = week.start.getFullYear();
		}
		if (weekNo === 1 && week.start.getFullYear() !== week.end.getFullYear()) {
			year = week.end.getFullYear();
		}
		result = weekNo + "-" + year;
	}
	return result;
}

function getDeliveryOrPickingsRepresentation(count: number, data: IArticleDelivery[] | IArticlePicking[], intl: IntlService): string {
	let total: number = 0;
	let maxDate: Date;
	const format = intl.locale === "nl" ? "dd-MM-yyyy" : "M/d/yyyy";
	each(data, (record: IArticleDelivery | IArticlePicking) => {
		total += record.count;
		maxDate = maxDate ? record.createdDate : max([maxDate, record.createdDate]);
	});
	let content: string = total + "/" + count;
	if (maxDate) {
		content += " - " + dateToString(maxDate, intl, format);
	}
	return content;
}

function isArticleForPurchaseOrder(article: IArticle): boolean {
	return includes([ArticleGroup.Appliance, ArticleGroup.Plumbing, ArticleGroup.Accessory], article.group);
}

export {
	stringFormat,
	urlFormat,
	newKey,
	isNullOrEmpty,
	emailRegex,
	phoneRegex,
	dossierNumberRegex,
	dateToString,
	getDeliveryOrPickingsRepresentation,
	getWeekRange,
	getWeekstring,
	isArticleForPurchaseOrder,
	dateToStringDayJs
};
