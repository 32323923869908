import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Endpoint from "../../../../services/api/endpoint";
import { IApplicationState } from "../../../../store";
import { Permission } from "../../../../utils/enums";
import { IUser } from "../../../../utils/types/models";
import GridPanel from "../../../global/gridpanel";
import { checkBoxCell, customCell } from "../../../global/gridpanel/customCells/gridCells";
import { IRoutedTabProps } from "../../../global/routertabpanel";
import ProductEditor from "./editor";

const Products: React.FC<IRoutedTabProps> = () => {
	const { t } = useTranslation();
	const currentUser: IUser = useSelector((applicationState: IApplicationState) => applicationState.authenticationState.currentUser);

	return (
		<React.Fragment>
			<GridPanel
				listEndpoint={Endpoint.ProductsList}
				endpoint={Endpoint.Products}
				listUrlArguments={{ companyId: currentUser.currentCompanyId }}
				addScreen={ProductEditor}
				editScreen={ProductEditor}
				showDelete
				addPermission={Permission.ProductsAdd}
				editPermission={Permission.ProductsUpdate}
				deletePermission={Permission.ProductsDelete}
				filter={{
					logic: "and",
					filters: [
						{
							field: "active",
							operator: "eq",
							value: true
						}
					]
				}}
			>
				<GridColumn field="modelNumber" title={t("modelNumber")} />
				<GridColumn field="supplier.name" title={t("supplier")} />
				<GridColumn field="productType.name" title={t("type")} />
				<GridColumn field="description" title={t("description")} />
				<GridColumn field="salesPriceExclVat" title={t("salesPriceExclVat")} format="{0:n2}" />
				<GridColumn field="active" title={t("active")} filter="boolean" width="160px" cell={customCell(checkBoxCell(false))} />
			</GridPanel>
		</React.Fragment>
	);
};

export default Products;