import React, { useEffect, useReducer, useState } from "react";

import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ComboBoxCloseEvent, ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import callApi from "../../../../services/api/callApi";
import Endpoint from "../../../../services/api/endpoint";
import { notify } from "../../../../services/notification";
import { getInitialState, initialProductState } from "../../../../state";
import { productReducer, productStockReducer, stockReducer } from "../../../../state/reducers";
import { IApplicationState } from "../../../../store";
import { handleChange, setEntity } from "../../../../utils/editorUtils";
import { IProduct, IStockLine, IUser } from "../../../../utils/types/models";
import { IAddscreenProps, NumericInput, SearchBox } from "../../../global/editor";
import Messages from "../../../global/messages";

interface IProps {
	product?: IProduct;
}

type Props = IAddscreenProps<IStockLine> & IProps;

const StockEditor: React.FC<Props> = (props: Props) => {
	const { t } = useTranslation();
	const { close, product } = props;
	const [productState, productDispatch] = useReducer(productReducer, initialProductState);
	const [stockState, stockDispatch] = useReducer(product ? productStockReducer : stockReducer, getInitialState<IStockLine>());
	const [stockLine, setStockLine] = useState<IStockLine>({
		id: 0,
		count: null,
		productId: product?.id,
		product
	});
	const [dataChanged, setDataChanged] = useState(false);
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);

	useEffect(() => {
		if (!stockState.isAdding && stockState.addedEntity) {
			close(stockState.addedEntity);
		}
	}, [stockState.isAdding, stockState.addedEntity, close]);

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		callApi(productDispatch, Endpoint.Products, "GET", { search: event.filter.value, companyId: currentUser.currentCompanyId });
	}

	function onChange(event: ComboBoxCloseEvent | NumericTextBoxChangeEvent): void {
		setStockLine(handleChange(stockLine, event));
		setDataChanged(true);
	}

	function save(): void {
		const messages: string[] = [];
		if (stockLine.count === 0) {
			messages.push(t("fill_in_actual_number", { field: t("count").toLowerCase() }));
		} else if (!stockLine.count) {
			messages.push(t("fill_in_required_field", { field: t("count").toLowerCase() }));
		}
		if (!product && !stockLine.productId) {
			messages.push(t("fill_in_required_field", { field: t("product").toLowerCase() }));
		}
		if (messages.length <= 0) {
			if (product) {
				callApi(stockDispatch, Endpoint.ProductStock, "POST", { productId: product.id }, stockLine);
			} else {
				callApi(stockDispatch, Endpoint.Stock, "POST", null, stockLine);
			}
		} else {
			notify(t("error"), <Messages messages={messages} />, "error");
		}
	}

	return (
		<Dialog width="50%" title={t("add") + " " + t("stockMovement")} onClose={() => close()}>
			<div className="k-form">
				<label className="k-form-field">
					<span>{t("count")} *</span>
					<NumericInput className="full-width-field" name="count" format={{ maximumFractionDigits: 0 }} onChange={onChange} required />
				</label>
				{!product && (
					<label className="k-form-field">
						<span>{t("product")} *</span>
						<SearchBox
							name="productId"
							entities={productState.entities}
							entityId={stockLine.productId}
							entity={stockLine.product}
							textField="modelNumber"
							onFilterChange={onFilterChange}
							onClose={onChange}
							onClear={() => {
								setStockLine(setEntity(stockLine, null, "product", "productId"));
								setDataChanged(true);
							}}
							required
						/>
					</label>
				)}
			</div>
			<DialogActionsBar>
				<Button onClick={() => close()}>{t("close")}</Button>
				<Button primary disabled={!dataChanged} onClick={() => save()}>
					{t("save")}
				</Button>
			</DialogActionsBar>
		</Dialog>
	);
};

export default StockEditor;
