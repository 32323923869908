import React from "react";

import style from "./imageGalleryModal.module.scss";

interface IProps {
	clickClose: (e: React.MouseEvent) => void;
	clickNext: (e: React.MouseEvent) => void;
	clickPrevious: (e: React.MouseEvent) => void;
	first: boolean;
	last: boolean;
	src: string;
}

const ImageGalleryModal: React.FC<IProps> = (props: IProps) => {
	return (
		<React.Fragment>
			<div className={style.overlay} onClick={props.clickClose} />
			<div className={style.modal + " d-flex align-items-center justify-content-center"}>
				<div className={style.body}>
					<div className={style.button + " " + style.close} onClick={props.clickClose}>
						<i className="las la-times" />
					</div>
					{!props.first && (
						<div className={style.button + " " + style.prev} onClick={props.clickPrevious}>
							<i className="las la-angle-left" />
						</div>
					)}
					{!props.last && (
						<div className={style.button + " " + style.next} onClick={props.clickNext}>
							<i className="las la-angle-right" />
						</div>
					)}
					<img src={props.src} alt={props.src} />
				</div>
			</div>
		</React.Fragment>
	);
};

export default ImageGalleryModal;