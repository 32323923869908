import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import Loader from "./components/global/loader";
import Login from "./components/login";
import LoginError from "./components/login/loginError";
import Portal from "./components/portal";
import Endpoint from "./services/api/endpoint";
import { authContext } from "./services/authentication";
import { IAuthenticationState } from "./state";
import { checkLoggedInUser } from "./state/actions/authActions";
import { IApplicationState } from "./store";
import API from "./store/actions/apiActions";

import "bootstrap-4-grid/css/grid.min.css";
import "./assets/kendo.scss";
import "./assets/main.scss";

const App: React.FC = () => {
	const authenticationState: IAuthenticationState = useSelector((state: IApplicationState) => state.authenticationState);
	const dispatch: Dispatch = useDispatch();

	useEffect(() => {
		dispatch(checkLoggedInUser());
	}, [dispatch]);

	useEffect(() => {
		if (authenticationState.authenticated) {
			dispatch(API.getActions()[Endpoint.CurrentUser]("GET"));
		}
	}, [dispatch, authenticationState.authenticated]);

	if (authenticationState.authenticated && authenticationState.currentUser) {
		return <Portal />;
	} else if (authenticationState.authenticated && authenticationState.loadingUser) {
		return <Loader />;
	} else if (authenticationState.loginError) {
		return <LoginError />;
	} else {
		return <Login login={() => authContext().login()} />;
	}
};

export default App;
