import React from "react";

import { GridFilterCellProps } from "@progress/kendo-react-grid";

import WeekPicker, { WeekPickerChangeEvent } from "../../../editor/weekPicker";

export function weekPickerFilterCell(): React.FC<GridFilterCellProps> {
	return (props: GridFilterCellProps) => {

		function onChange(event: WeekPickerChangeEvent): void {
			const filterDate: Date = event.value.start;
			props.onChange({
				value: new Date(filterDate.getTime() - (filterDate.getTimezoneOffset() * 60000)),
				operator: "eq",
				syntheticEvent: event.syntheticEvent
			});
		}

		function onClearButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
			event.preventDefault();
			props.onChange({
				value: null,
				operator: "",
				syntheticEvent: event
			});
		}

		return (
			<div className="k-filtercell">
				<WeekPicker
					name="filterWeek"
					value={props.value}
					onChange={onChange}
					className="full-width-field"
				/>
				<div className="k-filtercell-operator">
					<button
						className={"k-button k-button-icon" + (props.value && " k-clear-button-visible")}
						title="Clear"
						type="button"
						onClick={onClearButtonClick}
					>
						<span className="k-icon k-i-filter-clear" />
					</button>
				</div>
			</div>
		);
	};
}

export default weekPickerFilterCell;