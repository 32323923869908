import { IAction } from "../../utils/types/types";

export function showLoader(): IAction {
	return {
		type: "SHOW_LOADER"
	};
}

export function hideLoader(): IAction {
	return {
		type: "HIDE_LOADER"
	};
}
