import React, { useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { IArticle, IRemark } from "../../../../../../utils/types/models";
import PageEditor from "../pageEditor";
import RemarkEditorContent, { IRemarkEditorContentRef } from "./editorContent";

interface IProps {
	record?: IRemark;
	articles: IArticle[];
	onClose: (record?: IRemark) => void;
	readOnly: boolean;
}

const RemarkEditor: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const [dataChanged, setDataChanged] = useState(false);
	const [remark, setRemark] = useState<IRemark>(props.record);
	const contentRef: React.MutableRefObject<IRemarkEditorContentRef> = useRef<IRemarkEditorContentRef>(null);

	function updateRemark(newRemark: IRemark): void {
		setRemark(newRemark);
		setDataChanged(true);
	}

	const action: string = props.record ? t("edit") : t("add");
	return (
		<PageEditor
			width="70%"
			title={`${action} ${t("remark")}`.trim()}
			close={props.onClose}
			dataChanged={dataChanged}
			getErrorMessages={contentRef.current?.getErrorMessages}
			action={action}
			record={remark}
			readOnly={props.readOnly}
		>
			<RemarkEditorContent ref={contentRef} remark={remark} articles={props.articles} onRemarkChange={updateRemark} />
		</PageEditor>
	);
};

export default RemarkEditor; 