import { Reducer } from "react";

import { IInvoicingState, initialInvoicingState } from "..";
import Endpoint from "../../services/api/endpoint";
import { IAction } from "../../utils/types/types";

const invoicingReducer: Reducer<IInvoicingState, IAction> = (state: IInvoicingState = initialInvoicingState, action: IAction) => {
	switch (action.type) {
		case "REQUEST_GET_" + Endpoint.Invoicing:
			state = {
				...state,
				isLoading: true,
				loadError: null
			};
			break;
		case "REQUEST_SUCCESS_GET_" + Endpoint.Invoicing:
			if (action.payload && action.payload.response && action.payload.response.data) {
				state = {
					...state,
					entities: action.payload.response.data
				};
			}
			break;
		case "REQUEST_FAILURE_GET_" + Endpoint.Invoicing:
			state = {
				...state,
				loadError: action.payload.error
			};
			break;
		case "REQUEST_END_GET_" + Endpoint.Invoicing:
			state = {
				...state,
				isLoading: false
			};
			break;
		case "CLEAR_GET_" + Endpoint.Invoicing:
			state = {
				...state,
				entities: initialInvoicingState.entities,
				isLoading: initialInvoicingState.isLoading,
				loadError: initialInvoicingState.loadError
			};
			break;
		default:
			return state;
	}
	return state;
};

export default invoicingReducer;