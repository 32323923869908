import currencyData from "cldr-core/supplemental/currencyData.json";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import weekData from "cldr-core/supplemental/weekData.json";
import caGregorian from "cldr-dates-full/main/nl/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/nl/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/nl/timeZoneNames.json";
import numbers from "cldr-numbers-full/main/nl/numbers.json";

import React, { useEffect } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import { IntlProvider, load, loadMessages, LocalizationProvider } from "@progress/kendo-react-intl";
import { useTranslation } from "react-i18next";
import Notifications, { NotificationsState } from "react-notification-system-redux";
import { useSelector } from "react-redux";

import { ILanguageConfiguration, languages } from "../../services/translation/languages";
import { IAuthenticationState, ILoaderState, IRenderConfig, IRenderState } from "../../state";
import { IApplicationState } from "../../store";
import { Permission } from "../../utils/enums";
import Loader from "../global/loader";
import ProtectedRoute from "../global/protectedRoute";
import Communication from "../pages/communication";
import Gamma from "../pages/gamma";
import Help from "../pages/help";
import Home from "../pages/home";
import MasterData from "../pages/masterdata";
import MyProfile from "../pages/myprofile";
import Privacy from "../pages/privacy";
import Security from "../pages/security";
import Settings from "../pages/settings";
import Work from "../pages/work";
import DossierEditor from "../pages/work/dossiermanagement/editor";
import Menu, { IMenuItem } from "./menu";

import "bootstrap-4-grid/css/grid.min.css";
import style from "./portal.module.scss";

load(
	likelySubtags,
	currencyData,
	weekData,
	numbers,
	caGregorian,
	dateFields,
	timeZoneNames
);

const App: React.FC = () => {
	const { t, i18n } = useTranslation();
	const authenticationState: IAuthenticationState = useSelector((state: IApplicationState) => state.authenticationState);
	const loaderState: ILoaderState = useSelector((state: IApplicationState) => state.loaderState);
	const renderState: IRenderState = useSelector((state: IApplicationState) => state.renderState);
	const notificationState: NotificationsState = useSelector((state: IApplicationState) => state.notificationState);

	useEffect(() => {
		languages.forEach((language: ILanguageConfiguration) => {
			loadMessages(language.translations, language.code);
		});
	}, []);

	if (i18n.language !== authenticationState.currentUser.language) {
		i18n.changeLanguage(authenticationState.currentUser.language);
	}

	const gammaReadPermissions: Permission[] = [Permission.SuppliersRead, Permission.ProductsRead, Permission.StockRead, Permission.BrandsRead, Permission.ProductTypesRead];
	const workReadPermissions: Permission[] = [Permission.DossiersRead, Permission.ProcurementView, Permission.WarehouseView, Permission.PlanningView, Permission.InvoicingView];
	const dossierViewEditPermissions: Permission[] = [Permission.DossiersRead, Permission.DossiersUpdate];
	const masterDataReadPermissions: Permission[] = [Permission.CustomersRead, Permission.ContractorsRead, Permission.ConstructionYardsRead, Permission.ShopsRead, Permission.ProjectsRead];
	const communicationReadPermissions: Permission[] = [Permission.ContactTypesRead, Permission.CommunicationTemplatesRead];
	const securityReadPermissions: Permission[] = [Permission.UsersRead, Permission.RolesRead];

	const menuItems: IMenuItem[] = [
		{ key: "home", iconClass: "las la-home", label: t("home"), url: "/", exact: true },
		{ key: "work", iconClass: "las la-clipboard-list", label: t("work"), url: "/work", exact: false, permissions: workReadPermissions },
		{ key: "gamma", iconClass: "las la-shopping-cart", label: t("gamma"), url: "/gamma", exact: false, permissions: gammaReadPermissions },
		{ key: "masterdata", iconClass: "las la-book-reader", label: t("masterData"), url: "/masterdata", exact: false, permissions: masterDataReadPermissions },
		{ key: "communication", iconClass: "las la-envelope-open-text", label: t("communication"), url: "/communication", exact: false, permissions: communicationReadPermissions },
		{ key: "security", iconClass: "las la-shield-alt", label: t("security"), url: "/security", exact: false, permissions: securityReadPermissions },
		{ key: "settings", iconClass: "las la-sliders-h", label: t("settings"), url: "/settings", exact: true }
	];

	return (
		<Router >
			<LocalizationProvider language={i18n.language}>
				<IntlProvider locale={i18n.language}>
					<div className="bootstrap-wrapper">
						<div className={style.companyName}>{authenticationState.currentUser.currentCompany.name}</div>
						{loaderState.showLoader && <Loader />}
						{renderState.components.map((config: IRenderConfig) => <config.component key={config.key} {...config.props} />)}
						<Menu menuItems={menuItems} />
						<div className={style.bodyContainer}>
							<Switch>
								<ProtectedRoute permissions={[Permission.Home]} exact path="/" component={Home} />
								<ProtectedRoute permissions={[Permission.DossiersAdd]} path="/work/dossiermanagement/new" component={DossierEditor} />
								<ProtectedRoute permissions={dossierViewEditPermissions} path="/work/dossiermanagement/:id" component={DossierEditor} />
								<ProtectedRoute permissions={workReadPermissions} path="/work" component={Work} />
								<ProtectedRoute permissions={gammaReadPermissions} path="/gamma" component={Gamma} />
								<ProtectedRoute permissions={masterDataReadPermissions} path="/masterdata" component={MasterData} />
								<ProtectedRoute permissions={communicationReadPermissions} path="/communication" component={Communication} />
								<ProtectedRoute permissions={securityReadPermissions} path="/security" component={Security} />
								<ProtectedRoute permissions={[Permission.Settings]} path="/settings" component={Settings} />
								<Route path="/myprofile" component={MyProfile} />
								<Route path="/privacy" component={Privacy} />
								<Route path="/help" component={Help} />
							</Switch>
						</div>
						<Notifications
							notifications={notificationState}
							style={{
								Containers: {
									DefaultStyle: {
										width: "auto",
										padding: "0 10px 20px"
									}
								},
								Title: {
									DefaultStyle: { fontSize: "18px" }
								},
								NotificationItem: {
									DefaultStyle: { fontSize: "17px" }
								}
							}}
						/>
					</div>
				</IntlProvider>
			</LocalizationProvider>
		</Router>
	);
};

export default App;
