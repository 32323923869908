import React, { useEffect, useState } from "react";

import { AggregateDescriptor, DataResult, GroupResult, process, State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent, GridExpandChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import { GridRowDoubleClickEvent } from "@progress/kendo-react-grid/dist/npm/interfaces/events";
import { IntlService, useInternationalization } from "@progress/kendo-react-intl";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import find from "lodash/find";
import orderBy from "lodash/orderBy";
import remove from "lodash/remove";
import some from "lodash/some";
import sumBy from "lodash/sumBy";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { hasPermission } from "../../../../../../services/authentication";
import { printLabels } from "../../../../../../services/labelPrinting";
import { derender, render } from "../../../../../../state/actions/renderActions";
import { isProcurementArticle } from "../../../../../../utils/editorUtils";
import { ArticleGroup, Permission, RelatedToArticlePhase, RemarkType, VatType } from "../../../../../../utils/enums";
import { IArticle, IArticleDelivery, IArticlePicking, IDossier } from "../../../../../../utils/types/models";
import { dateToString, getDeliveryOrPickingsRepresentation, isNullOrEmpty, newKey } from "../../../../../../utils/utils";
import Confirm from "../../../../../global/confirm";
import { getGridCommandColumn } from "../../../../../global/gridpanel";
import { IFunctionCommand, IRecordCommand } from "../../../../../global/gridpanel/customCells/gridCells/commandCell";
import { IDossierSectionProps } from "../../editor";
import getArticleSubRecords from "./articleSubRecords";
import ArticleEditor from "./editor";

import style from "../editor.module.scss";

interface IProps extends IDossierSectionProps {
	stockChange: (count: number, productId: number) => void;
}

function sortArticleGroups(articles: IArticle[], gridState: State): DataResult {
	const groupedArticles: DataResult = process(articles, gridState);
	const data: GroupResult[] = [...groupedArticles.data];
	groupedArticles.data = orderBy(data, [
		(item: GroupResult) => {
			if (item.value === ArticleGroup.Furniture) {
				return 0;
			} else if (item.value === ArticleGroup.Worktops) {
				return 1;
			} else if (item.value === ArticleGroup.Appliance) {
				return 2;
			} else if (item.value === ArticleGroup.Plumbing) {
				return 3;
			} else if (item.value === ArticleGroup.Accessory) {
				return 4;
			} else if (item.value === ArticleGroup.Other) {
				return 5;
			}
		}
	]);
	return groupedArticles;
}

const Articles: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const intl: IntlService = useInternationalization();
	const aggregates: AggregateDescriptor[] = [
		{
			aggregate: "sum",
			field: "count"
		},
		{
			aggregate: "sum",
			field: "salesPriceInclVat"
		},
		{
			aggregate: "sum",
			field: "salesPriceExclVat"
		},
		{
			aggregate: "sum",
			field: "vat"
		},
		{
			aggregate: "sum",
			field: "lineTotalIncl"
		}
	];
	const [gridState, setGridState] = useState<State>({
		sort: [
			{
				field: "description",
				dir: "asc"
			}
		],
		group: [
			{
				field: "group",
				aggregates
			}
		]
	});
	const [articles, setArticles] = useState<DataResult>();
	const [currentNewId, setCurrentNewId] = useState(-1);
	const reduxDispatch: Dispatch = useDispatch();

	useEffect(() => {
		setArticles(sortArticleGroups(props.dossier.articles, gridState));
	}, [props.dossier.articles, gridState]);

	function showEditor(record?: IArticle): void {
		const key: string = newKey("Article_Editor");
		reduxDispatch(
			render(key, ArticleEditor, {
				record,
				vatType: props.dossier.vatType,
				onClose: (article: IArticle) => {
					if (article) {
						const newDossier: IDossier = {
							...props.dossier,
							articles: [...props.dossier.articles]
						};
						if (article.id) {
							remove(newDossier.articles, { id: article.id });
						} else {
							article.id = currentNewId;
							setCurrentNewId(currentNewId - 1);
						}
						newDossier.articles.push(article);
						onChange(newDossier);
					}
					reduxDispatch(derender(key));
				},
				readOnly: props.readOnly
			})
		);
	}

	function getCellColorClass(gridCellProps: GridCellProps): string {
		if (gridCellProps.field === "checkDate") {
			return null;
		}

		let relatedToArticlePhase: RelatedToArticlePhase = RelatedToArticlePhase.Ordered;
		switch (gridCellProps.field) {
			case "deliveries":
				relatedToArticlePhase = RelatedToArticlePhase.Delivered;
				break;
			case "pickings":
				relatedToArticlePhase = RelatedToArticlePhase.Picked;
				break;
		}
		if (props.dossier.remarks && props.dossier.remarks.length > 0) {
			if (some(props.dossier.remarks, { type: RemarkType.Red, relatedArticleId: gridCellProps.dataItem.id, relatedToArticlePhase })) {
				return "red";
			} else if (some(props.dossier.remarks, { type: RemarkType.Orange, relatedArticleId: gridCellProps.dataItem.id, relatedToArticlePhase })) {
				return "orange";
			}
		}
	}

	function onChange(newDossier: IDossier): void {
		setArticles(sortArticleGroups(newDossier.articles, gridState));
		props.onChange(newDossier);
	}

	function expandChange(event: GridExpandChangeEvent): void {
		event.dataItem[event.target.props.expandField] = event.value;
		setArticles({ ...articles });
		setGridState(gridState);
	}

	function confirmDeleteArticle(confirmKey: string, id: number): void {
		reduxDispatch(derender(confirmKey));
		const newDossier: IDossier = {
			...props.dossier,
			articles: [...props.dossier.articles]
		};
		const article: IArticle = find(newDossier.articles, { id });
		remove(newDossier.articles, { id });
		onChange(newDossier);

		if (article.id > 0 && article.deliveries && article.deliveries.length > 0) {
			const putInStockKey: string = newKey("putInStock");
			reduxDispatch(
				render(putInStockKey, Confirm, {
					title: t("article"),
					children: t("putArticleInStock"),
					onDecline: () => reduxDispatch(derender(putInStockKey)),
					onConfirm: () => {
						props.stockChange(sumBy(article.deliveries, "count"), article.productId);
						reduxDispatch(derender(putInStockKey));
					}
				})
			);
		}
	}

	function confirmResetArticle(confirmKey: string, id: number): void {
		reduxDispatch(derender(confirmKey));
		const newDossier: IDossier = cloneDeep(props.dossier);
		const article: IArticle = find(newDossier.articles, { id });
		if (article) {
			article.pokNumber = null;
			article.orderDate = null;
			article.deliveries = [];
			article.checkDate = null;
			article.pickings = [];
			onChange(newDossier);
		}
	}

	const commands: (IFunctionCommand<IArticle> | IRecordCommand<IArticle>)[] = [];
	if (!props.readOnly) {
		commands.push(
			{
				tooltip: t("edit"),
				iconClass: "las la-pencil-alt",
				recordAction: (record: IArticle) => {
					showEditor(record);
				}
			},
			{
				tooltip: t("remove"),
				iconClass: "las la-times",
				idAction: (id: number) => {
					const confirmKey: string = newKey("confirm_remove");
					reduxDispatch(
						render(confirmKey, Confirm, {
							title: t("confirm_title", { action: t("remove").toLowerCase() }),
							children: t("confirm_content", { action: t("remove").toLowerCase() }),
							onDecline: () => reduxDispatch(derender(confirmKey)),
							onConfirm: () => confirmDeleteArticle(confirmKey, id)
						})
					);
				}
			},
			{
				tooltip: t("reset"),
				iconClass: "las la-undo-alt",
				idAction: (id: number) => {
					const confirmKey: string = newKey("confirm_reset");
					reduxDispatch(
						render(confirmKey, Confirm, {
							title: t("confirm_title", { action: t("reset").toLowerCase() }),
							children: t("confirm_content", { action: t("reset").toLowerCase() }),
							onDecline: () => reduxDispatch(derender(confirmKey)),
							onConfirm: () => confirmResetArticle(confirmKey, id)
						})
					);
				},
				showCommand: (article: IArticle) => {
					return (
						!isNullOrEmpty(article.pokNumber) ||
						Boolean(article.orderDate || (article.deliveries && article.deliveries.length > 0) || article.checkDate || (article.pickings && article.pickings.length > 0))
					);
				}
			}
		);
	}

	if (hasPermission(Permission.PrintLabels)) {
		commands.push({
			iconClass: "las la-print",
			tooltip: t("printLabels"),
			recordAction: (dataItem: IArticle) => {
				printLabels(props.dossier, t, dataItem);
			},
			showCommand: (dataItem: IArticle) => dataItem.id > 0 && isProcurementArticle(dataItem)
		});
	}

	function cellRender(cell: React.ReactElement<HTMLTableCellElement> | null, cellProps: GridCellProps): React.ReactElement<HTMLTableCellElement> | null {
		if (cellProps.rowType === "groupHeader") {
			const agregate: AggregateDescriptor = find(aggregates, { field: cellProps.field });
			if (cellProps.field === "value") {
				// child is a p tag we overwrite it here to translate the group.
				// @ts-ignore
				// tslint:disable-next-line: no-any
				const children: any[] = [...cell.props.children.props.children];
				children[1] = t(cellProps.dataItem.value);
				return (
					<td key={cellProps.columnIndex} colSpan={3}>
						{children}
					</td>
				);
			} else if (cellProps.field === "expanded") {
				return null;
			} else if (agregate) {
				if (agregate.field === "count") {
					return <td key={newKey("column_")}>{Math.round(cellProps.dataItem.aggregates[agregate.field][agregate.aggregate] * 100) / 100}</td>;
				} else {
					return <td key={newKey("column_")}>{(Math.round(cellProps.dataItem.aggregates[agregate.field][agregate.aggregate] * 100) / 100).toFixed(2).replace(".", ",")}</td>;
				}
			} else if (cellProps.field !== "description") {
				if (cellProps.columnIndex === 14 && commands.length > 0) {
					return <td key={cellProps.columnIndex} colSpan={2} />;
				}
				return <td key={cellProps.columnIndex} />;
			}
		} else if (cellProps.rowType === "data") {
			const dataItem: IArticle = cellProps.dataItem;
			if (cellProps.field === "vatType") {
				let vat: number = 0;
				switch (dataItem.vatType) {
					case VatType.Vat6:
						vat = 6;
						break;
					case VatType.Vat21:
						vat = 21;
						break;
				}
				return React.cloneElement(cell, cell.props, vat);
			} else if (cellProps.field === "deliveries" || cellProps.field === "pickings") {
				const newProps: HTMLTableCellElement = { ...cell.props };
				newProps.className = getCellColorClass(cellProps) || newProps.className;
				let content: string;
				if (cellProps.field === "deliveries" || cellProps.field === "pickings") {
					const subRecords: IArticleDelivery[] | IArticlePicking[] = cellProps.field === "deliveries" ? dataItem.deliveries : dataItem.pickings;
					content = getDeliveryOrPickingsRepresentation(dataItem.count, subRecords, intl);
				} else {
					content = dateToString(cellProps.dataItem[cellProps.field], intl);
				}
				return React.cloneElement(cell, newProps, content);
			} else if (cellProps.field.endsWith("Date")) {
				const newProps: HTMLTableCellElement = { ...cell.props };
				newProps.className = getCellColorClass(cellProps) || newProps.className;
				return React.cloneElement(cell, newProps, dateToString(cellProps.dataItem[cellProps.field], intl));
			}
		}
		return cell;
	}

	function deleteSubArticle(confirmKey: string, subArticle: IArticleDelivery): void {
		const newDossier: IDossier = cloneDeep(props.dossier);
		const articleIndex: number = newDossier.articles.findIndex((a: IArticle) => a.id === subArticle.articleId);
		const deliverIndex: number = newDossier.articles[articleIndex].deliveries.findIndex((ad: IArticleDelivery) => ad.id === subArticle.id);

		newDossier.articles[articleIndex].deliveries.splice(deliverIndex, 1);

		props.onChange(newDossier);

		reduxDispatch(derender(confirmKey));
	}

	return (
		<div className="h-100 d-flex flex-column k-form">
			<div className="row">
				<div className="col-2">
					<h2 className={style.accentTitle}>{t("articles")}</h2>
				</div>
				<div className="col">
					<h2>
						{t("totalExclVat")}
						{props.dossier.totalArticleExclVat.toFixed(2).replace(".", ",")}
					</h2>
				</div>
				<div className="col">
					<h2>
						{t("totalInclVat")}
						{props.dossier.totalArticleInclVat.toFixed(2).replace(".", ",")}
					</h2>
				</div>
				<div className="col">
					<h2>
						{t("totalVat")}
						{props.dossier.totalArticleVat.toFixed(2).replace(".", ",")}
					</h2>
				</div>
				<div className="col-2" style={{ textAlign: "right" }}>
					<h3 style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
						{props.dossier?.dossierNumber} - {props.dossier?.customer?.name}
					</h3>
				</div>
			</div>
			<div className="row flex-grow-1">
				<div className="col">
					<Grid
						className="h-100"
						data={articles}
						{...gridState}
						sortable
						onExpandChange={expandChange}
						expandField="expanded"
						onDataStateChange={(event: GridDataStateChangeEvent) => setGridState(event.dataState)}
						cellRender={cellRender}
						onRowDoubleClick={(event: GridRowDoubleClickEvent) => {
							if (!event.dataItem.field || event.dataItem.field !== "group") {
								showEditor(event.dataItem);
							}
						}}
						detail={getArticleSubRecords(true, true, props.readOnly, deleteSubArticle)}
					>
						{(!props.readOnly || hasPermission(Permission.PrintLabels)) && (
							<GridToolbar>
								{!props.readOnly && (
									<Button primary onClick={() => showEditor()}>
										{t("add")}
									</Button>
								)}
								{hasPermission(Permission.PrintLabels) && (
									<Button
										primary
										onClick={() => {
											printLabels(props.dossier, t, ...filter(props.dossier.articles, isProcurementArticle));
										}}
									>
										{t("printLabels")}
									</Button>
								)}
							</GridToolbar>
						)}
						<GridColumn field="description" title={t("product")} width={150} />
						<GridColumn field="count" title={t("count")} width={70} />
						<GridColumn field="salesPriceExclVat" title={t("netPrice")} format="{0:n2}" />
						<GridColumn field="vatType" title={t("vat") + " %"} width={70} />
						<GridColumn field="vat" title={"€ " + t("vat")} format="{0:n2}" />
						<GridColumn field="salesPriceInclVat" title={t("unitPrice")} format="{0:n2}" />
						<GridColumn field="discount" title={t("discount") + " %"} />
						<GridColumn field="lineTotalIncl" title={t("lineTotal")} format="{0:n2}" />
						<GridColumn field="product.supplier.name" title={t("supplier")} />
						<GridColumn field="pokNumber" title={t("pokNumber")} />
						<GridColumn field="orderDate" title={t("orderDate")} />
						<GridColumn field="deliveries" title={t("delivered")} />
						<GridColumn field="pickings" title={t("pickDate")} />
						{commands.length > 0 && getGridCommandColumn(t("actions"), commands)}
					</Grid>
				</div>
			</div>
		</div>
	);
};

export default Articles;
