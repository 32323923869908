import React, { useEffect, useReducer, useState } from "react";
import { Link } from "react-router-dom";

import { process, State } from "@progress/kendo-data-query";
import { drawing, Text } from "@progress/kendo-drawing";
import { Chart, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend, ChartSeries, ChartSeriesItem, ChartSeriesLabels, ChartTitle, SeriesClickEvent } from "@progress/kendo-react-charts";
import { SeriesLabelsVisualArgs } from "@progress/kendo-react-charts/dist/npm/common/property-types";
import { Grid, GridColumn, GridDataStateChangeEvent, GridRowProps } from "@progress/kendo-react-grid";
import dayjs from "dayjs";
import "hammerjs";
import chunk from "lodash/chunk";
import map from "lodash/map";
import sumBy from "lodash/sumBy";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import callApi from "../../../services/api/callApi";
import Endpoint from "../../../services/api/endpoint";
import { getInitialState, initialDossierState, initialStatisticsState } from "../../../state";
import { dossierReducer, plannedTaskReducer } from "../../../state/reducers";
import statisticsReducer from "../../../state/reducers/statisticsReducer";
import { IDossierColorCount } from "../../../state/statistics";
import { IApplicationState } from "../../../store";
import { IDossier, IDossierTask, IUser } from "../../../utils/types/models";
import GridPanel from "../../global/gridpanel";
import { checkBoxCell, customCell, multilineCell, translatedCell } from "../../global/gridpanel/customCells/gridCells";
import Loader from "../../global/loader";
import { getRemarkCellClassName } from "../work/dossiermanagement/editor/remarks/remarksGrid";
import MyRemarkEditor from "./myRemarkEditor";

import style from "./home.module.scss";

interface IChartItem {
	category: string;
	dataItem: IDossierColorCount;
}

function getBackgroundColor(color: string): string {
	switch (color) {
		case "Green":
			return "#bfe7ad";
		case "Orange":
			return "#ffcc99";
		case "Red":
			return "#ffc7ce";
	}
}

function getColor(color: string): string {
	switch (color) {
		case "Green":
			return "#006100";
		case "Orange":
			return "#9c5700";
		case "Red":
			return "#9c0006";
	}
}

function getCellColorClass(color: string): string {
	switch (color) {
		case "Green":
			return "green";
		case "Orange":
			return "orange";
		case "Red":
			return "red";
	}
}

const initDossierByColorState: State = {
	skip: 0,
	take: 500,
	filter: {
		logic: "and",
		filters: [
			{
				field: "active",
				operator: "eq",
				value: true
			},
			{
				field: "complete",
				operator: "eq",
				value: true
			}
		]
	}
};

const Home: React.FC = () => {
	const { t } = useTranslation();
	const [taskGridState, setTaskGridState] = useState<State>({ skip: 0, take: 6 });
	const [selectedCategory, setSelectedCategory] = useState<string>("Red");
	const [statisticsState, statisticsDispatch] = useReducer(statisticsReducer, initialStatisticsState);
	const [dossierState, dossierDispatch] = useReducer(dossierReducer, initialDossierState);
	const [taskState, taskDispatch] = useReducer(plannedTaskReducer, getInitialState<IDossierTask>());
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);

	useEffect(() => {
		const interval: NodeJS.Timeout = setInterval(() => {
			let nextSkip: number = taskGridState.skip + taskGridState.take;
			if (nextSkip >= taskState.entities.length) {
				nextSkip = 0;
			}
			const newTaskGridState: State = {
				...taskGridState,
				skip: nextSkip
			};
			setTaskGridState(newTaskGridState);
		}, 10000);
		return () => clearInterval(interval);
	}, [taskGridState, taskState.entities.length]);

	useEffect(() => {
		callApi(statisticsDispatch, Endpoint.StatisticsRunningDossiersByColor, "GET", { companyId: currentUser.currentCompanyId });
		callApi(taskDispatch, Endpoint.PlannedTasks, "GET", {
			start: dayjs().startOf("day").toISOString(),
			end: dayjs().endOf("day").toISOString(),
			companyId: currentUser.currentCompanyId
		});
		const interval: NodeJS.Timeout = setInterval(() => {
			callApi(statisticsDispatch, Endpoint.StatisticsRunningDossiersByColor, "GET", { companyId: currentUser.currentCompanyId });
			callApi(taskDispatch, Endpoint.PlannedTasks, "GET", {
				start: dayjs().startOf("day").toISOString(),
				end: dayjs().endOf("day").toISOString(),
				companyId: currentUser.currentCompanyId
			});
		}, 5000 * 60);
		return () => clearInterval(interval);
	}, [currentUser.currentCompanyId]);

	useEffect(() => {
		if (selectedCategory) {
			callApi(dossierDispatch, Endpoint.DossiersByColor, "POST", { color: selectedCategory, companyId: currentUser.currentCompanyId }, initDossierByColorState);
		}
	}, [currentUser.currentCompanyId, selectedCategory]);

	function onSeriesClick(e: SeriesClickEvent): void {
		if (e.nativeEvent.type === "tap") {
			setSelectedCategory(e.category);
		}
	}

	function visual(e: SeriesLabelsVisualArgs): drawing.Element {
		// @ts-ignore
		const textColor: string = getColor(e.category);
		const text: Text = new Text(e.text, e.rect.topLeft(), {
			fill: {
				color: textColor
			}
		});
		return text;
	}

	return (
		<div className={style.home}>
			<div className={style.body}>
				{statisticsState.runningDossiersByColorLoading && <Loader />}
				<div className={"row " + style.title}>
					<div className="col">
						<h1>
							{t("welcome_message")} <span className={style.titleBold}>{currentUser.fullName}</span>
						</h1>
					</div>
				</div>
				<div className="row">
					<div className="col-3">
						<div className={style.tile}>
							<h2>
								{t("runningDossiers")}: {sumBy(statisticsState.runningDossiersByColorResult, "count")}
							</h2>
							<Chart onSeriesClick={onSeriesClick} transitions={false}>
								<ChartTitle visible>{t("runningDossiers")}</ChartTitle>
								<ChartLegend visible={false} />
								<ChartCategoryAxis>
									<ChartCategoryAxisItem categories={["Dossiers"]} />
								</ChartCategoryAxis>
								<ChartSeries>
									<ChartSeriesItem
										type="donut"
										data={statisticsState.runningDossiersByColorResult}
										categoryField="color"
										field="count"
										color={(e: IChartItem) => getBackgroundColor(e.category)}
									>
										<ChartSeriesLabels background="none" content={(e: IChartItem) => t(e.category) + ": " + e.dataItem.count} visual={visual} />
									</ChartSeriesItem>
								</ChartSeries>
							</Chart>
						</div>
					</div>
					<div className="col">
						{" "}
						{dossierState.isDossiersByColorLoading && <Loader />}
						<div className={style.dossierPanel}>
							{map(chunk(dossierState.dossiersByColor, 4), (dossiers: IDossier[], rowIndex: number) => (
								<div key={"dossier_row_" + rowIndex} className="row">
									{map(dossiers, (dossier: IDossier, colIndex: number) => (
										<div key={"dossier_col_" + colIndex} className={"col-3 " + style.dossierTile}>
											<Link to={"/work/dossiermanagement/" + dossier.id}>
												<div className={style.content + " " + getCellColorClass(selectedCategory)}>
													<h3>{dossier.dossierNumber}</h3>
													{dossier.customer.name}
												</div>
											</Link>
										</div>
									))}
								</div>
							))}
						</div>
					</div>
				</div>
				<div className={"row " + style.taskPanel}>
					<div className="col-7">
						<h3>{t("yourRemarks")}</h3>
						<GridPanel
							className={style.grid}
							listEndpoint={Endpoint.MyRemarksList}
							endpoint={Endpoint.MyRemarksList}
							editScreen={MyRemarkEditor}
							showDelete={false}
							filter={{
								logic: "and",
								filters: [
									{
										field: "finished",
										operator: "eq",
										value: false
									}
								]
							}}
							hideToolbar
						>
							<GridColumn field="dossier.description" title={t("dossier")} />
							<GridColumn field="type" title={t("type")} cell={customCell(translatedCell(), getRemarkCellClassName)} />
							<GridColumn field="relatedTo" title={t("related")} cell={customCell(translatedCell())} />
							<GridColumn field="content" title={t("remark")} cell={customCell(multilineCell())} />
						</GridPanel>
					</div>
					<div className="col-5">
						{taskState.areEntitiesLoading && <Loader />}
						<h3>{t("todaysTasks")}</h3>
						<Grid
							total={taskState.entities.length}
							{...taskGridState}
							onDataStateChange={(event: GridDataStateChangeEvent) => setTaskGridState(event.data)}
							data={process(taskState.entities, taskGridState)}
							sortable
							scrollable="scrollable"
							pageable
							className={style.grid}
							rowRender={(trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
								const task: IDossierTask = props.dataItem;
								const trProps: Partial<HTMLTableRowElement> = { ...trElement.props };
								if (task.completed) {
									trProps.className += " green";
								}
								return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
							}}
						>
							<GridColumn width="170px" field="dossier.dossierNumber" title={t("dossierNumber")} />
							<GridColumn width="170px" field="assignee1.fullName" title={t("lead")} />
							<GridColumn width="170px" field="assignee2.fullName" title={t("colleague")} />
							<GridColumn field="description" title={t("description")} />
							<GridColumn field="completed" title={t("taskComplete")} width="100px" cell={customCell(checkBoxCell(false))} />
						</Grid>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
