import { Reducer } from "react";

import { initialPriceTotalState, IPriceTotalState } from "..";
import { IAction } from "../../utils/types/types";

const totalPriceReducer: Reducer<IPriceTotalState, IAction> = (state: IPriceTotalState = initialPriceTotalState, action: IAction) => {
	switch (action.type) {
		case "REQUEST_POST_PRICES_TOTAL":
			state = {
				...state,
				isExecuting: true,
				priceResult: null,
				error: null
			};
			break;
		case "REQUEST_SUCCESS_POST_PRICES_TOTAL":
			if (action.payload && action.payload.response && action.payload.response.data) {
				state = {
					...state,
					priceResult: action.payload.response.data
				};
			}
			break;
		case "REQUEST_FAILURE_POST_PRICES_TOTAL":
			state = {
				...state,
				error: action.payload.error
			};
			break;
		case "REQUEST_END_POST_PRICES_TOTAL":
			state = {
				...state,
				isExecuting: false
			};
			break;
		case "CLEAR_POST_PRICES_TOTAL":
			state = {
				...state,
				isExecuting: false,
				priceResult: null,
				error: null
			};
			break;
		default:
			return state;
	}
	return state;
};

export default totalPriceReducer;