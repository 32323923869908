import React, { forwardRef, useImperativeHandle, useReducer, useState } from "react";

import { ComboBoxCloseEvent, ComboBoxFilterChangeEvent, DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { UploadResponse } from "@progress/kendo-react-upload";
import filter from "lodash/filter";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import callApi from "../../../../../../services/api/callApi";
import Endpoint from "../../../../../../services/api/endpoint";
import { initalUserState } from "../../../../../../state";
import { userReducer } from "../../../../../../state/reducers";
import { IApplicationState } from "../../../../../../store";
import { getEntity, handleChange, setEntity } from "../../../../../../utils/editorUtils";
import { ArticleGroup, RelatedTo, RelatedToArticlePhase, RemarkType } from "../../../../../../utils/enums";
import { IArticle, IFileData, IRemark, IUser } from "../../../../../../utils/types/models";
import { isNullOrEmpty } from "../../../../../../utils/utils";
import { ApiFileUpload, EnumDropDownList, SearchBox, TextArea, YesNoSwitch } from "../../../../../global/editor";
import ImageGallery from "../../../../../global/imageGallery";
import remove from "lodash/remove";
import indexOf from "lodash/indexOf";

interface IProps {
	remark?: IRemark;
	articles: IArticle[];
	onRemarkChange: (remark: IRemark) => void;
}

export interface IRemarkEditorContentRef {
	getErrorMessages: () => string[];
}

const RemarkEditorContent: React.ForwardRefRenderFunction<IRemarkEditorContentRef, IProps> = (props: IProps, ref: React.Ref<IRemarkEditorContentRef>) => {
	const { t } = useTranslation();
	const initialRemark: IRemark = {
		id: null,
		dossierId: null,
		type: null,
		relatedTo: null,
		finished: false,
		content: "",
		photos: []
	};
	const [remark, setRemark] = useState<IRemark>(props.remark || initialRemark);
	const [isRelatedToArticle, setIsRelatedToArticle] = useState(remark && remark.relatedTo && remark.relatedTo.startsWith("Article"));
	const [uploadError, setUploadError] = useState(false);
	const [userState, userDispatch] = useReducer(userReducer, initalUserState);
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);

	useImperativeHandle(ref, () => ({
		getErrorMessages: () => {
			const messages: string[] = [];
			if (!remark.type) {
				messages.push(t("fill_in_required_field", { field: t("color").toLowerCase() }));
			}
			if (!remark.relatedTo) {
				messages.push(t("fill_in_required_field", { field: t("relatedTo").toLowerCase() }));
			} else if (remark.relatedTo.startsWith("Article")) {
				if (!remark.relatedToArticlePhase) {
					messages.push(t("fill_in_required_field", { field: t("relatedToArticlePhase").toLowerCase() }));
				}
				if (!remark.relatedArticleId) {
					messages.push(t("fill_in_required_field", { field: t("article").toLowerCase() }));
				}
			} else {
				remark.relatedToArticlePhase = null;
				remark.relatedArticleId = null;
				remark.relatedArticle = null;
			}
			if (isNullOrEmpty(remark.content)) {
				messages.push(t("fill_in_required_field", { field: t("remark").toLowerCase() }));
			}
			if (messages.length <= 0) {
				remark.createdBy = `${currentUser.fullName} (${currentUser.email})`;
				remark.createdDate = new Date();
			}
			return messages;
		}
	}));

	function fileUploaded(response: UploadResponse): void {
		const newPhoto: IFileData = {
			filename: response.response.filename,
			internalFilename: response.response.internalFilename,
			size: response.response.size,
			type: response.response.type
		};
		const newRemark: IRemark = {
			...remark,
			photos: [...remark.photos, newPhoto]
		};
		setRemark(newRemark);
		props.onRemarkChange(newRemark);
		setUploadError(false);
	}

	function onUploadError(action: string): void {
		setUploadError(true);
	}

	function onChange(event: React.ChangeEvent<HTMLTextAreaElement> | DropDownListChangeEvent | ComboBoxCloseEvent | SwitchChangeEvent): void {
		if (event.target.name === "relatedTo") {
			if (event.target.value.key.startsWith("Article")) {
				setIsRelatedToArticle(true);
			} else {
				setIsRelatedToArticle(false);
			}
		}
		const newRemark: IRemark = handleChange(remark, event);
		setRemark(newRemark);
		props.onRemarkChange(newRemark);
	}

	function clearAssignee(): void {
		const newRemark: IRemark = setEntity(remark, null, "assignee", "assigneeId");
		setRemark(newRemark);
		props.onRemarkChange(newRemark);
	}

	function deletePhoto(index: number): void {
		const newRemark: IRemark = { ...remark };

		remove(newRemark.photos, (p: IFileData) => {
			return indexOf(newRemark.photos, p) === index;
		});

		setRemark(newRemark);
		props.onRemarkChange(newRemark);
	}

	return (
		<div className="k-form">
			<div className="row" style={{ marginBottom: "12px" }}>
				<div className="col">
					<div className="k-form-field">
						<span>{t("color")} *</span>
						<EnumDropDownList name="type" className="full-width-field" enum={RemarkType} value={remark.type} onChange={onChange} required />
					</div>
				</div>
				<div className="col">
					<label className="k-form-field">
						<span>{t("assignee")}</span>
						<SearchBox
							name="assigneeId"
							entities={userState.entities}
							entityId={remark.assigneeId}
							entity={remark.assignee}
							textField="fullName"
							onFilterChange={(event: ComboBoxFilterChangeEvent) => callApi(userDispatch, Endpoint.Users, "GET", { search: event.filter.value })}
							onClose={onChange}
							onClear={clearAssignee}
						/>
					</label>
				</div>
			</div>
			<div className="k-form-field">
				<div>{t("finished")}</div>
				<YesNoSwitch name="finished" checked={remark.finished} onChange={onChange} />
			</div>
			<div className="k-form-field">
				<span>{t("related")} *</span>
				<EnumDropDownList name="relatedTo" className="full-width-field" enum={RelatedTo} value={remark.relatedTo} onChange={onChange} required />
			</div>
			<div className={(isRelatedToArticle ? "" : "hidden ") + "row"}>
				<div className="col">
					<div className="k-form-field">
						<span>{t("relatedToArticlePhase")} *</span>
						<EnumDropDownList name="relatedToArticlePhase" className="full-width-field" enum={RelatedToArticlePhase} value={remark.relatedToArticlePhase} onChange={onChange} required />
					</div>
				</div>
				<div className="col">
					<div className="k-form-field">
						<span>{t("article")}</span>
						<DropDownList
							name="relatedArticleId"
							className="full-width-field"
							data={filter(props.articles, (article: IArticle) => {
								const isNewArticle: boolean = !article.id || article.id <= 0;
								if (isNewArticle) {
									return false;
								}
								switch (remark.relatedTo) {
									case RelatedTo.ArticleFurniture:
										return article.group === ArticleGroup.Furniture;
									case RelatedTo.ArticleWorktops:
										return article.group === ArticleGroup.Worktops;
									case RelatedTo.ArticlePlumbing:
										return article.group === ArticleGroup.Plumbing;
									case RelatedTo.ArticleAppliance:
										return article.group === ArticleGroup.Appliance;
									case RelatedTo.ArticleAccessory:
										return article.group === ArticleGroup.Accessory;
									case RelatedTo.ArticleOther:
										return article.group === ArticleGroup.Other;
								}
							})}
							dataItemKey="id"
							textField="description"
							value={getEntity(props.articles, remark.relatedArticleId, remark.relatedArticle)}
							onChange={onChange}
							required
						/>
					</div>
				</div>
			</div>
			<label className="k-form-field">
				<span>{t("remark")} *</span>
				<TextArea name="content" className="full-width-field" value={remark.content} onChange={onChange} required />
			</label>
			<label className="k-form-field">
				<span>
					{t("photos")}
					{uploadError && <i className="las la-exclamation-triangle" style={{ color: "red", fontSize: "15px" }} />}
				</span>
				<ApiFileUpload multiple showFileList={false} onError={onUploadError} fileUploaded={fileUploaded} accept="image/*" />
			</label>
			<ImageGallery images={remark.photos} imageTileSize={300} deletePhoto={deletePhoto} />
		</div>
	);
};

export default forwardRef(RemarkEditorContent);
