import React from "react";

import { DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { NumericTextBoxChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { useTranslation } from "react-i18next";

import { getDate, handleChange } from "../../../../../utils/editorUtils";
import { DepositType, VatType } from "../../../../../utils/enums";
import { IDossier } from "../../../../../utils/types/models";
import { EnumDropDownList, EnumRadioGroup, EnumRadioGroupChangeEvent, NumericInput, YesNoSwitch } from "../../../../global/editor";
import { IDossierSectionProps } from "../editor";

import style from "./editor.module.scss";

const Invoicing: React.FC<IDossierSectionProps> = (props: IDossierSectionProps) => {
	const { t } = useTranslation();

	function onRadioGroupChange(event: EnumRadioGroupChangeEvent<DepositType>): void {
		const newDossier: IDossier = handleChange(props.dossier, event);

		if (event.value === DepositType.None) {
			newDossier.depositAmountExclVat = 0;
			newDossier.amountAfterInstallation = calculateRemainingAmount(newDossier);
		}

		props.onChange(newDossier);
	}

	function onChange(event: NumericTextBoxChangeEvent | DropDownListChangeEvent | SwitchChangeEvent | DatePickerChangeEvent): void {
		const newDossier: IDossier = handleChange(props.dossier, event);

		if ((props.dossier.depositPayementDate && event.target.name === "depositPayed") || (event.target.name === "depositPayementDate" && props.dossier.depositPayed)) {
			newDossier.depositInvoiced = newDossier.depositPayed;
		} else if (props.dossier.totalArticleInclVat !== 0 && (event.target.name === "depositAmountExclVat" || event.target.name === "amountAtDelivery")) {
			newDossier.amountAfterInstallation = calculateRemainingAmount(newDossier);
		}
		props.onChange(newDossier);
	}

	function calculateRemainingAmount(dossier: IDossier): number {
		if (dossier.totalArticleInclVat !== 0) {
			const remainingAmount: number = dossier.totalArticleExclVat - dossier.depositAmountExclVat - dossier.amountAtDelivery;
			return remainingAmount;
		}
		return 0;
	}

	return (
		<div className="k-form">
			<div className="row">
				<div className="col">
					<h2 className={style.accentTitle}>{t("invoicing")}</h2>
				</div>
				<div className="col" style={{ textAlign: "right" }}>
					<h3>
						{props.dossier?.dossierNumber} - {props.dossier?.customer?.name}
					</h3>
				</div>
			</div>
			<fieldset>
				<legend className={style.accentTitle}>{t("Deposit")}</legend>
				<div className="row">
					<div className="col">
						<EnumRadioGroup enum={DepositType} name="depositType" title={t("invoiceDepositOn")} value={props.dossier.depositType} onChange={onRadioGroupChange} disabled={props.readOnly} />
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("depositAmountExclVat")}</span>
							<NumericInput
								name="depositAmountExclVat"
								format="n2"
								className="full-width-field"
								value={props.dossier.depositAmountExclVat}
								onChange={onChange}
								disabled={props.readOnly || props.dossier.depositType === DepositType.None}
							/>
						</label>
						<label className="k-form-field">
							<span>{t("vatOnDeposit")}</span>
							<EnumDropDownList
								name="depositVat"
								translationPrefix="fullDescription"
								className="full-width-field"
								enum={VatType}
								value={props.dossier.depositVat}
								onChange={onChange}
								required
								disabled={props.readOnly}
							/>
						</label>
						<div className="k-form-field">
							<span>{t("depositInvoiced")}</span>
							<YesNoSwitch name="depositInvoiced" checked={props.dossier.depositInvoiced} onChange={onChange} disabled={props.readOnly} />
						</div>
						<div className="row">
							<div className="col-3">
								<div className="k-form-field">
									<span>{t("depositPayed")}</span>
									<YesNoSwitch name="depositPayed" checked={props.dossier.depositPayed} onChange={onChange} disabled={props.readOnly} />
								</div>
							</div>
							<div className="col">
								<div className="k-form-field">
									<span>&nbsp;</span>
									<DatePicker
										name="depositPayementDate"
										value={getDate(props.dossier.depositPayementDate)}
										onChange={onChange}
										className="full-width-field"
										formatPlaceholder={{ year: t("year"), month: t("month"), day: t("day") }}
										disabled={props.readOnly}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
			<fieldset>
				<legend className={style.accentTitle}>{t("OnDelivery")}</legend>
				<div className="row">
					<div className="col-4">
						<label className="k-form-field">
							<span>{t("amountExclVat")}</span>
							<NumericInput name="amountAtDelivery" format="n2" className="full-width-field" value={props.dossier.amountAtDelivery} onChange={onChange} disabled={props.readOnly} />
						</label>
					</div>
					<div className="col-1">
						<div className="k-form-field">
							<span>{t("invoiced")}</span>
							<YesNoSwitch name="amountAtDeliveryInvoiced" checked={props.dossier.amountAtDeliveryInvoiced} onChange={onChange} disabled={props.readOnly} />
						</div>
					</div>
					<div className="col">
						<div className="k-form-field">
							<span>{t("payed")}</span>
							<YesNoSwitch name="amountAtDeliveryPayed" checked={props.dossier.amountAtDeliveryPayed} onChange={onChange} disabled={props.readOnly} />
						</div>
					</div>
				</div>
			</fieldset>
			<fieldset>
				<legend className={style.accentTitle}>{t("AfterInstallation")}</legend>
				<div className="row">
					<div className="col-4">
						<label className="k-form-field">
							<span>{t("amountExclVat")}</span>
							<NumericInput
								name="amountAfterInstallation"
								format="n2"
								className="full-width-field"
								value={props.dossier.amountAfterInstallation}
								onChange={onChange}
								disabled={props.readOnly}
							/>
						</label>
					</div>
					<div className="col-1">
						<div className="k-form-field">
							<span>{t("invoiced")}</span>
							<YesNoSwitch name="amountAfterInstallationInvoiced" checked={props.dossier.amountAfterInstallationInvoiced} onChange={onChange} disabled={props.readOnly} />
						</div>
					</div>
					<div className="col">
						<div className="k-form-field">
							<span>{t("payed")}</span>
							<YesNoSwitch name="amountAfterInstallationPayed" checked={props.dossier.amountAfterInstallationPayed} onChange={onChange} disabled={props.readOnly} />
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	);
};

export default Invoicing;
