import React from "react";

import { useTranslation } from "react-i18next";

import { Permission } from "../../../utils/enums";
import RoutedTabPanel from "../../global/routertabpanel";
import DossierManagement from "./dossiermanagement";
import Invoicing from "./invoicing";
import PlanningManagement from "./planningmanagement";
import Procurement from "./procurement";
import Warehouse from "./warehouse";

const Work: React.FC = () => {
	const { t } = useTranslation();

	return (
		<RoutedTabPanel loadFirstTab>
			<DossierManagement reactKey="dossierManagement" label={t("dossierManagement")} url="/dossiermanagement" permissions={[Permission.DossiersRead]} />
			<Procurement reactKey="procurement" label={t("procurement")} url="/procurement" permissions={[Permission.ProcurementView]} />
			<PlanningManagement type="service" reactKey="planningManagementService" label={t("planningManagementService")} url="/planningmanagement_service" permissions={[Permission.PlanningView]} />
			<PlanningManagement type="delivery" reactKey="planningManagementDelivery" label={t("planningManagementDelivery")} url="/planningmanagement_delivery" permissions={[Permission.PlanningView]} />
			<PlanningManagement 
				type="installation" 
				reactKey="planningManagementInstallation" 
				label={t("planningManagementInstallation")} 
				url="/planningmanagement_installation" 
				permissions={[Permission.PlanningView]} 
			/>
			<Warehouse reactKey="warehouse" label={t("warehouse")} url="/warehouse" permissions={[Permission.WarehouseView]} />
			<Invoicing reactKey="invoicing" label={t("invoicing")} url="/invoicing" permissions={[Permission.InvoicingView]} />
		</RoutedTabPanel>
	);
};

export default Work;
