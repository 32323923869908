import React from "react";

import { NumericTextBox, NumericTextBoxProps } from "@progress/kendo-react-inputs";
import { IntlProvider, IntlService, useInternationalization } from "@progress/kendo-react-intl";

class NumberProvider extends IntlProvider {
	public getChildContext(): { kendoIntlService: IntlService } {
		const childContext: { kendoIntlService: IntlService } = super.getChildContext();
		childContext.kendoIntlService.formatNumber = (dec: number): string => {

			return dec.toString().replace(".", ",");
		};
		childContext.kendoIntlService.parseNumber = (hex: string): number => {
			hex = hex.replace(",", ".");
			if (hex.endsWith(".")) {
				hex += "0";
			}
			return parseFloat(hex);
		};
		return childContext;
	}
}

const NumericInput: React.FC<NumericTextBoxProps> = (props: NumericTextBoxProps) => {
	const intlService: IntlService = useInternationalization();
	return (
		<NumberProvider locale={intlService.locale}>
			<NumericTextBox {...props} />
		</NumberProvider>
	);
};

export default NumericInput;