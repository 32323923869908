import React from "react";

import { useTranslation } from "react-i18next";

const Loader: React.FC = () => {
	const { t } = useTranslation();

	return (
		<div className="k-loading-mask">
			<span className="k-loading-text">{t("loading")}</span>
			<div className="k-loading-image" />
			<div className="k-loading-color" />
		</div>
	);
};

export default Loader;