import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";

import Endpoint from "../../../../services/api/endpoint";
import { Permission } from "../../../../utils/enums";
import GridPanel from "../../../global/gridpanel";
import { checkBoxCell, customCell } from "../../../global/gridpanel/customCells/gridCells";
import { IRoutedTabProps } from "../../../global/routertabpanel";
import RoleEditor from "./editor";

const Roles: React.FC<IRoutedTabProps> = () => {
	const { t } = useTranslation();

	return (
		<GridPanel
			listEndpoint={Endpoint.RolesList}
			endpoint={Endpoint.Roles}
			addScreen={RoleEditor}
			editScreen={RoleEditor}
			showDelete
			addPermission={Permission.RolesAdd}
			editPermission={Permission.RolesUpdate}
			deletePermission={Permission.RolesDelete}
			filter={{
				logic: "and",
				filters: [
					{
						field: "active",
						operator: "eq",
						value: true
					}
				]
			}}
		>
			<GridColumn field="name" title={t("name")} />
			<GridColumn field="active" title={t("active")} filter="boolean" width="160px" cell={customCell(checkBoxCell(false))} />
		</GridPanel>
	);
};

export default Roles;