import { Reducer } from "react";

import { IAuthenticationState } from "..";
import { IAction } from "../../utils/types/types";

const initialState: IAuthenticationState = {
	authenticated: false,
	loginError: null,
	userInfo: null,

	loadingUser: false,
	currentUser: null,
	savingCurrentUser: false,
	savingCurrentUserError: null
};

// Reducer that has to do with the logged in user.
const authenticationReducer: Reducer<IAuthenticationState, IAction> = (state: IAuthenticationState = initialState, action: IAction) => {
	switch (action.type) {
		case "SET_LOGIN_SUCCESS":
			state = {
				...state,
				authenticated: true,
				userInfo: action.userInfo,
				loginError: null
			};
			break;
		case "SET_LOGIN_ERROR":
			state = {
				...state,
				authenticated: false,
				loginError: action.errorDesc + ": " + action.error,
				userInfo: action.userInfo
			};
			break;
		case "SET_LOGOUT_SUCCESS":
			state = {
				...state,
				authenticated: false,
				userInfo: null,
				currentUser: null
			};
			break;
		case "SET_CURRENTLANGUAGE":
			if (state.currentUser) {
				state = {
					...state,
					currentUser: {
						...state.currentUser,
						language: action.language
					}
				};
			}
			break;
		case "SET_CURRENTCOMPANY":
			if (state.currentUser) {
				state = {
					...state,
					currentUser: {
						...state.currentUser,
						currentCompanyId: action.currentCompany.id,
						currentCompany: action.currentCompany
					}
				};
			}
			break;
		case "REQUEST_GET_CURRENT_USER":
			state = {
				...state,
				currentUser: null,
				loadingUser: true
			};
			break;
		case "REQUEST_SUCCESS_GET_CURRENT_USER":
			state = {
				...state,
				currentUser: action.payload.response.data,
				loginError: null
			};
			break;
		case "REQUEST_FAILURE_GET_CURRENT_USER":
			state = {
				...state,
				loginError: action.payload.error
			};
			break;
		case "REQUEST_END_GET_CURRENT_USER":
			state = {
				...state,
				loadingUser: false
			};
			break;

		case "REQUEST_PUT_CURRENT_USER":
			state = {
				...state,
				savingCurrentUser: true,
				savingCurrentUserError: null
			};
			break;
		case "REQUEST_FAILURE_PUT_CURRENT_USER":
			state = {
				...state,
				savingCurrentUserError: action.payload.error
			};
			break;
		case "REQUEST_END_PUT_CURRENT_USER":
			state = {
				...state,
				savingCurrentUser: false
			};
			break;
		default:
			return state;
	}
	return state;
};

export default authenticationReducer;