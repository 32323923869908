import React from "react";

import { SelectionRange } from "@progress/kendo-react-dateinputs/dist/npm/calendar/models";
import { GridCellProps } from "@progress/kendo-react-grid";
import { IntlService, useInternationalization } from "@progress/kendo-react-intl";
import get from "lodash/get";

import { cellContentsFunction } from ".";
import { getWeekRange, getWeekstring } from "../../../../../utils/utils";

export function weekCell(): cellContentsFunction {
	return (props: GridCellProps) => {
		const intl: IntlService = useInternationalization();
		const date: Date = get(props.dataItem, props.field);
		let week: SelectionRange;
		if (date) {
			week = getWeekRange(date, intl);
		}

		return <React.Fragment>{getWeekstring(week)}</React.Fragment>;
	};
}
