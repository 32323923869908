import { NotificationsState, reducer as notifications } from "react-notification-system-redux";
import { applyMiddleware, combineReducers, compose, createStore, Reducer, Store } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";

import { IAuthenticationState, IDossierManagementFilterState, ILoaderState, IRenderState } from "../state";
import { authenticationReducer,	dossierManagementFilterReducer, loaderReducer, renderReducer } from "../state/reducers";
import { IAction } from "../utils/types/types";

const middleware: ThunkMiddleware[] = [thunk];

// @ts-ignore
// tslint:disable-next-line: no-any
const composeEnhancers: any = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export interface IApplicationState {
	authenticationState: IAuthenticationState;
	notificationState: NotificationsState;
	loaderState: ILoaderState;
	renderState: IRenderState;
	dossierManagementFilterState: IDossierManagementFilterState;
}

const allReducers: Reducer<IApplicationState, IAction> = combineReducers<IApplicationState>({
	authenticationState: authenticationReducer,
	notificationState: notifications,
	loaderState: loaderReducer,
	renderState: renderReducer,
	dossierManagementFilterState: dossierManagementFilterReducer
});

const store: Store<IApplicationState> = createStore(allReducers, composeEnhancers(applyMiddleware(...middleware)));
export default store;
