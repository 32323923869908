import React from "react";

import { useTranslation } from "react-i18next";

import map from "lodash/map";
import values from "lodash/values";

export class EnumRadioGroupChangeEvent<T> {
	public value: T;
	public target: HTMLInputElement;
}

interface IProps<T> {
	// @ts-ignore
	enum: typeof T;
	name: string;
	title: string;
	value: T;
	onChange: (event: EnumRadioGroupChangeEvent<T>) => void;
	disabled?: boolean;
}

function EnumRadioGroup<T>(props: IProps<T>): JSX.Element {
	const { t } = useTranslation();

	function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
		props.onChange({
			value: (event.target.id as unknown) as T,
			target: event.target
		});
	}

	return (
		<React.Fragment>
			<h4>{props.title}</h4>{" "}
			{map(values(props.enum), (value: string) => {
				return (
					<div className="row" key={value} style={{ paddingTop: "10px" }}>
						<div className="col">
							<input type="radio" name={props.name} id={value} className="k-radio" checked={props.value === ((value as unknown) as T)} onChange={onChange} disabled={props.disabled} />
							<label className="k-radio-label" htmlFor={value}>
								{t(value)}
							</label>
						</div>
					</div>
				);
			})}
		</React.Fragment>
	);
}

export default EnumRadioGroup;
