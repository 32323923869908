import React from "react";

import { GridColumn } from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Endpoint from "../../../../services/api/endpoint";
import { IApplicationState } from "../../../../store";
import { Permission } from "../../../../utils/enums";
import { IUser } from "../../../../utils/types/models";
import GridPanel from "../../../global/gridpanel";
import { checkBoxCell, customCell } from "../../../global/gridpanel/customCells/gridCells";
import { IRoutedTabProps } from "../../../global/routertabpanel";
import ProductTypeEditor from "./editor";

const ProductTypes: React.FC<IRoutedTabProps> = () => {
	const { t } = useTranslation();
	const currentUser: IUser = useSelector((applicationState: IApplicationState) => applicationState.authenticationState.currentUser);

	return (
		<GridPanel
			listEndpoint={Endpoint.ProductTypesList}
			listUrlArguments={{ companyId: currentUser.currentCompanyId }}
			endpoint={Endpoint.ProductTypes}
			addScreen={ProductTypeEditor}
			editScreen={ProductTypeEditor}
			showDelete
			addPermission={Permission.ProductTypesAdd}
			editPermission={Permission.ProductTypesUpdate}
			deletePermission={Permission.ProductTypesDelete}
			filter={{
				logic: "and",
				filters: [
					{
						field: "active",
						operator: "eq",
						value: true
					}
				]
			}}
		>
			<GridColumn field="name" title={t("name")} />
			<GridColumn field="active" title={t("active")} filter="boolean" width="160px" cell={customCell(checkBoxCell(false))} />
		</GridPanel>
	);
};

export default ProductTypes;