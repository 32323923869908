import React from "react";

import { GridCellProps } from "@progress/kendo-react-grid";

import { cellContentsFunction } from ".";

import style from "./multiline.module.scss";

export function multilineCell(): cellContentsFunction {
	return (props: GridCellProps) => {
		return (
			<React.Fragment>
				<pre className={style.multiline}>{props.dataItem[props.field]}</pre>
			</React.Fragment>
		);
	};
}