import { Reducer } from "react";

import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import find from "lodash/find";
import remove from "lodash/remove";

import { IDossierManagementFilterState } from "..";
import { IAction } from "../../utils/types/types";
import { isNullOrEmpty } from "../../utils/utils";

const initialState: IDossierManagementFilterState = {
	filter: null,
	debounce: false
};

const dossierManagementFilterReducer: Reducer<IDossierManagementFilterState, IAction> = (state: IDossierManagementFilterState = initialState, action: IAction) => {
	switch (action.type) {
		case "SET_FILTER": {
			state = {
				filter: action.filter,
				debounce: true
			};
			break;
		}
		case "ADD_FILTER": {
			let newFilter: CompositeFilterDescriptor;
			if (state.filter) {
				newFilter = { ...state.filter };
			} else {
				newFilter = {
					logic: "and",
					filters: []
				};
			}
			const existingFilter: FilterDescriptor = find(newFilter.filters, { field: action.field }) as FilterDescriptor;
			if (existingFilter) {
				existingFilter.value = action.value;
				existingFilter.operator = action.operator;
				state = {
					filter: newFilter,
					debounce: false
				};
			} else if (!isNullOrEmpty(action.value)) {
				newFilter.filters.push({
					field: action.field,
					operator: action.operator,
					value: action.value
				});
				state = {
					filter: newFilter,
					debounce: false
				};
			}
			break;
		}
		case "REMOVE_FILTER": {
			const newFilter: CompositeFilterDescriptor = { ...state.filter };
			const existingFilter: FilterDescriptor = find(newFilter.filters, { field: action.field }) as FilterDescriptor;
			if (existingFilter) {
				remove(newFilter.filters, existingFilter);
				if (newFilter.filters.length >= 1) {
					state = {
						filter: newFilter,
						debounce: false
					};
				} else {
					state = {
						filter: null,
						debounce: false
					};
				}
			}
			break;
		}
	}
	return state;
};

export default dossierManagementFilterReducer;
