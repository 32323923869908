/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, useEffect, useReducer, useRef, useState } from "react";

import { ComboBoxCloseEvent, ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { useTranslation } from "react-i18next";
import { Dispatch as ReduxDispatch } from "redux";

import callApi from "../../../../services/api/callApi";
import Endpoint from "../../../../services/api/endpoint";
import { getInitialState, initalUserState, initialTeamState } from "../../../../state";
import { taskUpdateTeamsReducer, teamReducer, userReducer } from "../../../../state/reducers";
import { handleChange, setEntity } from "../../../../utils/editorUtils";
import { IDossierTask, ITeam, IUser } from "../../../../utils/types/models";
import { newKey } from "../../../../utils/utils";
import EntityEditor, { IEditorProps, IEditScreenProps, SearchBox, YesNoSwitch } from "../../../global/editor";

import isNull from "lodash/isNull";
import { useDispatch, useSelector } from "react-redux";
import { derender, render } from "../../../../state/actions/renderActions";
import { IApplicationState } from "../../../../store";
import { IAction } from "../../../../utils/types/types";
import Confirm from "../../../global/confirm";

const TeamEditor: React.FC<IEditorProps<ITeam>> = (props: IEditorProps<ITeam>) => {
	const { t } = useTranslation();

	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);

	const [teamState, teamDispatch] = useReducer(teamReducer, initialTeamState);
	const [userState, userDispatch] = useReducer(userReducer, initalUserState);

	const initialTeam: ITeam = {
		id: (props as IEditScreenProps<ITeam>).recordId || 0,
		name: "",
		lead: null,
		leadId: 0,
		colleague: null,
		colleagueId: 0,
		currentCompany: null,
		currentCompanyId: currentUser.currentCompanyId,
		active: true
	};
	const [team, setTeam] = useState<ITeam>(initialTeam);
	const [dataChanged, setDataChanged] = useState<boolean>(false);
	const [, tasksDispatch] = useReducer(taskUpdateTeamsReducer, getInitialState<IDossierTask>());
	const firstField: React.MutableRefObject<Input> = useRef();
	const [userEntities, setUserEntities] = useState<IUser[]>([]);
	const [previousTeam, setPreviousTeam] = useState<ITeam>();
	const [teamChanged, setTeamChanged] = useState(false);

	const reduxDispatch: ReduxDispatch = useDispatch();

	useEffect(() => {
		callApi(userDispatch, Endpoint.Installers, "GET", { companyId: currentUser.currentCompanyId });
	}, []);

	useEffect(() => {
		if (teamState.entity) {
			setTeam(teamState.entity);
			setPreviousTeam(teamState.entity);
		}
	}, [teamState.entity]);

	useEffect(() => {
		if (team.colleague && dataChanged && team.id !== 0) {
			setTeamChanged(true);
		}
	}, [team.colleague]);

	useEffect(() => {
		if (team.lead && dataChanged && team.id !== 0) {
			setTeamChanged(true);
		}
	}, [team.lead]);

	useEffect(() => {
		if (userState.entities) {
			setUserEntities(userState.entities);
		}
	}, [userState.entities]);

	function changeFutureTaskColleagues(): void {
		callApi(tasksDispatch, Endpoint.FutureTasksTeamChange, "PUT", { teamId: team.id }, previousTeam);
	}

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		let endpoint: Endpoint = null;
		let dispatch: Dispatch<IAction>;
		let installer: boolean = false;
		let leadInstaller: boolean = false;

		switch (event.target.name) {
			case "leadId":
				leadInstaller = true;
				dispatch = userDispatch;
				endpoint = Endpoint.Users;
				break;
			case "colleagueId":
				installer = true;
				dispatch = userDispatch;
				endpoint = Endpoint.Users;
				break;
		}

		if (endpoint && dispatch) {
			const query: object = {
				search: event.filter.value,
				companyId: currentUser.currentCompanyId,
				leadInstaller: leadInstaller ? leadInstaller : null,
				installer: installer ? installer : null
			};
			callApi(dispatch, endpoint, "GET", query);
		}
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNull(team.lead)) {
			messages.push(t("fill_in_required_field", { field: t("leadInstaller").toLowerCase() }));
		}
		if (isNull(team.colleague)) {
			messages.push(t("fill_in_required_field", { field: t("colleague").toLowerCase() }));
		}

		if (teamChanged) {
			const key: string = newKey("changeFutureTasks");
			reduxDispatch(
				render(key, Confirm, {
					title: t("changeFutureTasks"),
					children: t("changeFutureTasksQuestion"),
					onDecline: () => {
						reduxDispatch(derender(key));
					},
					onConfirm: () => {
						changeFutureTaskColleagues();
						reduxDispatch(derender(key));
					}
				})
			);
		}
		return messages;
	}

	function onChangeCollegue(event: ComboBoxCloseEvent): void {
		setTeam(handleChange(team, event));
		setDataChanged(true);
	}

	function onClear(field: string, idField: string): void {
		setTeam(setEntity(team, null, field, idField));
		setDataChanged(true);
	}

	function close(record?: ITeam): void {
		props.close(record);
	}

	function onChange(event: SwitchChangeEvent | React.ChangeEvent<HTMLInputElement>): void {
		setTeam(handleChange(team, event));
		setDataChanged(true);
	}

	const readonly: boolean = (props as IEditScreenProps<ITeam>).readonly;

	return (
		<EntityEditor
			width="70%"
			record={team}
			endpoint={Endpoint.Teams}
			entityState={teamState}
			entityType={t("team")}
			dispatch={teamDispatch}
			dataChanged={dataChanged}
			readonly={readonly}
			recordName={team.lead?.fullName === undefined ? "" : team.lead?.fullName}
			close={close}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<div className="k-form">
				<label className="k-form-field">
					<span>{t("lead") + " *"}</span>
					<SearchBox
						name="leadId"
						entities={userEntities}
						entityId={team.leadId}
						entity={team.lead}
						textField="fullName"
						onFilterChange={onFilterChange}
						onClose={onChangeCollegue}
						onClear={() => onClear("lead", "leadId")}
						required
					/>
				</label>
				<label className="k-form-field">
					<span>{t("colleague") + " *"}</span>
					<SearchBox
						name="colleagueId"
						entities={userEntities}
						entityId={team.colleagueId}
						entity={team.colleague}
						textField="fullName"
						onFilterChange={onFilterChange}
						onClose={onChangeCollegue}
						onClear={() => onClear("colleague", "colleagueId")}
						required
					/>
				</label>
				<div className="k-form-field">
					<span>{t("active")}</span>
					<YesNoSwitch name="active" checked={team.active} onChange={onChange} disabled={readonly} />
				</div>
			</div>
		</EntityEditor>
	);
};

export default TeamEditor;
