import { Reducer } from "react";

import { initialPriceState, IPriceState } from "..";
import { IAction } from "../../utils/types/types";

const priceReducer: Reducer<IPriceState, IAction> = (state: IPriceState = initialPriceState, action: IAction) => {
	switch (action.type) {
		case "REQUEST_POST_PRICES":
			state = {
				...state,
				isExecuting: true,
				priceResult: null,
				error: null
			};
			break;
		case "REQUEST_SUCCESS_POST_PRICES":
			if (action.payload && action.payload.response && action.payload.response.data) {
				state = {
					...state,
					priceResult: action.payload.response.data
				};
			}
			break;
		case "REQUEST_FAILURE_POST_PRICES":
			state = {
				...state,
				error: action.payload.error
			};
			break;
		case "REQUEST_END_POST_PRICES":
			state = {
				...state,
				isExecuting: false
			};
			break;
		case "CLEAR_POST_PRICES":
			state = {
				...state,
				isExecuting: false,
				priceResult: null,
				error: null
			};
			break;
		default:
			return state;
	}
	return state;
};

export default priceReducer;