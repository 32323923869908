import React, { useEffect, useReducer, useRef, useState } from "react";

import { addDays } from "@progress/kendo-date-math";
import { Button } from "@progress/kendo-react-buttons";
import { SelectionRange } from "@progress/kendo-react-dateinputs";
import { MultiSelect, MultiSelectChangeEvent, MultiSelectFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { GridColumn } from "@progress/kendo-react-grid";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { IntlService, useInternationalization } from "@progress/kendo-react-intl";
import { Card, CardHeader, CardSubtitle, CardTitle } from "@progress/kendo-react-layout";
import filter from "lodash/filter";
import find from "lodash/find";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import callApi from "../../../../services/api/callApi";
import Endpoint from "../../../../services/api/endpoint";
import { hasPermission } from "../../../../services/authentication";
import { getInitialState, initalUserState } from "../../../../state";
import { clearEntity } from "../../../../state/actions/entityActions";
import { derender, render } from "../../../../state/actions/renderActions";
import { installerReducer, plannedTaskReducer, taskReducer, taskUpdateReducer, userReducer } from "../../../../state/reducers";
import { IApplicationState } from "../../../../store";
import { DossierTaskType, Permission } from "../../../../utils/enums";
import { IDossier, IDossierTask, IUser } from "../../../../utils/types/models";
import { dateToString, getWeekRange, newKey } from "../../../../utils/utils";
import Confirm from "../../../global/confirm";
import GridPanel, { IGridPanelRef } from "../../../global/gridpanel";
import { weekPickerFilterCell } from "../../../global/gridpanel/customCells/filterCells";
import { customCell, weekCell } from "../../../global/gridpanel/customCells/gridCells";
import Loader from "../../../global/loader";
import { IRoutedTabProps } from "../../../global/routertabpanel";
import AssigneePopup from "./assigneePopup";
import CreateTask from "./createTask";
import DraggableTask from "./draggableTask";
import WeekPlanning from "./weekPlanning";

import style from "./planningView.module.scss";

interface IProps {
	type: string;
}

const PlanningManagement: React.FC<IRoutedTabProps & IProps> = (props: IProps) => {
	const { t } = useTranslation();
	const intl: IntlService = useInternationalization();
	const currentUser: IUser = useSelector((applicationState: IApplicationState) => applicationState.authenticationState.currentUser);
	const gridRef: React.MutableRefObject<IGridPanelRef<IDossier>> = useRef<IGridPanelRef<IDossier>>(null);
	const defaultWeek: SelectionRange = getWeekRange(new Date(), intl);

	const [taskState, taskDispatch] = useReducer(taskReducer, getInitialState<IDossierTask>());
	const [state, taskUpdate] = useReducer(taskUpdateReducer, getInitialState<IDossierTask>());
	const [teamsState, teamsDispatch] = useReducer(installerReducer, getInitialState<IUser>());
	const [usersFilterState, usersFilterDispatch] = useReducer(userReducer, initalUserState);
	const [plannedTasksState, plannedTasksDispatch] = useReducer(plannedTaskReducer, getInitialState<IDossierTask>());

	const [movingTask, setMovingTask] = useState<IDossierTask>();
	const [draggingFromFirstAssignee, setDraggingFromFirstAssignee] = useState<boolean>(null);
	const [selectedWeek, setSelectedWeek] = useState<SelectionRange>(defaultWeek);
	const [selectedDossier, setSelectedDossier] = useState<IDossier>();
	const [dossierView, setDossierView] = useState<Endpoint>(Endpoint.DossiersToBePlanned);

	const [typeEnum, setTypeEnum] = useState<DossierTaskType>();
	const [usersFilter, setUsersFilter] = useState<IUser[]>([]);
	const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);

	const reduxDispatch: Dispatch = useDispatch();

	// Get all the planned tasks in the chosen week, and refresh when the week is updated.
	useEffect(() => {
		callApi(plannedTasksDispatch, Endpoint.PlannedTasks, "GET", {
			start: selectedWeek.start.toISOString(),
			end: selectedWeek.end.toISOString(),
			companyId: currentUser.currentCompanyId,
			taskType: props.type
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedWeek, currentUser.currentCompanyId]);

	// What type of planning screen?
	useEffect(() => {
		if (props.type === "service") {
			setTypeEnum(DossierTaskType.Service);
		} else if (props.type === "installation") {
			setTypeEnum(DossierTaskType.Installation);
		} else if (props.type === "delivery") {
			setTypeEnum(DossierTaskType.Delivery);
		}
	}, [props.type]);

	// When a task is updated, fetch the new data for the planningboard so it is up to date.
	useEffect(() => {
		if (!state.isUpdating && state.updatedEntity) {
			callApi(plannedTasksDispatch, Endpoint.PlannedTasks, "GET", {
				start: selectedWeek.start.toISOString(),
				end: selectedWeek.end.toISOString(),
				companyId: currentUser.currentCompanyId,
				taskType: props.type
			});
			if (selectedDossier) {
				callApi(taskDispatch, Endpoint.Tasks, "GET", {
					dossierId: selectedDossier.id
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.isUpdating, state.updatedEntity, currentUser.currentCompanyId]);

	useEffect(() => {
		callApi(teamsDispatch, Endpoint.Installers, "GET", {
			companyId: currentUser.currentCompanyId
		});
		callApi(usersFilterDispatch, Endpoint.Users, "GET", { search: "", companyId: currentUser.currentCompanyId, installer: true, leadInstaller: true });
	}, [currentUser.currentCompanyId]);

	useEffect(() => {
		if ((!usersFilter.length && !selectedUsers.length) || defaultWeek !== selectedWeek) {
			if (plannedTasksState.entities.length !== 0 && !plannedTasksState.areEntitiesLoading) {
				const users: IUser[] = [];
				for (const taskP of plannedTasksState.entities) {
					if (!find(users, { id: taskP.assignee1Id })) {
						users.push(taskP.assignee1);
					}
				}
				setUsersFilter(users);
				setSelectedUsers(users);
			} else if (plannedTasksState.entities.length === 0 && !plannedTasksState.areEntitiesLoading) {
				if (!teamsState.areEntitiesLoading && teamsState.entities) {
					setSelectedUsers(filter(teamsState.entities, ["leadInstaller", true]));
					setUsersFilter(filter(teamsState.entities, ["leadInstaller", true]));
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [plannedTasksState.areEntitiesLoading, plannedTasksState.entities, teamsState.areEntitiesLoading, teamsState.entities, selectedWeek]);

	function onClickWeek(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
		if (event.currentTarget.id === "buttonPrev") {
			setSelectedWeek(getWeekRange(addDays(selectedWeek.start, -7), intl));
		} else if (event.currentTarget.id === "buttonCurrent") {
			setSelectedWeek(defaultWeek);
		} else if (event.currentTarget.id === "buttonNext") {
			setSelectedWeek(getWeekRange(addDays(selectedWeek.start, 7), intl));
		}
	}

	function showAssigneeDialog(newTask: IDossierTask, assignee: IUser): void {
		const assigneeDialogKey: string = newKey("assigneeDialog");
		reduxDispatch(
			render(assigneeDialogKey, AssigneePopup, {
				task: newTask,
				setFirstAssignee: draggingFromFirstAssignee === true || draggingFromFirstAssignee === null || draggingFromFirstAssignee === undefined,
				assignee,
				onClose: (task: IDossierTask) => {
					if (task) {
						callApi(taskUpdate, Endpoint.TaskUpdate, "PUT", { id: task.id }, task, null, (success: boolean) => {
							if (success) {
								reduxDispatch(derender(assigneeDialogKey));
							}
						});
					} else {
						reduxDispatch(derender(assigneeDialogKey));
					}
				}
			})
		);
	}

	function drop(dropDate: Date, event: React.DragEvent<HTMLDivElement>, assignee1?: IUser): void {
		event.preventDefault();
		const date: Date = new Date(dropDate.getTime() - dropDate.getTimezoneOffset() * 60000);

		const newTask: IDossierTask = { ...movingTask, startDate: date, planned: true };

		if (movingTask.dateIsFixed && movingTask.planned && dateToString(movingTask.startDate, intl) !== dateToString(date, intl)) {
			const confirmKey: string = newKey("confirm_fixedDate");
			reduxDispatch(
				render(confirmKey, Confirm, {
					title: t("confirm_title"),
					onConfirm: () => {
						reduxDispatch(derender(confirmKey));
						showAssigneeDialog(newTask, assignee1);
					},
					onDecline: () => reduxDispatch(derender(confirmKey)),
					children: t("confirmChangeFixedDate")
				})
			);
		} else {
			showAssigneeDialog(newTask, assignee1);
		}
	}

	function cancelTask(task: IDossierTask): void {
		const confirmKey: string = newKey("confirm_cancelTask");
		const newTask: IDossierTask = {
			...task,
			planned: false,
			startDate: task.dateIsFixed ? task.startDate : null,
			startTime: null,
			stopDate: null,
			stopTime: null,
			assignee1: null,
			assignee1Id: null,
			assignee2: null,
			assignee2Id: null
		};
		reduxDispatch(
			render(confirmKey, Confirm, {
				title: t("confirm_title") + " " + t("cancelling"),
				onConfirm: () =>
					callApi(
						taskUpdate,
						Endpoint.TaskUpdate,
						"PUT",
						{ id: newTask.id },
						newTask,
						null,
						(success: boolean) => {
							if (success) {
								reduxDispatch(derender(confirmKey));
							}
						},
						false
					),
				onDecline: () => reduxDispatch(derender(confirmKey)),
				children: t("confirm_content", { action: t("cancelling").toLowerCase() })
			})
		);
	}

	function drag(draggedTask: IDossierTask, event: React.DragEvent<HTMLDivElement>, dragFromFirstAssignee?: boolean): void {
		setMovingTask(draggedTask);
		setDraggingFromFirstAssignee(dragFromFirstAssignee);
		event.dataTransfer.setData("transfer", (event.target as HTMLDivElement).id);
	}

	function onClickCreateTask(): void {
		const createTaskKey: string = newKey("createTask");
		reduxDispatch(
			render(createTaskKey, CreateTask, {
				close: (record?: IDossier) => {
					reduxDispatch(derender(createTaskKey));
					callApi(plannedTasksDispatch, Endpoint.PlannedTasks, "GET", {
						start: selectedWeek.start.toISOString(),
						end: selectedWeek.end.toISOString(),
						companyId: currentUser.currentCompanyId,
						taskType: props.type
					});
					if (record) {
						callApi(taskDispatch, Endpoint.Tasks, "GET", {
							dossierId: record.id
						});
						gridRef.current.refresh();
					}
				},
				taskType: typeEnum
			})
		);
	}

	function onUsersChange(event: MultiSelectChangeEvent): void {
		const newUsers: IUser[] = [];
		for (const user of event.value) {
			newUsers.push(user);
		}
		setUsersFilter(newUsers);
		if (newUsers.length === 0) {
			setSelectedUsers(teamsState.entities);
		} else {
			setSelectedUsers(newUsers);
		}
	}

	function onFilterChange(event: MultiSelectFilterChangeEvent): void {
		callApi(usersFilterDispatch, Endpoint.Users, "GET", { search: event.filter.value, companyId: currentUser.currentCompanyId });
	}

	let extraToolbarItems: React.ReactElement[];
	if (hasPermission(Permission.DossiersAdd) && hasPermission(Permission.DossiersUpdate)) {
		extraToolbarItems = [
			<Button key="addNewTaskButton" primary onClick={onClickCreateTask}>
				{t("createNewTask")}
			</Button>
		];
	}

	return (
		<div className="d-flex flex-row" style={{ width: "100%" }}>
			<div className="d-flex flex-column">
				<div className="d-flex flex-row" style={{ height: "30px", padding: "10px" }}>
					<Checkbox
						defaultChecked
						onChange={(event: CheckboxChangeEvent) => (event.value ? setDossierView(Endpoint.DossiersToBePlanned) : setDossierView(Endpoint.DossiersList))}
						label={t("dossierViewCheck")}
					/>
				</div>
				<GridPanel
					ref={gridRef}
					className={style.masterGrid}
					listEndpoint={dossierView}
					listUrlArguments={{
						companyId: currentUser.currentCompanyId,
						taskType: props.type
					}}
					endpoint={Endpoint.Dossiers}
					editLink={"/work/dossiermanagement/:id"}
					editPermission={Permission.DossiersUpdate}
					sort={[
						{
							field: "installationWeek",
							dir: "asc"
						}
					]}
					filter={{
						logic: "and",
						filters: [
							{
								field: "active",
								operator: "eq",
								value: true
							}
						]
					}}
					showDelete={false}
					newTab={true}
					onSelectionChange={(entity: IDossier) => {
						if (entity) {
							setSelectedDossier(entity);
							callApi(taskDispatch, Endpoint.Tasks, "GET", {
								dossierId: entity.id
							});
						} else {
							setSelectedDossier(null);
							taskDispatch(clearEntity("GET", Endpoint.Tasks));
						}
					}}
					extraToolbarItems={extraToolbarItems}
				>
					<GridColumn field="dossierNumber" title={t("dossierNumber")} />
					<GridColumn field="customer" title={t("customer")} />
					<GridColumn field="installationWeek" title={t("installationWeek")} filterCell={weekPickerFilterCell()} cell={customCell(weekCell())} />
				</GridPanel>
				<div className={style.header + " d-flex"}>
					{t("tasks")}
					{!selectedDossier && " (" + t("selectDossierMessage") + ")"}
					<div className="flex-grow-1" />
				</div>
				{taskState.areEntitiesLoading && <Loader />}
				{selectedDossier && (
					<div>
						<Card style={{ width: "500px" }}>
							<CardHeader>
								<CardTitle className={style.cardTitle}>{t("dossier") + " " + selectedDossier.dossierNumber}</CardTitle>
								<CardSubtitle className={style.cardSubtitle}>
									<p>{selectedDossier.description}</p>
									<p>{t("customer") + ": " + selectedDossier.customer}</p>
									{selectedDossier.installationWeek && (
										<p>
											{t("installationWeek") +
												": " +
												dateToString(getWeekRange(selectedDossier.installationWeek, intl).start, intl) +
												" ---> " +
												dateToString(getWeekRange(selectedDossier.installationWeek, intl).end, intl)}
										</p>
									)}
								</CardSubtitle>
							</CardHeader>
						</Card>
					</div>
				)}
				<div className={style.detailGrid}>
					{selectedDossier &&
						filter(taskState.entities, { type: typeEnum }).map((task: IDossierTask) => (
							<DraggableTask key={"listTask_" + task.id} task={task} drag={drag} weekCard={false} installationWeek={selectedDossier.installationWeek} />
						))}
				</div>
			</div>
			<div className="col" style={{ maxHeight: "90%" }}>
				<div className={"row " + style.planningNavbar}>
					<div className="col">
						<div className="d-flex justify-content-center" style={{ marginTop: "20px", marginBottom: "25px" }}>
							<Button id="buttonPrev" onClick={onClickWeek}>
								{t("prevWeek")}
							</Button>
							<Button id="buttonCurrent" primary={true} onClick={onClickWeek}>
								{t("currentWeek")}
							</Button>
							<Button id="buttonNext" onClick={onClickWeek}>
								{t("nextWeek")}
							</Button>
						</div>
					</div>
					<div className="col">
						<span style={{ padding: "5px" }} className={style.userName}>
							{t("displayedUsers")}
						</span>
						<MultiSelect
							name="users"
							loading={usersFilterState.areEntitiesLoading}
							data={usersFilterState.entities}
							value={usersFilter}
							dataItemKey="id"
							textField="fullName"
							autoClose={false}
							onChange={onUsersChange}
							filterable
							onFilterChange={onFilterChange}
						/>
					</div>
				</div>
				{(state.isUpdating || plannedTasksState.areEntitiesLoading) && <Loader />}
				<WeekPlanning
					week={selectedWeek}
					companyId={currentUser.currentCompanyId}
					drag={drag}
					drop={drop}
					plannedTasks={plannedTasksState.entities}
					teams={selectedUsers}
					cancelTask={cancelTask}
				/>
			</div>
		</div>
	);
};

export default PlanningManagement;
