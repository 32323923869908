import React from "react";

import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { GridFilterCellProps } from "@progress/kendo-react-grid";

export function dropdownFilterCell<T>(data: T[], defaultItem: T, dataItemKey: string, textField: string): React.FC<GridFilterCellProps> {
	return (props: GridFilterCellProps) => {
		function hasValue(value: T): boolean {
			return Boolean(value && value !== defaultItem);
		}

		function onChange(event: DropDownListChangeEvent): void {
			const valueSelected: boolean = hasValue(event.target.value);
			props.onChange({
				value: valueSelected ? event.target.value[textField] : "",
				operator: valueSelected ? "eq" : "",
				syntheticEvent: event.syntheticEvent
			});
		}

		function onClearButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
			event.preventDefault();
			props.onChange({
				value: "",
				operator: "",
				syntheticEvent: event
			});
		}

		// @ts-ignore
		const selectedValue: T = {};
		// @ts-ignore
		selectedValue[textField] = props.value;

		return (
			<div className="k-filtercell">
				<DropDownList
					data={data}
					dataItemKey={dataItemKey}
					textField={textField}
					onChange={onChange}
					value={selectedValue || defaultItem}
					defaultItem={defaultItem}
				/>
				<button
					className="k-button k-button-icon k-clear-button-visible"
					title="Clear"
					disabled={!hasValue(props.value)}
					onClick={onClearButtonClick}
				>
					<span className="k-icon k-i-filter-clear" />
				</button>
			</div>
		);
	};
}

export default dropdownFilterCell;