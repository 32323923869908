import React, { Dispatch, useEffect, useReducer, useRef, useState } from "react";

import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxCloseEvent, ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input, NumericTextBoxChangeEvent, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch as ReduxDispatch } from "redux";

import callApi from "../../../../services/api/callApi";
import Endpoint from "../../../../services/api/endpoint";
import { getInitialState, initialProductState } from "../../../../state";
import { hideLoader, showLoader } from "../../../../state/actions/loaderActions";
import { brandReducer, productReducer, productTypeReducer, supplierReducer } from "../../../../state/reducers";
import { IApplicationState } from "../../../../store";
import { handleChange, setEntity } from "../../../../utils/editorUtils";
import { Permission } from "../../../../utils/enums";
import { IBrand, IEntity, IProduct, IProductType, ISupplier, IUser } from "../../../../utils/types/models";
import { IAction } from "../../../../utils/types/types";
import { isNullOrEmpty } from "../../../../utils/utils";
import EntityEditor, { IEditorProps, IEditScreenProps, ManageableField, NumericInput, SearchBox, TextArea, YesNoSwitch } from "../../../global/editor";
import ImageGallery from "../../../global/imageGallery";
import TabPanel, { Tab } from "../../../global/tabpanel";
import BrandEditor from "../brands/editor";
import ProductTypeEditor from "../producttypes/editor";
import Stock from "../stock";
import SupplierEditor from "../suppliers/editor";

import style from "./products.module.scss";

const ProductEditor: React.FC<IEditorProps<IProduct>> = (props: IEditorProps<IProduct>) => {
	const { t } = useTranslation();
	const [productState, productDispatch] = useReducer(productReducer, initialProductState);
	const [supplierState, supplierDispatch] = useReducer(supplierReducer, getInitialState<ISupplier>());
	const [productTypeState, productTypeDispatch] = useReducer(productTypeReducer, getInitialState<IProductType>());
	const [brandState, brandDispatch] = useReducer(brandReducer, getInitialState<IBrand>());
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);
	const initalProduct: IProduct = {
		id: (props as IEditScreenProps<IProduct>).recordId || 0,
		modelNumber: "",
		supplierId: 0,
		supplier: null,
		brandId: 0,
		brand: null,
		description: "",
		salesPriceExclVat: 0,
		active: true,
		eanInformation: null,
		companyId: currentUser.currentCompanyId
	};
	const [product, setProduct] = useState<IProduct>(initalProduct);
	const [dataChange, setDataChanged] = useState<boolean>(false);
	const firstField: React.MutableRefObject<Input> = useRef();
	const currenUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);
	const reduxDispatch: ReduxDispatch = useDispatch();

	useEffect(() => {
		if (productState.entity) {
			setProduct(productState.entity);
		}
	}, [productState.entity]);

	useEffect(() => {
		if (productState.isBarcodeLookupLoading) {
			reduxDispatch(showLoader());
		} else {
			reduxDispatch(hideLoader());
		}
	}, [reduxDispatch, productState.isBarcodeLookupLoading]);

	useEffect(() => {
		if (productState.barcodeLookupResult) {
			setProduct((prevProduct: IProduct) => {
				let barcode: string = prevProduct.barcode;
				if (isNullOrEmpty(barcode)) {
					barcode = productState.barcodeLookupResult.barcode;
				}
				let model: string = prevProduct.modelNumber;
				if (!model) {
					model = productState.barcodeLookupResult.model;
				}
				return {
					...prevProduct,
					modelNumber: model,
					barcode,
					eanInformation: productState.barcodeLookupResult
				};
			});
			setDataChanged(true);
		}
	}, [productState.barcodeLookupResult]);

	function onChange(event: React.ChangeEvent<HTMLInputElement> | SwitchChangeEvent | ComboBoxCloseEvent | NumericTextBoxChangeEvent): void {
		const newProduct: IProduct = handleChange(product, event);
		newProduct.description = getDescription(newProduct.modelNumber, newProduct.brand, newProduct.productType);
		setProduct(newProduct);
		setDataChanged(true);
	}

	function getDescription(modelNumber: string, brand: IBrand, type: IProductType): string {
		let result: string = "";
		if (!isNullOrEmpty(modelNumber) && modelNumber.trim() !== "-") {
			result += modelNumber;
		}
		if (brand && brand.name && brand.name.trim() !== "-") {
			if (!isNullOrEmpty(result)) {
				result += " - ";
			}
			result += brand.name;
		}
		if (type && type.name && type.name.trim() !== "-") {
			if (!isNullOrEmpty(result) && modelNumber.trim() !== "-") {
				result += " - ";
			}
			result += type.name;
		}
		return result;
	}

	function onFilterChange(event: ComboBoxFilterChangeEvent): void {
		let endPoint: Endpoint = Endpoint.Products;
		let dispatch: Dispatch<IAction> = productDispatch;
		if (event.target.name === "supplierId") {
			endPoint = Endpoint.Suppliers;
			dispatch = supplierDispatch;
		} else if (event.target.name === "brandId") {
			endPoint = Endpoint.Brands;
			dispatch = brandDispatch;
		} else if (event.target.name === "productTypeId") {
			endPoint = Endpoint.ProductTypes;
			dispatch = productTypeDispatch;
		}
		callApi(dispatch, endPoint, "GET", { search: event.filter.value, companyId: currenUser.currentCompanyId });
	}

	function setChild<T extends IEntity>(entity: T, field: string, idField: string): void {
		const newProduct: IProduct = setEntity(product, entity, field, idField);
		newProduct.description = getDescription(newProduct.modelNumber, newProduct.brand, newProduct.productType);
		setProduct(newProduct);
		setDataChanged(true);
	}

	function searchBarcode(): void {
		callApi(productDispatch, Endpoint.ProductsBarcodeLookup, "GET", { search: product.modelNumber, barcode: product.barcode });
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(product.modelNumber)) {
			messages.push(t("fill_in_required_field", { field: t("code").toLowerCase() }));
		}
		if (!product.supplierId) {
			messages.push(t("fill_in_required_field", { field: t("supplier").toLowerCase() }));
		}
		if (!product.brand) {
			messages.push(t("fill_in_required_field", { field: t("brand").toLowerCase() }));
		}
		if (isNullOrEmpty(product.description)) {
			messages.push(t("fill_in_required_field", { field: t("description").toLowerCase() }));
		}
		if (!product.salesPriceExclVat && product.salesPriceExclVat !== 0) {
			messages.push(t("fill_in_required_field", { field: t("salesPriceExclVat").toLowerCase() }));
		}
		if (product.id && product.id === product.replacementProductId) {
			messages.push(t("replacement_product_cannot_be_the_same"));
		}
		return messages;
	}

	const readonly: boolean = (props as IEditScreenProps<IProduct>).readonly || false;

	const content: JSX.Element = (
		<div className="k-form">
			<div className="row">
				<div className="col">
					<label className="k-form-field">
						<span>{`${t("modelNumber")} ${t("or")} ${t("description").toLowerCase()}`.trim()} *</span>
						<Input name="modelNumber" ref={firstField} className="full-width-field" value={product.modelNumber} onChange={onChange} required disabled={readonly} />
					</label>
				</div>
				<div className="col">
					<div className="d-flex">
						<div className="flex-grow-1" style={!readonly ? { marginRight: "10px" } : undefined}>
							<label className="k-form-field">
								<span>{t("barcode")}</span>
								<Input name="barcode" className="full-width-field" value={product.barcode} onChange={onChange} disabled={readonly} />
							</label>
						</div>
						{!readonly && (
							<div className="d-flex align-items-end justify-content-end">
								<Button primary onClick={() => searchBarcode()}>{t("fetchEanInformation")}</Button>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<ManageableField
						fieldLabel={t("supplier") + " *"}
						recordId={product.supplierId}
						addScreen={SupplierEditor}
						editScreen={SupplierEditor}
						addPermission={Permission.SuppliersAdd}
						editPermission={Permission.SuppliersUpdate}
						setEntity={(record: ISupplier) => setChild(record, "supplier", "supplierId")}
						readOnly={readonly}
					>
						<SearchBox
							name="supplierId"
							entities={supplierState.entities}
							entityId={product.supplierId}
							entity={product.supplier}
							required
							textField="name"
							onFilterChange={onFilterChange}
							onClose={onChange}
							onClear={() => setChild(null, "supplier", "supplierId")}
							disabled={readonly}
						/>
					</ManageableField>
				</div>
				<div className="col">
					<ManageableField
						fieldLabel={t("brand") + " *"}
						recordId={product.brandId}
						addScreen={BrandEditor}
						editScreen={BrandEditor}
						addPermission={Permission.BrandsAdd}
						editPermission={Permission.BrandsUpdate}
						setEntity={(record: IBrand) => setChild(record, "brand", "brandId")}
						readOnly={readonly}
					>
						<SearchBox
							name="brandId"
							entities={brandState.entities}
							entityId={product.brandId}
							entity={product.brand}
							required
							textField="name"
							onFilterChange={onFilterChange}
							onClose={onChange}
							onClear={() => setChild(null, "brand", "brandId")}
							disabled={readonly}
						/>
					</ManageableField>
				</div>
				<div className="col">
					<ManageableField
						fieldLabel={t("type")}
						recordId={product.productTypeId}
						addScreen={ProductTypeEditor}
						editScreen={ProductTypeEditor}
						addPermission={Permission.ProductTypesAdd}
						editPermission={Permission.ProductTypesUpdate}
						setEntity={(record: IProductType) => setChild(record, "productType", "productTypeId")}
						readOnly={readonly}
					>
						<SearchBox
							name="productTypeId"
							entities={productTypeState.entities}
							entityId={product.productTypeId}
							entity={product.productType}
							textField="name"
							onFilterChange={onFilterChange}
							onClose={onChange}
							onClear={() => setChild(null, "productType", "productTypeId")}
							disabled={readonly}
						/>
					</ManageableField>
				</div>
			</div>
			<label className="k-form-field">
				<span>{t("description")} *</span>
				<Input name="description" className="full-width-field" value={product.description} onChange={onChange} required disabled />
			</label>
			<div className="row">
				<div className="col-6">
					<label className="k-form-field">
						<span>{t("salesPriceExclVat")} *</span>
						<NumericInput name="salesPriceExclVat" format="n2" className="full-width-field" value={product.salesPriceExclVat} onChange={onChange} required disabled={readonly} />
					</label>
				</div>
				<div className="col-6">
					<label className="k-form-field">
						<span>{t("replacementProduct")}</span>
						<SearchBox
							name="replacementProductId"
							entities={productState.entities}
							entityId={product.replacementProductId}
							entity={product.replacementProduct}
							textField="modelNumber"
							onFilterChange={onFilterChange}
							onClose={onChange}
							onClear={() => setChild(null, "replacementProduct", "replacementProductId")}
							disabled={readonly}
						/>
					</label>
				</div>
			</div>
			{!props.hideActive && (
				<div className="k-form-field">
					<span>{t("active")}</span>
					<YesNoSwitch name="active" checked={product.active} onChange={onChange} disabled={readonly} />
				</div>
			)}
			<fieldset>
				<legend>{t("eanInformation")}</legend>
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>{t("name")}</span>
							<Input className="full-width-field" value={product.eanInformation?.name} disabled />
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("brand")}</span>
							<Input className="full-width-field" value={product.eanInformation?.brand} disabled />
						</label>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>{t("group")}</span>
							<Input className="full-width-field" value={product.eanInformation?.category} disabled />
						</label>
					</div>
					<div className="col">
						<label className="k-form-field">
							<span>{t("manufacturer")}</span>
							<Input className="full-width-field" value={product.eanInformation?.manufacturer} disabled />
						</label>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<label className="k-form-field">
							<span>{t("description")}</span>
							<TextArea className="full-width-field" value={product.eanInformation?.description} disabled />
						</label>
					</div>
				</div>
				<ImageGallery images={product.eanInformation?.images} imageTileSize={100} />
			</fieldset>
		</div>
	);

	return (
		<EntityEditor
			width="70%"
			record={product}
			endpoint={Endpoint.Products}
			entityState={productState}
			dispatch={productDispatch}
			entityType={t("product")}
			dataChanged={dataChange}
			readonly={(props as IEditScreenProps<IProduct>).readonly}
			recordName={product.modelNumber}
			close={props.close}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>

			{product.id > 0 && (
				<TabPanel tabBarStyle={{ margin: "-16px -16px 0" }}>
					<Tab reactKey="general" label={t("general")}>
						{content}
					</Tab>
					<Tab reactKey="stock" label={t("stock")} className={style.stockGrid}>
						<Stock product={product} />
					</Tab>
				</TabPanel>
			)}
			{(!product.id || product.id <= 0) && <React.Fragment>{content}</React.Fragment>}
		</EntityEditor>
	);
};

export default ProductEditor;
