import React, { useState } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

import find from "lodash/find";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import Endpoint from "../../../services/api/endpoint";
import { authContext, hasPermission } from "../../../services/authentication";
import { IAuthenticationState } from "../../../state";
import { IApplicationState } from "../../../store";
import API from "../../../store/actions/apiActions";
import { Permission } from "../../../utils/enums";
import { IUserCompany } from "../../../utils/types/models";

import style from "./menu.module.scss";

export interface IMenuItem {
	key: string;
	iconClass: string;
	label: string;
	url: string;
	exact: boolean;
	permissions?: Permission[];
}

interface IMenuProps {
	menuItems: IMenuItem[];
}

const Menu: React.FC<IMenuProps> = (props: IMenuProps) => {
	const { t } = useTranslation();
	const [menuBarOpen, setMenuBarOpen] = useState(false);
	const [currentHoverKey, setCurrentHoverKey] = useState("");
	const authenticationState: IAuthenticationState = useSelector((state: IApplicationState) => state.authenticationState);
	const dispatch: Dispatch = useDispatch();

	function getListItem(key: string, label: string, iconClass: string, sidebarLink: boolean, onClick?: () => void): JSX.Element {
		return (
			<li
				className={currentHoverKey && currentHoverKey === key ? style.hover : ""}
				onMouseEnter={() => {
					setCurrentHoverKey(key);
					if (sidebarLink) {
						openMenuBar();
					}
				}}
				onMouseLeave={() => setCurrentHoverKey("")}
				onClick={() => {
					closeMenuBar();
					if (onClick) {
						onClick();
					}
				}}
			>
				{!sidebarLink && label}
				{sidebarLink && <div className={style.highlight} />}
				{sidebarLink && <i className={iconClass} />}
			</li>
		);
	}

	function getLinkFull(key: string, iconClass: string, label: string, url: string, exact: boolean, sidebarLink: boolean, ...permissions: Permission[]): React.ReactElement<NavLinkProps> {
		const menuItem: IMenuItem = {
			key,
			iconClass,
			label,
			url,
			exact,
			permissions
		};
		return getLink(menuItem, sidebarLink);
	}

	function getLink(item: IMenuItem, sidebarLink: boolean): React.ReactElement<NavLinkProps> {
		return (
			<NavLink to={item.url} exact={item.exact} key={item.key} activeClassName={style.active}>
				{getListItem(item.key, item.label, item.iconClass, sidebarLink)}
			</NavLink>
		);
	}

	function openMenuBar(): void {
		setMenuBarOpen(true);
	}

	function closeMenuBar(): void {
		setMenuBarOpen(false);
	}

	function switchCompany(): void {
		const newCompanyId: number = find(authenticationState.currentUser.userCompanies, (userCompany: IUserCompany) => {
			return userCompany.companyId !== authenticationState.currentUser.currentCompanyId;
		}).companyId;
		authenticationState.currentUser.currentCompanyId = newCompanyId;
		dispatch(API.getActions()[Endpoint.CurrentUser]("PUT", null, authenticationState.currentUser, null, API.getActions()[Endpoint.CurrentUser]("GET"), true));
	}

	const sidebarLinks: React.ReactElement<NavLinkProps>[] = [];
	const links: React.ReactElement<NavLinkProps>[] = [];

	props.menuItems.forEach((item: IMenuItem) => {
		if (!item.permissions || hasPermission(...item.permissions)) {
			sidebarLinks.push(getLink(item, true));
			links.push(getLink(item, false));
		}
	});

	return (
		<div id={style.sidebar} className={menuBarOpen ? style.open : ""}>
			<div id={style.menu} onMouseLeave={() => setCurrentHoverKey("")}>
				<div id={style.mainMenu} onMouseEnter={openMenuBar} onMouseLeave={closeMenuBar}>
					<ul>
						<li id={style.logo} />
						{sidebarLinks}
						{authenticationState.currentUser.userCompanies.length > 1 && getListItem("switchcompany", null, "las la-building", true, () => switchCompany())}
						<li className={style.filler} />
						{getLinkFull("privacy", "las la-info-circle", t("privacy"), "/privacy", true, true, null)}
						{getLinkFull("help", "las la-question-circle", t("help"), "/help", true, true, null)}
						{getLinkFull("myprofile", "las la-user-circle", t("privacy"), "/myprofile", true, true, null)}
						{getListItem("logout", null, "las la-sign-out-alt", true, () => authContext().logOut())}
					</ul>
				</div>
				<div id={style.subMenu} onMouseEnter={openMenuBar} onMouseLeave={closeMenuBar}>
					<ul>
						<li>{authenticationState.currentUser.currentCompany.name}</li>
						{links}
						{authenticationState.currentUser.userCompanies.length > 1 && getListItem("switchcompany", t("switchcompany"), null, false, () => switchCompany())}
						<li className={style.filler} />
						{getLinkFull("privacy", "las la-info-circle", t("privacy"), "/privacy", true, false, null)}
						{getLinkFull("help", "las la-question-circle", t("help"), "/help", true, false, null)}
						{getLinkFull("myprofile", "las la-user-circle", t("yourprofile"), "/myprofile", true, false, null)}
						{getListItem("logout", t("logout"), null, false, () => authContext().logOut())}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Menu;
