import { Reducer } from "react";

import { getInitialSystemState, ISystemEntityState } from "..";
import Endpoint from "../../services/api/endpoint";
import { IAction } from "../../utils/types/types";

export function createSystemEntityReducer<T>(
	endpoint: Endpoint,
	specificReducer: Reducer<ISystemEntityState<T>, IAction> = null
): Reducer<ISystemEntityState<T>, IAction> {
	return (state: ISystemEntityState<T> = getInitialSystemState<T>(), action: IAction): ISystemEntityState<T> => {
		switch (action.type) {
			case "REQUEST_GET_" + endpoint:
				state = {
					...state,
					isLoading: true,
					entities: [],
					loadError: null
				};
				break;
			case "REQUEST_FAILURE_GET_" + endpoint:
				state = {
					...state,
					loadError: action.payload.error
				};
				break;
			case "REQUEST_SUCCESS_GET_" + endpoint:
				if (action.payload && action.payload.response && action.payload.response.data) {
					state = {
						...state,
						entities: action.payload.response.data
					};
				}
				break;
			case "REQUEST_END_GET_" + endpoint:
				if (state.isLoading) {
					state = {
						...state,
						isLoading: false
					};
				}
				break;
			default:
				if (specificReducer) {
					state = specificReducer(state, action);
				}
				break;
		}
		return state;
	};
}