import React from "react";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { Input, NumericTextBox, NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { handleChange } from "../../../utils/editorUtils";
import { SettingType } from "../../../utils/enums";
import { ISetting } from "../../../utils/types/models";

dayjs.extend(customParseFormat);

interface IProps {
	setting: ISetting;
	onChange: (setting: ISetting) => void;
	readOnly: boolean;
}

const SettingEditor: React.FC<IProps> = (props: IProps) => {
	function onChange(event: NumericTextBoxChangeEvent | React.ChangeEvent<HTMLInputElement>): void {
		const newSetting: ISetting = handleChange<ISetting, string>(props.setting, event);
		props.onChange(newSetting);
	}

	switch (props.setting.type) {
		case SettingType.Integer:
			return <NumericTextBox name="value" value={parseInt(props.setting.value, 0)} onChange={onChange} format="n0" className="full-width-field" disabled={props.readOnly} />;
		default:
			return <Input name="value" value={props.setting.value} onChange={onChange} className="full-width-field" disabled={props.readOnly} />;
	}
};

export default SettingEditor;
