import React from "react";

import { GridCellProps } from "@progress/kendo-react-grid";
import { IntlService, useInternationalization } from "@progress/kendo-react-intl";

import { cellContentsFunction } from ".";
import { dateToString } from "../../../../../utils/utils";

export function dateCell(): cellContentsFunction {
	return (props: GridCellProps) => {
		const intl: IntlService = useInternationalization();

		return <React.Fragment>{dateToString(props.dataItem[props.field], intl)}</React.Fragment>;
	};
}