import { Dispatch } from "react";

import { AxiosError, AxiosResponse, Method } from "axios";
import { Dispatch as ReduxDispatch } from "redux";

import { IAction, IEndpointType, IRequestOptions } from "../../utils/types/types";
import { stringFormat, urlFormat } from "../../utils/utils";
import { fetch } from "../authentication";
import handleError from "../errorHandling";
import Endpoint from "./endpoint";
import endpoints from "./endpoints";

export default function callApi(
	dispatch: Dispatch<IAction> | ReduxDispatch,
	endpointName: Endpoint,
	method: Method,
	urlArguments?: object,
	// tslint:disable-next-line: no-any
	data?: any,
	payloadOptions?: object,
	// tslint:disable-next-line: no-any
	actionAfterComplete?: any,
	dispatchActionAfterComplete: boolean = false
): void {
	const eventType: string = stringFormat("{0}_{1}", method, endpointName).toUpperCase();
	const endpoint: IEndpointType = endpoints[endpointName];
	dispatch({
		type: stringFormat("REQUEST_{0}", eventType).toUpperCase(),
		payload: {
			method,
			urlArguments,
			data,
			options: payloadOptions
		}
	});

	const requestOptions: IRequestOptions = {
		url: urlFormat(endpoint.href, urlArguments || {}),
		method,
		headers: {},
		responseType: null
	};
	if (method !== "GET") {
		requestOptions.data = data;
	}
	if (endpointName === Endpoint.DownloadDossierExcel) {
		requestOptions.responseType = "blob";
	}

	let success: boolean = false;

	fetch(requestOptions, endpoint.authNeeded)
		.then((response: AxiosResponse) => {
			success = true;
			dispatch({
				type: stringFormat("REQUEST_SUCCESS_{0}", eventType).toUpperCase(),
				payload: {
					response,
					options: payloadOptions || {}
				}
			});
		})
		.catch((err: AxiosError) => {
			handleError(err);
			dispatch({
				type: stringFormat("REQUEST_FAILURE_{0}", eventType).toUpperCase(),
				payload: {
					error: err
				}
			});
		})
		.then(() => {
			dispatch({
				type: stringFormat("REQUEST_END_{0}", eventType).toUpperCase(),
				payload: {
					options: payloadOptions || {}
				}
			});
			if (actionAfterComplete && dispatchActionAfterComplete) {
				dispatch(actionAfterComplete);
			} else if (actionAfterComplete) {
				actionAfterComplete(success);
			}
		});
}