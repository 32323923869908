import React, { useEffect, useReducer, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import Endpoint from "../../../services/api/endpoint";
import { getInitialState } from "../../../state";
import { myRemarkReducer } from "../../../state/reducers";
import { IRemark } from "../../../utils/types/models";
import EntityEditor, { IEditScreenProps } from "../../global/editor";
import RemarkEditorContent, { IRemarkEditorContentRef } from "../work/dossiermanagement/editor/remarks/editorContent";

const RemarkEditor: React.FC<IEditScreenProps<IRemark>> = (props: IEditScreenProps<IRemark>) => {
	const { t } = useTranslation();
	const [dataChanged, setDataChanged] = useState(false);
	const initalRemark: IRemark = {
		id: props.recordId,
		dossierId: null,
		type: null,
		relatedTo: null,
		finished: false,
		content: "",
		photos: []
	};
	const [remark, setRemark] = useState<IRemark>(initalRemark);
	const [remarkState, remarkDispatch] = useReducer(myRemarkReducer, getInitialState<IRemark>());
	const contentRef: React.MutableRefObject<IRemarkEditorContentRef> = useRef<IRemarkEditorContentRef>(null);

	useEffect(() => {
		if (remarkState.entity) {
			setRemark(remarkState.entity);
		}
	}, [remarkState.entity]);

	function updateRemark(newRemark: IRemark): void {
		setRemark(newRemark);
		setDataChanged(true);
	}

	const action: string = (props as IEditScreenProps<IRemark>).recordId ? t("edit") : t("add");
	return (
		<EntityEditor
			width="70%"
			endpoint={Endpoint.MyRemarks}
			entityState={remarkState}
			dispatch={remarkDispatch}
			title={`${action} ${t("remark")}`.trim()}
			close={props.close}
			dataChanged={dataChanged}
			getErrorMessages={contentRef.current?.getErrorMessages}
			record={remark}
			readonly={props.readonly}
			recordName=""
			entityType={t("remark")}
		>
			{remark.type && <RemarkEditorContent ref={contentRef} remark={remark} articles={remark.dossier.articles} onRemarkChange={updateRemark} />}
		</EntityEditor>
	);
};

export default RemarkEditor; 