import React from "react";

import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import { IDossierPrintData, IDossierTask, IFileData } from "../../../../../utils/types/models";

import style from "./print.module.scss";

interface IProps {
	record?: IDossierTask;
	onClose: () => void;
	readOnly: boolean;
	dossierPrintData: IDossierPrintData;
}

const PrintDossierTask: React.FC<IProps> = ({ record, onClose, readOnly, dossierPrintData }) => {
	const { t } = useTranslation();
	const componentRef = React.useRef(null);

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, [componentRef.current]);

	const reactToPrintTrigger = React.useCallback(() => {
		return <Button primary>Print</Button>;
	}, []);

	function getTaskPictures(): JSX.Element {
		return (
			<>
				{record.photos.map((r: IFileData) => {
					return (
						<img
							key={"image_" + r.filename}
							src={"/api/files/" + r.internalFilename + "/" + r.filename}
							alt="Nothing found"
							style={{ width: "80%", marginLeft: "10px", marginBottom: "10px", pageBreakInside: "avoid" }}
						/>
					);
				})}
			</>
		);
	}

	return (
		<>
			<Dialog title="Afdrukken Dossiertaak" width={710} onClose={onClose}>
				<div ref={componentRef}>
					<p>
						<span className={style.spanStyle}>{t("customer")}:</span>
						<span>
							<i>{dossierPrintData.customerName}</i>
						</span>
					</p>

					<p>
						<span className={style.spanStyle}>{t("dossierNumber")}:</span>
						<span>
							{" "}
							<i>{dossierPrintData.dossierNumber}</i>{" "}
						</span>
					</p>

					<p>
						<span className={style.spanStyle}>{t("dossierDescription")}:</span>
						<span>
							<i>{dossierPrintData.description}</i>
						</span>
					</p>

					<br />

					<p>
						<span className={style.spanStyle}>Taak Beschrijving:</span>
						<span>
							<i>{record.description}</i>
						</span>
					</p>

					{record.photos?.length > 0 && (
						<>
							<p style={{ fontWeight: "bold" }}>Taak foto's:</p>
							{getTaskPictures()}
						</>
					)}
				</div>

				<DialogActionsBar>
					<Button onClick={onClose}>{readOnly ? t("close") : t("cancel")}</Button>
					<ReactToPrint
						content={reactToPrintContent}
						documentTitle="AwesomeFileName"
						onAfterPrint={onClose}
						//onBeforeGetContent={handleOnBeforeGetContent}
						//onBeforePrint={handleBeforePrint}
						removeAfterPrint
						trigger={reactToPrintTrigger}
					/>
				</DialogActionsBar>
			</Dialog>
		</>
	);
};

export default PrintDossierTask;
