import { Reducer } from "react";
import { initialTotalState, ITotalState } from "..";
import Endpoint from "../../services/api/endpoint";

import { IAction } from "../../utils/types/types";

const totalReducer: Reducer<ITotalState, IAction> = (state: ITotalState = initialTotalState, action: IAction) => {
	switch (action.type) {
		case "REQUEST_POST_" + Endpoint.TotalConfigurationField:
			state = {
				...state,
				isExecuting: true,
				totalResult: null,
				error: null
			};
			break;
		case "REQUEST_SUCCESS_POST_" + Endpoint.TotalConfigurationField:
			if (action.payload && action.payload.response && action.payload.response.data) {
				state = {
					...state,
					totalResult: action.payload.response.data
				};
			}
			break;
		case "REQUEST_FAILURE_POST_" + Endpoint.TotalConfigurationField:
			state = {
				...state,
				error: action.payload.error
			};
			break;
		case "REQUEST_END_POST_" + Endpoint.TotalConfigurationField:
			state = {
				...state,
				isExecuting: false
			};
			break;
		case "CLEAR_POST_" + Endpoint.TotalConfigurationField:
			state = {
				...state,
				isExecuting: false,
				totalResult: null,
				error: null
			};
			break;
		default:
			return state;
	}
	return state;
};

export default totalReducer;