import { Reducer } from "react";

import { ILoaderState } from "..";
import { IAction } from "../../utils/types/types";

const initialState: ILoaderState = {
	showLoader: false
};

const loaderReducer: Reducer<ILoaderState, IAction> = (state: ILoaderState = initialState, action: IAction) => {
	switch (action.type) {
		case "SHOW_LOADER":
			state = {
				...state,
				showLoader: true
			};
			break;
		case "HIDE_LOADER":
			state = {
				...state,
				showLoader: false
			};
			break;
		default:
			return state;
	}
	return state;
};

export default loaderReducer;