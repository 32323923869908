import { Reducer } from "react";
import { initalUserState, IUserState } from "..";
import Endpoint from "../../services/api/endpoint";
import { IAction } from "../../utils/types/types";
import { createEntityReducer } from "./entityReducer";

const userReducer: Reducer<IUserState, IAction> = (state: IUserState = initalUserState, action: IAction) => {
	switch (action.type) {
		case "REQUEST_GET_" + Endpoint.UserLeadInstallers:
			state = {
				...state,
				isLeadsLoading: true
			};
			break;
		case "REQUEST_SUCCESS_GET_" + Endpoint.UserLeadInstallers:
			if (action.payload && action.payload.response && action.payload.response.data) {
				state = {
					...state,
					leadsEntities: action.payload.response.data
				};
			}
			break;
		case "REQUEST_FAILURE_GET_" + Endpoint.UserLeadInstallers:
			state = {
				...state,
				leadsError: action.payload.error
			};
			break;
		case "REQUEST_END_GET_" + Endpoint.UserLeadInstallers:
			state = {
				...state,
				isLeadsLoading: false
			};
			break;
		case "CLEAR_GET_" + Endpoint.UserLeadInstallers:
			state = {
				...state,
				leadsEntities: null
			};
			break;
		case "REQUEST_GET_" + Endpoint.UserColleagueInstallers:
			state = {
				...state,
				isColleaguesLoading: true
			};
			break;
		case "REQUEST_SUCCESS_GET_" + Endpoint.UserColleagueInstallers:
			if (action.payload && action.payload.response && action.payload.response.data) {
				state = {
					...state,
					colleaguesEntities: action.payload.response.data
				};
			}
			break;
		case "REQUEST_FAILURE_GET_" + Endpoint.UserColleagueInstallers:
			state = {
				...state,
				colleaguesError: action.payload.error
			};
			break;
		case "REQUEST_END_GET_" + Endpoint.UserColleagueInstallers:
			state = {
				...state,
				isColleaguesLoading: false
			};
			break;
		case "CLEAR_GET_" + Endpoint.UserColleagueInstallers:
			state = {
				...state,
				colleaguesEntities: null
			};
			break;
		default:
			return state;
	}
	return state;
};

export default createEntityReducer(Endpoint.Users, Endpoint.UsersList, userReducer, initalUserState);
