import React, { forwardRef, RefAttributes, useEffect, useImperativeHandle, useReducer, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { CompositeFilterDescriptor, GroupDescriptor, SortDescriptor, State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn, GridColumnProps, GridDataStateChangeEvent, GridRowProps, GridToolbar } from "@progress/kendo-react-grid";
import { GridExpandChangeEvent, GridFilterChangeEvent, GridRowClickEvent, GridRowDoubleClickEvent } from "@progress/kendo-react-grid/dist/npm/interfaces/events";
import { History } from "history";
import fileDownload from "js-file-download";
import includes from "lodash/includes";
import map from "lodash/map";
import { useTranslation } from "react-i18next";
import { Dispatch } from "redux";

import { useDispatch, useSelector } from "react-redux";
import callApi from "../../../services/api/callApi";
import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { getInitialState, IDossierManagementFilterState, IEntityState, initialFileState, initialTotalState } from "../../../state";
import { setFilter } from "../../../state/actions/filterActions";
import { derender, render } from "../../../state/actions/renderActions";
import { createEntityReducer } from "../../../state/reducers/entityReducer";
import { IApplicationState } from "../../../store";
import { Permission } from "../../../utils/enums";
import { IEntity } from "../../../utils/types/models";
import { isNullOrEmpty, newKey } from "../../../utils/utils";
import Confirm from "../confirm";
import { IAddscreenProps, IEditScreenProps } from "../editor";
import Loader from "../loader";
import { commandCell, customCell } from "./customCells/gridCells";
import { IAnchorCommand, IFunctionCommand, ILinkCommand, IRecordCommand } from "./customCells/gridCells/commandCell";

import { fileReducer, totalReducer } from "../../../state/reducers";
import "./gridpanel.scss";

export function getGridCommandColumn<T>(title: string, commands: (IFunctionCommand<T> | IRecordCommand<T> | ILinkCommand<T> | IAnchorCommand<T>)[]): JSX.Element {
	return <GridColumn title={title} cell={customCell(commandCell(commands))} width={Math.max(60, commands.length * 20 + 28) + "px"} filterable={false} editable={false} resizable={false} />;
}

export interface ITotalField {
	field: string;
	label: string;
}

interface IGridPanelProps<T extends IEntity> {
	listEndpoint: Endpoint;
	listUrlArguments?: object;
	endpoint?: Endpoint;
	children?: React.ReactElement<GridColumnProps>[] | React.ReactElement<GridColumnProps>;
	addScreen?: React.ComponentType<IAddscreenProps<T>>;
	extraAddScreenProps?: object;
	editScreen?: React.ComponentType<IEditScreenProps<T>>;
	extraEditScreenProps?: object;
	showDelete: boolean;
	addLink?: string;
	editLink?: string;
	addPermission?: Permission;
	editPermission?: Permission;
	deletePermission?: Permission;
	skip?: number;
	take?: number;
	filter?: CompositeFilterDescriptor;
	useReduxFilter?: boolean;
	group?: GroupDescriptor[];
	sort?: SortDescriptor[];
	style?: React.CSSProperties;
	frontActions?: boolean;
	disableDoubleClick?: boolean;
	newTab?: boolean;
	className?: string;
	selectionField?: string;
	onSelectionChange?: (entity: T) => void;
	extraCommands?: (IFunctionCommand<T> | IRecordCommand<T> | ILinkCommand<T>)[];
	totalFields?: ITotalField[];
	totalConfigurationFieldEndpoint?: Endpoint;
	hideToolbar?: boolean;
	getRowClass?: (record: T) => string;
	extraToolbarItems?: React.ReactNode[];
	showAllRecords?: boolean;
	exportExcel?: Endpoint;
	resizable?: boolean;
}

export interface IGridPanelRef<T extends IEntity> {
	refresh: () => void;
	gridEntities: T[];
}

let timeout: NodeJS.Timeout = null;

function GridPanelWithoutRef<T extends IEntity>(props: IGridPanelProps<T>, ref: React.Ref<IGridPanelRef<T>>): JSX.Element {
	const { t } = useTranslation();
	const history: History = useHistory();
	const [state, dispatch] = useReducer(createEntityReducer<T, IEntityState<T>>(props.endpoint, props.listEndpoint), getInitialState<T>());
	const [fileState, fileDispatch] = useReducer(fileReducer, initialFileState);
	const [totalState, totalDispatch] = useReducer(totalReducer, initialTotalState);
	const [commands, setCommands] = useState<(IFunctionCommand<T> | IRecordCommand<T> | ILinkCommand<T>)[]>([]);
	const filterState: IDossierManagementFilterState = useSelector((appState: IApplicationState) => appState.dossierManagementFilterState);
	const initialFilter: CompositeFilterDescriptor = props.useReduxFilter ? filterState.filter : props.filter;
	const initState: State = { skip: props.skip ?? 0, take: props.take ?? 25, filter: initialFilter, group: props.group, sort: props.sort };
	const [gridState, setGridState] = useState<State>(initState);
	const [displayFilter, setDisplayFilter] = useState(initialFilter);
	const [entities, setEntities] = useState<T[]>([]);
	const [selectedId, setSelectedId] = useState(-1);
	const [firstLoad, setFirstLoad] = useState(true);
	const reduxDispatch: Dispatch = useDispatch();

	function refreshGrid(): void {
		setSelectedId(-1);
		if (props.onSelectionChange) {
			props.onSelectionChange(null);
		}
		callApi(dispatch, props.listEndpoint, "POST", { ...props.listUrlArguments }, gridState);
		let aggregatorValues: string = "";
		if (props.totalFields && props.totalConfigurationFieldEndpoint) {
			props.totalFields.forEach((value: ITotalField, index: number) => {
				aggregatorValues += value.field;
				if (!(index === props.totalFields.length - 1)) {
					aggregatorValues += ",";
				}
			});
			callApi(totalDispatch, props.totalConfigurationFieldEndpoint, "POST", { ...props.listUrlArguments, aggregatorValues }, gridState);
		}
	}

	useImperativeHandle(ref, () => ({
		refresh: () => {
			refreshGrid();
		},
		gridEntities: [...entities]
	}));

	useEffect(() => {
		if (fileState.filename) {
			fileDownload(fileState.downloadFile, fileState.filename);
		}
	}, [fileState.downloadFile, fileState.filename]);

	useEffect(() => {
		const newCommands: (IFunctionCommand<T> | IRecordCommand<T> | ILinkCommand<T>)[] = props.extraCommands || [];
		if (props.showDelete && (!props.deletePermission || hasPermission(props.deletePermission))) {
			newCommands.unshift({
				tooltip: t("remove"),
				iconClass: "las la-times",
				idAction: deleteEntity
			});
		}

		if (!props.editPermission || hasPermission(props.editPermission)) {
			if (props.editLink) {
				newCommands.unshift({
					tooltip: t("edit"),
					iconClass: "las la-pencil-alt",
					link: props.editLink,
					newTab: props.newTab ? true : false
				});
			} else if (props.editScreen) {
				newCommands.unshift({
					tooltip: t("edit"),
					iconClass: "las la-pencil-alt",
					idAction: editEntity
				});
			}
		} else {
			if (props.editLink) {
				newCommands.unshift({
					tooltip: t("show"),
					iconClass: "las la-search",
					link: props.editLink,
					newTab: props.newTab ? true : false
				});
			}
		}

		setCommands(newCommands);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		refreshGrid();
		setFirstLoad(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gridState, props.listEndpoint]);

	useEffect(() => {
		if (state.list) {
			setEntities(state.list);
		}
	}, [state.list, totalState]);

	useEffect(() => {
		if (!firstLoad) {
			if (props.useReduxFilter && !filterState.debounce) {
				setGridState((oldState: State) => ({ ...oldState, filter: filterState.filter }));
			} else {
				clearTimeout(timeout);
				timeout = setTimeout(() => setGridState((oldState: State) => ({ ...oldState, filter: props.useReduxFilter ? filterState.filter : displayFilter })), 500);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [displayFilter, filterState.filter, props.useReduxFilter]);

	function addEntity(): void {
		const key: string = newKey("add_" + typeof state.entity);
		reduxDispatch(
			render(key, props.addScreen, {
				close: (record: T) => closeEditor(key, record),
				...props.extraAddScreenProps
			})
		);
	}

	function editEntity(id: number): void {
		if (props.editLink) {
			if (props.newTab) {
				window.open("#" + props.editLink.replace(":id", id.toString()), "_blank");
			} else {
				history.push(props.editLink.replace(":id", id.toString()));
			}
		} else if (props.editScreen) {
			const key: string = newKey("edit_" + typeof state.entity);
			reduxDispatch(
				render(key, props.editScreen, {
					recordId: id,
					readonly: props.editPermission && !hasPermission(props.editPermission),
					close: (record: T) => closeEditor(key, record),
					...props.extraAddScreenProps
				})
			);
		}
	}

	function deleteEntity(id: number): void {
		if (!props.deletePermission || props.deletePermission) {
			const confirmKey: string = newKey("confirm");
			reduxDispatch(
				render(confirmKey, Confirm, {
					title: t("confirm_title", { action: t("remove").toLowerCase() }),
					children: t("confirm_content", { action: t("remove").toLowerCase() }),
					onDecline: () => reduxDispatch(derender(confirmKey)),
					onConfirm: () => {
						reduxDispatch(derender(confirmKey));
						callApi(dispatch, props.endpoint, "DELETE", { id }, null, null, () => refreshGrid());
					}
				})
			);
		}
	}

	function onExpandChange(event: GridExpandChangeEvent): void {
		event.dataItem[event.target.props.expandField] = event.value;
		setEntities([...entities]);
	}

	function onRowClick(event: GridRowClickEvent): void {
		setSelectedId(props.selectionField ? event.dataItem[props.selectionField] : event.dataItem.id);
		if (props.onSelectionChange) {
			props.onSelectionChange(event.dataItem);
		}
	}

	function rowRender(row: React.ReactElement<HTMLTableRowElement>, rowProps: GridRowProps): React.ReactNode {
		const htmlRowProps: HTMLTableRowElement = { ...row.props };

		if (props.getRowClass) {
			const rowClass: string = props.getRowClass(rowProps.dataItem);
			if (!isNullOrEmpty(rowClass)) {
				htmlRowProps.className = rowClass;
			}
		}

		const isSelected: boolean = (props.selectionField ? rowProps.dataItem[props.selectionField] : rowProps.dataItem.id) === selectedId;
		if (rowProps.rowType === "data" && isSelected && !includes(htmlRowProps.className, "k-state-selected")) {
			htmlRowProps.className += " k-state-selected";
		} else if (rowProps.rowType === "data" && isSelected && includes(htmlRowProps.className, "k-state-selected")) {
			htmlRowProps.className = htmlRowProps.className.replace("k-state-selected", "");
		}
		return React.cloneElement(row, htmlRowProps, row.props.children);
	}

	function closeEditor(key: string, record: T): void {
		reduxDispatch(derender(key));
		if (record) {
			refreshGrid();
		}
	}

	function onFilterChange(event: GridFilterChangeEvent): void {
		if (props.useReduxFilter) {
			reduxDispatch(setFilter(event.filter));
		} else {
			setDisplayFilter(event.filter);
		}
	}

	function exportExcel(): void {
		callApi(fileDispatch, props.exportExcel, "POST", { ...props.listUrlArguments }, gridState);
	}

	let addButton: JSX.Element = null;
	if (!props.addPermission || hasPermission(props.addPermission)) {
		if (props.addLink) {
			addButton = (
				<Link to={props.addLink}>
					<Button primary>{t("add")}</Button>
				</Link>
			);
		} else if (props.addScreen) {
			addButton = (
				<Button primary onClick={addEntity}>
					{t("add")}
				</Button>
			);
		}
	}

	return (
		<>
			{(state.isListLoading || state.isDeleting) && <Loader />}
			<Grid
				className={"noTopBorder flex-grow-1 flex-shrink-1 " + props.className || ""}
				style={{ ...props.style }}
				total={state.totalCount}
				{...gridState}
				filter={props.useReduxFilter ? filterState.filter : displayFilter}
				onDataStateChange={(event: GridDataStateChangeEvent) => setGridState(event.data)}
				onRowDoubleClick={
					!props.disableDoubleClick
						? (event: GridRowDoubleClickEvent) => editEntity(event.dataItem.id)
						: () => {
								return;
						  }
				}
				onExpandChange={onExpandChange}
				onRowClick={onRowClick}
				rowRender={rowRender}
				expandField="expanded"
				data={entities}
				filterable
				sortable
				pageable={{ pageSizes: [10, 25, 50, 100] }}
				scrollable="scrollable"
				onFilterChange={onFilterChange}
				resizable={props.resizable}
			>
				{!props.hideToolbar && (
					<GridToolbar>
						<div className={(addButton ? "" : "noButtons ") + "toolbarButtonContainer d-flex width-100 align-items-center"}>
							{addButton}
							{props.extraToolbarItems}
							{props.exportExcel && (
								<button key="exportExcel" title="Export Excel" className="k-button k-primary" style={{ marginLeft: "150px" }} onClick={exportExcel}>
									{t("exportExcel")}
								</button>
							)}
							<div className="flex-grow-1 d-flex justify-content-center align-items-center">
								{totalState.totalResult &&
									map(props.totalFields, (totalField: ITotalField, index: number) => (
										<div key={index + "_" + totalField.field} className="totalField">
											{/* tslint:disable-next-line: no-any */}
											{totalField.label}:{" "}
											{Object.entries(totalState.totalResult)
												.find((a: any) => a[0] === totalField.field)[1]
												["sum"].toLocaleString()}
										</div>
									))}
							</div>
							<i className="refreshButton las la-sync" onClick={refreshGrid} />
						</div>
					</GridToolbar>
				)}
				{commands.length > 0 && props.frontActions && getGridCommandColumn(t("actions"), commands)}
				{props.children}
				{commands.length > 0 && !props.frontActions && getGridCommandColumn(t("actions"), commands)}
			</Grid>
		</>
	);
}

type GridPanelType = <T extends IEntity>(props: IGridPanelProps<T> & RefAttributes<IGridPanelRef<T>>) => JSX.Element;
const GridPanel: GridPanelType = forwardRef(GridPanelWithoutRef) as GridPanelType;

export default GridPanel;
