import { Reducer } from "react";

import { initialProductState, IProductState } from "..";
import Endpoint from "../../services/api/endpoint";
import { IAction } from "../../utils/types/types";
import { createEntityReducer } from "./entityReducer";

const productReducer: Reducer<IProductState, IAction> = (state: IProductState = initialProductState, action: IAction) => {
	switch (action.type) {
		case "REQUEST_GET_PRODUCTS_BARCODE_LOOKUP":
			state = {
				...state,
				isBarcodeLookupLoading: true,
				barcodeLookupResult: null,
				barcodeLookupError: null
			};
			break;
		case "REQUEST_SUCCESS_GET_PRODUCTS_BARCODE_LOOKUP":
			if (action.payload && action.payload.response && action.payload.response.data) {
				state = {
					...state,
					barcodeLookupResult: action.payload.response.data
				};
			}
			break;
		case "REQUEST_FAILURE_GET_PRODUCTS_BARCODE_LOOKUP":
			state = {
				...state,
				barcodeLookupError: action.payload.error
			};
			break;
		case "REQUEST_END_GET_PRODUCTS_BARCODE_LOOKUP":
			state = {
				...state,
				isBarcodeLookupLoading: false
			};
			break;
		case "CLEAR_GET_PRODUCTS_BARCODE_LOOKUP":
			state = {
				...state,
				isBarcodeLookupLoading: false,
				barcodeLookupResult: null,
				barcodeLookupError: null
			};
			break;
		default:
			return state;
	}
	return state;
};

export default createEntityReducer(Endpoint.Products, Endpoint.ProductsList, productReducer, initialProductState);