import React, { useEffect, useReducer, useRef, useState } from "react";

import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Endpoint from "../../../../services/api/endpoint";
import { getInitialState } from "../../../../state";
import { contactTypeReducer } from "../../../../state/reducers";
import { IApplicationState } from "../../../../store";
import { handleChange } from "../../../../utils/editorUtils";
import { IContactType, IUser } from "../../../../utils/types/models";
import { isNullOrEmpty } from "../../../../utils/utils";
import EntityEditor, { IEditorProps, IEditScreenProps, YesNoSwitch } from "../../../global/editor";

const ContactTypeEditor: React.FC<IEditorProps<IContactType>> = (props: IEditorProps<IContactType>) => {
	const { t } = useTranslation();
	const [contactTypeState, dispatch] = useReducer(contactTypeReducer, getInitialState<IContactType>());
	const currentUser: IUser = useSelector((reduxState: IApplicationState) => reduxState.authenticationState.currentUser);
	const initalContactType: IContactType = {
		id: (props as IEditScreenProps<IContactType>).recordId || 0,
		code: "",
		name: "",
		active: true,
		companyId: currentUser.currentCompanyId
	};
	const [contactType, setContactType] = useState<IContactType>(initalContactType);
	const [dataChange, setDataChanged] = useState<boolean>(false);
	const firstField: React.MutableRefObject<Input> = useRef();

	useEffect(() => {
		if (contactTypeState.entity) {
			setContactType(contactTypeState.entity);
		}
	}, [contactTypeState.entity]);

	function onChange(event: React.ChangeEvent<HTMLInputElement> | SwitchChangeEvent | ComboBoxChangeEvent): void {
		setContactType(handleChange(contactType, event));
		setDataChanged(true);
	}

	function getErrorMessages(): string[] {
		const messages: string[] = [];
		if (isNullOrEmpty(contactType.name)) {
			messages.push(t("fill_in_required_field", { field: t("name").toLowerCase() }));
		}
		return messages;
	}

	const readonly: boolean = (props as IEditScreenProps<IContactType>).readonly;

	return (
		<EntityEditor
			width="70%"
			record={contactType}
			endpoint={Endpoint.ContactTypes}
			entityState={contactTypeState}
			dispatch={dispatch}
			dataChanged={dataChange}
			readonly={readonly}
			entityType={t("type")}
			recordName={contactType.name}
			close={props.close}
			getErrorMessages={getErrorMessages}
			firstFieldRef={firstField}
		>
			<div className="k-form">
				<label className="k-form-field">
					<span>{t("name")} *</span>
					<Input name="name" ref={firstField} className="full-width-field" value={contactType.name} onChange={onChange} required disabled={readonly} />
				</label>
				{!props.hideActive && (
					<div className="k-form-field">
						<span>{t("active")}</span>
						<YesNoSwitch name="active" checked={contactType.active} onChange={onChange} disabled={readonly} />
					</div>
				)}
			</div>
		</EntityEditor>
	);
};

export default ContactTypeEditor;
