import React, { Dispatch, FormEvent, useEffect, useState } from "react";

import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar, DialogProps } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch as ReduxDispatch } from "redux";

import { usePreventWindowUnload } from "../../../hooks";
import callApi from "../../../services/api/callApi";
import Endpoint from "../../../services/api/endpoint";
import { notify } from "../../../services/notification";
import { IEntityState } from "../../../state";
import { hideLoader, showLoader } from "../../../state/actions/loaderActions";
import { IEntity } from "../../../utils/types/models";
import { IAction } from "../../../utils/types/types";
import Confirm from "../confirm";
import Messages from "../messages";
import AddressEdit from "./addressEdit";
import ApiFileUpload from "./apiFileUpload";
import DropDownListWithEmptyEntry from "./dropDownListWithEmptyEntry";
import EnumDropDownList from "./enumDropDownList";
import EnumRadioGroup, { EnumRadioGroupChangeEvent } from "./enumRadioGroup";
import ManageableField from "./manageableField";
import NumericInput from "./numericInput";
import SearchBox from "./searchBox";
import TextArea from "./textArea";
import TranslatedSwitch from "./translatedSwitch";
import YesNoSwitch from "./yesNoSwitch";

export interface IAddscreenProps<T extends IEntity> {
	close: (record?: T) => void;
	hideActive?: boolean;
}

export interface IEditScreenProps<T extends IEntity> extends IAddscreenProps<T> {
	recordId: number;
	readonly: boolean;
}

export type IEditorProps<T extends IEntity> = IAddscreenProps<T> | IEditScreenProps<T>;

interface IProps<T extends IEntity> extends DialogProps {
	record: T;
	endpoint: Endpoint;
	entityState: IEntityState<T>;
	dispatch: Dispatch<IAction>;
	dataChanged: boolean;
	recordName: string;
	entityType?: string;
	readonly: boolean;
	getErrorMessages: () => string[];
	close: (record?: T) => void;
	children: JSX.Element | JSX.Element[];
	firstFieldRef?: React.MutableRefObject<Input>;
	additionalUrlParameters?: object;
}

function EntityEditor<T extends IEntity>(props: IProps<T>): JSX.Element {
	const { record, endpoint, entityState, dispatch, dataChanged, recordName, entityType, readonly, getErrorMessages, close, firstFieldRef } = props;
	const { t } = useTranslation();
	const reduxDispatch: ReduxDispatch = useDispatch();
	const [askSaveChange, setAskSaveChange] = useState(false);

	usePreventWindowUnload(dataChanged);

	useEffect(() => {
		if (record.id) {
			callApi(dispatch, endpoint, "GET", { id: record.id });
		}
		return () => reduxDispatch(hideLoader());
	}, [dispatch, endpoint, record.id, reduxDispatch]);

	useEffect(() => {
		if (firstFieldRef && firstFieldRef.current) {
			firstFieldRef.current.focus();
		}
	}, [firstFieldRef]);

	useEffect(() => {
		if (!record.id && entityState.addedEntity) {
			close(entityState.addedEntity);
		} else if (record.id && entityState.updatedEntity) {
			close(entityState.updatedEntity);
		}
	}, [close, entityState.addedEntity, entityState.updatedEntity, record.id]);

	useEffect(() => {
		if (entityState.isEntityLoading || entityState.isAdding || entityState.isUpdating) {
			reduxDispatch(showLoader());
		} else if (!(entityState.isEntityLoading || entityState.isAdding || entityState.isUpdating)) {
			reduxDispatch(hideLoader());
		}
	}, [reduxDispatch, entityState.isEntityLoading, entityState.isAdding, entityState.isUpdating]);

	function save(): void {
		const errorMessages: string[] = getErrorMessages();
		if (!errorMessages || errorMessages.length <= 0) {
			if (!record.id) {
				let urlParams: object = null;
				if (props.additionalUrlParameters) {
					urlParams = { ...props.additionalUrlParameters };
				}
				callApi(dispatch, endpoint, "POST", urlParams, record);
			} else {
				const urlParams: object = props.additionalUrlParameters == null ? { id: record.id } : { ...props.additionalUrlParameters, id: record.id };
				callApi(dispatch, endpoint, "PUT", urlParams, record);
			}
		} else {
			notify(t("error"), <Messages messages={errorMessages} />, "error");
		}
	}

	let action: string = t("add");
	if (record.id) {
		action = t("edit");
	}

	function handleClose(): void {
		if (dataChanged && !askSaveChange) {
			setAskSaveChange(true);
		} else if (dataChanged && askSaveChange) {
			setAskSaveChange(false);
		} else {
			close();
		}
	}

	function onSubmit(event: FormEvent): void {
		event.stopPropagation();
		event.preventDefault();
	}

	return (
		<React.Fragment>
			<Dialog {...props} title={`${action} ${entityType} ${recordName}`.trim()} onClose={() => handleClose()}>
				<form noValidate onSubmit={onSubmit}>
					{props.children}
				</form>
				<DialogActionsBar>
					<Button onClick={() => handleClose()}>{readonly ? t("close") : t("cancel")}</Button>
					{!readonly && (
						<Button primary disabled={!dataChanged} onClick={() => save()}>
							{t("save")}
						</Button>
					)}
				</DialogActionsBar>
			</Dialog>
			{askSaveChange && (
				<Confirm
					title={t("pending_changes")}
					onConfirm={() => {
						setAskSaveChange(false);
						save();
					}}
					onDecline={() => close()}
					onCancel={() => handleClose()}
				>
					{t("ask_save")}
				</Confirm>
			)}
		</React.Fragment>
	);
}

export default EntityEditor;
export {
	AddressEdit,
	ApiFileUpload,
	EnumDropDownList,
	EnumRadioGroup,
	ManageableField,
	NumericInput,
	EnumRadioGroupChangeEvent,
	SearchBox,
	TextArea,
	TranslatedSwitch,
	YesNoSwitch,
	DropDownListWithEmptyEntry
};
