import React from "react";

import { GridCellProps } from "@progress/kendo-react-grid";

import { newKey } from "../../../../../utils/utils";
import { checkBoxCell } from "./checkboxCell";
import { commandCell } from "./commandCell";
import { dateCell } from "./dateCell";
import { multilineCell } from "./multilineCell";
import { translatedCell } from "./translatedCell";
import { weekCell } from "./weekCell";

export type cellContentsFunction = (props: GridCellProps) => JSX.Element;
type classNameFunction = (props: GridCellProps) => string;

function customCell(getCellContents?: cellContentsFunction, getCellClassName?: classNameFunction): React.FC<GridCellProps> {
	return (props: GridCellProps) => {
		if (props.rowType === "data") {
			let className: string;
			if (getCellClassName) {
				className = getCellClassName(props);
			}

			return (
				<td key={newKey("col")} className={className}>
					{getCellContents ? getCellContents(props) : props.dataItem[props.field]}
				</td>
			);
		}
		return <React.Fragment />;
	};
}
export { customCell, checkBoxCell, commandCell, dateCell, translatedCell, weekCell, multilineCell };