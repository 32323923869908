import React from "react";
import { Link } from "react-router-dom";

import { GridCellProps } from "@progress/kendo-react-grid";

import { cellContentsFunction } from ".";
import { urlFormat } from "../../../../../utils/utils";

interface ICommand<T> {
	iconClass: string;
	tooltip: string;
	showCommand?: (dataItem: T) => boolean;
}

export interface IFunctionCommand<T> extends ICommand<T> {
	idAction: (id: number) => void;
}

export interface ILinkCommand<T> extends ICommand<T> {
	link: string;
	newTab?: boolean;
}

export interface IRecordCommand<T> extends ICommand<T> {
	recordAction: (record: T) => void;
}

export interface IAnchorCommand<T> extends ICommand<T> {
	getHref: (record: T) => string;
}

export function commandCell<T>(commands: (IFunctionCommand<T> | ILinkCommand<T> | IRecordCommand<T> | IAnchorCommand<T>)[]): cellContentsFunction {
	function isFunctionCommand(toBeDetermined: IFunctionCommand<T> | ILinkCommand<T> | IRecordCommand<T> | IAnchorCommand<T>): toBeDetermined is IFunctionCommand<T> {
		if ((toBeDetermined as IFunctionCommand<T>).idAction) {
			return true;
		}
		return false;
	}

	function isLinkCommand(toBeDetermined: ILinkCommand<T> | IRecordCommand<T> | IAnchorCommand<T>): toBeDetermined is ILinkCommand<T> {
		if ((toBeDetermined as ILinkCommand<T>).link) {
			return true;
		}
		return false;
	}

	function isRecordCommand(toBeDetermined: IRecordCommand<T> | IAnchorCommand<T>): toBeDetermined is IRecordCommand<T> {
		if ((toBeDetermined as IRecordCommand<T>).recordAction) {
			return true;
		}
		return false;
	}

	return (props: GridCellProps) => {
		return (
			<React.Fragment>
				{(commands.map((command: IFunctionCommand<T> | ILinkCommand<T> | IRecordCommand<T> | IAnchorCommand<T>, index: number) => {
					if (!command.showCommand || command.showCommand(props.dataItem)) {
						if (isFunctionCommand(command)) {
							return <i key={index} className={"command " + command.iconClass} onClick={() => command.idAction(props.dataItem.id)} title={command.tooltip} />;
						} else if (isLinkCommand(command)) {
							return (
								<Link key={index} to={urlFormat(command.link, { id: props.dataItem.id })} title={command.tooltip} target={command.newTab ? "_blank" : ""}>
									<i className={"command " + command.iconClass} />
								</Link>
							);
						} else if (isRecordCommand(command)) {
							return <i key={index} className={"command " + command.iconClass} onClick={() => command.recordAction(props.dataItem)} title={command.tooltip} />;
						}
						return (
							<a key={index} href={command.getHref(props.dataItem)}>
								<i key={index} className={"command " + command.iconClass} title={command.tooltip} />
							</a>
						);
					}
					return "";
				}))}
			</React.Fragment >
		);
	};
}
