import React from "react";

import { AxiosError } from "axios";
import i18next from "i18next";
import forIn from "lodash/forIn";
import map from "lodash/map";

import { newKey } from "../utils/utils";
import { notify } from "./notification";

export default function handleError(error: AxiosError): void {
	const { response } = error;
	if (response) {
		const title: string = i18next.t("error");
		let content: string | JSX.Element = i18next.t("errors.server_error", { statuscode: response.status });
		switch (response.status) {
			case 400:
				if (response.data.errors) {
					const errors: JSX.Element[] = [];
					forIn(response.data.errors, (value: string[], key: string) => {
						errors.push(
							<li key={key}>
								{i18next.t(key)}:
								<ul>
									{map(value, (validationError: string) => (
										<li key={newKey("error")}>{validationError}</li>
									))}
								</ul>
							</li>
						);
					});
					content = (
						<React.Fragment>
							<div>{i18next.t("theseAreTheServerErrorMessages")}</div>
							<ul>{errors}</ul>
						</React.Fragment>
					);
				} else if (typeof response.data === "string") {
					const errorKey: string = "errors." + response.data;
					if (i18next.exists(errorKey)) {
						content = i18next.t(errorKey);
					}
				}
				break;
			case 401:
				content = i18next.t("errors.not_authenticated");
				break;
			case 403:
				content = i18next.t("errors.forbidden");
				break;
			case 404:
				content = i18next.t("errors.not_found");
				break;
			case 500:
				if (response.config.url.startsWith("/api/dossiers/") && response.config.method === "delete" && response.data.message === "error_entityused") {
					content = (
						<React.Fragment>
							{i18next.t("dossierCannotBeDeleted")}
							<ul>
								<li>{i18next.t("documents")}</li>
								<li>{i18next.t("articles")}</li>
								<li>{i18next.t("contacts")}</li>
								<li>{i18next.t("remarks")}</li>
								<li>{i18next.t("communication")}</li>
								<li>{i18next.t("tasks")}</li>
								<li>{i18next.t("aftersales")}</li>
							</ul>
						</React.Fragment>
					);
				} else {
					content =
						response.data.message === "error_argumentnull"
							? i18next.t("errors." + response.data.message, { argument: i18next.t(response.data.paramName) })
							: (content = i18next.t("errors." + (response.data.message || "error_general")));
				}
				break;
		}

		notify(title, content, "error");
	}
}
