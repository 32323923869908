import { Reducer } from "react";

import has from "lodash/has";

import { getInitialState, IEntityState } from "..";
import Endpoint from "../../services/api/endpoint";
import { IAction } from "../../utils/types/types";

export function createEntityReducer<T, R extends IEntityState<T> = IEntityState<T>>(
	endpoint: Endpoint,
	listEndpoint: Endpoint = null,
	specificReducer: Reducer<R, IAction> = null,
	initialState: R = getInitialState<T>() as R
): Reducer<R, IAction> {
	return (state: R = initialState, action: IAction): R => {
		switch (action.type) {
			case "REQUEST_POST_" + listEndpoint:
				state = {
					...state,
					isListLoading: true,
					listLoadError: null
				};
				break;
			case "REQUEST_SUCCESS_POST_" + listEndpoint:
				if (action.payload && action.payload.response && action.payload.response.data) {
					let results: T[] = action.payload.response.data.data;
					if (action.payload.response.data.group) {
						results = action.payload.response.data.group;
					}
					state = {
						...state,
						list: results || [],
						totalCount: action.payload.response.data.total || 0
					};
				}
				break;
			case "REQUEST_FAILURE_POST_" + listEndpoint:
				state = {
					...state,
					listLoadError: action.payload.error
				};
				break;
			case "REQUEST_END_POST_" + listEndpoint:
				state = {
					...state,
					isListLoading: false
				};
				break;
			case "CLEAR_POST_" + listEndpoint:
				state = {
					...state,
					list: [],
					totalCount: 0,
					isListLoading: false,
					listLoadError: null
				};
				break;
			case "REQUEST_GET_" + endpoint:
				if (action.payload.urlArguments && has(action.payload.urlArguments, "id")) {
					state = {
						...state,
						isEntityLoading: true,
						entitiesLoadError: null
					};
				} else {
					state = {
						...state,
						areEntitiesLoading: true,
						entityLoadError: null
					};
				}
				break;
			case "REQUEST_FAILURE_GET_" + endpoint:
				if (state.areEntitiesLoading) {
					state = {
						...state,
						entitiesLoadError: action.payload.error
					};
				} else {
					state = {
						...state,
						entityLoadError: action.payload.error
					};
				}
				break;
			case "REQUEST_SUCCESS_GET_" + endpoint:
				if (action.payload && action.payload.response && action.payload.response.data) {
					if (Array.isArray(action.payload.response.data)) {
						state = {
							...state,
							entities: action.payload.response.data
						};
					} else {
						state = {
							...state,
							entity: action.payload.response.data
						};
					}
				}
				break;
			case "REQUEST_END_GET_" + endpoint:
				if (state.areEntitiesLoading) {
					state = {
						...state,
						areEntitiesLoading: false
					};
				} else {
					state = {
						...state,
						isEntityLoading: false
					};
				}
				break;
			case "CLEAR_GET_" + endpoint:
				if (state.entity || state.entityLoadError) {
					state = {
						...state,
						entity: null,
						entityLoadError: null
					};
				} else {
					state = {
						...state,
						entities: [],
						entitiesLoadError: null
					};
				}
				break;
			case "REQUEST_POST_" + endpoint:
				state = {
					...state,
					isAdding: true,
					addError: null
				};
				break;
			case "REQUEST_SUCCESS_POST_" + endpoint:
				state = {
					...state,
					addedEntity: action.payload.response.data
				};
				break;
			case "REQUEST_FAILURE_POST_" + endpoint:
				state = {
					...state,
					addError: action.payload.error.toString()
				};
				break;
			case "REQUEST_END_POST_" + endpoint:
				state = {
					...state,
					isAdding: false
				};
				break;
			case "CLEAR_POST_" + endpoint:
				state = {
					...state,
					addedEntity: null,
					addError: null
				};
				break;
			case "REQUEST_PUT_" + endpoint:
				state = {
					...state,
					isUpdating: true,
					updateError: null
				};
				break;

			case "REQUEST_SUCCESS_PUT_" + endpoint:
				state = {
					...state,
					updatedEntity: action.payload.response.data
				};
				break;
			case "REQUEST_FAILURE_PUT_" + endpoint:
				state = {
					...state,
					updateError: action.payload.error
				};
				break;
			case "REQUEST_END_PUT_" + endpoint:
				state = {
					...state,
					isUpdating: false
				};
				break;
			case "CLEAR_PUT_" + endpoint:
				state = {
					...state,
					updatedEntity: null,
					updateError: null
				};
				break;
			case "REQUEST_DELETE_" + endpoint:
				state = {
					...state,
					isDeleting: true,
					deleteError: null
				};
				break;
			case "REQUEST_FAILURE_DELETE_" + endpoint:
				state = {
					...state,
					deleteError: action.payload.error
				};
				break;
			case "REQUEST_END_DELETE_" + endpoint:
				state = {
					...state,
					isDeleting: false
				};
				break;
			case "CLEAR_DELETE_" + endpoint:
				state = {
					...state,
					addError: null
				};
				break;
			default:
				if (specificReducer) {
					state = specificReducer(state, action);
				}
				break;
		}
		return state;
	};
}