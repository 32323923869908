enum CommunicationStatus {
	New = "New",
	Sending = "Sending",
	AwaitingReminder = "AwaitingReminder",
	SendingReminder = "SendingReminder",
	Sent = "Sent",
	Error = "Error",
	ReminderError = "ReminderError"
}

export default CommunicationStatus;