enum ArticleGroup {
	Furniture = "Furniture",
	Worktops = "Worktops",
	Plumbing = "Plumbing",
	Appliance = "Appliance",
	Accessory = "Accessory",
	Other = "Other"
}

export default ArticleGroup;
