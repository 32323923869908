import React from "react";

import { DateInputProps, SelectionRange } from "@progress/kendo-react-dateinputs";
import { Input } from "@progress/kendo-react-inputs";
import { getWeekstring } from "../../../../utils/utils";

function weekInput(week: SelectionRange): React.FC<DateInputProps> {
	return () => {
		return <Input value={getWeekstring(week)} />;
	};
}

export default weekInput;