import { Reducer } from "react";

import { IConstructionYardState, initialConstructionYardState } from "..";
import Endpoint from "../../services/api/endpoint";
import { IAction } from "../../utils/types/types";
import { createEntityReducer } from "./entityReducer";

const constructionYardReducer: Reducer<IConstructionYardState, IAction> = (state: IConstructionYardState = initialConstructionYardState, action: IAction) => {
	switch (action.type) {
		case "REQUEST_GET_CONSTRUCTIONYARDS_COPY_FROM_CUSTOMER":
			state = {
				...state,
				isCopying: true,
				copiedEntity: null,
				copyError: null
			};
			break;
		case "REQUEST_SUCCESS_GET_CONSTRUCTIONYARDS_COPY_FROM_CUSTOMER":
			if (action.payload && action.payload.response && action.payload.response.data) {
				state = {
					...state,
					copiedEntity: action.payload.response.data
				};
			}
			break;
		case "REQUEST_FAILURE_GET_CONSTRUCTIONYARDS_COPY_FROM_CUSTOMER":
			state = {
				...state,
				copyError: action.payload.error
			};
			break;
		case "REQUEST_END_GET_CONSTRUCTIONYARDS_COPY_FROM_CUSTOMER":
			state = {
				...state,
				isCopying: false
			};
			break;
		case "CLEAR_GET_CONSTRUCTIONYARDS_COPY_FROM_CUSTOMER":
			state = {
				...state,
				isCopying: false,
				copiedEntity: null,
				copyError: null
			};
			break;
		default:
			return state;
	}
	return state;
};

export default createEntityReducer(Endpoint.ConstructionYards, Endpoint.ConstructionYardsList, constructionYardReducer, initialConstructionYardState);