/* eslint-disable react-hooks/exhaustive-deps */
import React, { FormEvent, useEffect, useReducer } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Button } from "@progress/kendo-react-buttons";
import { History } from "history";
import each from "lodash/each";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import { usePreventWindowUnload } from "../../../../../hooks";
import callApi from "../../../../../services/api/callApi";
import Endpoint from "../../../../../services/api/endpoint";
import endpoints from "../../../../../services/api/endpoints";
import { fetch, hasPermission } from "../../../../../services/authentication";
import handleError from "../../../../../services/errorHandling";
import { notify } from "../../../../../services/notification";
import { getInitialState, initialDossierState, initialPriceTotalState } from "../../../../../state";
import { hideLoader, showLoader } from "../../../../../state/actions/loaderActions";
import { derender, render } from "../../../../../state/actions/renderActions";
import { dossierReducer, stockReducer, totalPriceReducer } from "../../../../../state/reducers";
import { IApplicationState } from "../../../../../store";
import { DepositType, Permission } from "../../../../../utils/enums";
import { IDossier, IEntity, IStockLine, IUser } from "../../../../../utils/types/models";
import { IRequestOptions } from "../../../../../utils/types/types";
import { dossierNumberRegex, isNullOrEmpty, newKey, urlFormat } from "../../../../../utils/utils";
import Confirm from "../../../../global/confirm";
import PendingSavePrompt from "../../../../global/PendingSavePrompt";
import Stepper from "../../../../global/stepper";
import Step from "../../../../global/stepper/step";
import AfterSales from "./aftersales";
import Articles from "./articles";
import Communications from "./communication";
import Contacts from "./contacts";
import Documents from "./documents";
import { addStockChange, changeDossier, removeStockChange, setCurrentStep, setSectionChanged, setSectionValid } from "./dossierEditorActions";
import { getInitialDossierState, IState, reducer } from "./dossierEditorReducer";
import General from "./general";
import Invoicing from "./invoicing";
import Planning from "./planning";
import Remarks from "./remarks";

import style from "./editor.module.scss";

export interface IDossierSectionProps {
	dossier: IDossier;
	onChange: (newDossier: IDossier) => void;
	readOnly: boolean;
	onAddContact?: (newDossier: IDossier) => void;
}

const DossierEditor: React.FC = () => {
	const { t } = useTranslation();
	const { id } = useParams<{ id: string }>();
	const history: History = useHistory();
	const currentUser: IUser = useSelector((reduxState: IApplicationState) => reduxState.authenticationState.currentUser);
	const initialState: IState = getInitialDossierState(id, currentUser);
	const [state, dispatch] = useReducer(reducer, initialState);
	const [dossierState, dossierDispatch] = useReducer(dossierReducer, initialDossierState);
	const [, stockDispatch] = useReducer(stockReducer, getInitialState<IStockLine>());
	const [totalPriceState, totalPriceDispatch] = useReducer(totalPriceReducer, initialPriceTotalState);
	const reduxDispatch: Dispatch = useDispatch();

	const readOnly: boolean = id && !hasPermission(Permission.DossiersUpdate);

	usePreventWindowUnload(state.dataChanged);

	useEffect(() => {
		if (dossierState.entity) {
			if (dossierState.entity.customer.companyId !== currentUser.currentCompanyId) {
				history.push("/");
			}
		}
	}, [currentUser.currentCompanyId]);

	useEffect(() => {
		if (id) {
			callApi(dossierDispatch, Endpoint.Dossiers, "GET", { id });
		}
	}, [id]);

	useEffect(() => {
		if (dossierState.entity) {
			dispatch(changeDossier(dossierState.entity));
		}
	}, [dossierState.entity]);

	useEffect(() => {
		if (dossierState.isEntityLoading) {
			reduxDispatch(showLoader());
		} else {
			reduxDispatch(hideLoader());
			if (dossierState.entityLoadError && dossierState.entityLoadError.response.status === 404) {
				const key: string = newKey("dossier_notfound");
				reduxDispatch(
					render(key, Confirm, {
						title: t("notFound", { entity: t("dossier") }),
						children: (
							<Trans i18nKey="createNew">
								This {t("dossier").toLowerCase()} is not found.
								<br />
								Do you want to create a new one?.
							</Trans>
						),
						onDecline: () => {
							reduxDispatch(derender(key));
							history.push("/work/dossiermanagement");
						},
						onConfirm: () => {
							reduxDispatch(derender(key));
							history.push("/work/dossiermanagement/new");
						}
					})
				);
			}
		}
	}, [dossierState.isEntityLoading]);

	useEffect(() => {
		if (dossierState.isAdding || dossierState.isUpdating) {
			reduxDispatch(showLoader());
		} else {
			if (!dossierState.addError && !dossierState.updateError) {
				dispatch(setSectionChanged("general", false));
				dispatch(setSectionChanged("documents", false));
				dispatch(setSectionChanged("articles", false));
				dispatch(setSectionChanged("contacts", false));
				dispatch(setSectionChanged("remarks", false));
				dispatch(setSectionChanged("communication", false));
				dispatch(setSectionChanged("invoicing", false));
				dispatch(setSectionChanged("planning", false));
				dispatch(setSectionChanged("aftersales", false));
			}
			reduxDispatch(hideLoader());
		}
	}, [dossierState.isAdding, dossierState.isUpdating]);

	useEffect(() => {
		if (!state.dataChanged && (dossierState.addedEntity || dossierState.updatedEntity)) {
			if (dossierState.addedEntity) {
				history.push("/work/dossiermanagement/" + dossierState.addedEntity.id);
			} else if (dossierState.updatedEntity) {
				callApi(dossierDispatch, Endpoint.Dossiers, "get", { id: dossierState.updatedEntity.id });
			}
		}
	}, [state.dataChanged, dossierState.addedEntity, dossierState.updatedEntity]);

	useEffect(() => {
		if (!totalPriceState.isExecuting && totalPriceState.priceResult) {
			const newDossier: IDossier = {
				...state.dossier,
				totalArticleExclVat: totalPriceState.priceResult.totalExclVat,
				totalArticleInclVat: totalPriceState.priceResult.totalInclVat,
				totalArticleVat: totalPriceState.priceResult.totalVat
			};
			dispatch(changeDossier(newDossier));
			reduxDispatch(hideLoader());
		} else if (totalPriceState.isExecuting) {
			reduxDispatch(showLoader());
		}
	}, [totalPriceState.isExecuting, totalPriceState.priceResult]);

	function changeStep(current: number): void {
		if (current >= 0 && current < 9) {
			dispatch(setCurrentStep(current));
		}
	}

	function onChange(section: string, newDossier: IDossier): void {
		dispatch(setSectionChanged(section, true));
		dispatch(changeDossier(newDossier));
	}

	async function saveWithNavigation(setNavigationAllowed: (navigationAllowed: boolean) => void): Promise<void> {
		reduxDispatch(showLoader());
		let hasErrors: boolean = false;

		try {
			let dossierRequest: IRequestOptions;
			if (!id) {
				dossierRequest = {
					url: urlFormat(endpoints[Endpoint.Dossiers], null),
					method: "POST",
					headers: {},
					data: state.dossier
				};
			} else {
				dossierRequest = {
					url: urlFormat(endpoints[Endpoint.Dossiers], { id }),
					method: "PUT",
					headers: {},
					data: state.dossier
				};
			}
			await fetch(dossierRequest, true);
			if (id) {
				for (const stockLine of state.stockChanges) {
					try {
						const stockRequestOptions: IRequestOptions = {
							url: urlFormat(endpoints[Endpoint.Stock], null),
							method: "POST",
							headers: {},
							data: stockLine
						};
						await fetch(stockRequestOptions, true);
						dispatch(removeStockChange(stockLine));
					} catch (err) {
						handleError(err);
						hasErrors = true;
					}
				}
			}
		} catch (err) {
			handleError(err);
			hasErrors = true;
		}

		if (!hasErrors) {
			setNavigationAllowed(true);
		}
		reduxDispatch(hideLoader());
	}

	function save(setNavigationAllowed?: (navigationAllowed: boolean) => void): void {
		let isGeneralValid: boolean = true;

		const messages: JSX.Element[] = [];
		if (!isNullOrEmpty(state.dossier.dossierNumber) && !state.dossier.dossierNumber.match(dossierNumberRegex)) {
			messages.push(t("fill_in_correct_format", { field: t("dossierNumber").toLowerCase(), example: "1234_12" }), <br />);
		}

		if (
			isNullOrEmpty(state.dossier.dossierNumber) ||
			!state.dossier.sellingDate ||
			!state.dossier.creationDate ||
			!state.dossier.customerId ||
			!state.dossier.constructionYardId ||
			!state.dossier.dossierManagerId ||
			!state.dossier.salesRepId ||
			!state.dossier.vatType
		) {
			isGeneralValid = false;

			if (isNullOrEmpty(state.dossier.dossierNumber)) {
				messages.push(t("fill_in_required_field", { field: t("dossierNumber").toLowerCase() }), <br />);
			}
			if (!state.dossier.sellingDate) {
				messages.push(t("fill_in_required_field", { field: t("soldOn").toLowerCase() }), <br />);
			}
			if (!state.dossier.creationDate) {
				messages.push(t("fill_in_required_field", { field: t("createdOn").toLowerCase() }), <br />);
			}
			if (!state.dossier.customerId) {
				messages.push(t("fill_in_required_field", { field: t("customer").toLowerCase() }), <br />);
			}
			if (!state.dossier.constructionYardId) {
				messages.push(t("fill_in_required_field", { field: t("constructionYard").toLowerCase() }), <br />);
			}
			if (!state.dossier.dossierManagerId) {
				messages.push(t("fill_in_required_field", { field: t("dossierManager").toLowerCase() }), <br />);
			}
			if (!state.dossier.salesRepId) {
				messages.push(t("fill_in_required_field", { field: t("salesRep").toLowerCase() }), <br />);
			}
			if (!state.dossier.vatType) {
				messages.push(t("fill_in_required_field", { field: t("vat").toLowerCase() }), <br />);
			}

			notify(t("error"), <React.Fragment>{messages}</React.Fragment>, "error");
		}

		dispatch(setSectionValid("general", isGeneralValid));

		const isInvoicingValid: boolean = state.dossier.depositType !== DepositType.Now || (state.dossier.depositType === DepositType.Now && state.dossier.depositAmountExclVat > 0);

		if (!isInvoicingValid) {
			notify(t("error"), <React.Fragment>{t("errorInvoicing")}</React.Fragment>, "error");
		}

		if (isGeneralValid && isInvoicingValid) {
			state.dossier.dossierNumber = state.dossier.dossierNumber.toUpperCase();
			resetIdsForNewEntities(state.dossier.documents);
			resetIdsForNewEntities(state.dossier.articles);
			resetIdsForNewEntities(state.dossier.contacts);
			resetIdsForNewEntities(state.dossier.remarks);
			resetIdsForNewEntities(state.dossier.communications);
			resetIdsForNewEntities(state.dossier.tasks);
			resetIdsForNewEntities(state.dossier.serviceItems);
			if (setNavigationAllowed) {
				saveWithNavigation(setNavigationAllowed);
			} else {
				if (!id) {
					callApi(dossierDispatch, Endpoint.Dossiers, "POST", null, state.dossier);
				} else {
					callApi(dossierDispatch, Endpoint.Dossiers, "PUT", { id }, state.dossier);
					each(state.stockChanges, (stockLine: IStockLine) => {
						callApi(stockDispatch, Endpoint.Stock, "POST", null, stockLine);
						dispatch(removeStockChange(stockLine));
					});
				}
			}
		}
	}

	function resetIdsForNewEntities<T extends IEntity>(entitities: T[]): void {
		each(entitities, (entity: T) => {
			if (entity.id <= 0) {
				entity.id = 0;
			}
		});
	}

	function onSubmit(event: FormEvent): void {
		event.stopPropagation();
		event.preventDefault();
	}

	return (
		<form noValidate onSubmit={onSubmit} className={style.editorForm}>
			<div className={style.editor + " d-flex flex-column"}>
				<div className={style.editorContainer + " flex-grow-1 d-flex"}>
					<PendingSavePrompt when={state.dataChanged} title={t("pending_changes")} onConfirm={save}>
						{t("ask_save")}
					</PendingSavePrompt>
					<Stepper currentStep={state.currentStep} wizardMode={!id} className={style.stepper} onChildClick={changeStep}>
						<Step key="general" title={t("general") + (state.sectionChangedStatus.general ? " *" : "")} type={state.sectionValidStatus.general ? undefined : "error"} />
						<Step key="documents" title={t("documents") + (state.sectionChangedStatus["documents"] ? " *" : "")} />
						<Step key="articles" title={t("articles") + (state.sectionChangedStatus["articles"] ? " *" : "")} />
						<Step key="contacts" title={t("contacts") + (state.sectionChangedStatus["contacts"] ? " *" : "")} />
						<Step key="remarks" title={t("remarks") + (state.sectionChangedStatus["remarks"] ? " *" : "")} />
						<Step key="communication" title={t("communication") + (state.sectionChangedStatus["communication"] ? " *" : "")} />
						<Step key="invoicing" title={t("invoicing") + (state.sectionChangedStatus["invoicing"] ? " *" : "")} />
						<Step key="planning" title={t("planning") + (state.sectionChangedStatus["planning"] ? " *" : "")} />
						<Step key="aftersales" title={t("aftersales") + (state.sectionChangedStatus["aftersales"] ? " *" : "")} />
					</Stepper>
					<div className={style.content + " flex-grow-1 d-flex flex-column"}>
						<div className={style.editorPage + (state.currentStep === 0 ? "" : " hidden")}>
							<General dossier={state.dossier} onChange={(newDossier: IDossier) => onChange("general", newDossier)} readOnly={readOnly} />
						</div>

						<div className={style.editorPage + (state.currentStep === 1 ? "" : " hidden")}>
							<Documents dossier={state.dossier} onChange={(newDossier: IDossier) => onChange("documents", newDossier)} readOnly={readOnly} />
						</div>
						<div className={style.editorPage + (state.currentStep === 2 ? "" : " hidden")}>
							<Articles
								dossier={state.dossier}
								onChange={(newDossier: IDossier) => {
									onChange("articles", newDossier);
									callApi(totalPriceDispatch, Endpoint.PriceTotal, "POST", null, newDossier.articles);
								}}
								stockChange={(count: number, productId: number) => dispatch(addStockChange(count, productId))}
								readOnly={readOnly}
							/>
						</div>
						<div className={style.editorPage + (state.currentStep === 3 ? "" : " hidden")}>
							<Contacts dossier={state.dossier} onChange={(newDossier: IDossier) => onChange("contacts", newDossier)} readOnly={readOnly} />
						</div>
						<div className={style.editorPage + (state.currentStep === 4 ? "" : " hidden")}>
							<Remarks dossier={state.dossier} onChange={(newDossier: IDossier) => onChange("remarks", newDossier)} readOnly={readOnly} />
						</div>
						<div className={style.editorPage + (state.currentStep === 5 ? "" : " hidden")}>
							<Communications
								dossier={state.dossier}
								onChange={(newDossier: IDossier) => onChange("communication", newDossier)}
								readOnly={readOnly}
								onAddContact={(newDossier: IDossier) => onChange("contacts", newDossier)}
							/>
						</div>
						<div className={style.editorPage + (state.currentStep === 6 ? "" : " hidden")}>
							<Invoicing dossier={state.dossier} onChange={(newDossier: IDossier) => onChange("invoicing", newDossier)} readOnly={readOnly} />
						</div>
						<div className={style.editorPage + (state.currentStep === 7 ? "" : " hidden")}>
							<Planning
								dossier={state.dossier}
								onChange={(newDossier: IDossier) => onChange("planning", newDossier)}
								updateArticlePickDate={() => dispatch(setSectionChanged("articles", true))}
								readOnly={readOnly}
							/>
						</div>
						<div className={style.editorPage + (state.currentStep === 8 ? "" : " hidden")}>
							<AfterSales dossier={state.dossier} onChange={(newDossier: IDossier) => onChange("aftersales", newDossier)} readOnly={readOnly} />
						</div>

						<div className={style.editorButtons + " d-flex"}>
							<div className="flex-grow-1 d-flex justify-content-center align-content-stretch">
								{!id && (
									<Button disabled={state.currentStep === 0} onClick={() => changeStep(state.currentStep - 1)}>
										{t("previous")}
									</Button>
								)}
								{!id && (
									<Button primary disabled={state.currentStep === 8} onClick={() => changeStep(state.currentStep + 1)}>
										{t("next")}
									</Button>
								)}
								{!readOnly && (
									<Button primary disabled={!state.dataChanged} onClick={() => save()}>
										{t("save")}
									</Button>
								)}
								{readOnly && (
									<Button primary onClick={() => history.goBack()}>
										{t("back")}
									</Button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

export default DossierEditor;
