import React from "react";

import { useTranslation } from "react-i18next";

import { Permission } from "../../../utils/enums";
import RoutedTabPanel from "../../global/routertabpanel";
import ConstructionYards from "./constructionyards";
import Contractors from "./contractors";
import Customers from "./customers";
import Projects from "./projects";
import Shops from "./shops";

import content from "../../../assets/content.png";

const MasterData: React.FC = () => {
	const { t } = useTranslation();

	return (
		<RoutedTabPanel title={t("masterData")} image={content}>
			<Customers reactKey="customers" label={t("customers")} url="/customers" tileIcon="las la-handshake" permissions={[Permission.CustomersRead]} />
			<Contractors reactKey="contractors" label={t("contractors")} url="/contractors" tileIcon="las la-drafting-compass" permissions={[Permission.ContractorsRead]} />
			<ConstructionYards reactKey="constructionyards" label={t("constructionYards")} url="/constructionyards" tileIcon="las la-hard-hat" permissions={[Permission.ConstructionYardsRead]} />
			<Projects reactKey="projects" label={t("projects")} url="/projects" tileIcon="las la-pencil-ruler" permissions={[Permission.ProjectsRead]} />
			<Shops reactKey="shops" label={t("shops")} url="/shops" tileIcon="las la-store-alt" permissions={[Permission.ShopsRead]} />
		</RoutedTabPanel>
	);
};

export default MasterData;